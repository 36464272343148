import React, { Component } from "react";
import IconSelector from "../../components/IconSelector";
import { SpsTextInput } from "@spscommerce/ui-react";
import { SpsButton, SpsCard } from "@spscommerce/ds-react";
import "./AppDetails.scss";

export default class AppDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            app: {},
            errors: {},
            disableForm: true,
        };
    }

    componentDidMount() {
        if (this.props.app) {
            this.setState({ app: this.props.app });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.app !== this.props.app) {
            this.setState({ app: this.props.app }, this.validateFields);
        }
    }

    handleIconChange = (iconData) => {
        this.setState((prev, props) => {
            const newApp = Object.assign({}, props.app, {
                iconName: iconData.icon || prev.app.icon,
                iconColor: iconData.color || prev.app.color,
            });
            return Object.assign({}, props, {
                app: newApp,
            });
        }, this.triggerOnChange);
    };

    handleDetailsChange = (e) => {
        if (!e || !e.target.name) {
            return;
        }
        const {
            target: { value, name },
        } = e;
        this.setState((prev) => {
            return {
                app: Object.assign({}, prev.app, { [name]: value }),
            };
        }, this.triggerOnChange);
    };

    triggerOnChange = () => {
        let { onChange } = this.props;
        if (!onChange) {
            onChange = (formData) => {
                console.error(
                    "you need a change handler function passed into the AppDetails component",
                );
            };
        }
        // communicate state back up to page
        onChange(this.state);
    };

    handleError = (fieldName, errorMessage) => {
        // This function receives errors from fields in the form
        // and allows field-level errors to be reacted to in this component
        const newErrors = { ...this.state.errors };
        newErrors[fieldName] = errorMessage;
        this.setState({ errors: newErrors });
    };

    validateFields = () => {
        const errorEntries = Object.entries(this.state.errors);
        const hasError = errorEntries.some((error) => error[1] !== "");
        if (this.state.app.name && !hasError) {
            // enable submit
            this.setState({ disableForm: false });
        } else {
            this.setState({ disableForm: true });
        }
    };

    handleInputValidation = (inputValueObject) => {
        return Boolean(inputValueObject.inputValue);
    };

    render() {
        const { onSave } = this.props;
        return (
            <SpsCard>
                <form noValidate>
                    <div className="container">
                        <div className="row app-details">
                            <div className="col col-2" />
                            <div className="col col-8">
                                <div className="sps-form-group app-name">
                                    <SpsTextInput
                                        name="name"
                                        labelId="app-name-label"
                                        inputLabel="App Name"
                                        placeholder="Provide a name for your app"
                                        value={this.state.app.name}
                                        onChange={this.handleDetailsChange}
                                        required
                                        customValidator={(inputValue) => {
                                            return this.handleInputValidation({
                                                name: "name",
                                                inputValue: inputValue,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="sps-form-group app-description">
                                    <SpsTextInput
                                        name="description"
                                        labelId="description-label"
                                        inputLabel="Description"
                                        placeholder="Provide a brief description of your app and its use (140 character limit)"
                                        value={this.state.app.description}
                                        onChange={this.handleDetailsChange}
                                        tag="textarea"
                                        rows="4"
                                    />
                                </div>
                            </div>
                            <div className="col col-2" />
                        </div>
                        <div className="row">
                            <div className="col col-2" />

                            <div className="col icon-section">
                                <label className="sps-form-group__label sps-form-group__label--description">
                                    App Icon
                                </label>
                                <div className="sps-form-control__description">
                                    Select an icon to represent your app. Click the arrow buttons to
                                    view more icon options and then choose a color.
                                </div>
                                <IconSelector
                                    rows={1}
                                    columnsPerRow={3}
                                    onChange={this.handleIconChange}
                                    iconName={this.state.app.iconName || "sps-icon-burst-bolt"}
                                    iconColor={this.state.app.iconColor || "orange200"}
                                />
                            </div>
                            <div className="col col-2" />
                        </div>

                        <div className="row save-detail">
                            <div className="col save-button">
                                <SpsButton
                                    type="button"
                                    kind="key"
                                    disabled={this.state.disableForm}
                                    onClick={onSave}
                                >
                                    Save and Continue
                                </SpsButton>
                            </div>
                        </div>
                    </div>
                </form>
            </SpsCard>
        );
    }
}
