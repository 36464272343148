import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

export class Wizard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expandedIndexes: props.initiallyExpanded ? [parseInt(props.initiallyExpanded)] : [0],
        };
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyPress);
    }

    componentDidUpdate(prevProps) {
        if (this.props.expandedItem !== prevProps.expandedItem) {
            this.moveToNextItem();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress);
    }

    handleKeyPress = (event) => {
        // todo
        // const currentCollapsedIndexes = this.state.expandedIndexes;
        const { key } = event;
        if (key === "Escape") {
            // todo
            // this.collapseItem(currentCollapsedIndexes)
        }
    };

    onWizardHeaderClick = (idx) => {
        const currentCollapsedIndexes = this.state.expandedIndexes;
        const foundAtIndex = currentCollapsedIndexes.indexOf(idx);
        if (foundAtIndex > -1) {
            this.collapseItem(currentCollapsedIndexes, idx);
        } else {
            this.expandItem(currentCollapsedIndexes, idx);
        }
        this.onClickUpdateURL(idx);
    };

    onClickUpdateURL = (idx) => {
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: `?expandIndex=${idx}`,
        });
    };

    moveToNextItem = (array = []) => {
        const currentCollapsedIndexes = this.state.expandedIndexes;
        const newItem = currentCollapsedIndexes[0] + 1;
        const newItemIndex = array.concat([newItem]);
        this.setState({
            expandedIndexes: newItemIndex,
        });
    };

    collapseItem = (array, index) => {
        const newCollapsedIndexes = array.filter((item) => item !== index);
        this.setState({
            expandedIndexes: newCollapsedIndexes,
        });
    };

    expandItem = (array, index, openItem = []) => {
        if (this.props.allowMultiple || array.length === 0) {
            const unCollapsedIndexes = array.concat([index]);
            this.setState({
                expandedIndexes: unCollapsedIndexes,
            });
        } else if (array.length > 0) {
            const newItemIndex = openItem.concat([index]);
            this.setState({
                expandedIndexes: newItemIndex,
            });
        }
    };

    filteredItems = () => {
        let originalElements = React.Children.toArray(this.props.children);
        let filteredElements = originalElements.filter((value, index, array) => {
            return index !== 2 && !this.props.includeRedirectURI;
        });
        const newElements = filteredElements.length > 0 ? filteredElements : originalElements;
        return newElements;
    };

    render() {
        let exceptIndex = this.state.expandedIndexes;
        let { initially, exceptKey = [], disableItems, expandAll } = this.props;

        return (
            <div className="app-wizard">
                {React.Children.map(this.filteredItems(), (child, index) => {
                    const isInExceptions =
                        exceptIndex.includes(index) || exceptKey.includes(child.key);
                    const initiallyCollapsed =
                        initially === "expand" ? isInExceptions : !isInExceptions;
                    return React.cloneElement(child, {
                        disableItems,
                        initiallyCollapsed,
                        expandAll,
                        compIndex: index,
                        onWizardHeaderClick: () => this.onWizardHeaderClick(index),
                    });
                })}
            </div>
        );
    }
}

export default withRouter(Wizard);

Wizard.propTypes = {
    initially: PropTypes.string.isRequired,
    allowMultiple: PropTypes.bool,
};
