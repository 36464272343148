import React from "react";

import { SpsCard } from "@spscommerce/ds-react";

import "./LoginCard.scss";

export default function LoginCardV2(props) {
    const { children, topShelf = "", className } = props;

    return (
        <SpsCard
            className={className ? `sps-login-card-ul ${className}` : "sps-login-card-ul"}
            style={{
                padding: 0,
            }}
        >
            <div className="sps-login-card__top-shelf">{topShelf ? topShelf() : null}</div>
            <div className="login-card-ul">{children}</div>
        </SpsCard>
    );
}
