import "./App.scss";

import React, { Fragment, useContext, useEffect } from "react";

import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { Redirect, Route, Switch } from "react-router-dom";

import { useGrowlers } from "@spscommerce/ds-react";
import { CommercePlatformUrl, SpsGrowlerArea } from "@spscommerce/ui-react";

import { DevCenterApi } from "./api/devcenter";
import AuthorizeApp from "./components/AuthorizeApp/AuthorizeApp";
import AuthorizeAppV2 from "./components/AuthorizeApp/AuthorizeAppV2";
import BannerAllUser from "./components/BannerAllUser/BannerAllUser";
import BannerTrialUser from "./components/BannerTrialUser/BannerTrialUser";
import { BlackBar } from "./components/BlackBar";
import FileNotFound from "./components/FileNotFound";
import PageBanner from "./components/PageBanner";
import { ProfileDropdown } from "./components/ProfileDropdown";
import ProtectedRoute from "./components/ProtectedRoute";
import SupplyPikeConsent from "./components/SupplyPikeConsent";
import ServiceStoplight from "./pages/ServiceStoplight";
import Footer from "./Footer";
import Nav from "./Nav";
import AppEditPage from "./pages/AppEditPage/AppEditPage";
import ApplicationsPage from "./pages/ApplicationsPage/ApplicationsPage";
import ContactInfo from "./pages/ContactInfo/ContactInfo";
import DocumentationPage from "./pages/DocumentationPage/DocumentationPage";
import DocumentationPageStoplight from "./pages/DocumentationPageStoplight/DocumentationPageStoplight";
import HomePage from "./pages/HomePage/HomePage";
import HomePageNotLoggedIn from "./pages/HomePage/HomePageNotLoggedIn";
import OfficeLocationPage from "./pages/LocationsPage/LocationsPage";
import LoginPage from "./pages/LoginPage/";
import ProfileLayout from "./pages/Profile/ProfileLayout";
import RSXLandingPage from "./pages/RsxUiPage/RSXLandingPage";
import RsxUiPage from "./pages/RsxUiPage/RsxUiPage";
import SignUpPage from "./pages/SignUpPage/";
import ToolsPage from "./pages/ToolsPage/ToolsPage";
import BannerIEWarning from "./components/BannerIEWarning/BannerIEWarning";
import {
    TermsOfUsePage,
    UserAgreementPageWithoutAcceptance,
} from "./pages/UserAgreementPage/UserAgreementPage";

import { PrivateRoutesContext } from "./contexts/privateRouteContext";
import UserGuideLandingPage from "./pages/UserGuides/UserGuideLandingPage";
import UserGuidePage from "./pages/UserGuides/UserGuidePage";

function getPageClassNames(currentUser, hasSession, hasRole, location) {
    // temp removing terms of service
    // TODO: reapply when currentUser cache stuff has been resolved
    /* if (currentUser) {
    return classnames("sps-page", {
      "nav-hidden": !currentUser.developer_center_tos_agreed_at
    });
  } */
    if (hasSession) {
        if (
            !currentUser ||
            (currentUser && !hasRole) ||
            location.pathname.includes("/login") ||
            location.pathname.includes("/signup") ||
            location.pathname.includes("/verify-org") ||
            location.pathname.includes("/supply-pike/consent")
        ) {
            return "collapse-top";
        }
    }
}

function AppRoutes(props) {
    const [growlers, createGrowler] = useGrowlers();
    const privateRoutesContext = useContext(PrivateRoutesContext);

    const devCenterApi = new DevCenterApi(props);
    const {
        initialRoute,
        token,
        currentUser,
        hasSession,
        environment,
        auth,
        hasRole,
        location,
        isTrialOrg,
        flags: {
            documentCollectionApi,
            inventoryApi,
            maintenanceMessage,
            showIeBanner: showIeBannerLDFlag,
            universalLoginExperience,
        },
    } = props;
    const noScrollToTopRoutes = ["docs", "system-integrations"];

    const isIEUser = !!window.navigator.userAgent.match(/MSIE|Trident/);

    const showIeBanner = isIEUser && showIeBannerLDFlag;

    useEffect(() => {
        if (!noScrollToTopRoutes.includes(location.pathname.split("/")[1])) {
            window.scrollTo(0, 0);
        }
    }, [location.pathname]);

    if (!token && privateRoutesContext.privateRoutes.includes(location.pathname)) {
        return auth.login({
            appState: window.location.hash,
        });
    }

    return (
        <Fragment>
            <Fragment>
                {growlers()}
                <Switch>
                    {/* This switch statement conditionally renders out the BlackBar for any route not explicitly defined below */}
                    <Route path="/login" />
                    <Route path="/signup" />
                    <Route path="/verify-org" />
                    <Route path="/supply-pike/consent" />
                    <Route
                        render={() => (
                            <Fragment>
                                {showIeBanner && <BannerIEWarning />}
                                <BlackBar className={showIeBanner ? "black-bar__show-ie-banner" : ""}>
                                    <ProfileDropdown currentUser={currentUser} />
                                </BlackBar>

                                {hasSession && !currentUser ? (
                                    <>
                                        <BannerAllUser
                                            className={showIeBanner ? "all-users-banner__show-ie-banner" : ""}
                                            maintenanceMessage={maintenanceMessage}
                                        />
                                        <PageBanner className={showIeBanner ? "page-banner__show-ie-banner" : ""} />
                                    </>
                                ) : (
                                    <>
                                        {isTrialOrg && <BannerTrialUser className={showIeBanner ? "trial-user-banner__show-ie-banner" : ""} />}
                                        <BannerAllUser
                                            className={showIeBanner ? "all-users-banner__show-ie-banner" : ""}
                                            maintenanceMessage={maintenanceMessage}
                                        />
                                    </>
                                )}
                            </Fragment>
                        )}
                    />
                </Switch>
                <div
                    className={`sps-page ${showIeBanner ? "sps-page__show-ie-banner" : ""} page-height ${getPageClassNames(
                        currentUser,
                        hasSession,
                        hasRole,
                        location,
                    )}`}
                >
                    <div className={`sps-body sps-body--collapse-600`}>
                        <CommercePlatformUrl>
                            <Fragment>
                                <Switch>
                                    {/* This switch statement conditionally renders out the NavBar for any route not explicitly defined below */}
                                    <Route path="/signup" />
                                    <Route path="/login" />
                                    <Route path="/verify-org" />
                                    <Route path="/supply-pike/consent" />
                                    <Route render={() => <Nav />} />
                                </Switch>
                                <Switch>
                                    <Route
                                        exact
                                        path="/"
                                        render={() => <Redirect to={initialRoute} />}
                                    />
                                    <Route path="/login" component={LoginPage} />
                                    <Route path="/signup" component={SignUpPage} />
                                    <Route
                                        path="/verify-org"
                                        exact
                                        render={(routeProps) => {
                                            if (universalLoginExperience) {
                                                return <AuthorizeAppV2 {...routeProps} />
                                            } else {
                                                return <AuthorizeApp {...routeProps} />
                                            }
                                        }}
                                    />
                                    <Route
                                        path="/supply-pike/consent"
                                        exact
                                        render={(routeProps) => {
                                            return <SupplyPikeConsent {...routeProps} />;
                                        }}
                                    />
                                    {currentUser && !hasRole ? (
                                        <Redirect to="/login/no-user-found" />
                                    ) : null}
                                    <Route
                                        exact
                                        path="/home"
                                        render={() => {
                                            if (hasSession && token) {
                                                return (
                                                    <HomePage
                                                        {...props}
                                                        devCenterApi={devCenterApi}
                                                        createGrowler={createGrowler}
                                                    />
                                                );
                                            }
                                            return <HomePageNotLoggedIn />;
                                        }}
                                    />
                                    <Route
                                        path="/applications"
                                        render={() => {
                                            return (
                                                <ApplicationsPage
                                                    {...props}
                                                    devCenterApi={devCenterApi}
                                                />
                                            );
                                        }}
                                    />
                                    <ProtectedRoute
                                        auth={auth}
                                        path="/app/:appId?"
                                        render={(routeProps) => {
                                            return (
                                                <AppEditPage
                                                    {...props}
                                                    {...routeProps}
                                                    createGrowler={createGrowler}
                                                />
                                            );
                                        }}
                                    />
                                    <ProtectedRoute
                                        auth={auth}
                                        path="/sso"
                                        render={() => {
                                            return <Redirect to="/home" />;
                                        }}
                                    />
                                    <Route
                                        path="/docs"
                                        render={() => {
                                            return (
                                                <DocumentationPage
                                                    documentCollectionApi={documentCollectionApi}
                                                    inventoryApi={inventoryApi}
                                                    token={token}
                                                    environment={environment}
                                                    hasSession={hasSession}
                                                    devCenterApi={devCenterApi}
                                                    createGrowler={createGrowler}
                                                />
                                            );
                                        }}
                                    />
                                    <Route
                                        path="/api-docs"
                                        render={() => {
                                            return (
                                                <DocumentationPageStoplight
                                                    documentCollectionApi={documentCollectionApi}
                                                    inventoryApi={inventoryApi}
                                                    token={token}
                                                    environment={environment}
                                                    hasSession={hasSession}
                                                    devCenterApi={devCenterApi}
                                                    createGrowler={createGrowler}
                                                />
                                            );
                                        }}
                                    />
                                    <Route
                                        path="/stoplight-docs/:serviceSlug"
                                        render={(routeProps) => {
                                            return (
                                                <ServiceStoplight
                                                    serviceSlug={routeProps.match.params.serviceSlug}
                                                    devCenterApi={devCenterApi}
                                                    createGrowler={createGrowler}
                                                    {...routeProps}
                                                />
                                            );
                                        }}
                                    />
                                    <Route
                                        path="/rsx"
                                        render={() => {
                                            if (hasSession && token) {
                                                return (
                                                    <RsxUiPage
                                                        token={token}
                                                        environment={environment}
                                                        hasSession={hasSession}
                                                    />
                                                );
                                            } else {
                                                return <RSXLandingPage />;
                                            }
                                        }}
                                    />
                                    <Route
                                        path="/user-guides"
                                        render={() => {
                                            if (hasSession && token) {
                                                return (
                                                    <UserGuidePage
                                                        token={token}
                                                        environment={environment}
                                                        hasSession={hasSession}
                                                    />
                                                );
                                            } else {
                                                return <UserGuideLandingPage />;
                                            }
                                        }}
                                    />
                                    <ProtectedRoute
                                        auth={auth}
                                        path="/tools"
                                        render={(props) => {
                                            return <ToolsPage token={token} {...props} />;
                                        }}
                                    />
                                    <Route
                                        exact
                                        path="/user-agreement"
                                        component={UserAgreementPageWithoutAcceptance}
                                    />
                                    <Route exact path="/locations" component={OfficeLocationPage} />
                                    <Route
                                        exact
                                        path="/contact-info"
                                        render={() => {
                                            return <ContactInfo environment={environment} />;
                                        }}
                                    />
                                    <Route
                                        exact
                                        path="/contact-info/:success"
                                        render={() => {
                                            return <ContactInfo environment={environment} />;
                                        }}
                                    />
                                    <Route exact path="/terms-of-use" component={TermsOfUsePage} />
                                    <Route
                                        path="/profile"
                                        render={() => {
                                            if (hasSession && token) {
                                                return (
                                                    <ProfileLayout
                                                        token={token}
                                                        environment={environment}
                                                        hasSession={hasSession}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <div className="col-12">
                                                        <FileNotFound
                                                            heading="You need to sign-in."
                                                            subheading="Only logged-in users can check their profile."
                                                        />
                                                    </div>
                                                );
                                            }
                                        }}
                                    />
                                    <Route
                                        render={() => {
                                            return (
                                                <div className="col-12">
                                                    <FileNotFound
                                                        heading="The page you're looking for can't be found."
                                                        subheading="You may have mistyped the URL or the page has been deleted or moved."
                                                    />
                                                </div>
                                            );
                                        }}
                                    />
                                </Switch>
                            </Fragment>
                        </CommercePlatformUrl>
                    </div>
                </div>
                <Switch>
                    <Route path="/signup" />
                    <Route path="/docs" />
                    <Route path="/rsx" />
                    <Route path="/profile" />
                    <Route path="/verify-org" />
                    <Route path="/supply-pike/consent" />
                    <Route path="/login" />
                    <Route render={() => <Footer />} />
                </Switch>
                <SpsGrowlerArea />
            </Fragment>
        </Fragment>
    );
}

export default withLDConsumer()(AppRoutes);
