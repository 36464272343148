import React from "react";
import classnames from "classnames";

import { truncate } from "../../utils/Truncate";
import "./SpsTruncate.scss";

const truncateText = (text, length) => {
    if (typeof text !== "string") {
        throw Error("Children must be a string for the SpsTruncate component");
    }
    return truncate(text, length);
};

export default function SpsTruncate(props) {
    const { truncateWith, children } = props;

    const text = truncateText(props.children, props.length);
    let truncateClass = "sps-truncate";
    const truncateTypes = {
        ellipses: "sps-truncate-ellipses",
    };

    if (typeof truncateWith === "string" && truncateTypes[truncateWith]) {
        truncateClass =
            text.length !== children.length
                ? classnames(truncateClass, truncateTypes[truncateWith])
                : classnames(truncateClass);
    } else {
        throw Error(
            `${truncateWith} is not a valid choice, please select one of the following: ${Object.keys(
                truncateTypes,
            )}`,
        );
    }

    return (
        <span className={truncateClass}>
            {text}
            {typeof truncateWith === "function" && text.length !== children.length
                ? truncateWith()
                : null}
        </span>
    );
}
