import React from "react";
import { SpsButton } from "@spscommerce/ds-react";
import { SpsGrowlerFactory } from "@spscommerce/ui-react";

const copyCode = ({ textToCopy, disabled = false }) => {
    const onClick = () => {
        navigator.clipboard.writeText(textToCopy);
        const msg = "Copied to Clipboard";
        SpsGrowlerFactory.success(msg, {
            timeoutDuration: 5000,
            preset: "success",
            fade: true,
        });
    };

    return (
        <SpsButton disabled={disabled} className="copy-code-button" kind="link" onClick={onClick}>
            <i className="sps-icon sps-icon-duplicate" />
            Copy Code
        </SpsButton>
    );
};

export default copyCode;
