import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

import { SpsCard, SpsPageTitle, SpsZeroState, SpsButton, SpsGrowler } from "@spscommerce/ds-react";
import { withCommercePlatform } from "@spscommerce/ui-react";

import SchemaDocument from "../../components/SchemaDocument/SchemaDocument";
import Loading from "../../components/Loading";
import config from "../../App.config";
import "./ViewFieldRequirementsPage.scss";

export function ViewFieldRequirementsPage(props) {
    const [loading, setLoading] = useState(false);
    const [growlerIsRendered, setGrowlerIsRendered] = useState(false);
    const [growlerMessage, setGrowlerMessage] = useState("");
    const [schemaConfig, setSchemaConfig] = useState({
        url: null,
        requestType: null,
        requestBody: null,
    });
    const [hasSelectedRetailers, SetHasSelectedRetailers] = useState(true);
    const [retailers, setRetailers ] = useState([]);
    const params = getQueryParams();
    const {labels, packingSlips} = params;
    useEffect(() => {
        fetchData();
    },[]);

    useEffect(() => {
        fetchData();
    },[props.commercePlatform.token])

    const fetchData = () => {
        setLoading(true);
        fetchSelectedRetailers();
        buildSchemaEndpointUrl();
    };

    const buildSchemaEndpointUrl = () => {
        // View Single Shipping Label or Packing Slip
        // https://internal.api.test.spsapps.net/consolidate/v1/packing-slip/consolidate
        const env = props.commercePlatform.environment;
        const host = config.kubeRoot[env];
        let request;
        if (packingSlips.length === 0 && labels.length === 1) {
            // for single shipping label
            request = {
                url: `${host}/label/v1/${labels[0]}/design-schema?flatten=true`,
                requestType: "get",
            };
            setSchemaConfig(request);
        } else if (packingSlips.length === 1 && labels.length === 0) {
            // for single packing slips
            request = {
                url: `${host}/packing-slip/v1/${packingSlips[0]}/design-schema?flatten=true`,
                requestType: "get",
            };
            setSchemaConfig(request);
        } else if (packingSlips.length === 0 && labels.length > 1) {
            // for multiple labels
            request = {
                url: `${host}/label/v1/consolidate`,
                requestType: "post",
                requestBody: {
                    templates: [],
                },
            };
            request.requestBody.templates = labels.map((labelId) => {
                return { id: labelId };
            });
            setSchemaConfig(request);
        } else if (packingSlips.length > 1 && labels.length === 0) {
            // for multiple packing slips
            request = {
                url: `${host}/packing-slip/v1/consolidate`,
                requestType: "post",
                requestBody: {
                    templates: [],
                },
            };
            request.requestBody.templates = packingSlips.map((packingId) => {
                return { id: packingId };
            });
            setSchemaConfig(request);
        } else {
            // show zero state
            SetHasSelectedRetailers(false);
        }
    };

    const fetchSelectedRetailers = () => {
        const { environment, token } = props.commercePlatform;
        const env = environment;
        const host = config.kubeRoot[env];

        if (environment && token) {
            try {
                const headers = {
                    Authorization: `Bearer ${token}`,
                };

                let endpoints;
                // Make sure we're avoiding an error state since packing slips
                // and labels can't be mixed on this page.
                if (packingSlips.length > 0 && labels.length === 0) {
                    endpoints = packingSlips.map((id) => {
                        return axios({
                            method: "get",
                            headers,
                            url: `${host}/packing-slip/v1/${id}`,
                        });
                    });
                } else if (labels.length > 0 && packingSlips.length === 0) {
                    endpoints = labels.map((id) => {
                        return axios({
                            method: "get",
                            headers,
                            url: `${host}/label/v1/${id}`,
                        });
                    });
                } else {
                    // This should never happen but we want to report the error just in case
                    console.error(
                        "Received both packing slips and shipping labels, cannot correctly construct endpoint calls.",
                    );
                }

                if (endpoints.length > 0) {
                    // Get retailers from packing slips and shipping labels
                    axios.all(endpoints)
                    .then(
                        (response) => {
                            // combine all returned responses into a single array
                            let retailers = response.reduce((result, current) => {
                                return [...result, current.data.ownerName];
                            }, []);

                            // de duplicate retailer list
                            retailers = [...new Set(retailers)];
                            setRetailers(retailers);
                            setLoading(false);
                        },
                        (error) => {
                            setEmptyState();
                            return;
                        },
                    );
                }
            } catch (error) {
                setEmptyState();
                const msg =
                    "An error has occurred while getting retailers, please try again later.";
                setGrowlerIsRendered(true);
                setGrowlerMessage(msg);
            }
        }
    };

    const setEmptyState = () => {
        setLoading(false);
        setRetailers([]);
    };

    function getQueryParams() {
        // Get search paramaters from URL if present
        const { search } = props.location;
        const whitelistedParams = ["slip", "label"];

        if (search) {
            return decodeURIComponent(search.slice(1)) // Remove ? from query string
                .split("&")
                .map((p) => p.split("="))
                .filter((arr) => whitelistedParams.includes(arr[0]))
                .reduce(
                    (result, arr) => {
                        const [param, value] = arr;
                        switch (param) {
                            case "slip":
                                result["packingSlips"].push(value);
                                break;
                            case "label":
                                result["labels"].push(value);
                                break;
                            default:
                                break;
                        }
                        return result;
                    },
                    { packingSlips: [], labels: [] },
                );
        } else {
            return { packingSlips: [], labels: [] };
        }
    };

    const renderSelectedRetailers = () => {
        return (
            <SpsCard headerTitle="SELECTED RETAILERS">
                {loading && (
                    <div className="retailer-panel--loader">
                        <Loading mode="small" />
                    </div>
                )}
                {!loading &&
                    retailers &&
                    retailers.map((retailer, i) => (
                        <p key={i} className="gray500 sps-text-semibold">
                            {retailer}
                        </p>
                    ))}
            </SpsCard>
        );
    };

    const renderSchema = () => {
        const { commercePlatform } = props;
        const { url, requestType, requestBody } = schemaConfig;
        return (
            url && (
                <SchemaDocument
                    schemaUrl={url}
                    requestType={requestType}
                    requestBody={requestBody}
                    commercePlatform={commercePlatform}
                    needsAuth
                />
            )
        );
    };
    
    const { history } = props;
    
    return (
        <>
            <div className="view-field-requirements">
                {hasSelectedRetailers ? (
                    <div className="container">
                        <SpsButton
                            kind="link"
                            icon="arrow-left-circle"
                            onClick={() => history.goBack()}
                        >
                            Back to Packing Slip and Shipping Label Browser
                        </SpsButton>
                        <SpsPageTitle>Schema for Selected Retailers</SpsPageTitle>
                        <div className="row mt-3">
                            <div className="col-9">{renderSchema()}</div>
                            <div className="col-3">{renderSelectedRetailers()}</div>
                        </div>
                    </div>
                ) : (
                    <SpsZeroState
                        heading="No label or packing slips were selected"
                        artwork="https://cdn.prod.spsc.io/web/framework/assets/18.03.02/images/icon-error.svg"
                    >
                        <SpsButton kind="confirm" onClick={() => history.goBack()}>
                            Go back to shipping browser
                        </SpsButton>
                    </SpsZeroState>
                )}
            </div>
            {growlerIsRendered && (
                <SpsGrowler
                    kind="error"
                    onClose={() => {
                        setGrowlerIsRendered(false);
                    }}
                >
                    {growlerMessage}
                </SpsGrowler>
            )}
        </>
    );    
}

export default withCommercePlatform(withRouter(ViewFieldRequirementsPage));
