import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  SpsCard,
  SpsForm,
  SpsValidators,
  useForm,
  formControl,
  formGroup,
  SpsLabel,
  SpsTextInput,
  SpsButtonGroup,
  SpsButton,
  useGrowlers,
  SpsSelect,
} from "@spscommerce/ds-react";
import { ButtonKind, ButtonType } from "@spscommerce/ds-shared";
import ReactPlaceholder from "react-placeholder";
import { AUTH_FLOW_OAUTH_IMPLICIT } from "@spscommerce/ui-react";
import ProfileImage from "./ProfileImage/ProfileImage";
import Footer from "../../Footer";

function ProfileDetails({
  commercePlatform: {
    identityServiceUrl,
    token,
    currentUser,
    refreshCurrentUser
  } = {}
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [growlers, createGrowler] = useGrowlers();
  const [userData, setUserData] = useState({});
  const [preferredOrg, setPreferredOrg] = useState();
  const [preferredOrgOptions, setPreferredOrgOptions] = useState();
  const preferredOrgNoneOption = {
    id:null,
    namespace: "",
    organization_name: "None",
    organization_site: ""
  }

  const form = useForm(
    formGroup({
      firstName: formControl("", {
        validators: [SpsValidators.required, SpsValidators.maxLength(60)]
      }),
      lastName: formControl("", {
        validators: [SpsValidators.required, SpsValidators.maxLength(60)]
      }),
      jobTitle: formControl(""),
      preferredOrg: formControl(),
    })
  );

  useEffect(
    () => {
      const user = {
        firstName: currentUser.first_name,
        lastName: currentUser.last_name,
        jobTitle: currentUser.job_title
      };
      setUserData(user);
      setPreferredOrgOptions([...currentUser.organizations, preferredOrgNoneOption])
      setPreferredOrg(currentUser.preferred_org_id==null ? preferredOrgNoneOption : currentUser.organizations.find(org => org.id === currentUser.preferred_org_id));
      form.setValue(user);
    },
    [currentUser]
  );

  function isFormValid() {
    const formValues = form.getValue();
    if (formValues.firstName === "" || formValues.lastName === "") {
      return false;
    } else {
      return true;
    }
  }

  const preferredOrgChange = (event) => {
    setPreferredOrg(event.target.value);
  }

  async function updateProfile() {
    if (form.isValid()) {
      const payload = {
        first_name: form.getValue().firstName,
        last_name: form.getValue().lastName,
        job_title: form.getValue().jobTitle,
        email: currentUser.email,
        preferred_org_id: preferredOrg.id, 
      };
      const url = `${identityServiceUrl}/identity/v3/users/${
        currentUser.id
      }/profile/`;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      };

      try {
        setIsSubmitting(true);
        await axios.patch(url, payload, config);
        createGrowler({
          kind: "success",
          title: "Personal Information saved",
          content: () => "Your personal information was successfully saved."
        });
        setTimeout(() => {
          refreshCurrentUser(AUTH_FLOW_OAUTH_IMPLICIT);
        }, 500);
      } catch (error) {
        createGrowler({
          kind: "error",
          title: "Error saving Personal Information",
          content: () => "Your personal information was not saved."
        });
      } finally {
        setIsSubmitting(false);
      }
    }
  }

  return (
    <>
      {growlers()}
      <div className="personal-info col-9 docs-main-content">
        <div className="row">
          <div className="col-8">
            <div className="sps-docs-container-markdown">
              <SpsForm formGroup={form} onSubmit={updateProfile}>
                <SpsCard
                  className="sps-docs-markdown"
                  headerTitle="Personal Information"
                  footer={() => {
                    return (
                      <SpsButtonGroup className="text-right">
                        <SpsButton
                          kind={ButtonKind.DEFAULT}
                          onClick={() => {
                            form.reset(userData);
                          }}
                        >
                          Cancel
                        </SpsButton>
                        <SpsButton
                          disabled={!isFormValid() || form.isPristine()}
                          kind={ButtonKind.CONFIRM}
                          type={ButtonType.SUBMIT}
                          spinning={isSubmitting}
                        >
                          Save
                        </SpsButton>
                      </SpsButtonGroup>
                    );
                  }}
                >
                  <ReactPlaceholder ready={true}>
                    <div className="sps-row">
                      <div className="col-6">
                        <SpsLabel
                          for={form.get("firstName")}
                          errors={() =>
                            (form.get("firstName").hasError("required") &&
                              "This field is required") ||
                            (form.get("firstName").hasError("maxLength") &&
                              "Name cannot be longer than 60 characters.")
                          }
                        >
                          First Name
                        </SpsLabel>
                        <SpsTextInput formControl={form.get("firstName")} />
                      </div>
                      <div className="col-6">
                        <SpsLabel
                          for={form.get("lastName")}
                          errors={() =>
                            (form.get("lastName").hasError("required") &&
                              "This field is required") ||
                            (form.get("lastName").hasError("maxLength") &&
                              "Name cannot be longer than 60 characters.")
                          }
                        >
                          Last Name
                        </SpsLabel>
                        <SpsTextInput formControl={form.get("lastName")} />
                      </div>
                    </div>
                    <div className="sps-row">
                      <div className="col-6">
                        <SpsLabel for={form.get("jobTitle")}>Job Title</SpsLabel>
                        <SpsTextInput formControl={form.get("jobTitle")} />
                      </div>
                      <div className="col-6">
                        <SpsLabel for={form.get("preferredOrg")}>Preferred Organization</SpsLabel>
                        <SpsSelect 
                          options={preferredOrgOptions} 
                          value={preferredOrg} 
                          textKey="organization_name" 
                          onChange={preferredOrgChange} 
                          formControl={form.get("preferredOrg")}
                          notClearable
                        />
                      </div>
                    </div>
                  </ReactPlaceholder>
                </SpsCard>
              </SpsForm>
            </div>
          </div>
        <div className="col-4">
          <ProfileImage currentUser={currentUser} />
        </div>
      </div>
        <Footer/>
      </div>
    </>
  );
}

export default ProfileDetails;
