import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./IconSelector.scss";

const iconBoxWidth = 106;
const iconBoxHeight = 78;
export class IconSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            left: 0,
            disableRight: false,
            disableLeft: true,
            page: 1,
        };
    }

    getPageData = (colsPerRow, rows) => {
        return Math.ceil(IconSelector.fontIcons.length / (colsPerRow * rows));
    };

    static fontIcons = [
        "sps-icon-burst-bolt",
        "sps-icon-key",
        "sps-icon-bolt",
        "sps-icon-shopping-cart",
        "sps-icon-credit-card",
        "sps-icon-barcode",
        "sps-icon-item-tag",
        "sps-icon-search",
        "sps-icon-qr-code",
        "sps-icon-database",
        "sps-icon-code-fork",
        "sps-icon-info-circle",
        "sps-icon-lightbulb",
        "sps-icon-heart",
        "sps-icon-star",
        "sps-icon-dashboard",
        "sps-icon-drill",
        "sps-icon-wrench",
        "sps-icon-handshake",
        "sps-icon-group",
        "sps-icon-rocket",
        "sps-icon-building",
        "sps-icon-globe",
        "sps-icon-truck-shipping",
        "sps-icon-chart-line",
        "sps-icon-chart-area",
        "sps-icon-chart-pie",
    ];

    colors = ["blue200", "blue400", "purple200", "orange200", "gray500", "green300"];

    /**
     * eventHandler for left button
     * @param {Event} - event from click
     */
    advanceScroller = (event) => {
        this.moveScroller("right", this.props.columnsPerRow);
    };

    /**
     * eventHandler for right button
     * @param {Event} - event from click
     */
    recedeScroller = (event) => {
        this.moveScroller("left", this.props.columnsPerRow);
    };

    /**
     * event handler to set selected icon prop on state
     * also triggers passed in prop function to convey
     * important state props to parent
     * (onChage is coming from the parent component as a prop)
     * @param {Event} - event that contains item that triggered event
     */
    clickHandler = (event) => {
        this.props.onChange({
            icon: event.target.dataset.icon,
            color: this.props.iconColor,
        });
    };

    /**
     * event handler to update selected color
     * event.target has attribute `data-color="some color"`
     * used to communicate selected color
     * also triggers passed in prop function to convey
     * important state props to parent
     * @param {Event} - event that contains item that triggered event
     */
    colorChangeHandler = (event) => {
        this.props.onChange({
            icon: this.props.iconName,
            color: event.target.dataset.color,
        });
    };

    /**
     * utility function to move iconSelector block of items
     * @param {string} direction - The direction the scroller will move.
     * @param {integer} intemsPerclick - How many items should move per click
     */
    moveScroller = (direction, itemsPerClick) => {
        const pages = this.getPageData(this.props.columnsPerRow, this.props.rows);
        const widthToMove =
            direction === "left" ? itemsPerClick * iconBoxWidth * -1 : itemsPerClick * iconBoxWidth;
        this.setState((prev, props) => {
            const newLeft = prev.left + widthToMove;
            const newPageIncrement = direction === "left" ? 1 : -1;
            const disableRightButton = pages === prev.page + newPageIncrement;
            const disableLeftButton = newLeft === 0;

            return {
                page: prev.page + newPageIncrement,
                left: newLeft,
                disableLeft: disableLeftButton,
                disableRight: disableRightButton,
            };
        });
    };
    static determinePage = (iconName, rows, columnsPerRow) => {
        const itemsPerPage = rows * columnsPerRow;
        const iconIndex = IconSelector.fontIcons.indexOf(iconName);
        const pageWithMatchingIcon = Math.ceil((iconIndex + 1) / itemsPerPage);
        return pageWithMatchingIcon;
    };

    static getDerivedStateFromProps(nextProps, state) {
        if (nextProps.iconName !== state.lastIconName) {
            const newPage = IconSelector.determinePage(
                nextProps.iconName,
                nextProps.rows,
                nextProps.columnsPerRow,
            );
            const newLeft = (newPage - 1) * (nextProps.columnsPerRow * iconBoxWidth) * -1;
            const disableRightButton =
                newPage ===
                Math.floor(IconSelector.fontIcons.length / (nextProps.rows * nextProps.columnsPerRow));
            const disableLeftButton = newLeft === 0;

            return {
                page: newPage,
                left: newLeft,
                disableLeft: disableLeftButton,
                disableRight: disableRightButton,
                lastIconName: nextProps.iconName,
            };
        }
        return null;
    }
    render() {
        const pages = this.getPageData(this.props.columnsPerRow, this.props.rows);
        const iconObjects = IconSelector.fontIcons.map((icon) => {
            return { class: icon, active: false };
        });
        const containerClasses = classnames("iconSelectorContainer", {
            grid9: this.props.rows === 3 && this.props.columnsPerRow === 3,
            row3: this.props.rows === 1 && this.props.columnsPerRow === 3,
        });
        const windowWidth = this.props.columnsPerRow * iconBoxWidth;
        const windowHeight = this.props.rows * iconBoxHeight;
        const sliderWidth = pages * windowWidth;
        return (
            <Fragment>
                <div className="iconSelectorWrapper">
                    <button
                        type="button"
                        className="nav-buttons"
                        onClick={this.advanceScroller}
                        disabled={this.state.disableLeft}
                    >
                        <i className="sps-icon sps-icon-chevron-left" />
                    </button>
                    <div
                        className={containerClasses}
                        style={{ width: windowWidth + 2, height: windowHeight }}
                    >
                        <div
                            className="iconSelector"
                            style={{
                                left: this.state.left,
                                width: sliderWidth,
                                height: windowHeight,
                            }}
                        >
                            {iconObjects.map((icon) => {
                                const classes = classnames("icon", this.props.iconColor, {
                                    active: this.props.iconName === icon.class,
                                });
                                return (
                                    <button
                                        type="button"
                                        className={classes}
                                        key={`${icon.class}`}
                                        onClick={this.clickHandler}
                                    >
                                        <i
                                            className={`${icon.class} sps-icon`}
                                            data-icon={`${icon.class}`}
                                        />
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                    <button
                        type="button"
                        className="nav-buttons"
                        onClick={this.recedeScroller}
                        disabled={this.state.disableRight}
                    >
                        <i className="sps-icon sps-icon-chevron-right" />
                    </button>
                </div>
                <div className="color-picker">
                    {this.colors.map((color) => {
                        return (
                            <button
                                type="button"
                                key={color}
                                data-color={color}
                                onClick={this.colorChangeHandler}
                                className={`selector ${color}`}
                            />
                        );
                    })}
                </div>
            </Fragment>
        );
    }
}

IconSelector.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default IconSelector;
