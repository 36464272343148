import React from "react";

import {
    SpsContentRow,
    SpsContentRowCol,
    SpsContentRowExpansion,
    SpsSelect,
} from "@spscommerce/ds-react";

import CopyCode from "../SwaggerDocumentation/CopyCode";
import { getLanguageList, generateCode } from "../../utils";
import "./CodeRequestRow.scss";

function CodeRequestRow({
    name,
    url,
    method,
    header,
    body,
    className,
    fixLanguage,
    code = "",
    copyCode,
}) {
    const languages = code ? [] : getLanguageList();
    const [loading, setLoading] = React.useState();
    const [language, setLanguage] = React.useState(languages[1]);
    const [codeExample, setCodeExample] = React.useState(code);

    React.useEffect(() => {
        (async function () {
            if (language) {
                setLoading(true);
                setCodeExample(
                    await generateCode(language.key, language.variants[0].key, {
                        url,
                        method,
                        header,
                        body,
                    }),
                );
                setLoading(false);
            }
        })();
    }, [language]);

    return (
        <SpsContentRow className={className}>
            <SpsContentRowCol>
                <div className="fs-14 lh-20">{name || "Call"}</div>
            </SpsContentRowCol>
            {code && fixLanguage ? (
                <SpsContentRowCol style={{ width: "8.4rem" }}>
                    <div className="text-center">{fixLanguage}</div>
                </SpsContentRowCol>
            ) : code ? (
                <></>
            ) : (
                <SpsContentRowCol style={{ width: "8.4rem" }}>
                    <SpsSelect
                        notClearable
                        options={languages}
                        onChange={(e) => {
                            setLanguage(e.target.value);
                        }}
                        textKey="label"
                        value={language}
                    />
                </SpsContentRowCol>
            )}
            {copyCode === !false ? (
                <SpsContentRowCol style={{ width: "7.4rem" }}>
                    <CopyCode textToCopy={codeExample} disabled={!codeExample} />
                </SpsContentRowCol>
            ) : (
                <></>
            )}

            <SpsContentRowExpansion>
                <pre className="m-0 dcui-code-snippet">
                    {loading
                        ? "Generating code example..."
                        : codeExample
                        ? codeExample
                        : "Please select the language..."}
                </pre>
            </SpsContentRowExpansion>
        </SpsContentRow>
    );
}

export { CodeRequestRow };
export default CodeRequestRow;
