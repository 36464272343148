import React, { useEffect, Fragment } from "react";
import axios from "axios";

import { Link } from "react-router-dom";

import { SpsButton, usePatchReducer } from "@spscommerce/ds-react";
import { withCommercePlatform, SpsTextInput } from "@spscommerce/ui-react";

export function validateEmail(email) {
    /* eslint-disable */
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    /* eslint-enable */
    if (reg.test(email)) {
        return false;
    } else {
        return true;
    }
}

export function ResetPasswordWithoutCommercePlatform(props) {
    const [state, patchState] = usePatchReducer({
        requestMade: false,
        email: "",
        disabled: true,
        requestErred: false,
    });

    useEffect(() => {
        const isDisabled = validateEmail(state.email);
        patchState({ disabled: isDisabled });
    }, [state.email]);

    async function resetPassword() {
        const {
            commercePlatform: { identityServiceUrl },
        } = props;
        const url = `${identityServiceUrl}/identity/users/forgot-password/`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const body = {
            email_address: state.email,
            reset_destination: "devcenter",
        };
        try {
            await axios.post(url, body, config);
            patchState({ requestMade: true });
        } catch (err) {
            console.error(err);
            patchState({ requestMade: true, requestErred: true });
        }
    }

    function handleInputChange(e) {
        patchState({ [e.target.name]: e.target.value });
    }

    function login(e) {
        const {
            commercePlatform: { auth },
        } = props;
        e.preventDefault();
        auth.login({ appState: "#/home" });
    }

    return (
        <>
            {!state.requestMade ? (
                <>
                    <div className="sps-logo-ul">
                        <img
                            src="https://www.spscommerce.com/wp-content/uploads/2018/07/logo-blue.svg"
                            alt="sps commerce logo"
                        />
                    </div>
                    <h1 className="login-content-body__header">Reset Password</h1>
                    <p className="login-content-body__text">
                        Enter your email address and we will send you an email to reset your
                        password.
                    </p>
                    <label className="login-content-body__form-label sps-form-group__label">
                        Email Address
                    </label>
                    <SpsTextInput
                        className="sps-form-control login-content-body__form-input"
                        placeholder="name@email.com"
                        value={state.email}
                        name="email"
                        onChange={handleInputChange}
                    />
                    <div className="login-content-body__form-buttons-ul">
                        <SpsButton
                            style={{ width: "100%" }}
                            className="login-content-body__form-submit-ul mb-3"
                            kind="confirm"
                            onClick={resetPassword}
                            disabled={state.disabled}
                        >
                            Send a Reset Link
                        </SpsButton>
                        <a onClick={login} href="/" style={{ fontWeight: "600" }}>
                            Return to Sign In page
                        </a>
                    </div>
                </>
            ) : (
                <>
                    {!state.requestErred ? (
                        <>
                            <div className="password-reset-complete">
                                <img src="assets/signup-complete-page.svg" alt="SPS Commerce" />
                                <h1>Reset email sent</h1>
                                <p>Check your inbox for a link to create a new password.</p>
                                <div className="login-content-body__form-buttons mt-3">
                                    <a onClick={login} href="/" style={{ fontWeight: "600" }}>
                                        Return to Sign In page
                                    </a>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="password-reset-complete">
                                <img src="assets/password-reset-error.svg" alt="SPS Commerce" />
                                <h1 className="mb-0">Oops! We’re having a problem on our end.</h1>
                                <p>
                                    Please try again later or{" "}
                                    <Link to="/contact-info?contactSupport=true">
                                        {" "}
                                        contact SPS support{" "}
                                    </Link>{" "}
                                    if the problem persists.
                                </p>
                                <div className="login-content-body__form-buttons mt-3">
                                    <a onClick={login} href="/" style={{ fontWeight: "600" }}>
                                        Return to Sign In page
                                    </a>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
}

export default withCommercePlatform(ResetPasswordWithoutCommercePlatform);
