import React from "react";
import Parser from "html-react-parser";
import { SpsCard } from "@spscommerce/ds-react/";

import { uuid } from "../../utils/utils.js";
import ResponseCodeRow from "./ResponseCodeRow";

import "./SwaggerDocumentation.scss";

const SwaggerResponseCodesComponent = function ({
    codes,
    modifySections = {},
    paragraph = "",
    heading,
    className = "",
}) {
    return (
        <SpsCard className="card-margin">
            <h4>{heading}</h4>
            {modifySections.RESPONSE_CODE && <p>{Parser(modifySections.RESPONSE_CODE)}</p>}
            {paragraph && <p>{Parser(paragraph)}</p>}
            <React.Fragment>
                {codes.map((responseCode) => {
                    return (
                        <ResponseCodeRow
                            className={className}
                            key={uuid()}
                            responseCode={responseCode}
                        ></ResponseCodeRow>
                    );
                })}
            </React.Fragment>
        </SpsCard>
    );
};

export default SwaggerResponseCodesComponent;
