import React, { useEffect, Fragment } from "react";
import { validatePassword } from "../../utils";

import { usePatchReducer } from "@spscommerce/ds-react";
import { SpsIcon } from "@spscommerce/ds-react";
import { SpsIcon as SpsIcons } from "@spscommerce/ds-shared";

import "./PatternMatcher.scss";

const patternNames = {
    minlength: "8 characters long",
    upper: "1 uppercase letter",
    lower: "1 lowercase letter",
    number: "1 number",
    special: "1 special character",
};

export function PatternMatcherValidator(props) {
    const {
        password,
        policy: { name, pattern },
    } = props;

    const [state, patchState] = usePatchReducer({
        valid: false,
    });

    useEffect(() => {
        patchState({ valid: validatePassword(pattern, password) });
    }, [props]);

    return (
        <Fragment>
            <div className="password-icons">
                <SpsIcon icon={state.valid ? SpsIcons.STATUS_OK : SpsIcons.CIRCLE_OUTLINE} />
            </div>
            <span>{patternNames[name]}</span>
        </Fragment>
    );
}

export default function PatternMatcher(props) {
    const { passwordPolicy, password } = props;

    return (
        <div className="pattern-matcher">
            <span className="font-italic">*Passwords must include at least:</span>
            {passwordPolicy
                ? passwordPolicy.map((policy) => (
                      <div className="container-pattern" key={policy.error}>
                          <PatternMatcherValidator password={password} policy={policy} />
                      </div>
                  ))
                : null}
        </div>
    );
}
