import React, { Component } from "react";
import ReactPlaceholder from "react-placeholder";
import Obfuscator from "../Obfuscator";
import ClickToCopy from "../ClickToCopy";

export default class ConnectionInformationRow extends Component {
    state = {
        hideSecret: true,
    };
    toggleVisibility = () => {
        this.setState((prevState) => ({ hideSecret: !prevState.hideSecret }));
    };
    render() {
        const { title, loaded, value, hiddenField } = this.props;
        return (
            <div className="container-connection-information">
                <span className="connection-information__title">{title}</span>
                <div className="connection-information__content">
                    <div className="connection-information__container-input">
                        <ReactPlaceholder
                            ready={loaded}
                            type="text"
                            rows={2}
                            color="#E0E0E0"
                            showLoadingAnimation
                        >
                            {hiddenField ? (
                                <Obfuscator obfuscate={this.state.hideSecret}>{value}</Obfuscator>
                            ) : (
                                <span className="connection-information__value">{value}</span>
                            )}
                        </ReactPlaceholder>
                    </div>
                    <div className="connection-information__container-copy">
                        <ClickToCopy value={value} id={title.replace(" ", "-").toLowerCase()} />
                    </div>
                    {hiddenField ? (
                        <div className="connection-information__container-visibility">
                            <i className="sps-icon sps-icon-eye" onClick={this.toggleVisibility} />
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}
