import _ from "lodash";

import SwaggerParser from "@apidevtools/swagger-parser";

import config from "../App.config";

function parseDataParamsSchema(schema) {
    let parsedObject;
    let topParent = "";

    if (schema.type === "object") {
        parsedObject = {};
        iterateObject(schema.properties);
        return parsedObject;
    } else if (schema.type === "array") {
        parsedObject = {};
        handleArray(null, null, schema.items);
        return [parsedObject[""]];
    }

    function iterateObject(obj) {
        Object.keys(obj).forEach((key) => {
            topParent = key;
            if (obj[key].type === "object") {
                parsedObject[key] = {};
                handleObject(key, obj[key].properties);
            } else if (obj[key].type === "array") {
                handleArray(key, obj[key].items, obj[key].items);
            } else {
                parsedObject[key] = obj[key].type;
            }
        });
    }

    function handleObject(parentKey, obj = {}, type = "") {
        const customObj = {};
        Object.keys(obj).forEach((key) => {
            if (obj[key].type === "object") {
                handleObject(key, obj[key].properties);
            } else if (obj[key].type === "array") {
                handleArray(parentKey, key, obj[key].items);
            } else {
                const typeOfElement = replaceNumberOrInteger(obj[key].type);
                customObj[key] = typeOfElement;
                if (topParent === parentKey) {
                    if (type === "array") {
                        _.set(parsedObject, [parentKey], [customObj]);
                    } else {
                        _.set(parsedObject, [parentKey, key], typeOfElement);
                    }
                } else {
                    if (type === "array") {
                        if (typeof parentKey === "string") {
                            _.set(parsedObject, [topParent, parentKey], [customObj]);
                        } else {
                            _.set(parsedObject, [topParent], [customObj]);
                        }
                    } else {
                        _.set(parsedObject, [topParent, parentKey, key], typeOfElement);
                    }
                }
            }
        });
    }

    function handleArray(parentKey, key, array) {
        const type = replaceNumberOrInteger(array ? array.type : []);
        if (type === "object") {
            handleObject(key, array.properties, "array");
        } else if (type === "array") {
            handleArray(parentKey, key, array);
        } else {
            if (topParent === parentKey) {
                _.set(parsedObject, [parentKey, key], [type]);
            } else {
                _.set(parsedObject, [topParent, parentKey, key], [type]);
            }
        }
    }

    function replaceNumberOrInteger(type) {
        if (type === "number" || type === "integer") {
            return 0;
        } else {
            return type;
        }
    }
}

function getParams(params, paramType) {
    if (!params) return undefined;
    return _.filter(params, ["in", paramType]);
}

function getDataParams(params) {
    const parsedParams = getParams(params, "body");
    if (!(parsedParams && parsedParams.length)) return undefined;
    if (parsedParams[0].schema)
        parsedParams.map((params) => {
            params.schema = parseDataParamsSchema(params.schema);
            return params;
        });
    return parsedParams;
}

function parseContent(response, code) {
    const content = _.get(response, [code, "content"]);
    const contentHeaders = [];
    if (content) {
        Object.keys(content).forEach((key) => {
            contentHeaders.push({
                schema: _.get(content, [key, "schema"]),
            });
        });
        return contentHeaders[0].schema;
    } else return null;
}

function parseResponse(response) {
    if (!response) return undefined;
    return _.keys(response).map((code) => {
        const schema = _.has(response, [code, "schema"])
            ? _.get(response, [code, "schema"])
            : parseContent(response, code);
        const items = schema
            ? {
                  responseKeys: parseResponseKeys(
                      _.get(
                          schema,
                          schema.type === "array" ? ["items", "properties"] : ["properties"],
                      ),
                      schema.defaultValue,
                  ),
              }
            : {};
        return {
            code: code,
            description: _.get(response, [code, "description"]) || "",
            type: _.get(response, [code, "type"]) || "",
            ...items,
        };
    });
}

function parseResponseKeys(keys, defaultValue) {
    if (!keys) return undefined;

    return _.keys(keys).map((key) => {
        const items = _.get(keys, [key, "items"]);
        const properties = items ? _.get(items, ["properties"]) : _.get(keys, [key, "properties"]);
        return {
            key: key,
            description: _.get(keys, [key, "description"]) || "",
            type: _.get(keys, [key, "type"]) || "",
            defaultValue: defaultValue,
            ...{ items: parseResponseKeys(properties) },
        };
    });
}

function parseSwaggerJson(parsedJson, path, method, link, env, tags) {
    const api = _.get(parsedJson, ["paths", path, method]);
    const servers = _.filter(_.get(parsedJson, "servers", []), { description: "prod" });
    const server = servers.length > 0 ? servers[0].url : "";
    const serviceName = _.get(parsedJson, ["info", "title"]);
    let summary = _.get(api, "summary", "Missing a Path Summary");
    const latestTag = tags.find((tag) => tag.hasOwnProperty("x-sps-latest"));
    let groupTag = tags.find(({ name }) => name === api.tags[0]);
    let hasGroup = groupTag ? groupTag["x-sps-group-by"] : false;
    const isInternal =
        summary &&
        summary.split(config.sidenavRulesCharacter).includes(config.swaggerSidebarRules.INTERNAL);
    let text = summary;
    summary = summary.split(config.sidenavRulesCharacter)[0];
    let sideNavConfig = {};
    if (link && env) {
        let title = _.get(parsedJson, ["info", "title"]);
        if (title && typeof title === "string") {
            title = title.replace(/ /g, "-") + "/";
        } else title = "";
        if (text && typeof text === "string") {
            text = text.replace(/\./g, "");
        } else text = "";
        if (env && typeof text === "string") {
            env = env.replace(/ /g, "-");
        }
        sideNavConfig = {
            text,
            link: `/api-doc/${title ? title : ""}${text.replace(
                / /g,
                "-",
            )}/?path=${path}&method=${method}&doc=${link}${
                isInternal ? `&${config.swaggerSidebarRules.INTERNAL}=true` : ""
            }`,
            swagger: true,
        };
        sideNavConfig[env] = true;
        sideNavConfig["children"] = sideNavConfig;
    }

    return {
        ...sideNavConfig,
        API: api,
        title: summary,
        serviceName: serviceName,
        latestTag: latestTag,
        hasGroup: !!hasGroup,
        description: api.description || "Endpoint description not available.",
        path: `${method.toUpperCase()} ${path}`,
        server: server,
        method: method,
        pathParams: getParams(api.parameters, "path"),
        queryParams: getParams(api.parameters, "query"),
        headerParams: getParams(api.parameters, "header"),
        dataParams: getDataParams(api.parameters),
        responseCodes: parseResponse(api.responses),
        tags: api.tags,
    };
}

const HTTP_METHODS = ["post", "put", "delete", "get", "patch"]; // supported HTTP methods, we can add more in case any document needs

function parseSwaggerJsonAuto(parsedJson, link, env, tags) {
    return new Promise((resolve) => {
        const results = [];
        const paths = _.get(parsedJson, ["paths"]);
        let i = 0;
        const appPaths = Object.keys(paths);
        _.map(paths, function (v, path) {
            i++;
            let allMethods = Object.keys(v);
            allMethods = allMethods.filter((am) =>
                HTTP_METHODS.includes(am ? am.toLocaleLowerCase() : am),
            );
            allMethods.forEach((m) => {
                results.push(parseSwaggerJson(parsedJson, path, m, link, env, tags));
            });
            if (i === appPaths.length) {
                resolve(results);
            }
        });
    });
}

async function deRef(swagger) {
    try {
        return await SwaggerParser.dereference(swagger);
    } catch (e) {
        console.error(e);
        return {};
    }
}

export function parseSwaggerGroups(allOps, tags) {
    if (!tags.length) {
        return allOps;
    }

    allOps = allOps.filter((op) =>
        op.tags ? tags.find(({ name }) => name === op.tags[0]) : false,
    );

    try {
        const result = [];
        const [withGroupTag, withoutGroupTag] = _.partition(allOps, (s) => {
            let foundTag = tags.find(({ name }) => name === s.tags[0]);
            let isLatest = foundTag["x-sps-latest"];
            let isGrouped = foundTag["x-sps-group-by"];

            if (isLatest) {
                s.tags[0] = s.tags[0] + " (Latest)";
            }

            return !!isGrouped;
        });

        let grouppedOps = _.groupBy(withGroupTag, "tags");
        for (var group in grouppedOps) {
            const children = grouppedOps[group];
            result.push({
                text: group,
                links: children.map((c) => c.link),
                children,
            });
        }

        return [...result, ...withoutGroupTag];
    } catch (e) {
        console.error(e);
        return [];
    }
}

export async function swaggerParser(swagger, path, method, link, env) {
    const dereffed = await deRef(swagger);
    const tags = dereffed.tags || [];
    let parsed = [];
    if (path && method) {
        parsed = await parseSwaggerJson(dereffed, path, method, link, env, tags);
    } else {
        parsed = await parseSwaggerJsonAuto(dereffed, link, env, tags);
        parsed = parseSwaggerGroups(parsed, tags);
    }
    return parsed;
}
