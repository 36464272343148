import React, { useRef } from "react";

const DEFAULT_OPTIONS = {
  accept: ".png, .jpg, .gif, .jpeg",
  multiple: false,
  maxSize: "3MB"
};

export default function FileUploadWrapper({ onImageSelect, mini = null }) {
  const ref = useRef();
  React.useEffect(() => {
    ref.current.addEventListener("selection", handleChange);
    return () => {
      ref.current.removeEventListener("selection", handleChange);
    };
  });

  function handleChange(event) {
    const fr = new FileReader();
    fr.onload = e => {
      onImageSelect(e.target.result);
    };
    fr.readAsDataURL(event.detail[0]);
  }
  return (
    <>
      <sps-file-upload
        ref={ref}
        description="Photo"
        acceptExtensions={DEFAULT_OPTIONS.accept}
        maxSize={DEFAULT_OPTIONS.maxSize}
        mini={mini}
      />
    </>
  );
}
