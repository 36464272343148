import React from "react";
import { Route, Switch, Redirect } from "react-router";
import { NavLink, withRouter } from "react-router-dom";
import clsx from "clsx";
import { withCommercePlatform } from "@spscommerce/ui-react";

import { SpsCard } from "@spscommerce/ds-react";
import ProfileDetails from "./ProfileDetails";
import ProfileContact from "./ProfileContact";
import ProfileDisplay from "./ProfileDisplay";
import ProfilePassword from "./ProfilePassword";
import "./ProfileLayout.scss";

export function SideNavSection(props) {
  return <div {...props}>{props.children}</div>;
}

function ProfileLayout({ commercePlatform, token, ...props }) {
  return (
    <div className="row sps-page-wrapper">
      <div className="col-3 docs-body__sidebar sps-side-nav">
        <SpsCard headerTitle="Profile And Settings">
          <SideNavSection className="sps-side-nav__section profile-side-nav">
            <ul className="side-nav__branch">
              <div
                className={clsx("sps-side-nav__section-content", {
                  active: props.location.pathname === "/profile/personal"
                })}
              >
                <NavLink
                  className={clsx("sps-side-nav__link side-nav__leaf pl-3")}
                  style={{ height: "36px" }}
                  to="/profile/personal"
                >
                  Personal Information
                </NavLink>
              </div>
              <div
                className={clsx("sps-side-nav__section-content", {
                  active: props.location.pathname === "/profile/contact"
                })}
              >
                <NavLink
                  style={{ height: "36px" }}
                  className="sps-side-nav__link side-nav__leaf pl-3"
                  to="/profile/contact"
                >
                  Contact Information
                </NavLink>
              </div>
              <div
                className={clsx("sps-side-nav__section-content", {
                  active: props.location.pathname === "/profile/display"
                })}
              >
                <NavLink
                  style={{ height: "36px" }}
                  className="sps-side-nav__link side-nav__leaf pl-3"
                  to="/profile/display"
                >
                  Display Settings
                </NavLink>
              </div>
              <div
                className={clsx("sps-side-nav__section-content", {
                  active: props.location.pathname === "/profile/password"
                })}
              >
                <NavLink
                  style={{ height: "36px" }}
                  className="sps-side-nav__link side-nav__leaf pl-3"
                  to="/profile/password"
                >
                  Password Management
                </NavLink>
              </div>
            </ul>
          </SideNavSection>
        </SpsCard>
      </div>
      <Switch>
        <Route
          exact={true}
          path="/profile"
          render={() => {
            return <Redirect to="profile/personal" />;
          }}
        />
        <Route
          exact={true}
          path="/profile/personal"
          render={() => {
            return <ProfileDetails commercePlatform={commercePlatform} />;
          }}
        />
        <Route
          exact={true}
          path="/profile/contact"
          render={() => {
            return (
              <ProfileContact
                commercePlatform={commercePlatform}
                environment={props.environment}
              />
            );
          }}
        />
        <Route
          exact={true}
          path="/profile/display"
          render={() => {
            return <ProfileDisplay commercePlatform={commercePlatform} />;
          }}
        />
        <Route
          exact={true}
          path="/profile/password"
          render={() => {
            return (
              <ProfilePassword
                commercePlatform={commercePlatform}
                environment={props.environment}
              />
            );
          }}
        />
      </Switch>
    </div>
  );
}

export default withCommercePlatform(withRouter(ProfileLayout));
