import React from "react";
import { SpsCard } from "@spscommerce/ds-react/";
import "./UserGuidesLandingPage.scss";
import Footer from "../../Footer";

export default function UserGuideLandingPage() {
    return (
        <div className="docs-main-content" style={{ width: "100%" }}>
            <div className="col-9 landing-page">
                <SpsCard className="home-page-content__card">
                    <h2 className="home-page-content__card-heading">User Guides</h2>
                    <p>
                        This section contains SPS Commerce's standard mapping guidelines on
                        connecting to our network.
                    </p>
                </SpsCard>
            </div>
            <Footer />
        </div>
    );
}
