import React, { Fragment } from "react";

import { SpsCard } from "@spscommerce/ds-react";

import CodeRequestRow from "../CodeContentRow/CodeRequestRow";
import CodeResponseRow from "../CodeContentRow/CodeResponseRow";

const SwaggerExampleComponent = ({
    heading,
    responseCodes,
    dataParams,
    url,
    method,
    headers,
    server,
    notes,
    paragraph,
}) => {
    const getDataParam = (param) => {
        if (param !== undefined) {
            return JSON.stringify(param[0].schema, null, " ");
        }
    };

    const getHeaderParam = (param) => {
        const header = {};
        param.map((item) => (header[item.name] = item.DefaultValue));
        return header;
    };

    const getResponseCodes = (param) => {
        let responseCode = [];
        param &&
            param.map(
                (item) =>
                    (responseCode = [
                        ...responseCode,
                        {
                            code: item.code,
                            description: `${item.code} - ${item.description}`,
                            responseKeys: item.responseKeys,
                            type: item.type,
                        },
                    ]),
            );
        return responseCode;
    };

    return (
        <SpsCard className="card-margin">
            <h4>{heading}</h4>
            {paragraph && <p>{paragraph}</p>}
            <CodeRequestRow
                copyCode={true}
                header={getHeaderParam(headers)}
                className="mb-2"
                url={server + (url || "").split(" ")[1]}
                method={method}
                body={getDataParam(dataParams)}
            />
            <CodeResponseRow
                responseFormatDropdown={true}
                codes={getResponseCodes(responseCodes)}
            />
            {notes.length ? (
                <Fragment>
                    <h6 className="mt-3">
                        <b>Notes</b>
                    </h6>
                    {notes.map((note, index) => {
                        return <p key={index}>{note}</p>;
                    })}
                </Fragment>
            ) : null}
        </SpsCard>
    );
};

export default SwaggerExampleComponent;
