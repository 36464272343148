import React, { useEffect, useState, useRef, Fragment } from "react";
import { NavLink, Redirect } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { get } from "lodash";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

import {
    useForm,
    formGroup,
    SpsValidators,
    formControl,
    SpsForm,
    SpsTextInput,
    SpsButton,
    SpsLabel,
    SpsIcon,
    SpsFeedbackBlock,
} from "@spscommerce/ds-react";
import { SpsIcon as Icon } from "@spscommerce/ds-shared";
import { withCommercePlatform } from "@spscommerce/ui-react";

import LoginCard from "../../components/LoginCard";
import SETTINGS from "../../App.config";
import "./SignUpPage.scss";
import LoginCardV2 from "../../components/LoginCard/LoginCardV2";

const checkEmail = (value) => {
    // checks for standard email format
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const spsEmailRegex = /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?spscommerce\.com$/;
    if (re.test(String(value).toLowerCase()) && !spsEmailRegex.test(String(value).toLowerCase())) {
        return null;
    }
    return { email: "invalid" };
};

export function SignUpPageWithOutCommercePlatform(props) {
    const {
        commercePlatform: { environment, hasSession, token, auth },
        flags,
    } = props;
    const initialForm = {
        firstname: "",
        lastname: "",
        email: "",
        title: "",
        company: "",
    };
    const [feedBackBlock, setFeedBackBlock] = useState(null);
    const [recaptchaSiteKey, setRecaptchaSiteKey] = useState();
    const [recaptchaValidationKey, setRecaptchaValidationKey] = useState(null);
    const [signupComplete, setSignupComplete] = useState(false);
    const [makingRequest, setMakingRequest] = useState(false);

    const serverSideValidationErrors = useRef();
    function serverSideErrorsAdapter() {
        return serverSideValidationErrors.current;
    }

    const form = useForm(
        formGroup({
            firstname: formControl("", {
                validators: [SpsValidators.required],
            }),
            lastname: formControl(initialForm.lastname, {
                validators: [SpsValidators.required],
            }),
            email: formControl(initialForm.email, {
                validators: [SpsValidators.required, checkEmail, serverSideErrorsAdapter],
            }),
            // TODO: Right now this is a required field in the API, remove
            // once that constraint has been removed
            title: formControl(initialForm.title),
            company: formControl(initialForm.company),
        }),
    );

    useEffect(() => {
        if (flags.universalLoginExperience) {
            document.body.classList.add("sps-wallpaper-ul");
        } else {
            document.body.classList.add("sps-wallpaper");
        }

        return () => {
            if (flags.universalLoginExperience) {
                document.body.classList.remove("sps-wallpaper-ul");
            } else {
                document.body.classList.remove("sps-wallpaper");
            }
        };
    });

    useEffect(() => {
        if (environment) {
            setRecaptchaSiteKey(SETTINGS.recaptchaKeys[environment]);
        }
    }, [environment]);

    const onRecaptchaChange = (key) => {
        setRecaptchaValidationKey(key);
    };
    const onRecaptchaError = (key) => {};

    const validateForm = () => {
        const formMemberKeys = Object.keys(form.members);
        return formMemberKeys.every((key) => {
            return !form.members[key].errors;
        });
    };

    const createTrialUser = async (user) => {
        const {
            commercePlatform: { environment },
        } = props;

        const url = `${SETTINGS.kubeRoot[environment]}/devcenter/public/v1/signup/`;

        try {
            setMakingRequest(true);
            await axios.post(url, user);
            setMakingRequest(false);
            setSignupComplete(true);
        } catch (err) {
            if (window.grecaptcha) {
                window.grecaptcha.reset();
            }

            setMakingRequest(false);
            console.error(err.response);
            const emailFieldValidationError = get(
                err.response,
                "data.error.validationErrors.meta.email",
            );
            if (
                emailFieldValidationError &&
                emailFieldValidationError.includes(
                    `User with email ${form.get("email").getValue()} already exists`,
                )
            ) {
                // Catch existing user error
                const emailField = form.get("email");
                serverSideValidationErrors.current = { existingAccount: true };
                emailField.update();
                // there isn't currently a way to manually set focus on an SpsTextInput's input field,
                // for now we will also display a feedBackBlock
                setFeedBackBlock(
                    <Fragment>
                        This account already exists.{" "}
                        <SpsButton
                            kind="link"
                            className="signup-page__signin--link align-baseline fs-14"
                            onClick={() => {
                                auth.login({ appState: window.location.hash });
                            }}
                        >
                            Sign In
                        </SpsButton>{" "}
                        to get access to Dev Center.
                    </Fragment>,
                );
            } else {
                setFeedBackBlock(
                    <Fragment>
                        We are experiencing difficulties communicating with the server. Please try
                        again later.
                    </Fragment>,
                );
            }
        }
    };

    // remove empty values (undefined, null, or empty strings) in a flat object
    const removeEmpty = (obj) => {
        const newObj = {};
        Object.keys(obj).forEach((key) => {
            if (obj[key] && obj[key] != null && obj[key].length > 0) {
                newObj[key] = obj[key];
            }
        });

        return newObj;
    };

    const submitForm = () => {
        // there was a race condition when a user submits without interacting with the form
        // the setTimeout ensures the validateForm function is called after the form is set to dirty
        // and the fields have been validated internally
        setTimeout(() => {
            const valid = validateForm();
            if (valid) {
                const formValues = form.getValue();
                const user = {
                    ...removeEmpty(formValues),
                    recaptcha: recaptchaValidationKey,
                };
                createTrialUser(user);
            }
        });
    };

    const handleEmailValidationErrors = () => {
        if (form.get("email").errors) {
            const errors = form.get("email").errors;
            if (errors.required) {
                return "Email is a required field";
            } else if (errors.email) {
                return "Please enter a valid email address.";
            } else if (errors.existingAccount) {
                return "This account already exists. Please contact your SPS Commerce Organization Admin to get access to Dev Center.";
            }
        }
    };

    const disableForm = () => {
        return !recaptchaValidationKey;
    };

    // we need to remove the validation error on the email form manually
    const handleEmailFormOnChange = (e) => {
        const emailField = form.get("email");
        setFeedBackBlock(null);
        serverSideValidationErrors.current = null;
        emailField.update();
    };

    const signIn = (e) => {
        const {
            commercePlatform: { auth },
        } = props;
        e.preventDefault();
        auth.login({ appState: "home" });
    };

    return (
        <>
            {hasSession && token && <Redirect to="/home" />}
            {flags.universalLoginExperience ? (
                <div className="sign-up__wrapper">
                    <LoginCardV2
                        className="sign-up__card"
                        bodyClass="sign-up__body"
                        topShelf={() => (
                            <NavLink
                                to="/home"
                                className="signup-page__nav-link signup-page__nav-link--icon"
                            >
                                <SpsIcon icon={Icon.ARROW_LEFT_CIRCLE} />
                                <span>Back to Dev Center Home</span>
                            </NavLink>
                        )}
                    >
                        <div className="create-trial-account">
                            {!signupComplete ? (
                                <>
                                    {feedBackBlock && (
                                        <div className="fs-14">
                                            <SpsFeedbackBlock kind="error">
                                                {feedBackBlock}
                                            </SpsFeedbackBlock>
                                        </div>
                                    )}
                                    <div className="sps-logo-ul">
                                        <img
                                            src="https://www.spscommerce.com/wp-content/uploads/2018/07/logo-blue.svg"
                                            alt="sps commerce logo"
                                        />
                                    </div>
                                    <h1 className="login-content-body__header login-content-body__header--signup">
                                        Create trial account
                                    </h1>
                                    <div className="mb-3 fs-14">
                                        With your free trial account, you can explore all services that
                                        SPS Commerce has to offer.
                                    </div>
                                    <SpsForm formGroup={form} onSubmit={submitForm}>
                                        <SpsLabel
                                            for={form.get("firstname")}
                                            errors={() =>
                                                form.get("firstname").errors &&
                                                form.get("firstname").errors.required &&
                                                "First Name is a required field"
                                            }
                                        >
                                            First Name
                                        </SpsLabel>
                                        <SpsTextInput
                                            formControl={form.get("firstname")}
                                            placeholder="First Name"
                                        />
                                        <SpsLabel
                                            for={form.get("lastname")}
                                            errors={() =>
                                                form.get("lastname").errors &&
                                                form.get("lastname").errors.required &&
                                                "Last Name is a required field"
                                            }
                                        >
                                            Last Name
                                        </SpsLabel>
                                        <SpsTextInput
                                            formControl={form.get("lastname")}
                                            placeholder="Last Name"
                                        />
                                        <SpsLabel
                                            for={form.get("email")}
                                            errors={handleEmailValidationErrors}
                                        >
                                            Email Address
                                        </SpsLabel>
                                        <SpsTextInput
                                            onChange={handleEmailFormOnChange}
                                            formControl={form.get("email")}
                                            placeholder="Enter an Email Address"
                                        />
                                        <SpsLabel
                                            for={form.get("title")}
                                            errors={() =>
                                                form.get("title").errors &&
                                                form.get("title").errors.required &&
                                                "Job Title is a required field"
                                            }
                                        >
                                            Job Title
                                        </SpsLabel>
                                        <SpsTextInput
                                            formControl={form.get("title")}
                                            placeholder="Enter a Job Title"
                                        />
                                        <SpsLabel for={form.get("company")}>Company Name</SpsLabel>
                                        <SpsTextInput
                                            formControl={form.get("company")}
                                            placeholder="Enter a Company Name"
                                        />
                                        {recaptchaSiteKey && (
                                            <div className="recaptcha-container">
                                                <ReCAPTCHA
                                                    sitekey={recaptchaSiteKey}
                                                    onChange={onRecaptchaChange}
                                                    onError={onRecaptchaError}
                                                />
                                            </div>
                                        )}
                                        <div className="signup-page__container-submit-btn-ul">
                                            <SpsButton
                                                type="submit"
                                                kind="confirm"
                                                className="w-100"
                                                disabled={disableForm()}
                                                data-testid="form-submit"
                                                spinning={makingRequest}
                                            >
                                                Create Trial Account
                                            </SpsButton>
                                        </div>
                                    </SpsForm>
                                    <div className="signup-page__container-sign-in">
                                        Already Have an Account?{" "}
                                        <a
                                            className="signup-page__nav-link"
                                            href="_#_"
                                            onClick={signIn}
                                        >
                                            Sign In
                                        </a>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className={"signup-complete-page"}>
                                        <img src="assets/signup-complete-page.svg" alt="SPS Commerce" />
                                        <h1 className="login-content-body__header login-content-body__header--signup">
                                            Thank you for signing up!
                                        </h1>
                                        <p>
                                            Check your inbox for a link to verify your trial account.
                                            You will then be able to sign in with your email address and
                                            password.
                                        </p>
                                        <NavLink to="/home" className="signup-page__nav-link">
                                            Return to Dev Center Home
                                        </NavLink>
                                    </div>
                                </>
                            )}
                        </div>
                    </LoginCardV2>
                </div>
            ) : (
                <LoginCard
                    bodyClass="sign-up__body"
                    topShelf={() => (
                        <NavLink
                            to="/home"
                            className="signup-page__nav-link signup-page__nav-link--icon"
                        >
                            <SpsIcon icon={Icon.ARROW_LEFT_CIRCLE} />
                            <span>Back to Dev Center Home</span>
                        </NavLink>
                    )}
                >
                    <div className="create-trial-account">
                        {!signupComplete ? (
                            <>
                                {feedBackBlock && (
                                    <div className="fs-14">
                                        <SpsFeedbackBlock kind="error">
                                            {feedBackBlock}
                                        </SpsFeedbackBlock>
                                    </div>
                                )}
                                <h1 className="login-content-body__header login-content-body__header--signup">
                                    Create trial account
                                </h1>
                                <div className="mb-3 fs-14">
                                    With your free trial account, you can explore all services that
                                    SPS Commerce has to offer.
                                </div>
                                <SpsForm formGroup={form} onSubmit={submitForm}>
                                    <SpsLabel
                                        for={form.get("firstname")}
                                        errors={() =>
                                            form.get("firstname").errors &&
                                            form.get("firstname").errors.required &&
                                            "First Name is a required field"
                                        }
                                    >
                                        First Name
                                    </SpsLabel>
                                    <SpsTextInput
                                        formControl={form.get("firstname")}
                                        placeholder="First Name"
                                    />
                                    <SpsLabel
                                        for={form.get("lastname")}
                                        errors={() =>
                                            form.get("lastname").errors &&
                                            form.get("lastname").errors.required &&
                                            "Last Name is a required field"
                                        }
                                    >
                                        Last Name
                                    </SpsLabel>
                                    <SpsTextInput
                                        formControl={form.get("lastname")}
                                        placeholder="Last Name"
                                    />
                                    <SpsLabel
                                        for={form.get("email")}
                                        errors={handleEmailValidationErrors}
                                    >
                                        Email Address
                                    </SpsLabel>
                                    <SpsTextInput
                                        onChange={handleEmailFormOnChange}
                                        formControl={form.get("email")}
                                        placeholder="Enter an Email Address"
                                    />
                                    <SpsLabel
                                        for={form.get("title")}
                                        errors={() =>
                                            form.get("title").errors &&
                                            form.get("title").errors.required &&
                                            "Job Title is a required field"
                                        }
                                    >
                                        Job Title
                                    </SpsLabel>
                                    <SpsTextInput
                                        formControl={form.get("title")}
                                        placeholder="Enter a Job Title"
                                    />
                                    <SpsLabel for={form.get("company")}>Company Name</SpsLabel>
                                    <SpsTextInput
                                        formControl={form.get("company")}
                                        placeholder="Enter a Company Name"
                                    />
                                    {recaptchaSiteKey && (
                                        <div className="recaptcha-container">
                                            <ReCAPTCHA
                                                sitekey={recaptchaSiteKey}
                                                onChange={onRecaptchaChange}
                                                onError={onRecaptchaError}
                                            />
                                        </div>
                                    )}
                                    <div className="signup-page__container-submit-btn">
                                        <SpsButton
                                            type="submit"
                                            kind="confirm"
                                            className="w-100"
                                            disabled={disableForm()}
                                            data-testid="form-submit"
                                            spinning={makingRequest}
                                        >
                                            Create Trial Account
                                        </SpsButton>
                                    </div>
                                </SpsForm>
                                <div className="signup-page__container-sign-in">
                                    Already Have an Account?{" "}
                                    <a
                                        className="signup-page__nav-link"
                                        href="_#_"
                                        onClick={signIn}
                                    >
                                        Sign In
                                    </a>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={"signup-complete-page"}>
                                    <img src="assets/signup-complete-page.svg" alt="SPS Commerce" />
                                    <h1 className="login-content-body__header login-content-body__header--signup">
                                        Thank you for signing up!
                                    </h1>
                                    <p>
                                        Check your inbox for a link to verify your trial account.
                                        You will then be able to sign in with your email address and
                                        password.
                                    </p>
                                    <NavLink to="/home" className="signup-page__nav-link">
                                        Return to Dev Center Home
                                    </NavLink>
                                </div>
                            </>
                        )}
                    </div>
                </LoginCard>
            )}
        </>
    );
}

export default withCommercePlatform(withLDConsumer()(SignUpPageWithOutCommercePlatform));
