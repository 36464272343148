import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

import AppConfig from "../../App.config";
import AppRoutes from "../../AppRoutes";
import Loading from "../Loading";
import { UserAgreementPage as TOS } from "../../pages/UserAgreementPage/UserAgreementPage";
import SplashPage from '../../splash-page/SplashPage';
import { PrivateRoutesProvider } from "../../contexts/privateRouteContext";

function renderComponent(props, hasRole) {
    return (
        <AppRoutes
            {...props}
            hasRole={hasRole || props.roleIndependentPaths.includes(props.location.pathname)}
        />
    );
}

const CheckRoles = (props) => {
    const { token, currentUser, identityServiceUrl, environment, flags: { splashPage }, } = props;
    const [userPermissions, setUserPermissions] = useState({
        hasAccess: false,
        loading: true,
    });
    const [isTrialOrg, setIsTrialOrg] = useState(false);

    const getCurrentOrg = (response) => {
        const {
            organization: { id: orgId },
        } = currentUser;
        return response.data.results[0].organizations.find((org) => {
            return org.organization === orgId;
        });
    };

    const getCurrentOrgFromService = (currentUser) => {
        if (currentUser && currentUser.organization) {
            const {
                organization: { id: orgId },
            } = currentUser;
            (async function () {
                const url = `${AppConfig.identityRoot[environment]}`;
                const config = {
                    method: "GET",
                    url: `${url}/identity/orgs/${orgId}/`,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                };

                try {
                    const {
                        data: { is_trial },
                    } = await axios(config);
                    setIsTrialOrg(is_trial);
                } catch (e) {
                    // left blank because we're just checking if user is trial.
                }
            })();
        }
    };

    const updateUserForLaunchDarkly = (user, props) => {
        const currentOrg = getCurrentOrg(user);
        const userDetails = user.data.results[0].details;
        if (userDetails) {
            if (props.ldClient && props.ldClient.identify) {
                props.ldClient.identify(
                    {
                        key: userDetails.id,
                        firstName: userDetails.first_name,
                        lastName: userDetails.last_name,
                        email: userDetails.email,
                        anonymous: false,
                        avatar: userDetails.avatar_image_url,
                        custom: {
                            organizationId: currentOrg.id,
                            organizationName: currentOrg.organization_name,
                            user_type: userDetails.user_type,
                        },
                    },
                    null,
                );
            }
        }
    };

    useEffect(() => {
        if (currentUser) {
            fetchV3User().then((resp) => {
                setUserPermissions({
                    access: hasDevCenterRolesOrVisitingAllowedPage(resp),
                    loading: false,
                });
                updateUserForLaunchDarkly(resp, props);
            });
            const isSPSUser = currentUser.email.indexOf("@spscommerce.com") > 1;
            let userType;
            if (isSPSUser) {
                userType = "SPS-User";
            } else {
                const orgName = currentUser.organization.organization_name;
                const regx = /(- Trial)$/g;
                userType =
                    orgName === "Trial Account" || regx.test(orgName)
                        ? "Trial-User"
                        : "Non-Trial-User";
            }
            getCurrentOrgFromService(currentUser);
            window.dataLayer.push({ "user-type": userType });
        } else {
            window.dataLayer.push({ "user-type": "Visitor" });
            setUserPermissions({
                access: false,
                loading: false,
            });
        }
    }, [currentUser]);

    const hasDevCenterRolesOrVisitingAllowedPage = (user) => {
        const currentOrg = getCurrentOrg(user, props);
        const devCenterPerms = currentOrg.permissions.filter((perm) => {
            return perm.startsWith("devcenter:");
        });
        return devCenterPerms.length > 0;
    };

    const fetchV3User = () => {
        const { id } = currentUser;
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        return axios.get(identityServiceUrl + `/identity/v3/users/?id=${id}`, config);
    };

    return (
        <PrivateRoutesProvider>
            <Fragment>
                {splashPage ? (
                    <SplashPage />
                ) : (
                    !userPermissions.loading ? (
                        <>
                            {currentUser && !currentUser.developer_center_tos_agreed_at ? (
                                <TOS />
                            ) : (
                                renderComponent({ ...props, isTrialOrg }, userPermissions.access)
                            )}
                        </>
                    ) : (
                        <div className={`sps-page }`}>
                            <div className={`sps-body sps-body--collapse-600`}>
                                <Loading />
                            </div>
                        </div>
                    )
                )}
            </Fragment>
        </PrivateRoutesProvider>
    );
};

export default withLDConsumer()(CheckRoles);
