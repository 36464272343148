import React, { Fragment } from "react";

import _ from "lodash";

import { SpsPageTitle } from "@spscommerce/ds-react/";

import SwaggerAbout from "./SwaggerAboutComponent";
import SwaggerAuth from "./SwaggerAuthenticationComponent";
import SwaggerDataParams from "./SwaggerDataParamsComponent";
import SwaggerExampleComponent from "./SwaggerExampleComponent";
import SwaggerHeaderParams from "./SwaggerHeaderParamsComponent";
import SwaggerPath from "./SwaggerPathcomponent";
import SwaggerPathParams from "./SwaggerPathParamsComponent";
import SwaggerQueryParamsComponent from "./SwaggerQueryParamsComponent";
import SwaggerResponseCodes from "./SwaggerResponseCodesComponent";
import SwaggerVersionTag from "./SwaggerVersionTag";

const SwaggerDocumentationPage = (props) => {
    const {
        authDocsPath,
        data = {},
        modifySections = {},
        hideSections = [],
        itemsHeaderPara = [],
    } = props;
    return (
        <Fragment>
            {data.hasGroup && (
                <SwaggerVersionTag
                    serviceName={data.serviceName}
                    tagName={data.tags[0]}
                    latestTag={data.latestTag}
                />
            )}
            <SpsPageTitle className="col-12 home-page__heading">
                {(data.title || "").replace(/\./g, "")}
            </SpsPageTitle>
            <SwaggerAbout about={data.description} modifySections={modifySections} />
            <SwaggerAuth authDocsPath={authDocsPath} />
            {!hideSections.includes("HEADER_PARAMS") && (
                <SwaggerHeaderParams
                    itemsHeaderParams={
                        data.headerParams && data.headerParams.length
                            ? data.headerParams
                            : itemsHeaderPara
                    }
                />
            )}
            {!hideSections.includes("PATH") && <SwaggerPath path={data.path} />}
            {!_.isEmpty(data.pathParams) && !hideSections.includes("PATH_PARAMS") ? (
                <SwaggerPathParams pathParams={data.pathParams} />
            ) : null}
            {!_.isEmpty(data.queryParams) && !hideSections.includes("QUERY_PARAMS") ? (
                <SwaggerQueryParamsComponent
                    itemsQueryPara={data.queryParams}
                    notes={data.queryParams.notes}
                />
            ) : null}
            {!_.isEmpty(data.dataParams) && !hideSections.includes("DATA_PARAMS") && (
                <SwaggerDataParams
                    params={data.dataParams}
                    description={data.dataParams ? data.dataParams.description : null}
                    modifySections={modifySections}
                />
            )}
            {!_.isEmpty(data.responseCodes) && !hideSections.includes("RESPONSE_CODES") && (
                <SwaggerResponseCodes
                    heading="Response Codes"
                    codes={data.responseCodes}
                    modifySections={modifySections}
                />
            )}
            {
                <SwaggerExampleComponent
                    heading="Example"
                    url={data.path}
                    server={data.server}
                    headers={itemsHeaderPara}
                    method={data.method}
                    responseCodes={data.responseCodes}
                    dataParams={data.dataParams}
                    notes={[]}
                    paragraph=""
                />
            }
        </Fragment>
    );
};

export default SwaggerDocumentationPage;
