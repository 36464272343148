import React from "react";
import axios from "axios";

import { SpsButton, SpsCard } from "@spscommerce/ds-react";

import PropTypes from "prop-types";
import "./DownloadLink.scss";

export default function DownloadLink(props) {
    const {
        text,
        url,
        token,
        icon,
        card,
        className,
        fileName,
        kind,
        documentName,
        prettyPrintJSON,
        cdn_url,
        relativeUrl,
        dataLayerEvent = null,
        ...rest
    } = props;

    const [loading, setLoading] = React.useState(false);

    const encode = function (prettyJSON) {
        let out = [];
        for (let char = 0; char < prettyJSON.length; char++) {
            out[char] = prettyJSON.charCodeAt(char);
        }
        return new Uint8Array(out);
    };

    const downloadFile = async () => {
        if (!loading) {
            try {
                if (url) {
                    setLoading(true);
                    const isExampleFileAPI = url.includes("sample_files");
                    const patt = /^(http:\/\/|https:\/\/)(www\.)?[a-z]+\.?/gi;
                    let docUrl = "";
                    if (patt.test(url)) {
                        docUrl = url;
                    } else {
                        docUrl = `${cdn_url}${relativeUrl || "files"}/${url}`;
                    }
                    const response = await axios.get(docUrl, {
                        headers: { Authorization: `Bearer ${token}` },
                        responseType: !isExampleFileAPI ? "arraybuffer" : "",
                    });
                    let data;
                    if (isExampleFileAPI) {
                        let currentDoc;
                        for (var obj of response.data.documents) {
                            currentDoc = obj;
                            if (obj.document === documentName) {
                                break;
                            }
                        }
                        data = currentDoc.files[`${documentName}.xml`];
                    } else {
                        data = response.data;
                    }

                    if (prettyPrintJSON) {
                        try {
                            var decodedString = String.fromCharCode.apply(
                                null,
                                new Uint8Array(data),
                            );
                            var jsonObj = JSON.parse(decodedString);
                            const prettyJSON = JSON.stringify(jsonObj, null, 2);
                            data = encode(prettyJSON);
                        } catch (error) {
                            console.log(error);
                        }
                    }

                    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement("a");
                    link.href = downloadUrl;
                    link.setAttribute(
                        "download",
                        fileName ? fileName : text ? `${text}.xml` : "schema.xml",
                    );
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setLoading(false);

                    if (dataLayerEvent) {
                        window.dataLayer.push({
                            event: dataLayerEvent.event,
                            [dataLayerEvent.eventInfoLabel]: dataLayerEvent.eventInfoValue,
                        });
                    }
                }
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        }
    };

    return (
        <div className={className}>
            {card ? (
                <SpsCard>
                    <SpsButton
                        className="link-text"
                        kind={kind ? kind : "link"}
                        icon={icon}
                        onClick={downloadFile}
                        spinning={loading}
                        {...rest}
                    >
                        {text ? text : ""}
                    </SpsButton>
                </SpsCard>
            ) : (
                <SpsButton
                    className="link-text"
                    kind={kind ? kind : "link"}
                    icon={icon}
                    onClick={downloadFile}
                    spinning={loading}
                    {...rest}
                >
                    {text ? text : ""}
                </SpsButton>
            )}
        </div>
    );
}

DownloadLink.propTypes = {
    url: PropTypes.string.isRequired,
    text: PropTypes.string,
    token: PropTypes.string,
    icon: PropTypes.string,
    card: PropTypes.bool,
    kind: PropTypes.string,
    prettyPrintJSON: PropTypes.bool,
};
