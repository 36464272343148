import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import TemplateDropdown from "../TemplateDropdown";
import CompanySwitcher from "../CompanySwitcher";
import { withRouter } from "react-router-dom";
import { withCommercePlatform } from "@spscommerce/ui-react";
import { SpsCard, SpsButton } from "@spscommerce/ds-react";
import PlaceHolderImage from "./place_holder_image.svg";
import "./ProfileDropdown.scss";

// ToDo: We need environment specific path for this
const placeHolderImage = PlaceHolderImage;

class ProfileDropdownWithoutCommercePlatform extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.profileDropRef = React.createRef();
    }

    signInComp = () => {
        return (
            <div className="sign-in-link">
                <NavLink to="/signup">Create Trial Account</NavLink>
                <a onClick={this.signIn} href="/">
                    Sign In
                </a>
            </div>
        );
    };

    triggerComp = () => {
        const { currentUser } = this.props;
        const avatar_image =
            currentUser && currentUser.avatar_image_url
                ? currentUser.avatar_image_url
                : placeHolderImage;
        return (
            <div className="profile-dropdown">
                <span className="trigger-company-name">
                    {currentUser.organization.organization_name}
                </span>
                <div className="blackbar-image">
                    <img src={avatar_image} alt="" />
                </div>
            </div>
        );
    };

    signIn = (e) => {
        const {
            commercePlatform: { auth },
        } = this.props;
        e.preventDefault();
        auth.login({ appState: window.location.hash });
    };

    signOut = (e) => {
        const {
            commercePlatform: { auth },
        } = this.props;
        e.preventDefault();
        auth.logout();
    };

    onClose = () => {
        this.setState({ isOpen: false });
        this.props.history.push("/profile");
    };

    render() {
        const { currentUser } = this.props;
        const avatar_image =
            currentUser && currentUser.avatar_image_url
                ? currentUser.avatar_image_url
                : placeHolderImage;
        return currentUser ? (
            <TemplateDropdown
                triggerComponent={this.triggerComp}
                label="Profile Settings"
                isOpen={this.state.isOpen}
                afterOpen={() => {
                    this.setState({ isOpen: undefined });
                }}
            >
                <SpsCard
                    className="dropdown-card"
                    footer={() => {
                        return (
                            <SpsButton kind="link" onClick={this.signOut}>
                                Sign Out
                            </SpsButton>
                        );
                    }}
                >
                    <div className="container pl-0">
                        <div className="d-flex">
                            <div className="dropdown-image">
                                <img src={avatar_image} alt="" />
                            </div>
                            <div className="d-flex ml-3" style={{ flexDirection: "column" }}>
                                <div className="name">
                                    {currentUser.first_name} {currentUser.last_name}
                                </div>
                                <div className="email mb-2">{currentUser.email} </div>
                                <div className="controls">
                                    <SpsButton
                                        kind="link"
                                        onClick={this.onClose}
                                        className="d-flex align-items-center"
                                    >
                                        <i className="sps-icon sps-icon-gear" />
                                        Edit Profile & Settings
                                    </SpsButton>
                                </div>
                            </div>
                        </div>
                        {currentUser.organizations.length > 1 ? (
                            <CompanySwitcher currentUser={currentUser} />
                        ) : null}
                    </div>
                </SpsCard>
            </TemplateDropdown>
        ) : (
            this.signInComp()
        );
    }
}

export default withRouter(
    withCommercePlatform(withLDConsumer()(ProfileDropdownWithoutCommercePlatform)),
);
