import React from "react";

import axios from "axios";
import { isEmpty } from "lodash";

import {
    SpsForm,
    useForm,
    formControl,
    formGroup,
    SpsButton,
    SpsSelect,
    SpsSummaryListColumn,
    SpsSummaryListRow,
    SpsTooltip,
} from "@spscommerce/ds-react";

import AppConfig from "../../App.config";

const SchemaDownloadRow = ({
    docName,
    versionList,
    commercePlatform,
    environment,
    createGrowler,
    selectedVersion,
    isDownloading,
    format,
    url,
    // isInternal, // commenting this now, to use it in future if needed.
    docNumbers,
    showLegacy,
    isBaseSchema,
}) => {
    const localDocNumbers = AppConfig.rsx["documentNumbers"];
    const helpDesc = AppConfig.rsx["help"];

    const [isApiCalled, setIsApiCalled] = React.useState(false);
    const [rowIsSelected, setRowIsSelected] = React.useState(false);

    React.useEffect(() => {
        if (!isEmpty(selectedVersion)) setRowIsSelected(true);
        else setRowIsSelected(false);
    }, [selectedVersion]);

    React.useEffect(() => {
        form.setValue({ version: (versionList[0]) });
    }, [versionList]);

    const form = useForm(
        formGroup({
            version: formControl("")
        })
    );

    /* Commenting checkbox until the bulk download is figured out */

    // function handleSelectionChange(event, docName, version) {
    //   setRowIsSelected(event.target.checked);
    //   handleRowSelect(event, docName, version);
    // }

    function SearchVersion(search) {
        return [...versionList].filter((s) => new RegExp(search, "i").test(s.label));
    }

    const headers = {
        Authorization: `Bearer ${commercePlatform ? commercePlatform.token : ""}`,
        "X-SPS-Service": `${AppConfig.devCenterUI.name}:${AppConfig.devCenterUI.id}`,
    };

    const fetchParticularData = async (documentName) => {
        const version = (form.getValue().version || {}).value;
        await getSchema(documentName, version);
    };

    const downloadFunc = (data, fileName) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const fileLink = document.createElement("a");
        fileLink.href = downloadUrl;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
    };

    const getSchema = async (documentName, version = "") => {
        const isSip = version === AppConfig.rsx.sipVersion;
        let selectedFormat = format;
        if (isSip && format === "XML") {
            selectedFormat = "xsd";
        }
        if (format === "CSV" || format === "Positional") {
            selectedFormat = "zip";
        }
        selectedFormat = selectedFormat.toLowerCase();

        let fileName = `${documentName.split(".")[0]}-${version}-schema.${selectedFormat}`;

        try {
            setIsApiCalled(true);
            const reqConfig = {
                method: "get",
                headers,
                responseType: "arraybuffer",
            };
            // code to download schema.
            if (format === "CSV") {
                const schemaurl = `${url}${isBaseSchema ? "network-schemas/": ""}CSV/${documentName.split(".")[0]}.${selectedFormat}`;
                reqConfig.url = schemaurl;
            } else if (format === "Positional") {
                const schemaurl = `${url}${isBaseSchema ? "network-schemas/": ""}PositionalCSV/${documentName.split(".")[0]
                    }.${selectedFormat}`;
                reqConfig.url = schemaurl;
            } else {
                const apiurl = isSip
                    ? `${AppConfig.rsx[environment]}/v1/internal/assets/sip_schema/${version}/${documentName.split(".")[0]
                    }/?format=${selectedFormat}`
                    : `${AppConfig.rsx[environment]}/v1/assets/${version}/${documentName.split(".")[0]
                    }/?format=${selectedFormat}${isBaseSchema ? "&structure=true" : ""}`;
                reqConfig.url = apiurl;

                fileName = `${documentName.split(".")[0]}-${version}-schema.${isSip ? selectedFormat : "zip"
                    }`;
            }

            const schemaResponse = await axios(reqConfig);
            downloadFunc(schemaResponse.data, fileName);

            // Track schema downloads
            window.dataLayer.push({
                event: "schemaDownload",
                schemaInfo: `${documentName.split(".")[0] || documentName}_${format}_v${version}`,
            });

            // sample files are now downloaded via zip from assets
        } catch (error) {
            createGrowler({
                kind: "error",
                title: "An error has occurred while fetching schema names",
                content: () =>
                    "An error has occurred while fetching schema names, please try again later.",
            });
        } finally {
            setIsApiCalled(false);
        }
    };

    const getDocType = () => {
        if (form.getValue().version) {
            const rowVersion = docNumbers[form.getValue().version.value.split(/.(?=[^.]+$)/)[0]];
            if (rowVersion) {
                const rowDocTypes = rowVersion
                    .filter((doc) => doc.name === docName.split(".")[0])
                    .map((doc) => doc.docTypes.join(", "));
                return rowDocTypes;
            }
        }
    };

    const checkLegacy = (docName) => !AppConfig.rsx.legacyDocs.includes(docName);

    return (
        <>
            {docName.includes(format.toLowerCase() === "xml" ? "xsd" : format.toLowerCase()) &&
                (!showLegacy ? checkLegacy(docName.split(".")[0]) : true) && (
                    <SpsSummaryListRow selected={rowIsSelected}>
                        <SpsSummaryListColumn>
                            {/* Commenting checkbox until the bulk download is figured out */}
                            {/* <SpsSummaryListColumn width="2.8rem" lean>
        <SpsCheckbox
          aria-label="Row Selected"
          checked={!isEmpty(selectedVersion)}
          disabled={isDownloading}
          onChange={e => handleSelectionChange(e, docName, version)}
        />
      </SpsSummaryListColumn> */}
                            <div className="d-inline-block align-middle">
                                <div className="fs-18 lh-24">
                                    {docName.split(".")[0]}{" "}
                                    <i
                                        id={`header-${docName.split(".")[0]}`}
                                        className={`sps-icon sps-icon--sm sps-icon-info-circle`}
                                        style={{ color: "#0077AA" }}
                                    />
                                    <SpsTooltip for={`header-${docName.split(".")[0]}`}>
                                        {helpDesc[docName.split(".")[0]]}
                                    </SpsTooltip>
                                </div>
                                <div className="fs-14">
                                    {localDocNumbers.hasOwnProperty(docName.split(".")[0])
                                        ? localDocNumbers[docName.split(".")[0]]
                                        : getDocType()}
                                </div>
                            </div>
                        </SpsSummaryListColumn>
                        <SpsSummaryListColumn width={isBaseSchema ? "14rem" : "11.375rem"}>
                            {versionList.length > 1 ? (
                                <SpsForm formGroup={form} onSubmit={() => { }}>
                                    <span className={isBaseSchema ? "d-flex justify-content-between align-items-center" : ""}>
                                        {isBaseSchema && <label className="sps-form-group__label mb-0">
                                            <span className="sps-form-group__label-content">
                                                VERSION
                                            </span>
                                        </label>}
                                        <SpsSelect
                                            id="debounce-select"
                                            options={SearchVersion}
                                            disabled={isDownloading}
                                            searchDebounce={200}
                                            notClearable
                                            className="w-100 ml-1"
                                            searchPlaceholder="Find a version"
                                            textKey="label"
                                            formControl={form.get("version")}
                                        /></span>
                                </SpsForm>
                            ) : (
                                <span className="d-flex justify-content-start align-items-center">
                                    <label className="sps-form-group__label mb-0 mr-1">
                                        <span className="sps-form-group__label-content">
                                            VERSION
                                        </span>
                                    </label>{isBaseSchema ? parseFloat(versionList[0].label) : versionList[0].label}</span>
                            )}
                        </SpsSummaryListColumn>

                        <SpsSummaryListColumn width="3.8rem" leaner>
                            <SpsButton
                                kind="icon"
                                icon="download-cloud"
                                disabled={isDownloading}
                                onClick={() => fetchParticularData(docName)}
                                spinning={isApiCalled}
                            />
                        </SpsSummaryListColumn>
                    </SpsSummaryListRow>
                )}
        </>
    );
};

export default SchemaDownloadRow;
