import React from "react";

export default function AgreementPageContent({ headingClass }) {
    return (
        <div>
            <h2 className={`sps-page-title__subtitle ${headingClass}`}>PLEASE READ CAREFULLY.</h2>
            <p className="p2">
                These Terms of Use (“Terms”) govern your and your employer’s access to and use of
                this Web site (“Site”), which is owned and operated by SPS Commerce, Inc. (“SPS”,
                “we”, “us”, “our”), and the products and services offered on or available through
                the Site (“Products” and “Services”). Please return to this Site and review these
                Terms from time to time, as they may be amended without notice. Any changes to these
                Terms will be effective immediately upon the posting of the revised Terms on this
                Site. Some Products and Services are subject to additional terms and conditions. To
                the extent that any provision of those additional terms and conditions conflicts
                with these Terms, those additional terms and conditions shall prevail. By clicking a
                box indicating your acceptance or by accessing this Site, you agree to be bound by
                these Terms and are accepting these Terms on behalf of your employer, for which you
                represent and warrant that you have full authority to do whether your employer is a
                person, company, or legal entity. IF YOU DO NOT AGREE WITH THESE TERMS, DO NOT USE
                THIS SITE OR THE PRODUCTS OR SERVICES. Also please YOUR USE OF THIS SITE AND/OR THE
                PRODUCTS AND SERVICES INDICATES YOUR ACCEPTANCE OF THESE TERMS.
            </p>
            <h2 className={`sps-page-title__subtitle ${headingClass}`}>GENERAL.</h2>
            <p className="p2">
                SPS does not warrant that the contents of this Site or the Products and Services are
                error-free. Information on this Site may contain technical inaccuracies and/or
                typographical or other types of errors and may be changed or updated at any time
                without notice. SPS may also make improvements and/or changes in the Products and
                Services or services, products and/or programs described on this Site at any time
                without notice. SPS will use reasonable efforts to place accurate and up-to-date
                information on this Site but makes no warranty of its accuracy, completeness and/or
                timeliness. You acknowledge that your use of this Site, the Products and Services,
                and any information available through this Site is at your own risk.
            </p>
            <h2 className={`sps-page-title__subtitle ${headingClass}`}>COPYRIGHT AND TRADEMARK.</h2>
            <p className="p2">
                All materials on this Site, including, without limitation, all copyrights,
                trademarks, artwork, images and other elements (collectively, the “Content”), are
                protected by copyrights and other intellectual property rights owned and controlled
                by us or by other parties that have licensed or otherwise provided their material to
                us. Except as stated in these Terms, none of the Content on this Site may be copied,
                reproduced, used for the creation of derivative works, distributed, republished,
                downloaded, displayed, posted, framed or transmitted in any form or by any means,
                including, but not limited to, electronic, mechanical, photocopying, recording or
                otherwise, without the prior written permission of SPS or the copyright owner. You
                also may not, without SPS’ permission, “mirror” any Content contained on this Site
                on any other server. Any unauthorized use of any material contained on this Site may
                violate copyright laws, trademark laws, the laws of privacy and publicity, and
                communications regulations and statutes. You will not delete any proprietary,
                confidential, or intellectual property rights notices from anything provided on or
                though this Site or any Product or Service.
            </p>
            <h2 className={`sps-page-title__subtitle ${headingClass}`}>USE.</h2>
            <p className="p2">
                You agree that you will only use this Site (including features thereof and Products
                and Services) for their intended purpose. You agree to limit such uses to your
                personal, non-commercial use. You further agree not to: (i) use this Site, any
                content or other materials on this Site, or any Products or Services for any
                unlawful purpose; (ii) restrict or inhibit any other user from using and enjoying
                this Site or any Products or Services, including by means of hacking or defacing any
                portion of this Site, introducing any virus, worm, Trojan Horse, time bomb, spyware,
                or any other computer code, file or program that may or is intended to damage or
                hijack the operation of any hardware, software or telecommunications equipment, or
                otherwise acting in a manner that negatively affects other users’ ability to engage
                in real time exchanges; (iii) impersonate any other person or entity, or
                misrepresent your affiliation with any other person or entity; or (iv) use any
                robot, spider, site search/retrieval application, or other manual or automatic
                device or process to retrieve, index, “data mine,” or in any way reproduce or
                circumvent the navigational structure or presentation of this Site or any content or
                other materials on this Site. Any software or documentation included on this Site or
                in a Product or Service is supplied only under license.
            </p>
            <p className="p2">
                You are responsible for and shall abide by all applicable local, state, national and
                foreign laws, treaties and regulations in connection with your access to and use of
                this Site and all Products and Services, including those related to data privacy,
                international communications and the transmission of technical or personal data. You
                will not decompile, reverse engineer, modify, adapt, translate, prepare derivative
                works, disassemble, or otherwise attempt to discover the source code of this Site or
                the Products or Services, nor will you allow others to do the aforementioned.
            </p>
            <p className="p2">
                You authorize SPS to access, collect, modify, process, transfer, analyze, or
                otherwise use (Process) data transmitted or provided by You to SPS. In addition, you
                authorize SPS to Process your data to provide reports to you or other third parties.
                You represent and warrant that (1) you have received the consent necessary to
                access, collect, and export data, and that you have no reason to believe you may not
                do so, (2) you are only transmitting data to Us which is necessary for Us to provide
                Services to You, and that the data, or your transmission of the data, (i) does not
                violate your obligations to any third party, (ii) any third party’s intellectual
                property rights, or (iii) any laws or regulations, and (3) the data that you provide
                SPS does not contain credit or debit card information. You agree to indemnify SPS
                for any of its costs arising from your breach of these representations and
                warranties.
            </p>
            <p className="p2">
                SPS has the right to terminate your use of this Site, any portion of this Site, or
                any Product or Service (including, but not limited to, revoking any rights or
                licenses and suspending or terminating any Products or Services) with or without
                notice, in its sole discretion, for any reason or no reason at all, including, but
                not limited to, if SPS determines (in its sole discretion) that your use of or
                conduct on or related to this Site (i) violates these Terms or any additional terms
                and conditions, (ii) is harmful or disruptive to SPS, its customers or vendors,
                another user or any other party, or (iii) violates applicable law.
            </p>
            <h2 className={`sps-page-title__subtitle ${headingClass}`}>DOWNLOADING.</h2>
            <p className="p2">
                You may only download those materials from this Site that are expressly designated
                to be downloaded by users. Where a fee is required to download any materials, you
                may not download such materials without paying such fee. You agree not to use
                technical or other means to circumvent these Terms or any content protection
                technology used on this Site. Any unauthorized use, transmission, distribution,
                reproduction, reverse engineering, modification of any downloaded materials, or use
                thereof for an illegal purpose, is expressly prohibited.
            </p>
            <p className="p2">
                There are inherent dangers in the use of any software available for downloading on
                the Internet, and SPS cautions you to make sure that you completely understand the
                potential risks before downloading any of the software (including the potential
                infection of your system by computer viruses). You are solely responsible for
                adequate protection and backup of the data and equipment used in connection with any
                of the software and assume all risks associated with any downloaded software.
            </p>
            <h2 className={`sps-page-title__subtitle ${headingClass}`}>
                SUBMISSION OF INFORMATION OR MATERIALS.
            </h2>
            <p className="p2">
                You may be required to provide us with information about yourself in order to enjoy
                certain features of this Site, such as setting up your company profile and product
                catalog. If you choose to provide us with such information, you agree: (i) to
                provide true, accurate, current and complete information about yourself (and your
                company if you are opening an account or making a purchase on behalf of your
                company) as prompted by this Site, and (ii) to maintain and update this information
                to keep it true, accurate, current and complete. If any information provided by you
                is untrue, inaccurate, not current or incomplete, we have the right to terminate
                your account and refuse any and all current or future use of this Site and/or the
                Products and Services. In addition, you hereby grant us a worldwide, royalty-free,
                paid-up, transferable, non-exclusive license to use, reproduce, distribute,
                transmit, display, publicly perform, and publish your and your company’s information
                including but not limited to logos, trademarks, product names and product pictures
                for the purposes of providing, administering, enhancing, supporting, marketing and
                other business purposes of our products and services to you or any customer.
            </p>
            <p className="p2">
                If you are submitting data through this Site, you are solely responsible for the
                protection and the integrity of such transmissions and the data contained therein,
                and shall provide all documents and data in a format as specified by SPS.
            </p>
            <p className="p2">
                SPS does not accept unsolicited ideas for new products, packaging, marketing,
                advertising, research, business operations and the like. Should you ignore this
                policy and submit an idea to SPS, you agree that the idea becomes and remains the
                sole and exclusive property of SPS without further liability or compensation to you.
                You agree that any information or materials that you submit to SPS via this Site or
                any email links provided on this Site will not be considered confidential or
                proprietary. All such materials shall be deemed the property of SPS, and your
                submission of information shall constitute an assignment to SPS of all worldwide
                rights, titles, and interests in such information. SPS will not be liable for any
                use or disclosure of such information. SPS will not have any obligation to keep such
                information confidential and will be free to reproduce, use, disclose, distribute,
                publicly perform and transmit such information to others without limitation or
                compensation. SPS will be free to use any ideas, concept, know-how or techniques
                contained in such information for any purpose whatsoever, including but not limited
                to developing, manufacturing and marketing products which incorporate or otherwise
                rely upon such information.
            </p>
            <h2 className={`sps-page-title__subtitle ${headingClass}`}>PASSWORD AND USER NAME.</h2>
            <p className="p2">
                Access to some Content and other materials and use of some Products and Services is
                password controlled and is accessible to registered users only. You certify that all
                information provided in the registration process is accurate and correct. If you are
                registering or making a purchase or conducting other transactions on behalf of your
                company, you represent and certify that you are an authorized representative of your
                company with the right to bind your company to these Terms (and any additional terms
                necessary for access to password-controlled content, Products or Services). You are
                solely responsible for maintaining the confidentiality of your account information,
                including, without limitation, your user name and password. You are solely
                responsible for any and all activities that occur under your user name and password.
                You agree to notify SPS immediately of any unauthorized use of your user name,
                password or account or any other breach of security. SPS will not be liable for any
                loss that you may incur as a result of someone else using your user name, password
                or account, either with or without your knowledge. You will be held liable for
                losses incurred by SPS as a result of the misuse of your user name, password or
                account.
            </p>
            <h2 className={`sps-page-title__subtitle ${headingClass}`}>
                LINKS TO THIRD PARTY SITES.
            </h2>
            <p className="p2">
                There are links on this Site that will permit you to travel to other, third-party
                sites over which SPS has no control. These links are provided for your convenience
                only and your use of them is at your own risk. SPS makes no representations
                whatsoever about the content of any of these other Web sites, or about any of the
                links contained in the Web sites that you may access through this Site. SPS does not
                endorse or accept any responsibility for the content, or use, of any such linked Web
                sites.
            </p>
            <h2 className={`sps-page-title__subtitle ${headingClass}`}>THIRD-PARTY DISCLAIMER.</h2>
            <p className="p2">
                SPS offers third-party names and information as an informational service only; it is
                not an endorsement or recommendation – implied or otherwise – of any of the listed
                companies or information. Each company listed is independent from SPS and is not
                under the control of SPS. SPS accepts no responsibility for and disclaims any
                liability from the actions of the listed companies or reliance on any information
                from or about such companies. You should make your own independent evaluation before
                conducting business with any such company or relying on any such information.
            </p>
            <h2 className={`sps-page-title__subtitle ${headingClass}`}>
                CONFIDENTIAL INFORMATION.
            </h2>
            <p className="p2">
                You may be given access to SPS confidential information through this site, the
                Products, Services, or links from this site. You may not disclose SPS confidential
                information to any third party without the written consent of SPS. You must protect
                SPS confidential information with at least the same degree of care that is accorded
                to your confidential information, but in no event less than reasonable care. SPS
                confidential information includes, but is not limited to – all non-public
                information regarding SPS, its intellectual property or its products, quantity and
                prices of products purchased, design and development data, engineering details,
                sales and marketing plans, unannounced products, any information marked as
                “confidential” or “proprietary” or similarly marked, or any information that, if
                disclosed, might be competitively detrimental to SPS. You may enter into separate
                non-disclosure agreements with SPS governing specific disclosures. To the extent the
                terms governing a specific disclosure are more restrictive than the terms in this
                paragraph, the more restrictive terms will control for the specific disclosure.
            </p>
            <p className="p2">
                EMAIL FROM SPS. SPS may from time to time send you email. You acknowledge that such
                emails are sent with your consent and permission. If you do not wish to receive any
                promotional email from us, you can follow the opt-out direction set forth in such
                email.
            </p>
            <h2 className={`sps-page-title__subtitle ${headingClass}`}>
                INTERNATIONAL TRADE COMPLIANCE.
            </h2>
            <p className="p2">
                The Products, Services, and goods licensed or sold through this Site, which may
                include technology and software, are subject to the customs and export control laws
                and regulations of the U.S. and may also be subject to the customs and export laws
                and regulations of the country in which the products are manufactured or received.
                Further, under U.S. law, such goods may not be sold, leased or otherwise transferred
                to restricted countries, or used by a restricted end-user or an end-user engaged in
                activities related to weapons of mass destruction including, without limitation,
                activities related to designing, developing, producing or using nuclear weapons,
                materials, or facilities, missiles or supporting missile projects, or chemical or
                biological weapons. You acknowledge that it is your responsibility to comply with
                and abide by those laws and regulations, and that any customer or vendor that you
                allow to access this Site, the Products or Services, or that you request SPS to
                route product to directly has also been made aware of and will comply with the
                associated export controls.
            </p>
            <h2 className={`sps-page-title__subtitle ${headingClass}`}>INDEMNITY.</h2>
            <p className="p2">
                By using this Site and/or a Product of Service you agree to indemnify SPS, and its
                subsidiaries, partners, affiliates, agents, consultants, sponsors, advertisers and
                licensors, and their respective officers, directors, employees, shareholders, agents
                and representatives (collectively, the “Released Parties”), and hold the Released
                Parties harmless from any and all claims and expenses, including attorneys’ fees,
                arising from your use of this Site or the Content, Products, Services, or other
                materials on this Site, your breach of any term or condition contained in these
                Terms or any other agreement applicable to any specific products, services,
                functions or areas of this Site, or your submission of information or materials to
                SPS by any means or from any person’s use of any account or password you maintain
                with SPS or this Site, regardless of whether such use is authorized by you. By using
                this Site, the Products, or Services, or submitting any ideas and/or related
                materials to SPS, you are hereby agreeing to release the Released Parties from any
                and all claims, demands, debts, obligations, damages (actual or consequential),
                costs, and expenses of any kind or nature whatsoever, whether known or unknown,
                suspected or unsuspected, disclosed or undisclosed, that you may have against them
                arising out of or in any way related to such disputes and/or to any Products and
                Services or to any disputes regarding use of ideas and/or related materials
                submitted to SPS.
            </p>
            <p className="p2">
                YOU HEREBY AGREE TO WAIVE ALL LAWS THAT MAY LIMIT THE EFFICACY OF SUCH RELEASES. FOR
                EXAMPLE, YOU SPECIFICALLY AGREE TO WAIVE THE PROVISIONS OF CALIFORNIA CIVIL CODE
                SECTION 1542, WHICH PROVIDES IN RELEVANT PART: “A GENERAL RELEASE DOES NOT EXTEND TO
                CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME
                OF EXECUTING THE RELEASE, WHICH IS KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS
                SETTLEMENT WITH THE DEBTOR.”
            </p>
            <h2 className={`sps-page-title__subtitle ${headingClass}`}>WARRANTY DISCLAIMER.</h2>
            <p className="p2">
                THIS SITE, THE PRODUCTS AND SERVICES, AND ALL CONTENT AND MATERIALS ON THIS SITE ARE
                PROVIDED “AS IS” WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. TO THE
                FULLEST EXTENT POSSIBLE PURSUANT TO THE APPLICABLE LAW, SPS DISCLAIMS ALL
                WARRANTIES, EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES
                OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OR OTHER
                VIOLATION OF RIGHTS. SPS DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE
                USE, VALIDITY, ACCURACY, OR RELIABILITY OF, OR THE RESULTS OF THE USE OF, OR
                OTHERWISE RESPECTING, THIS SITE OR THE CONTENT OR MATERIALS ON THIS SITE OR ANY WEB
                SITES LINKED TO THIS SITE OR ANY PRODUCTS OR SERVICES, INCLUDING, WITHOUT
                LIMITATION, THAT (I) USE OF THIS SITE OR ANY CONTENT OR MATERIALS ON OR FROM THIS
                SITE (INCLUDING ANY SOFTWARE, PRODUCT OR SERVICE) WILL BE UNINTERRUPTED, (II) THIS
                SITE WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, (III) ANY ERRORS ON THIS
                SITE WILL BE CORRECTED, OR (IV) THE SERVERS ON WHICH THIS SITE, CONTENT AND
                MATERIALS ARE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. Some
                jurisdictions do not allow a disclaimer of some or any implied warranties. In such
                jurisdictions, the foregoing disclaimers may not apply to you insofar as they relate
                to implied warranties, to the extent prohibited.
            </p>
            <h2 className={`sps-page-title__subtitle ${headingClass}`}>LIMITATION OF LIABILITY.</h2>
            <p className="p2">
                IN NO EVENT WILL SPS, OR ITS SUBSIDIARIES, PARTNERS, AGENTS, AFFILIATES, LICENSORS,
                SPONSORS OR ADVERTISERS, BE LIABLE TO YOU OR ANY OTHER PERSON OR ENTITY FOR ANY
                DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, EVEN IF WE ARE
                EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, FOR ANY USE OF THIS SITE, THE
                PRODUCTS OR SERVICES, OR INFORMATION CONTAINED ON THIS SITE, OR ON ANY OTHER
                HYPERLINKED WEB SITE, INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, LOST REVENUE,
                BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR OTHER DATA ON YOUR INFORMATION HANDLING
                SYSTEM OR OTHERWISE.
            </p>
            <h2 className={`sps-page-title__subtitle ${headingClass}`}>
                JURISDICTION AND GOVERNING LAW.
            </h2>
            <p className="p2">
                This Site is maintained, controlled, operated and administered by SPS from within
                the United States of America (“U.S.”). SPS makes no representation that the Content,
                Products, Services, or any other materials on this Site are appropriate for use at
                locations outside of the U.S. and access to this Site from countries where such
                Content or materials are illegal is prohibited. You are responsible for compliance
                with all laws. The use of this Site, the Products and Services, the Content or any
                other materials on this Site, and these Terms are governed by the laws of the United
                States and the State of Minnesota, without regard to its conflict of laws
                provisions. Any legal proceeding arising out of the use of this Site, the Products
                and Services, the Content or any other materials on this Site, or these Terms must
                be brought in Minneapolis, Minnesota and must be brought within one year after the
                claim or cause of action arises or it is barred. By using this Site or the Products
                and Services, you irrevocably submit to the jurisdiction of the State and Federal
                courts located in Minneapolis, Minnesota. If for any reason a court of competent
                jurisdiction finds any provision of these Terms to be unenforceable, that provision
                will be enforced to the maximum extent permissible so as to effect the intent of
                these Terms, and the remainder of these Terms will continue in full force and
                effect.
            </p>
            <h2 className={`sps-page-title__subtitle ${headingClass}`}>ENTIRE AGREEMENT.</h2>
            <p className="p2">
                These Terms represent the entire agreement relating to the use of the Site. SPS’
                failure to enforce any right or provision of these Terms does not constitute a
                waiver of that right or provision.
            </p>
            <h2 className={`sps-page-title__subtitle ${headingClass}`}>QUESTIONS OR COMMENTS.</h2>
            <p className="p2">
                If you have any questions or comments about these Terms, please contact us at{" "}
                <a href="mailto:legal@spscommerce.com">legal@spscommerce.com</a>
            </p>
        </div>
    );
}
