import React from "react";
import RemarkLowlight from "remark-react-lowlight";
import "highlight.js/styles/github.css";

import { supportedLanguages } from "../../utils";

const RemarkCode = RemarkLowlight(supportedLanguages);

export default function SpsCode(props) {
    const { children, ...rest } = props;
    return (
        <RemarkCode {...rest} inline={false}>
            {children}
        </RemarkCode>
    );
}
