import React, {useState} from "react";
import { SpsButton } from "@spscommerce/ds-react";
import { SpsIcon, ButtonKind } from "@spscommerce/ds-shared";

import "./BannerAllUser.scss";

function BannerAllUser({maintenanceMessage, className: addClassName}) {
    const [showBanner, setShowBanner] = useState(true);
    const bannerInfo = maintenanceMessage ? maintenanceMessage : {isEnabled:false};
    return (
        bannerInfo.isEnabled &&
        showBanner && 
        <div className={`all-user-banner${addClassName ? " " + addClassName : ""}`}>
            <div className="message">
                <p className="m-1">
                    Dev Center will be down for scheduled maintenance on {bannerInfo.date} at {bannerInfo.time} for up to {bannerInfo.duration}. Visit the 
                    <a href="https://status.spscommerce.com/" target="_blank" rel="noreferrer noopener" style={{textDecoration:"none"}}> status page </a> 
                    for updates.
                </p>
            </div>
            <div className="closeBtn">
                <SpsButton 
                    onClick={()=>setShowBanner(false)}
                    kind={ButtonKind.ICON}
                    icon={SpsIcon.X}
                />
            </div>
        </div>
    );
}

export default BannerAllUser;