import "./FieldsAndQualifierPage.scss";

import React, {
    Fragment,
    useEffect,
    useState,
} from "react";

import axios from "axios";
import ReactPlaceholder from "react-placeholder";
import {
    NavLink,
    withRouter,
} from "react-router-dom";

import {
    SpsButton,
    SpsCard,
    SpsCheckbox,
    SpsDescriptionList,
    SpsDescriptionListDefinition,
    SpsDescriptionListTerm,
    SpsListToolbar,
    SpsListToolbarSearch,
    SpsSelect,
    SpsTag,
    SpsTextInput,
    useGrowlers,
} from "@spscommerce/ds-react";
import { withCommercePlatform } from "@spscommerce/ui-react";

import AppConfig from "../../../App.config";
import { MarkdownPlaceHolder } from "../../../components/Placeholders";
import Footer from "../../../Footer";

const FieldsAndQualifierPage = function(props) {
  const { environment, token } = props.commercePlatform;
  const { version, documentType } = props;
  const [resultList, setResultList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterList, setFilterList] = useState([]);
  const [documentVal, setDocumentVal] = useState();
  const [onlyQualifiers, setOnlyQualifiers] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [growlers, createGrowler] = useGrowlers();

  useEffect(() => {
    getSchemasNames();
    setDocumentVal(() => documentType);
  }, []);

  useEffect(
    () => {
      if (documentVal) {
        clearList();
        getSchema();
        updateQueryString();
      }
    },
    [documentVal]
  );

  useEffect(
    () => {
      updateResults();
    },
    [resultList, searchTerm, onlyQualifiers]
  );

  const handleSearchTermChange = e => {
    setSearchTerm(e.target.value);
  };

  const options = (search = "") => {
    return documents.filter(s => new RegExp(search, "i").test(s));
  };

  const changeHandler = e => {
    setDocumentVal(() => e.target.value);
  };

  const clearList = () => {
    setResultList([]);
  };

  const updateQueryString = () => {
    const { history } = props;
    const newPath = `/rsx/docs/fields-qualifiers/${version}/${documentVal}`;
    history.push(newPath);
  };

  const updateResults = () => {
    const searchResults = resultList
      .filter(doc => (onlyQualifiers ? doc.enums.length > 0 : doc))
      .filter(
        obj =>
          obj.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          obj.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    setFilterList(searchResults);
  };

  const getSchema = async () => {
    if (environment && token) {
      try {
        const url = `${
          AppConfig.rsx[environment]
        }/v1/schema/${version}/${documentVal}/fields/`;
        const headers = {
          Authorization: `Bearer ${token}`,
          "X-SPS-Service": `${AppConfig.devCenterUI.name}:${AppConfig.devCenterUI.id}`,
        };
        const schemaEndPointConfig = {
          method: "get",
          headers,
          url: url
        };
        axios(schemaEndPointConfig).then(
          response => {
            const data = response.data.fields;
            setResultList(data);
          },
          error => {
            setResultList([]);
            return;
          }
        );
      } catch (error) {
        setResultList([]);
        createGrowler({
          kind: "error",
          title: "Error",
          content: () =>
            "An error has occurred while getting schema, please try again later."
        });
      }
    }
  };

  const getSchemasNames = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`
      };
      const url = `${
        AppConfig.rsx[environment]
      }/v1/valid_schema_names/?version=${version}`;
      const apiConfig = {
        method: "get",
        headers,
        url: url
      };
      const response = await axios(apiConfig);
      setDocuments(response.data.schemas[version]);
    } catch (error) {
      setDocuments([]);
      createGrowler({
        kind: "error",
        title: "An error has occurred while fetching schema names",
        content: () =>
          "An error has occurred while fetching schema names, please try again later."
      });
    }
  };

  const generateFieldName = (name, isQualifier) => {
    if (isQualifier) {
      return (
        <Fragment>
          <NavLink
            to={`/rsx/docs/fields-qualifiers/${version}/${documentType}/${name}`}
          >
            {name}
          </NavLink>{" "}
          <SpsTag kind="info">Qualifier</SpsTag>
        </Fragment>
      );
    }
    return name;
  };

  const renderFields = () => {
    return filterList.map(obj => {
      const name = obj.name;
      return (
        <Fragment key={name}>
          <SpsDescriptionListTerm>
            {generateFieldName(name, obj.enums.length)}
          </SpsDescriptionListTerm>
          <SpsDescriptionListDefinition>
            {obj.description}
          </SpsDescriptionListDefinition>
        </Fragment>
      );
    });
  };

  return (
    <Fragment>
      {growlers()}
      <div className="breadcrumb">
        <NavLink to={`/rsx/docs/fields-qualifiers`}>Fields and Qualifiers</NavLink>{" "}
        <i
          className="sps-icon sps-icon-angle-right section-icon"
          aria-hidden="true"
        />{" "}
        {documentVal}
      </div>
      <div className="col-9 docs-main-content fields-qualifiers-page">
        <SpsCard className="sps-docs-markdown">
          <span className="version-display">Version {version}</span>
          <div className="rsx-ui-page">
            <h2>Field and Qualifier List</h2>
            <p>
              You can access the certified RSX schemas qualifier list (approved
              for Universal Network eligibility) for a specific set of
              documents, tailored to an Industry and Fulfillment Model or access
              the full schema qualifier list.
            </p>
            <SpsListToolbar>
              <SpsListToolbarSearch>
                <SpsTextInput
                  name="search_text"
                  onChange={handleSearchTermChange}
                  placeholder="Filter Field and Qualified Field List"
                />
                <SpsButton type="submit" kind="icon" icon="search" />{" "}
              </SpsListToolbarSearch>
              <div>
                <SpsCheckbox
                  label="Show only Qualified Fields"
                  onChange={e => {
                    setOnlyQualifiers(e.target.checked);
                  }}
                />
                <SpsSelect
                  options={options}
                  onChange={changeHandler}
                  value={documentType}
                />
              </div>
            </SpsListToolbar>
            <ReactPlaceholder
              customPlaceholder={<MarkdownPlaceHolder />}
              ready={!!resultList.length}
            >
              <SpsDescriptionList wideTerms>
                {renderFields()}
              </SpsDescriptionList>
            </ReactPlaceholder>
          </div>
        </SpsCard>
        <Footer/>
      </div>
    </Fragment>
  );
};

export default withCommercePlatform(withRouter(FieldsAndQualifierPage));
