import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  SpsCard,
  SpsForm,
  SpsValidators,
  useForm,
  formControl,
  formGroup,
  SpsLabel,
  SpsTextInput,
  SpsButtonGroup,
  SpsButton,
  useGrowlers
} from "@spscommerce/ds-react";
import { ButtonKind, ButtonType } from "@spscommerce/ds-shared";
import ReactPlaceholder from "react-placeholder";
import { AUTH_FLOW_OAUTH_IMPLICIT } from "@spscommerce/ui-react";
import Footer from "../../Footer";

function ProfileContact({
  commercePlatform: {
    identityServiceUrl,
    token,
    currentUser,
    refreshCurrentUser
  } = {}
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [growlers, createGrowler] = useGrowlers();
  const [userData, setUserData] = useState({});

  function validatePhone(value) {
    const phonePattern = new RegExp(/^[^A-Za-z]+$/);
    return phonePattern.test(value) || !value ? null : { pattern: true };
  }

  const form = useForm(
    formGroup({
      phone_number: formControl("", {
        validators: [SpsValidators.maxLength(31), validatePhone]
      })
    })
  );

  useEffect(
    () => {
      const user = {
        phone_number: currentUser.phone_number
      };
      setUserData(user);
      form.setValue(user);
    },
    [currentUser]
  );

  function isFormValid() {
    const phone = form.get("phone_number");
    if (phone.value && phone.errors) {
      return false;
    } else {
      return true;
    }
  }

  async function updateContactInfo() {
    if (form.isValid()) {
      const payload = {
        email: form.getValue().email,
        phone_number: form.getValue().phone_number
      };
      const url = `${identityServiceUrl}/identity/v3/users/${
        currentUser.id
      }/profile/`;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      };

      try {
        setIsSubmitting(true);
        await axios.patch(url, payload, config);
        createGrowler({
          kind: "success",
          title: "Contact Information saved",
          content: () => "Your contact information was successfully saved."
        });
        setTimeout(() => {
          refreshCurrentUser(AUTH_FLOW_OAUTH_IMPLICIT);
        }, 500);
      } catch (error) {
        createGrowler({
          kind: "error",
          title: "Error saving Contact Information",
          content: () => "Your contact information was not saved."
        });
      } finally {
        setIsSubmitting(false);
      }
    }
  }

  return (
    <>
      {growlers()}
      <div className="col-9 docs-main-content">
        <div className="sps-docs-container-markdown">
          <SpsForm formGroup={form} onSubmit={updateContactInfo}>
            <SpsCard
              className="sps-docs-markdown"
              headerTitle="Contact Information"
              footer={() => {
                return (
                  <SpsButtonGroup className="text-right">
                    <SpsButton
                      kind={ButtonKind.DEFAULT}
                      onClick={() => {
                        form.reset(userData);
                      }}
                    >
                      Cancel
                    </SpsButton>
                    <SpsButton
                      disabled={!isFormValid() || form.isPristine()}
                      kind={ButtonKind.CONFIRM}
                      type={ButtonType.SUBMIT}
                      spinning={isSubmitting}
                    >
                      Save
                    </SpsButton>
                  </SpsButtonGroup>
                );
              }}
            >
              <ReactPlaceholder ready={true}>
                <div className="sps-row">
                  <div className="col-6">
                    <SpsLabel
                      help="To change your email address, please contact support."
                      for={form.get("email")}
                    >
                      Email Address
                    </SpsLabel>
                    {currentUser.email}
                  </div>
                  <div className="col-6">
                    <SpsLabel
                      for={form.get("phone_number")}
                      errors={() =>
                        (form.get("phone_number").hasError("pattern") &&
                          "Please enter a valid phone number.") ||
                        (form.get("phone_number").hasError("maxLength") &&
                          "Phone number cannot be longer than 31 characters.")
                      }
                    >
                      Phone Number
                    </SpsLabel>
                    <SpsTextInput formControl={form.get("phone_number")} />
                  </div>
                </div>
              </ReactPlaceholder>
            </SpsCard>
          </SpsForm>
        </div>
        <Footer/>
      </div>
    </>
  );
}

export default ProfileContact;
