import React from "react";

export default function AuthInfo({ authDocs = {} }) {
    return (
        <ul>
            <li>
                <a href={`/#/docs/${authDocs.slug}/web-service-applications`}>Learn more</a> about
                authentication for Web Service Applications
            </li>
            <li>
                <a href={`/#/docs/${authDocs.slug}/native-applications`}>Learn more</a> about
                authentication for Native Applications
            </li>
            <li>
                <a href={`/#/docs/${authDocs.slug}/single-page-applications`}>Learn more</a> about
                authentication for Single-Page Applications (SPA)
            </li>
            <li>
                <a href={`/#/docs/${authDocs.slug}/machine2machine-applications`}>Learn more</a>{" "}
                about authentication for Machine-to-Machine Applications
            </li>
        </ul>
    );
}
