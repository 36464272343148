import React, { useEffect, Fragment } from "react";
import remark from "remark";
import axios from "axios";
import remark2react from "remark-react";
import { withRouter, Link } from "react-router-dom";
import slug from "remark-slug";
import { defaultSchema } from "hast-util-sanitize/lib/schema.js"; 

import { SpsCard, SpsFeedbackBlock, usePatchReducer } from "@spscommerce/ds-react";

import { FileNotFound } from "../FileNotFound";
import DocumentationLink from "../DocumentationLink";
import SpsCode from "../Code";
import CodeOrSpsBlock from "./CodeOrSpsBlock";
import { uuid } from "../../utils/utils.js";
import { makePath } from "../../utils";

import "./MarkdownDocument.scss";

function MarkdownDocument(props) {
    const [state, patchState] = usePatchReducer({
        text: null,
        error: false,
    });

    const currentAvailableSwaggerDocPath = [
        "README",
        "identity-service/swagger",
        "mna/swagger",
        "objects-api/swagger",
    ];
    const newCardPatternPage = [
        "labels/shipping_label_object",
        "packing_slips/packing_slip_object",
    ];

    useEffect(() => {
        fetchAndParseDocument(props);
    }, [props]);

    async function fetchAndParseDocument(props) {
        const doc = await fetchDocument(props);
        if (doc) {
            await parseDocument(doc, props);
        }
    }

    async function fetchDocument(props) {
        // Fetch README or the given path.
        try {
            const markdownResponse = await axios.get(
                makePath({
                    root: props.docsRoot,
                    path: props.path,
                    defaultPath: "README",
                    appendExtension: "md",
                }),
            );
            patchState({
                error: false,
            });
            return markdownResponse.data;
        } catch (err) {
            patchState({
                error: true,
            });
        }
    }

    async function parseDocument(markdown, higherProps) {
        defaultSchema.attributes["*"].push("className");
        const rmk = remark()
            .use(slug)
            .use(remark2react, {
                sanitize: defaultSchema,
                remarkReactComponents: {
                    a: DocumentationLink,
                    code: SpsCode,
                    pre: (props) => <CodeOrSpsBlock cdn_url={higherProps.docsRoot} {...props} />,
                },
            });

        const MarkdownParts = markdown.split("!!!card");
        let delimitedPart = [];

        MarkdownParts.forEach((part) => {
            const splitArray = part.split("---card");
            delimitedPart.push({ contents: splitArray[0], insideCard: true });
            if (splitArray[1]) {
                delimitedPart.push({ contents: splitArray[1], insideCard: false });
            }
        });

        const contentResult = [];
        delimitedPart.forEach(async (part, index) => {
            let result = await rmk.process(part);
            contentResult.push({
                contents: result.contents,
                insideCard: part.insideCard,
            });
            if (index === delimitedPart.length - 1) {
                patchState({
                    text: contentResult,
                });
                if (props.scrollTo != null) {
                    // Scroll to an anchor if we were told to.
                    const el = document.getElementById(props.scrollTo);
                    if (el) {
                        el.scrollIntoView();
                    }
                }
            }
        });
    }

    function checkNewSwaggerDocumentation() {
        let isNewSwaggerDocumentation = false;
        const service = props.currentService.name;
        if (
            service === "developer-center-swagger-docs" &&
            !currentAvailableSwaggerDocPath.includes(props.path)
        ) {
            isNewSwaggerDocumentation = true;
        }

        if (service === "Shipping Doc API" && newCardPatternPage.includes(props.path)) {
            isNewSwaggerDocumentation = true;
        }
        return isNewSwaggerDocumentation;
    }

    return (
        <Fragment>
            {checkNewSwaggerDocumentation() ? (
                <Fragment>
                    {!state.error ? (
                        <Fragment>
                            {state.text == null ? "Loading" : state.text[0].contents}
                        </Fragment>
                    ) : (
                        <FileNotFound
                            heading="Documentation Not Found"
                            subheading="We could not find this page of the documentation"
                        />
                    )}
                </Fragment>
            ) : (
                <div className="sps-docs-container-markdown">
                    {!state.error ? (
                        state.text ? (
                            state.text.map((obj, index) => {
                                if (index === 0 && state.text.length < 2) {
                                    return (
                                        <SpsCard
                                            key={uuid()}
                                            className="sps-docs-markdown mb-3"
                                            id="preview"
                                        >
                                            {props.currentService && props.currentService.isBeta && (
                                                <SpsFeedbackBlock kind="tip">
                                                    <strong>Please Note:</strong> This new tool is
                                                    still in Beta! As you use this new service{" "}
                                                    <Link to="/contact-info">submit feedback </Link>
                                                    so we can continue to improve this service!
                                                </SpsFeedbackBlock>
                                            )}
                                            {obj.contents}
                                        </SpsCard>
                                    );
                                }
                                if (index === 0 || !obj.insideCard) {
                                    return <Fragment key={uuid()}>{obj.contents}</Fragment>;
                                }
                                return (
                                    <SpsCard
                                        key={uuid()}
                                        className="sps-docs-markdown mb-3"
                                        id="preview"
                                    >
                                        {props.currentService &&
                                            props.currentService.isBeta &&
                                            index === 1 && (
                                                <SpsFeedbackBlock key={uuid()} kind="tip">
                                                    <strong>Please Note:</strong> This new tool is
                                                    still in Beta! As you use this new service{" "}
                                                    <Link to="/contact-info">submit feedback </Link>
                                                    so we can continue to improve this service!
                                                </SpsFeedbackBlock>
                                            )}
                                        {obj.contents}
                                    </SpsCard>
                                );
                            })
                        ) : (
                            "loading"
                        )
                    ) : (
                        <FileNotFound
                            heading="Documentation Not Found"
                            subheading="We could not find this page of the documentation"
                        />
                    )}
                </div>
            )}
        </Fragment>
    );
}

export default withRouter(MarkdownDocument);
