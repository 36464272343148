import React from "react";
import * as PropTypes from "prop-types";

import { SpsCard } from "@spscommerce/ds-react";

export function contentOf(nodeOrRenderFn) {
    return typeof nodeOrRenderFn === "function" ? nodeOrRenderFn() : nodeOrRenderFn;
}

export const propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    heading: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    body: PropTypes.string,
};

export default function FullWidthCard(props) {
    const { heading, body, children } = props;
    return (
        <SpsCard className="home-page-connect-card">
            <h3 className="home-page-connect-card__heading">{contentOf(heading)}</h3>
            <p>{body}</p>
            <div className="row">{children}</div>
        </SpsCard>
    );
}
