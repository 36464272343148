import langBash from "highlight.js/lib/languages/bash";
import langCSS from "highlight.js/lib/languages/css";
import langDockerfile from "highlight.js/lib/languages/dockerfile";
import langDiff from "highlight.js/lib/languages/diff";
import langGo from "highlight.js/lib/languages/go";
import langHTTP from "highlight.js/lib/languages/http";
import langJava from "highlight.js/lib/languages/java";
import langJavaScript from "highlight.js/lib/languages/javascript";
import langJSON from "highlight.js/lib/languages/json";
import langMakefile from "highlight.js/lib/languages/makefile";
import langMarkdown from "highlight.js/lib/languages/markdown";
import langPython from "highlight.js/lib/languages/python";
import langSQL from "highlight.js/lib/languages/sql";
import langXML from "highlight.js/lib/languages/xml";
import langYAML from "highlight.js/lib/languages/yaml";

export const supportedLanguages = {
  bash: langBash,
  css: langCSS,
  docker: langDockerfile,
  dockerfile: langDockerfile,
  diff: langDiff,
  go: langGo,
  // Note that HTML is just an alias of XML
  // Also several of these languages have
  // aliases in this list.
  html: langXML,
  http: langHTTP,
  java: langJava,
  js: langJavaScript,
  javascript: langJavaScript,
  json: langJSON,
  make: langMakefile,
  makefile: langMakefile,
  markdown: langMarkdown,
  md: langMarkdown,
  python: langPython,
  py: langPython,
  sql: langSQL,
  sh: langBash,
  xml: langXML,
  yaml: langYAML
};
