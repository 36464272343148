import "./InlineServiceCard.scss";

import React from "react";

import classnames from "classnames";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import _ from "lodash";
import ReactPlaceholder from "react-placeholder";
import { Link } from "react-router-dom";

import {
    SpsCard,
    SpsToggle,
} from "@spscommerce/ds-react";

import config from "../../App.config";
import { getDocPath } from "../../utils";
import { InlineServicePlaceHolder } from "../Placeholders";

function InlineServiceCardBody(props) {
    const { services, servicesLoaded, onToggle, ldControlledApiDocs } = props;
    const serviceIconClass = (icon) => {
        // TODO: default icon
        return icon ? icon : "sps-icon-truck-shipping";
    };

    return (
        <div>
            <ReactPlaceholder
                customPlaceholder={<InlineServicePlaceHolder onToggle={onToggle} />}
                ready={servicesLoaded}
            >
                {services.map((service) => {
                    if (
                        (_.has(ldControlledApiDocs, service.slug) &&
                            ldControlledApiDocs[service.slug]) ||
                        !_.has(ldControlledApiDocs, service.slug)
                    ) {
                        return (
                            <div
                                key={service.id}
                                className={`service-row ${onToggle ? "toggle" : ""}`}
                            >
                                <div className="container-circle">
                                    <div className="circle">
                                        <i
                                            className={`circle-icon sps-icon ${serviceIconClass(
                                                service.icon,
                                            )}`}
                                        />
                                    </div>
                                </div>
                                <div className="service-link">
                                    <div className="service-name">
                                        <Link
                                            to={{
                                                pathname: `${getDocPath(service)}`,
                                                state: { service: service.name },
                                            }}
                                        >
                                            {service.name}
                                        </Link>
                                    </div>
                                    <div>
                                        <p className="service-descr">{service.description}</p>
                                    </div>
                                </div>
                                {onToggle ? (
                                    <div className="toggle">
                                        <SpsToggle onChange={props.onToggle} />
                                    </div>
                                ) : null}
                            </div>
                        );
                    }
                    return null;
                })}
            </ReactPlaceholder>
        </div>
    );
}

function InlineServiceCard(props) {
    const {
        environment,
        hasHeader,
        headerTitle,
        className,
        services,
        // Add the feature flag here for other API Resources docs
        flags: { documentCollectionApi, inventoryApi },
        ...rest
    } = props;

    const ldControlledApiDocs = {
        // "<service slug>": <Launchdarkly feature flag>
        "document-collection-api": documentCollectionApi,
        "inventory-api": inventoryApi,
    };

    // Any service whose slug is included  in the blacklist array for any environment
    // won't display in that environment
    const filteredServices = services.filter(
        (service) =>
            !config.documentation.serviceDocsBlacklist[environment].includes(service.id) &&
            service.public &&
            service.active &&
            service.serviceType === "API",
    );

    const cardClass = classnames("sps-card--collpased", className);
    return (
        <div className="inline-service-card">
            {hasHeader ? (
                <SpsCard headerTitle={headerTitle} className={cardClass}>
                    <InlineServiceCardBody
                        ldControlledApiDocs={ldControlledApiDocs}
                        services={filteredServices}
                        {...rest}
                    />
                </SpsCard>
            ) : (
                <SpsCard className={cardClass}>
                    <InlineServiceCardBody
                        ldControlledApiDocs={ldControlledApiDocs}
                        services={filteredServices}
                        {...rest}
                    />
                </SpsCard>
            )}
        </div>
    );
}

export default withLDConsumer()(InlineServiceCard);
export { InlineServiceCardBody };
