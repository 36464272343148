import * as React from "react";
import PropTypes from "prop-types";

import { SpsCard } from "@spscommerce/ds-react";

import "./QualifierLink.scss";

const QualifierLink = function (props) {
    const { text, description, onClick } = props;
    const clickHandler = () => {
        if (onClick && typeof onClick === "function") {
            onClick(text);
        }
    };
    return (
        <div className="qualifier-link col-6" onClick={clickHandler}>
            <SpsCard>
                <p className="qualifier-link--text">{text}</p>
                <p className="qualifier-link--description">{description}</p>
            </SpsCard>
        </div>
    );
};

QualifierLink.propTypes = {
    text: PropTypes.string.isRequired,
    description: PropTypes.string,
    onClick: PropTypes.func,
};

export default QualifierLink;
