import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

import { SpsNavBar, SpsNavBarItem, SpsNavBarItems } from "@spscommerce/ui-react";

function SpsNavLinkWithoutRouter(props) {
    const {
        children,
        to,
        match,
        location,
        navLinkStyle,
        navLinkActiveStyle,
        staticContext,
        ...rest
    } = props;
    // Highlight the appropriate top-level navigation section in the UI even if we're not on that exact path
    const startPath = `/${to.split("/")[1]}`;
    const active = location.pathname.startsWith(startPath);

    return (
        <NavLink
            to={to}
            style={{ ...navLinkStyle }}
            activeStyle={{ ...navLinkActiveStyle }}
            {...rest}
        >
            <SpsNavBarItem active={active}>{children}</SpsNavBarItem>
        </NavLink>
    );
}

export const SpsNavLink = withRouter(SpsNavLinkWithoutRouter);

function Nav(props) {
    const {
        children,
        flags: {
            stoplightDocs
        },
    } = props;

    return (
        <>
            <SpsNavBar
                appLogo="assets/devcenter-logo.svg"
                appName="DEV CENTER"
                appUrl="/home"
                items={({ NavBarItems, NavBarItem, navLinkStyle, navLinkActiveStyle }) => {
                    return (
                        <SpsNavBarItems>
                            <SpsNavLink navLinkStyle={navLinkStyle} to="/home">
                                Home
                            </SpsNavLink>
                            <SpsNavLink navLinkStyle={navLinkStyle} to="/applications">
                                Applications
                            </SpsNavLink>
                            <SpsNavLink navLinkStyle={navLinkStyle} to="/docs/getting-started">
                                API Documentation
                            </SpsNavLink>
                            {stoplightDocs && (
                                <SpsNavLink navLinkStyle={navLinkStyle} to="/api-docs/getting-started">
                                    API Documentation (Stoplight)
                                </SpsNavLink>
                            )}
                            <SpsNavLink navLinkStyle={navLinkStyle} to="/rsx/docs/rsx-overview">
                                Integration Resources
                            </SpsNavLink>
                            <SpsNavLink navLinkStyle={navLinkStyle} to="/user-guides/docs/customer-mapping-guides">
                                User Guides
                            </SpsNavLink>
                        </SpsNavBarItems>
                    );
                }}
            />
            {children}
        </>
    );
}

export default withLDConsumer()(Nav);
