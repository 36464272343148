import React, { useRef, useContext } from "react";
import { NavLink } from "react-router-dom";
import classnames from "classnames";

import { withCommercePlatform } from "@spscommerce/ui-react";

import {
    getWindowHrefWithMaxOneHash,
    isHash,
    stripFileExtension,
    stripLeadingDotSlashes,
    isExternal,
    isInternalLink,
} from "../../utils";
import { DocumentationServiceContext } from "../DocumentationServiceContext";
import config from "../../App.config";
import { PrivateRoutesContext } from "../../contexts/privateRouteContext";

// DocumentationLinkBase is needed so that we can have slightly different styles
// to replace anchor tags using remark's element replacement
// see TOC (side nav link) vs MarkdownDocument (normal link)
function DocumentationLinkBaseWithoutCommercePlatform(props) {
    const anchorElement = useRef(null);
    const privateRoutesContext = useContext(PrivateRoutesContext);

    function checkRules(item, link, user) {
        const rules = config.swaggerSidebarRules;
        const path = link.includes("?") ? link.split("?")[0] : link;
        if (!item || typeof item !== "string") {
            return false;
        }

        const itemArray = item.split(config.sidenavRulesCharacter);

        if (
            config.sideNavLockByName.some((i) => itemArray.includes(i)) ||
            itemArray.includes(rules.LOGIN_RESTRICTED) ||
            itemArray.includes(rules.INTERNAL)
        ) {
            privateRoutesContext.addPrivateRoute(path);
        }

        if (itemArray.includes(rules.INTERNAL)) {
            return (
                user &&
                user.organization &&
                (user.organization.namespace === "sps" || user.organization.namespace === "spsc")
            );
        }

        return true;
    }

    const {
        linkClass,
        isswagger,
        commercePlatform: { currentUser },
        sideNavLink = false,
    } = props || "";
    const baselink = props.baselink || "/docs";
    return (
        <span>
            <DocumentationServiceContext.Consumer>
                {({ currentlySelectedService }) => {
                    if (props.serviceSlug || currentlySelectedService) {
                        let { href, to } = props;
                        // Is there a (second) hash on the url?
                        // This is an inter anchor link that can
                        // be created in markdown with
                        // [Hello There](#hellothere)
                        let link = href || to;
                        // Regular relative links
                        const pathWithoutLeadingDot = stripLeadingDotSlashes(link);
                        const pathParts = stripFileExtension(pathWithoutLeadingDot);

                        // Adding this for new top-level TOC sections
                        const selectedServiceSlug = props.serviceSlug
                            ? props.serviceSlug
                            : currentlySelectedService.slug;

                        const navLinkPath =
                            `${baselink}/${selectedServiceSlug}` +
                            "/" +
                            pathParts.pathWithoutExtension +
                            (pathParts.pathAfterExtension.includes("?") && !isswagger
                                ? `?${pathParts.pathAfterExtension.split("?").splice(1)}`
                                : "") +
                            (isswagger ? pathParts.pathAfterExtension : "") +
                            (pathParts.hash ? "#" + pathParts.hash : "");

                        const children = sideNavLink
                            ? props.children.split(config.sidenavRulesCharacter)[0]
                            : props.children;

                        const route = link.split("/#/").join("");

                        if (sideNavLink && !checkRules(props.children, isInternalLink(link) ? route : navLinkPath, currentUser)) {
                            return null;
                        }

                        if (isHash(link)) {
                            const windowHrefWithoutHash = getWindowHrefWithMaxOneHash();
                            return (
                                <a
                                    className={linkClass}
                                    href={
                                        windowHrefWithoutHash +
                                        "#user-content-" +
                                        link.substr(1)
                                    }
                                    ref={anchorElement}
                                    onMouseDown={(e) => {
                                        if (e.button === 0) {
                                            const hrefBeforeMouseDown = anchorElement.current.href;
                                            anchorElement.href =
                                                window.document.location.href;
                                            const el = document.getElementById(
                                                "user-content-" + link.substr(1),
                                            );
                                            el.scrollIntoView();
                                            window.history.replaceState(
                                                null,
                                                null,
                                                getWindowHrefWithMaxOneHash() +
                                                "#user-content-" +
                                                href.substr(1),
                                            );
                                            anchorElement.current.href = hrefBeforeMouseDown;
                                        }
                                    }}
                                >
                                    {props.children}
                                </a>
                            );
                        }
                        // External links can be made in
                        // markdown with
                        // [Google](https://google.com)
                        if (isExternal(link)) {
                            return (
                                <a
                                    href={link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={linkClass}
                                >
                                    {props.children}
                                </a>
                            );
                        }

                        if (isInternalLink(link)) {
                            return (
                                <NavLink exact className={linkClass} to={`/${route}`}>
                                    {props.children}
                                </NavLink>
                            );
                        }

                        return (
                            <NavLink
                                exact
                                className={linkClass}
                                isActive={(_, { pathname }) =>
                                    pathname === navLinkPath.split("?")[0]
                                }
                                to={navLinkPath}
                            >
                                {children}
                            </NavLink>
                        );
                    }
                }}
            </DocumentationServiceContext.Consumer>
        </span>
    );
};

// export default withCommercePlatform(withRouter(AuthorizeApp));
export default function DocumentationLink(props) {
    return <DocumentationLinkBase {...props} />;
}

export function DocumentationSideLink(props) {
    const { className, ...rest } = props;
    let linkClasses = classnames("sps-side-nav__link", className);
    return <DocumentationLinkBase linkClass={linkClasses} sideNavLink={true} {...rest} />;
}

export const DocumentationLinkBase = withCommercePlatform(
    DocumentationLinkBaseWithoutCommercePlatform,
);
