import React, { Component } from "react";
import "./wizard.scss";

export default class WizardSection extends Component {
    render() {
        const {
            initiallyCollapsed,
            compIndex,
            onWizardHeaderClick,
            disableItems,
            expandAll,
        } = this.props;
        return (
            <div className="wizard-section">
                {React.Children.map(this.props.children, (child, index) => {
                    return React.cloneElement(child, {
                        disableItems,
                        expandAll,
                        initiallyCollapsed: initiallyCollapsed === true,
                        activeSection: this.props.compIndex,
                        compIndex: compIndex,
                        onWizardHeaderClick: () => onWizardHeaderClick(),
                    });
                })}
            </div>
        );
    }
}
