import React from "react";

import { SpsCard } from "@spscommerce/ds-react/";
import FullWidthCard from "../../components/FullWidthCard";
import "./HomePage.scss";

export default function Home() {
    return (
        <div className="col-9 not-loggedin-page">
            <SpsCard className="home-page-content__card">
                <h2 className="home-page-content__card-heading">Welcome to Dev Center!</h2>
                <p>
                    Welcome to SPS Commerce Dev Center where our tools and service leverage the
                    power of our retail network through services that are easy to build, implement,
                    and manage. Whether your OMS, ERP, or other solution is locally installed or
                    web-based, Dev Center allows you to easily get connected to the largest retail
                    network and start providing additional value to your customers.
                </p>
            </SpsCard>
            <FullWidthCard heading="Developer Benefits">
                <div className="row service-row service-row-mb">
                    <div className="col-3">
                        <img src="assets/buildapps.svg" alt="Build Custom Applications" />
                    </div>
                    <div className="col-9">
                        <div className="col-six-card-title">
                            <h4>Build Custom Applications</h4>
                        </div>
                        <p>
                            Our API services leverage the power of our retail network with
                            easy-to-build connections through a REST API service model. Dev Center
                            allows you to easily build solutions to streamline EDI integrations and
                            automate manual processes through our retailer-compliant APIs, including
                            our shipping label and packing slip APIs.
                        </p>
                    </div>
                </div>
                <div className="row service-row service-row-mb">
                    <div className="col-3">
                        <img src="assets/apiservices.svg" alt="Connect to Our Network" />
                    </div>
                    <div className="col-9">
                        <div className="col-six-card-title">
                            <h4>Connect to Our Network</h4>
                        </div>
                        <p>
                            Our comprehensive data interchange format includes all the data elements
                            that trading partners need to transact business as well as communicate
                            inventory, delivery, and status information. By utilizing Retail
                            Standard XML (RSX), you can use a single connection to interact with any
                            of our trading partners.
                        </p>
                    </div>
                </div>
                <div className="row service-row">
                    <div className="col-3">
                        <img src="assets/schema.svg" alt="Read API Resources" />
                    </div>
                    <div className="col-9">
                        <div className="col-six-card-title">
                            <h4>Read API Resources</h4>
                        </div>
                        <p>
                            All of the API resources are open to the public without an account.
                            Learn more about the services we currently provide, and come back often,
                            as we will be adding even more services over time!
                        </p>
                    </div>
                </div>
            </FullWidthCard>
        </div>
    );
}
