import React, { Fragment } from "react";
import { SpsCard, SpsDl, SpsDd, SpsDt, SpsButton, SpsIcon } from "@spscommerce/ds-react/";

import { SpsIcon as IconNames, SpsIconSize } from "@spscommerce/ds-shared";
import Parser from "html-react-parser";

import { uuid } from "../../utils/utils.js";

import "./SwaggerDocumentation.scss";

const SwaggerResponseKeysComponent = function ({ responseKeys = [] }) {
    function performScroll(elementId) {
        let height = 0;
        let blackBar = document.querySelector(".black-bar");
        if (blackBar) {
            height += blackBar.offsetHeight;
        }
        let navBarHeight = document.querySelector(".sps-navbar-container");
        if (navBarHeight) {
            height += navBarHeight.offsetHeight;
        }
        let scrollToElement = document.getElementById(elementId);
        if (scrollToElement) {
            scrollToElement.scrollIntoView();
            window.scrollBy(0, -height);
        }
    }

    function generateSectionTitle(name, path) {
        path = path ? "Response Keys." + path : "Response Keys" + path;
        const pathArray = path.split(".");
        return (
            <>
                <div className="section-title">
                    {pathArray.map((section, index) => {
                        if (index !== pathArray.length - 1) {
                            return (
                                <Fragment key={uuid()}>
                                    <span key={index} className="section-parent">
                                        {pathArray.length > 1 && (
                                            <SpsButton
                                                kind="link"
                                                onClick={() => {
                                                    performScroll(
                                                        `${pathArray
                                                            .slice(0, index + 1)
                                                            .join(".")}`,
                                                    );
                                                }}
                                            >
                                                {section}
                                            </SpsButton>
                                        )}
                                    </span>
                                    {!(index === pathArray.length - 2) && (
                                        <SpsIcon
                                            className="mx-1 section-icon"
                                            size={SpsIconSize.EXTRA_LARGE}
                                            icon={IconNames.ANGLE_RIGHT}
                                        />
                                    )}
                                </Fragment>
                            );
                        } else {
                            return null;
                        }
                    })}

                    {pathArray.length > 1 && (
                        <SpsIcon
                            className="mx-1 section-icon"
                            size={SpsIconSize.EXTRA_LARGE}
                            icon={IconNames.ANGLE_RIGHT}
                        />
                    )}
                    <span className="current-section" id={path}>
                        {name}
                    </span>
                </div>
            </>
        );
    }

    function generateLengthInfo(min, max, defaultValue) {
        return (
            <>
                {
                    <div>
                        {max && (
                            <>
                                <b>Max Length:</b> {max}
                                <br />
                            </>
                        )}
                        {min && (
                            <>
                                <b>Min Length:</b> {min}
                                <br />
                            </>
                        )}
                        {defaultValue && (
                            <>
                                <b>Default Value:</b> {defaultValue}
                                <br />
                            </>
                        )}
                    </div>
                }
            </>
        );
    }

    function generateDescriptionListTerm(name, path) {
        path = path ? "Response Keys." + path : "Response Keys" + path;
        const elementId = `${path}.${name}`;
        return (
            <SpsButton
                className="font-weight-bold fs-14"
                kind="link"
                onClick={() => {
                    performScroll(elementId);
                }}
            >
                {name}
            </SpsButton>
        );
    }

    function generateTypeInfo(type) {
        return (
            <div className="my-1">
                <div className="label-tool-type-info d-inline">{type}</div>{" "}
            </div>
        );
    }

    function generateDescriptionListRow(rowObject, path) {
        const { key, defaultValue, description, items, minLength, maxLength, type } = rowObject;
        return (
            <Fragment key={uuid()}>
                <SpsDt>
                    {items && items.length ? generateDescriptionListTerm(key, path) : key}
                    {type && generateTypeInfo(type)}
                </SpsDt>
                <SpsDd>
                    <div className="row">
                        <div className="col-12">
                            <span className="definition">
                                {description ? (
                                    <div className="mb-1">{Parser(description)}</div>
                                ) : null}
                                {generateLengthInfo(minLength, maxLength, defaultValue)}
                            </span>
                        </div>
                        <div className="col-2" style={{ textAlign: "right" }} />
                    </div>
                </SpsDd>
            </Fragment>
        );
    }

    function generateSectionBody(children, path) {
        let output = [];
        children.map((section) => {
            return output.push(generateDescriptionListRow(section, path));
        });
        return (
            <SpsDl wideTerms className="mt-2">
                {output}
            </SpsDl>
        );
    }

    function getValue(pathArray) {
        const path = [];
        let obj = responseKeys;
        pathArray = pathArray.split(".");
        pathArray.forEach((key) => {
            if (key === "key" || key === "items") {
                path.push(obj.key);
                if (key === "items") {
                    obj = obj[key];
                }
            } else {
                obj = obj[parseInt(key)];
            }
        });
        return path;
    }

    function getPath(name, obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (name === obj[key]) {
                    return key;
                } else if (obj[key] && typeof obj[key] === "object") {
                    var path = getPath(name, obj[key]);
                    if (path) {
                        return key + "." + path;
                    }
                }
            }
        }
    }

    function generateSection(section, keyName, path) {
        return (
            <>
                <Fragment key={uuid()}>
                    {
                        <>
                            {generateSectionTitle(keyName, path)}
                            {generateSectionBody(section, path)}
                        </>
                    }
                </Fragment>
            </>
        );
    }

    const renderResponseDocument = (response, keyName) => {
        const findPathFromObject = getPath(keyName, responseKeys);
        const path = keyName ? getValue(findPathFromObject).join(".") : "";
        return (
            <>
                <div key={uuid()} className="schema-document--card">
                    {generateSection(response, keyName ? keyName : "Response Keys", path)}
                </div>
                {response.map((child) => (
                    <>
                        {child.items && (
                            <div key={uuid()} className="schema-document--card">
                                {renderResponseDocument(child.items, child.key)}
                            </div>
                        )}
                    </>
                ))}
            </>
        );
    };

    return (
        <SpsCard>
            <div>{responseKeys && renderResponseDocument(responseKeys)}</div>
        </SpsCard>
    );
};

export default SwaggerResponseKeysComponent;
