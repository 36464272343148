import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  SpsCard,
  SpsForm,
  SpsValidators,
  useForm,
  formControl,
  formGroup,
  SpsLabel,
  SpsSelect,
  SpsButtonGroup,
  SpsButton,
  useGrowlers
} from "@spscommerce/ds-react";
import { ButtonKind, ButtonType } from "@spscommerce/ds-shared";
import ReactPlaceholder from "react-placeholder";
import { AUTH_FLOW_OAUTH_IMPLICIT } from "@spscommerce/ui-react";
import moment from "moment-timezone";
import Footer from "../../Footer";

const languages = [
  { value: "en-US", text: "English" },
  { value: "zh-CN", text: "简体字" },
  { value: "zh-TW", text: "繁體字" },
  { value: "fr-FR", text: "Français" },
  { value: "fr-CA", text: "Français Canadien" },
  { value: "es-ES", text: "Español" }
];
const timeZones = moment.tz.names();

function ProfileDisplay({
  commercePlatform: {
    identityServiceUrl,
    token,
    currentUser,
    refreshCurrentUser
  } = {}
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [growlers, createGrowler] = useGrowlers();
  const [userPreferences, setUserPreferences] = useState({});
  const [locales, setLocales] = useState([]);

  const form = useForm(
    formGroup({
      language: formControl("", {
        validators: [SpsValidators.required]
      }),
      timeZone: formControl("", {
        validators: [SpsValidators.required]
      }),
      dateTime: formControl("", {
        validators: [SpsValidators.required]
      })
    })
  );

  useEffect(
    () => {
      const prefrences = currentUser.preferences;
      updateDateTimeFormat(currentUser.preferences.timezone);
      const userPreference = {
        language: prefrences.language
          ? languages.find(lang => lang.value === prefrences.language[0])
          : "",
        timeZone: prefrences.timezone
          ? timeZones.find(tz => tz === prefrences.timezone)
          : "",
        dateTime: { name: "", value: prefrences.locale }
      };
      setUserPreferences(userPreference);
      form.setValue(userPreference);
    },
    [currentUser]
  );

  useEffect(
    () => {
      if (locales.length > 0) {
        form
          .get("dateTime")
          .setValue(
            locales.find(dt => dt.value === form.getValue().dateTime.value)
          );
      }
    },
    [locales]
  );

  const setLocaleDateTimeFormat = (locale, timeZone) => {
    moment.locale(locale);
    return moment.tz(timeZone).format("lll");
  };

  function isFormValid() {
    const formValues = form.getValue();
    if (!formValues.language || !formValues.dateTime || !formValues.timeZone) {
      return false;
    } else {
      return true;
    }
  }

  async function updateUserDisplay() {
    if (form.isValid()) {
      const payload = {
        first_name: currentUser.first_name,
        last_name: currentUser.last_name,
        email: currentUser.email,
        preferences: {
          language: [form.getValue().language.value],
          timezone: form.getValue().timeZone,
          locale: form.getValue().dateTime.value
        }
      };
      const url = `${identityServiceUrl}/identity/v3/users/${
        currentUser.id
      }/profile/`;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      };

      try {
        setIsSubmitting(true);
        await axios.put(url, payload, config);
        createGrowler({
          kind: "success",
          title: "Display Information saved",
          content: () => "Your display information was successfully saved."
        });
        setTimeout(() => {
          refreshCurrentUser(AUTH_FLOW_OAUTH_IMPLICIT);
        }, 500);
      } catch (error) {
        createGrowler({
          kind: "error",
          title: "Error saving Display Information",
          content: () => "Your display information was not saved."
        });
      } finally {
        setIsSubmitting(false);
      }
    }
  }

  function resetForm() {
    updateDateTimeFormat(userPreferences.timeZone);
    const preferences = Object.assign({}, userPreferences);
    preferences.dateTime = locales.find(
      dt => dt.value === userPreferences.dateTime.value
    );
    form.reset(preferences);
  }

  function timeZoneSearch(search) {
    return timeZones.filter(s => new RegExp(search, "i").test(s));
  }

  function handleTimeZoneChange(e) {
    updateDateTimeFormat(e.target.value);
  }

  function updateDateTimeFormat(timeZone) {
    setLocales([
      {
        value: "en-AU",
        name: `English - ${setLocaleDateTimeFormat("en-AU", timeZone)}`
      },
      {
        value: "en-CA",
        name: `English (Canada) - ${setLocaleDateTimeFormat("en-CA", timeZone)}`
      },
      {
        value: "en-HK",
        name: `English (Hong Kong) - ${setLocaleDateTimeFormat(
          "en-HK",
          timeZone
        )}`
      },
      {
        value: "en-IE",
        name: `English (Ireland) - ${setLocaleDateTimeFormat(
          "en-IE",
          timeZone
        )}`
      },
      {
        value: "en-IN",
        name: `English (India) - ${setLocaleDateTimeFormat("en-IN", timeZone)}`
      },
      {
        value: "en-PH",
        name: `English (Philipines) - ${setLocaleDateTimeFormat(
          "en-PH",
          timeZone
        )}`
      },
      {
        value: "en-GB",
        name: `English (United Kingdom) - ${setLocaleDateTimeFormat(
          "en-GB",
          timeZone
        )}`
      },
      {
        value: "en-US",
        name: `English (US 12 hour) - ${setLocaleDateTimeFormat(
          "en-US",
          timeZone
        )}`
      },
      {
        value: "en-US24",
        name: `English (US 24 hour) - ${setLocaleDateTimeFormat(
          "en-US24",
          timeZone
        )}`
      },
      {
        value: "en-SG",
        name: `English (Singapore) - ${setLocaleDateTimeFormat(
          "en-SG",
          timeZone
        )}`
      },
      {
        value: "fr-FR",
        name: `French - ${setLocaleDateTimeFormat("fr-FR", timeZone)}`
      },
      {
        value: "fr-CA",
        name: `French (Canada) - ${setLocaleDateTimeFormat("fr-CA", timeZone)}`
      }
    ]);
  }

  return (
    <>
      {growlers()}
      <div className="col-9 docs-main-content">
        <div className="sps-docs-container-markdown">
          <SpsForm formGroup={form} onSubmit={updateUserDisplay}>
            <SpsCard
              className="sps-docs-markdown"
              headerTitle="Display Settings"
              footer={() => {
                return (
                  <SpsButtonGroup className="text-right">
                    <SpsButton kind={ButtonKind.DEFAULT} onClick={resetForm}>
                      Cancel
                    </SpsButton>
                    <SpsButton
                      kind={ButtonKind.CONFIRM}
                      type={ButtonType.SUBMIT}
                      disabled={!isFormValid() || form.isPristine()}
                      spinning={isSubmitting}
                    >
                      Save
                    </SpsButton>
                  </SpsButtonGroup>
                );
              }}
            >
              <ReactPlaceholder ready={true}>
                <div className="sps-row">
                  <div className="col-6">
                    <SpsLabel
                      for={form.get("dateTime")}
                      errors={() =>
                        form.get("dateTime").hasError("required") &&
                        "This field is required"
                      }
                    >
                      Date and Time Format
                    </SpsLabel>
                    <SpsSelect
                      options={locales}
                      textKey="name"
                      formControl={form.get("dateTime")}
                      placeholder="Select Date and Time Format"
                    />
                  </div>
                  <div className="col-6">
                    <SpsLabel
                      for={form.get("timeZone")}
                      errors={() =>
                        form.get("timeZone").hasError("required") &&
                        "This field is required"
                      }
                    >
                      Time Zone
                    </SpsLabel>
                    <SpsSelect
                      options={timeZoneSearch}
                      formControl={form.get("timeZone")}
                      placeholder="Select Time Zone"
                      searchDebounce={500}
                      zeroState="No Zone found."
                      onChange={handleTimeZoneChange}
                    />
                  </div>
                </div>
                <div className="sps-row mt-1">
                  <div className="col-6">
                    <SpsLabel
                      for={form.get("language")}
                      help="Not all apps support all languages."
                      errors={() =>
                        form.get("language").hasError("required") &&
                        "This field is required"
                      }
                    >
                      Preferred Language
                    </SpsLabel>
                    <SpsSelect
                      options={languages}
                      textKey="text"
                      formControl={form.get("language")}
                      placeholder="Select Language"
                    />
                  </div>
                </div>
              </ReactPlaceholder>
            </SpsCard>
          </SpsForm>
        </div>
        <Footer/>
      </div>
    </>
  );
}

export default ProfileDisplay;
