import auth0 from "auth0-js";
import AppConfig from "../App.config";

export const switchOrgSilently = (newOrg, environment) => {
    localStorage.setItem("triedToSilentOrgSwitch", newOrg.id);
    localStorage.setItem("silentOrgSwitchLocation", window.location.hash);
    const auth = new auth0.WebAuth({
        clientID: AppConfig.auth0ClientId[environment] || "",
        domain: AppConfig.auth0Domain[environment] || "",
        realm: AppConfig.authRealm[environment] || "",
        audience: "api://api.spscommerce.com/",
        responseType: "token",
        scope: "openid profile email",
    });
    auth.authorize({
        realm: AppConfig.authRealm[environment] || "",
        appState: window.location.hash,
        login_hint: String(newOrg.id),
        redirectUri: window.location.href,
        prompt: "none",
    });
};

export const switchOrgWithPrompt = (orgId, environment, url, userLocation) => {
    const auth = new auth0.WebAuth({
        clientID: AppConfig.auth0ClientId[environment] || "",
        domain: AppConfig.auth0Domain[environment] || "",
        realm: AppConfig.authRealm[environment] || "",
        audience: "api://api.spscommerce.com/",
        responseType: "token",
        scope: "openid profile email",
    });
    auth.authorize({
        realm: AppConfig.authRealm[environment] || "",
        appState: userLocation,
        login_hint: String(orgId),
        redirectUri: url + userLocation,
    });
};
