import React, { useState, Fragment } from "react";

import {
    SpsCard,
    SpsTag,
    SpsButton,
    SpsIcon,
    SpsDl,
    SpsDt,
    SpsDd,
    SpsTooltip,
    SpsModal,
    SpsModalFooter,
    SpsModalBody,
} from "@spscommerce/ds-react";
import { SpsIcon as IconNames, SpsIconSize } from "@spscommerce/ds-shared";

import { uuid } from "../../utils/utils.js";

const MyDesignsSchemaDocument = (props) => {
    const { data, loading } = props;
    const [selectedTradingPartnerNotes, setSelectedTradingPartnerNotes] = useState({});
    const [showModal, setShowModal] = useState(false);

    function performScroll(elementId) {
        let height = 0;
        let blackBar = document.querySelector(".black-bar");
        if (blackBar) {
            height += blackBar.offsetHeight;
        }
        let navBarHeight = document.querySelector(".sps-navbar-container");
        if (navBarHeight) {
            height += navBarHeight.offsetHeight;
        }
        let scrollToElement = document.getElementById(elementId);
        if (scrollToElement) {
            scrollToElement.scrollIntoView();
            window.scrollBy(0, -height);
        }
    }

    function generateSectionTitle(name, path, minOccurs) {
        const pathArray = path.split(".");
        return (
            <>
                <div className="section-title">
                    {pathArray.map((section, index) => {
                        if (index !== pathArray.length - 1) {
                            return (
                                <Fragment key={uuid()}>
                                    <span key={index} className="section-parent">
                                        {pathArray.length > 1 && (
                                            <SpsButton
                                                kind="link"
                                                onClick={() => {
                                                    performScroll(
                                                        `${pathArray
                                                            .slice(0, index + 1)
                                                            .join(".")}`,
                                                    );
                                                }}
                                            >
                                                {section}
                                            </SpsButton>
                                        )}
                                    </span>
                                    {!(index === pathArray.length - 2) && (
                                        <SpsIcon
                                            className="mx-1 section-icon"
                                            size={SpsIconSize.EXTRA_LARGE}
                                            icon={IconNames.ANGLE_RIGHT}
                                        />
                                    )}
                                </Fragment>
                            );
                        } else {
                            return null;
                        }
                    })}

                    {pathArray.length > 1 && (
                        <SpsIcon
                            className="mx-1 section-icon"
                            size={SpsIconSize.EXTRA_LARGE}
                            icon={IconNames.ANGLE_RIGHT}
                        />
                    )}
                    <span className="current-section" id={path}>
                        {name}
                    </span>
                    {name !== data.name && (
                        <div className="my-1">
                            <SpsTag className="text-capitalize ml-1" kind="info">
                                {minOccurs !== "0" && "Required"}
                            </SpsTag>
                        </div>
                    )}
                </div>
            </>
        );
    }

    function generateTypeInfo(type) {
        return (
            <div className="my-1">
                <div className="label-tool-type-info d-inline">{type}</div>{" "}
            </div>
        );
    }

    function generateLengthInfo(min, max, attributes) {
        return (
            <>
                {!attributes || attributes.displayName === "String" ? (
                    <div>
                        {
                            <>
                                <b>Max :</b>{" "}
                                {attributes
                                    ? attributes.maxLength
                                        ? attributes.maxLength
                                        : "0"
                                    : max
                                    ? max
                                    : "1"}
                                <br />
                            </>
                        }
                        {
                            <>
                                <b>Min :</b>{" "}
                                {attributes
                                    ? attributes.minLength
                                        ? attributes.minLength
                                        : "0"
                                    : min}
                            </>
                        }
                    </div>
                ) : null}
            </>
        );
    }

    function generateDescriptionListTerm(name, path) {
        const elementId = `${path}.${name}`;
        return (
            <SpsButton
                className="font-weight-bold"
                kind="link"
                onClick={() => {
                    performScroll(elementId);
                }}
            >
                {name}
            </SpsButton>
        );
    }

    function generateDate(format) {
        return (
            <div>
                <b>Date Format:</b> {format || "YYYY-MM-DD"}
            </div>
        );
    }

    function generateEnumFields(consolidatedQualifiers, path) {
        return (
            <div>
                <b>Qualifiers:</b>
                <br />
                {Object.entries(consolidatedQualifiers).map(([key, value]) => (
                    <div className="ml-1">
                        {value.mandatoryFor.length > 0 ? (
                            <>
                                <SpsTag kind="key" icon="info-circle" id={`${path}${key}`}>
                                    {" "}
                                    <span>{key}</span>{" "}
                                </SpsTag>
                                <SpsTooltip for={`${path}${key}`}>
                                    Required by {value.mandatoryFor.join(", ")}
                                </SpsTooltip>{" "}
                                : "{value.description}" (Required)
                            </>
                        ) : (
                            <>
                                <SpsTag kind="key">
                                    {" "}
                                    <span>{key}</span>{" "}
                                </SpsTag>{" "}
                                : "{value.description}"
                            </>
                        )}
                        <br />
                    </div>
                ))}
            </div>
        );
    }

    function hasNotes(notes) {
        return Object.keys(notes).some((key) => {
            return notes[key];
        });
    }

    function generateTradingPartnerNotes(notes) {
        setSelectedTradingPartnerNotes(notes);
        setShowModal(true);
    }

    function generateDescriptionListRow(rowObject, path) {
        const {
            name,
            attributes,
            minOccurs,
            maxOccurs,
            children,
            extraProperties = {},
            sourcing,
        } = rowObject;
        return (
            <Fragment key={uuid()}>
                <SpsDt>
                    {children.length ? generateDescriptionListTerm(name, path) : name}
                    {attributes[0]
                        ? generateTypeInfo(attributes[0].displayName)
                        : generateTypeInfo(
                              maxOccurs === "unbounded" || parseInt(maxOccurs) > 1
                                  ? "Array"
                                  : "Object",
                          )}
                    <div className="my-1">
                        <SpsTag className="text-capitalize mt-0" kind="info">
                            {minOccurs !== "0" && "Required"}
                        </SpsTag>
                    </div>
                </SpsDt>
                <SpsDd>
                    <div className="row">
                        <div className="col-12">
                            <span className="definition">
                                {sourcing && sourcing.documentation ? (
                                    <div>{sourcing.documentation}</div>
                                ) : (
                                    extraProperties.consolidatedDocumentation && (
                                        <div>{extraProperties.consolidatedDocumentation}</div>
                                    )
                                )}
                                {extraProperties.consolidatedNotes &&
                                    hasNotes(extraProperties.consolidatedNotes) && (
                                        <SpsButton
                                            kind="link"
                                            onClick={() =>
                                                generateTradingPartnerNotes(
                                                    extraProperties.consolidatedNotes,
                                                )
                                            }
                                        >
                                            View Trading Partner Notes
                                        </SpsButton>
                                    )}
                                {generateLengthInfo(minOccurs, maxOccurs, attributes[0])}
                                {extraProperties.consolidatedQualifiers &&
                                    generateEnumFields(
                                        extraProperties.consolidatedQualifiers,
                                        path,
                                    )}
                                {attributes[0] && attributes[0].displayName === "Date"
                                    ? generateDate(attributes[0].format)
                                    : null}
                            </span>
                        </div>
                        <div className="col-2" style={{ textAlign: "right" }} />
                    </div>
                </SpsDd>
            </Fragment>
        );
    }

    function generateSectionBody(children, path) {
        let output = [];
        children.map((section) => {
            if (section.visible) {
                return output.push(generateDescriptionListRow(section, path));
            } else return null;
        });
        return (
            <SpsDl wideTerms className="mt-2">
                {output}
            </SpsDl>
        );
    }

    function getValue(pathArray) {
        const path = [];
        let obj = data;
        pathArray = pathArray.split(".");
        pathArray.forEach((key) => {
            if (key === "children" || key === "id") {
                path.push(obj.name);
            } else {
                obj = obj.children[parseInt(key)];
            }
        });
        return path;
    }

    function getPath(name, obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key) && key !== "attributes") {
                if (name === obj[key]) {
                    return key;
                } else if (obj[key] && typeof obj[key] === "object") {
                    var path = getPath(name, obj[key]);
                    if (path) {
                        return key + "." + path;
                    }
                }
            }
        }
    }

    function generateSection(section) {
        const { children, name, id, minOccurs } = section;
        const findPathFromObject = getPath(id, data);
        const path = getValue(findPathFromObject).join(".");
        return (
            <>
                <Fragment key={uuid()}>
                    {
                        <>
                            {generateSectionTitle(name, path, minOccurs)}
                            {generateSectionBody(children, path)}
                        </>
                    }
                    {id !== 0 &&
                        children.map(
                            (section) =>
                                section.children.length !== 0 &&
                                section.visible &&
                                generateSection(section),
                        )}
                </Fragment>
            </>
        );
    }

    const renderSchemaDocument = (schema) => {
        return (
            <>
                <SpsCard key={uuid()} className="schema-document--card mt-3">
                    {generateSection(schema)}
                </SpsCard>
                {schema.children.map((child) => (
                    <>
                        {child.visible && (
                            <SpsCard key={uuid()} className="schema-document--card">
                                {generateSection(child)}
                            </SpsCard>
                        )}
                    </>
                ))}
            </>
        );
    };

    return (
        <>
            <div className="schema-document">{!loading && data && renderSchemaDocument(data)}</div>
            <SpsModal
                isOpen={showModal}
                id="sps_app_info_modal"
                size="small"
                kind="info"
                header="Trading Partner Notes"
            >
                <SpsModalBody>
                    {Object.entries(selectedTradingPartnerNotes).map(([key, value]) => {
                        if (value)
                            return (
                                <div>
                                    {key}
                                    <div className="ml-1">
                                        <ul>
                                            <li>{value}</li>
                                        </ul>
                                    </div>
                                </div>
                            );
                        else return null;
                    })}
                </SpsModalBody>
                <SpsModalFooter>
                    <SpsButton kind="default" onClick={() => setShowModal(false)}>
                        Cancel
                    </SpsButton>
                </SpsModalFooter>
            </SpsModal>
        </>
    );
};
export default MyDesignsSchemaDocument;
