import React from "react";
/* Component used to test custom component rendering in markdown,
   we need to whitelist any component that will be renderd making
   it pretty tough to test without doing this.
*/

export default function SpsLabelBrowser(props) {
    return (
        <div {...props} style={{ backgroundColor: "goldenrod" }}>
            Label browser currently in {props.mode} mode. This means the component was successfully
            rendered.
        </div>
    );
}
