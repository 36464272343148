import React, { Fragment } from "react";
import classnames from "classnames";
import { TextBlock, RoundShape, TextRow } from "react-placeholder/lib/placeholders";

import { SpsCard } from "@spscommerce/ds-react/";

import { IconCard } from "../IconCard";
import "./Placeholders.scss";

const createAppIconRenderList = () => {
    const totalCards = 3;
    const renderArray = [];
    for (let i = 0; i < totalCards; i++) {
        renderArray.push(
            <IconCard key={`${i}`}>
                <div className="app-card-container-bottom">
                    <TextBlock rows={4} color="#e8e9e9" />
                </div>
            </IconCard>,
        );
    }
    return renderArray;
};

const AppCardPlaceholder = () => {
    const rows = [{}, {}]; // utility array for iteration
    return (
        <div className="sps-placeholders__container show-loading-animation">
            <div className="sps-placeholders__overlay--fade-out" />
            {rows.map((row, idx) => {
                return (
                    <div className="column3 row-wrapper" key={idx}>
                        {createAppIconRenderList()}
                    </div>
                );
            })}
        </div>
    );
};

const createSmallAppCardRenderList = () => {
    const totalRows = 3;
    const renderArray = [];
    for (let i = 0; i < totalRows; i++) {
        renderArray.push(
            <SpsCard className="app-card--small-placeholder" key={i}>
                <div
                    className={`sps-placeholders__container service-row inline-service show-loading-animation`}
                >
                    <div className="container-cicle">
                        <RoundShape color="#e8e9e9" style={{ width: 90, height: 90 }} />
                    </div>
                    <div className="service-link">
                        <TextRow color="#e8e9e9" />
                        <TextRow color="#e8e9e9" />
                    </div>
                </div>
            </SpsCard>,
        );
    }
    return renderArray;
};

const SmallAppCardPlaceholder = () => {
    return <Fragment>{createSmallAppCardRenderList()}</Fragment>;
};

//TODO: a bit more randomness here would be nice
const TieredPlaceHolder = () => {
    return (
        <div className="sps-placeholders__container">
            <div className="sps-placeholders__container--inner">
                <div className="sps-placeholders__tiered-item sps-placeholders__tiered-item--parent" />
                <div className="sps-placeholders__tiered-item sps-placeholders__tiered-item--child" />
                <div className="sps-placeholders__tiered-item sps-placeholders__tiered-item--grandchild" />
            </div>
        </div>
    );
};

const MarkdownPlaceHolder = (props) => {
    const renderArray = [];
    const rows = props.rows ? props.rows : 3;
    for (let i = 0; i < rows; i++) {
        renderArray.push(
            <Fragment key={i}>
                <div className="sps-placeholders__markdown-item sps-placeholders__markdown-item--sequencefirst" />
                <div className="sps-placeholders__markdown-item sps-placeholders__markdown-item--child" />
                <div className="sps-placeholders__markdown-item sps-placeholders__markdown-item--sequencefirst" />
                <div className="sps-placeholders__markdown-item sps-placeholders__markdown-item--child" />
                <div className="sps-placeholders__markdown-item sps-placeholders__markdown-item--last" />
            </Fragment>,
        );
    }
    return (
        <div className="sps-placeholders__container">
            <div className="sps-placeholders__markdown-item sps-placeholders__markdown-item--first" />
            {renderArray}
        </div>
    );
};

const createInlineServiceRenderList = (toggle) => {
    const totalRows = 3;
    const renderArray = [];
    const rowClass = classnames({ toggle: toggle });
    for (let i = 0; i < totalRows; i++) {
        renderArray.push(
            <div
                className={`sps-placeholders__container service-row inline-service show-loading-animation ${rowClass}`}
                key={i}
            >
                <div className="container-cicle">
                    <RoundShape color="#e8e9e9" style={{ width: 40, height: 40 }} />
                </div>
                <div className="service-link">
                    <TextRow color="#e8e9e9" />
                    <TextRow color="#e8e9e9" />
                </div>
            </div>,
        );
    }
    return renderArray;
};

const InlineServicePlaceHolder = ({ toggle }) => {
    return <Fragment>{createInlineServiceRenderList(toggle)}</Fragment>;
};

export {
    AppCardPlaceholder,
    TieredPlaceHolder,
    InlineServicePlaceHolder,
    SmallAppCardPlaceholder,
    MarkdownPlaceHolder,
};
