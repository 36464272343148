import React, { useEffect, Fragment } from "react";
import ReactPlaceholder from "react-placeholder";
import axios from "axios";

import { usePatchReducer } from "@spscommerce/ds-react";

import { TieredPlaceHolder } from "../Placeholders";
import { SideNavSection } from "../SideNav";
import { isExternal, stripTrailingSlash } from "../../utils";
import { DocumentationSideLink } from "../DocumentationLink";

const fetchTOC = async ({ docsRoot, tocPath = "", tocFilename = "toc.json" }) => {
    const tocUrl = docsRoot + tocFilename;
    try {
        const tocResponse = await axios.get(tocUrl);
        return tocResponse.data;
    } catch (err) {
        console.error(err);
        return null;
    }
};

function CollapsibleItem(props) {
    const { child, depth, pathname, isTop, docsRoot, serviceSlug } = props;
    const [state, patchState] = usePatchReducer({
        expandedByUser: false,
    });

    function handleExpandedByUser() {
        patchState({
            expandedByUser: true,
        });
    }

    function handleCollapsedByUser() {
        patchState({
            expandedByUser: false,
        });
    }

    function handleToggleByUser() {
        patchState({
            expandedByUser: !state.expandedByUser,
        });
    }

    function isExpanded() {
        // pluck out the path to the md file
        const propsShortPath =
            "/" + stripTrailingSlash(props.pathname.split("/").slice(3).join("/")) + ".md";

        if (state.expandedByUser) {
            return true;
        }
        if (props.child.link === propsShortPath || props.child.links.indexOf(propsShortPath) > -1) {
            return true;
        }
    }

    return (
        <li className={`${isTop ? "toc-top" : ""}`}>
            {child.children.length > 0 ? (
                isExpanded() ? (
                    <span className="side-nav__toggle opened" onClick={handleCollapsedByUser}>
                        <i className="sps-icon sps-icon-chevron-down" />
                    </span>
                ) : (
                    <span className="side-nav__toggle closed" onClick={handleExpandedByUser}>
                        <i className="sps-icon sps-icon-chevron-right" />
                    </span>
                )
            ) : null}
            {child.link ? (
                isExternal(child.link) ? (
                    <a
                        className="side-nav__leaf side-nav__leaf--external"
                        href={child.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {child.text}
                    </a>
                ) : (
                    <DocumentationSideLink
                        className="side-nav__leaf"
                        to={child.link || ""}
                        serviceSlug={serviceSlug}
                    >
                        {child.text}
                    </DocumentationSideLink>
                )
            ) : (
                <span onClick={handleToggleByUser} className="side-nav__leaf">
                    {child.text}
                </span>
            )}
            {child.children && child.children.length > 0 && isExpanded()
                ? renderNavList(docsRoot, serviceSlug, pathname, child.children, false, depth)
                : null}
        </li>
    );
}

const renderNavList = (docsRoot, serviceSlug, pathname, node, isTop, depth = 0) => {
    depth++;
    return (
        <ul className={`side-nav__branch side-nav__branch--${depth}`}>
            {node.map((child) => {
                return (
                    <CollapsibleItem
                        serviceSlug={serviceSlug}
                        docsRoot={docsRoot}
                        isTop={isTop}
                        key={child.text + child.link + child.children.length}
                        pathname={pathname}
                        child={child}
                        depth={depth}
                    />
                );
            })}
        </ul>
    );
};

export default function TopLevelTOC(props) {
    const { docsRoot, pathname, isTop, serviceSlug } = props;

    const [state, patchState] = usePatchReducer({
        toc: null,
        tocLoaded: false,
    });

    useEffect(() => {
        fetchData();
    }, [props]);

    async function fetchData() {
        const { docsRoot } = props;
        if (docsRoot) {
            const tocResult = await fetchTOC({
                docsRoot,
            });
            patchState({
                toc: tocResult,
                tocLoaded: true,
            });
        }
    }

    return (
        <Fragment>
            <SideNavSection>
                <ReactPlaceholder customPlaceholder={<TieredPlaceHolder />} ready={state.tocLoaded}>
                    <Fragment>
                        {state.toc !== null
                            ? renderNavList(docsRoot, serviceSlug, pathname, state.toc, isTop)
                            : null}
                    </Fragment>
                </ReactPlaceholder>
            </SideNavSection>
        </Fragment>
    );
}
