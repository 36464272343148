import React from "react";
import {
    SpsCard,
    SpsContentRow,
    SpsContentRowCol,
    SpsContentRowExpansion,
} from "@spscommerce/ds-react";
import Parser from "html-react-parser";

import CopyCode from "./CopyCode";

const SwaggerDataParamsComponent = ({ params, modifySections, description }) => {
    if (!params || !params.length) return null;
    return (
        <SpsCard className="card-margin">
            <h4>Data Parameters</h4>
            {modifySections.DATA_PARAMS && <p>{Parser(modifySections.DATA_PARAMS)}</p>}
            {params.map((item, i) => {
                const textToCopy = JSON.stringify(item.schema, null, " ");
                return (
                    <SpsContentRow key={i}>
                        <SpsContentRowCol>
                            <div className="fs-14 lh-20">{"Sample"}</div>
                        </SpsContentRowCol>
                        <SpsContentRowCol style={{ width: "4.4rem" }}>JSON</SpsContentRowCol>
                        <SpsContentRowCol style={{ width: "7.4rem" }}>
                            <CopyCode textToCopy={textToCopy} />
                        </SpsContentRowCol>
                        <SpsContentRowExpansion>
                            <pre className="m-0 dcui-code-snippet">{textToCopy}</pre>
                        </SpsContentRowExpansion>
                    </SpsContentRow>
                );
            })}
        </SpsCard>
    );
};

export default SwaggerDataParamsComponent;
