import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import { SpsCard } from "@spscommerce/ds-react";

import "./ColSixCard.scss";

export default function ColSixCard(props) {
    const { icon, iconColor, title, description, links } = props;
    const iconClasses = classnames("sps-icon", icon, iconColor);

    return (
        <div className="col-6 col-six-wrapper">
            <SpsCard>
                <div className="row">
                    <div className="col-2 circle">
                        <i className={iconClasses} />
                    </div>
                    <div className="col-six-body col-10">
                        <div className="col-six-card-title">
                            <h3>{title}</h3>
                        </div>
                        <p>{description}</p>
                        <div>
                            {links
                                ? links.map((link, i) => {
                                      return (
                                          <Link key={i} className="col-six-link" to={link.link}>
                                              {link.description}
                                          </Link>
                                      );
                                  })
                                : null}
                        </div>
                    </div>
                </div>
            </SpsCard>
        </div>
    );
}
