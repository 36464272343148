const generalEmailAddresses = [
    {
        icon: "dollar-sign",
        name: "Inquires/Sales",
        emailTo: "info@spscommerce.com",
    },
    {
        icon: "building",
        name: "Careers",
        emailTo: "hr@spscommerce.com",
    },
    {
        icon: "handshake",
        name: "Public Relations",
        emailTo: "pr@spscommerce.com",
    },
    {
        icon: "globe",
        name: "Investor Relations:",
        emailTo: "IR@spscommerce.com",
    },
    {
        icon: "truck-shipping",
        name: "Fulfillment Support:",
        emailTo: "support@spscommerce.com",
    },
];

export default generalEmailAddresses;
