import React from "react";
import { SpsCard } from "@spscommerce/ds-react";

import "./LoginCardSupplyPike.scss";

export default function LoginCardBlue(props) {
    const { children, topShelf, bodyClass = "" } = props;
    return (
        <SpsCard className="sps-login-card-supply-pike">
            <div className="sps-login-card-supply-pike__top-shelf">
                {topShelf ? topShelf() : null}
            </div>
            <div className="sps-login-card-supply-pike__col sps-login-card-supply-pike__col--left">
                <img src="assets/sps-logo@2x.png" alt="sps-logo" />
            </div>
            <div className="sps-login-card-supply-pike__col sps-login-card-supply-pike__col--right">
                <div className={`login-content-body-supply-pike ${bodyClass}`}>{children}</div>
            </div>
        </SpsCard>
    );
}
