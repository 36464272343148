import React from "react";
import { Link } from "react-router-dom";
import "./ConnectionPanel.scss";

export default function ConnectionPanel(props) {
    return (
        <div className="col connection-panel">
            {props.img ? (
                <Link to={props.to}>
                    <img
                        src={props.img}
                        className="connection-panel-image mx-auto d-block"
                        alt={props.alt}
                    />
                </Link>
            ) : null}
            <h5 className="text-center connection-panel-title">{props.title}</h5>
            <p className="text-center connection-panel-link">
                <Link to={props.to}>{props.linkText}</Link>
            </p>
        </div>
    );
}
