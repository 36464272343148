// TODO consolidate the component with FetchedMarkdown
import React, { useState, useEffect } from "react";
import axios from "axios";
import remark from "remark";
import slug from "remark-slug";
import remark2react from "remark-react";
import { SpsCard } from "@spscommerce/ds-react";
import { SpsGrowlerFactory } from "@spscommerce/ui-react";
import { defaultSchema } from "hast-util-sanitize/lib/schema.js"; 

import CodeOrSpsBlock from "../../components/MarkdownDocument/CodeOrSpsBlock";
import SpsCode from "../../components/Code";
import "../../components/MarkdownDocument/MarkdownDocument.scss";
import WorkflowNavigation from "../../components/WorkflowNavigation/WorkflowNavigation";
import ReactPlaceholder from "react-placeholder";
import { MarkdownPlaceHolder } from "../../components/Placeholders";
import "./RsxUIPage.scss";

export default function RsxFetchedMarkdown(props) {
    const { url, doc } = props;
    const [markdown, setMarkdown] = useState(null);
    const [text, setText] = useState(null);
    useEffect(() => {
        const fetchMarkdown = async () => {
            const response = await axios(`${url}${doc}.md`);
            setMarkdown(response.data);
        };
        if (url) {
            try {
                fetchMarkdown();
            } catch (e) {
                const msg =
                    "An error has occurred while loading this page. Please try again later.";
                const title = "Page Load Error";
                SpsGrowlerFactory.error(msg, {
                    preset: "error",
                    timeoutDuration: 0,
                    fade: false,
                    title: title,
                });
            }
        }
    }, [`${url}${doc}.md`]);
    const parseDocument = async (markdown) => {
        const MarkdownParts = markdown.split("!!!card");
        const contentResult = [];
        defaultSchema.attributes["*"].push("className");
        const rmk = remark()
            .use(slug)
            .use(remark2react, {
                sanitize: defaultSchema,
                remarkReactComponents: {
                    code: SpsCode,
                    pre: (props) => <CodeOrSpsBlock cdn_url={url} {...props} />,
                },
            });
        MarkdownParts.forEach(async (part, index) => {
            const result = await rmk.process(part);
            contentResult.push(result.contents);
            if (index === MarkdownParts.length - 1) {
                setText(contentResult);
            }
        });
    };
    useEffect(() => {
        if (!markdown) {
            return;
        }
        parseDocument(markdown);
    }, [markdown]);
    useEffect(() => {
        if (!text) {
            return;
        }
        if (props.scrollTo != null) {
            // Scroll to an anchor if we were told to.
            const el = document.getElementById(this.props.scrollTo);
            if (el) {
                el.scrollIntoView();
            }
        }
    }, [text]);

    const workflowSteps = [
        {
            label: "Integration Readiness",
            path: "integration-readiness",
            icon: "rocket",
            pathText: "Integration Readiness Overview",
        },
        {
            label: "Connect to SPS Commerce",
            path: "connect-to-sps-commerce",
            icon: "gear",
            pathText: "Connection Overview",
        },
        {
            label: "Integrate Your Order",
            path: "integrate-your-order",
            icon: "handshake",
            pathText: "Integration Overview",
        },
        {
            label: "Generate Order Ack",
            path: "return-documents/generate-order-acknowledgement",
            icon: "file-text",
            pathText: "Order Acknowledgment Overview",
        },
        {
            label: "Generate Shipment File",
            path: "return-documents/generate-shipment-file",
            icon: "truck-shipping",
            pathText: "Shipment Overview",
        },
        {
            label: "Generate Invoice File",
            path: "return-documents/generate-invoice-file",
            icon: "dollar-sign",
            pathText: "Invoice Overview",
        },
    ];
    const worflowPage = [
        "rsx-overview",
        "integration-readiness",
        "connect-to-sps-commerce",
        "integrate-your-order",
        "generate-order-acknowledgement",
        "generate-shipment-file",
        "generate-invoice-file",
        "return-documents",
    ];
    const workflowSubPages = [
        "importing-the-order",
        "processing-the-order",
        "connect-with-as2",
        "connect-with-sftp",
        "connect-with-transaction-api",
        "test-data",
        "file-naming-convention",
        "folder-structures",
    ];
    const isSubstep = workflowSubPages.includes(doc);
    const subStepIcon =
        isSubstep && (doc === "importing-the-order" || doc === "processing-the-order")
            ? "sps-icon-handshake"
            : "sps-icon-gear";

    return (
        <div className="sps-docs-container-markdown rsx-ui-page">
            <ReactPlaceholder customPlaceholder={<MarkdownPlaceHolder />} ready={!!text}>
                {text && text.length < 2 ? (
                    <SpsCard className="sps-docs-markdown" id="preview">
                        <div className="d-flex">
                            <div>{text}</div>
                            {worflowPage.includes(doc) && (
                                <WorkflowNavigation
                                    activeStep={doc}
                                    steps={workflowSteps}
                                    title="Integration Workflow"
                                />
                            )}
                            {isSubstep && (
                                <div>
                                    <i
                                        aria-hidden="true"
                                        className={`sps-icon ${subStepIcon} steps-icon`}
                                    />
                                </div>
                            )}
                        </div>
                    </SpsCard>
                ) : (
                    <div>
                        {text && text.length > 1 && isSubstep && (
                            <div className="float-right">
                                <i
                                    aria-hidden="true"
                                    className={`sps-icon ${subStepIcon} steps-icon`}
                                />
                            </div>
                        )}
                        {text ? (
                            text.map((content, index) => {
                                if (index === 0) {
                                    return content;
                                }
                                return (
                                    <SpsCard
                                        key={index}
                                        className="sps-docs-markdown mb-3 split-card-pattern"
                                    >
                                        <div className="d-flex">
                                            <div className="w-100">{content}</div>
                                            {worflowPage.includes(doc) && index === 1 && (
                                                <WorkflowNavigation
                                                    activeStep={doc}
                                                    steps={workflowSteps}
                                                    title="Integration Workflow"
                                                />
                                            )}
                                        </div>
                                    </SpsCard>
                                );
                            })
                        ) : (
                            <div />
                        )}
                    </div>
                )}
            </ReactPlaceholder>
        </div>
    );
}
