import "./SwaggerDocumentation.scss";

import React from "react";

import {
    SpsCard,
    SpsTable,
    SpsTableBody,
    SpsTableCell,
    SpsTableHead,
    SpsTableHeader,
    SpsTableRow,
} from "@spscommerce/ds-react/";

const SwaggerPathParamsComponent = function ({ pathParams = [] }) {
    return (
        <SpsCard className="card-margin">
            <h4>Path Parameters</h4>
            <SpsTable>
                <SpsTableHead>
                    <SpsTableRow>
                        <SpsTableHeader>Parameter Name</SpsTableHeader>
                        <SpsTableHeader>Type</SpsTableHeader>
                        <SpsTableHeader>Mandatory</SpsTableHeader>
                        <SpsTableHeader>Default Value</SpsTableHeader>
                        <SpsTableHeader>Description</SpsTableHeader>
                    </SpsTableRow>
                </SpsTableHead>
                <SpsTableBody>
                    {pathParams &&
                        pathParams.map((row, index) => {
                            const { type, default: def } = row.schema ? row.schema : row;
                            return (
                                <SpsTableRow key={index}>
                                    <SpsTableCell className="parameter">
                                        <span className="text-highlight">{row["name"]}</span>
                                    </SpsTableCell>
                                    <SpsTableCell className="text-capitalize">{type}</SpsTableCell>
                                    <SpsTableCell>{row["required"] ? "Yes" : "No"}</SpsTableCell>
                                    <SpsTableCell>{def === undefined ? "None" : def}</SpsTableCell>
                                    <SpsTableCell wrap={400}>{row["description"]}</SpsTableCell>
                                </SpsTableRow>
                            );
                        })}
                </SpsTableBody>
            </SpsTable>
        </SpsCard>
    );
};

export default SwaggerPathParamsComponent;
