import React, { useEffect } from "react";

import { stripTrailingSlash } from "../../utils";
import MarkdownDocument from "../MarkdownDocument";

export default function RenderedMarkdown(props) {
    const { documentationRouteProps, currentService, services } = props;
    const lastHashPosition = window.location.href.lastIndexOf("#");
    const servicePath =
        "#" + (props.baseRoute || "/docs") + "/" + documentationRouteProps.match.params.serviceSlug;
    let partialPath = stripTrailingSlash(
        documentationRouteProps.location.pathname.substr(servicePath.length).split("#")[0],
    );

    // Default to README for filename when partialPath is empty
    const path = partialPath.length > 0 ? partialPath : "README";
    const pathPosition = window.location.href.lastIndexOf(path);
    let scrollTo = null;

    if (lastHashPosition && path && lastHashPosition > pathPosition) {
        scrollTo = window.location.href.substr(lastHashPosition + 1);
    }

    useEffect(() => {
        const el = document.querySelector(".page-banner");
        el
            ? window.scrollTo({
                  top: el.offsetHeight + 9,
                  left: 0,
                  behavior: "smooth",
              })
            : window.scrollTo(0, 0);
    }, []);

    return (
        <MarkdownDocument
            services={services}
            docsRoot={currentService.currentDocLocation}
            path={path}
            scrollTo={scrollTo}
            currentService={props.currentService}
        />
    );
}
