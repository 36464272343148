import React, { Fragment, Component } from "react";
import axios from "axios";

import {
    SpsCard,
    SpsDescriptionList,
    SpsDescriptionListTerm,
    SpsDescriptionListDefinition,
    SpsTag,
    SpsButton,
    SpsIcon,
} from "@spscommerce/ds-react";
import { SpsIcon as IconNames, SpsIconSize } from "@spscommerce/ds-shared";
import { withCommercePlatform, SpsGrowlerFactory } from "@spscommerce/ui-react";

import { uuid } from "../../utils/utils.js";
import Loading from "../../components/Loading";
import config from "../../App.config";
import "./SchemaDocument.scss";

class SchemaDocument extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: true,
        };
    }

    componentDidMount = () => {
        this.fetchData();
    };

    componentDidUpdate = (prevProps) => {
        const tokenChanged =
            this.props.commercePlatform &&
            this.props.commercePlatform.token !== prevProps.commercePlatform.token;
        const schemaUrlChanged = this.props.schemaUrl !== prevProps.schemaUrl;

        if ((this.props.needsAuth && tokenChanged) || schemaUrlChanged) {
            this.fetchData();
        }
    };

    urlIsFullyQualified = (url) => {
        return url.startsWith("http://") || url.startsWith("https://");
    };

    getFullSchemaUrl = (url) => {
        if (this.urlIsFullyQualified(url)) {
            return url;
        } else {
            const env = this.props.commercePlatform.environment;
            const host = config.kubeRoot[env];
            return `${host}${url}`;
        }
    };

    fetchData = async () => {
        await this.getAllDefs();
    };

    getAllDefs = async () => {
        const { commercePlatform, needsAuth, schemaUrl, requestType, requestBody } = this.props;
        try {
            let headers;
            if (needsAuth && commercePlatform) {
                // Only try to get auth information when needed by the specific schemaUrl
                const { token } = commercePlatform;
                headers = { Authorization: `Bearer ${token}` };
            }
            // Schema URLs may be relative or fully qualified, account for both
            const url = this.getFullSchemaUrl(schemaUrl);
            const schemaConfig = {
                method: requestType,
                headers,
                url,
                data: requestBody,
            };
            // Get terms & definitions for schema document
            const response = await axios(schemaConfig);
            this.setState({ data: response.data, loading: false });
        } catch (error) {
            this.reset();
            const msg = "An error has occurred while fetching schema data, please try again later.";
            SpsGrowlerFactory.error(msg, {
                title: "Error while fetching data",
            });
        }
    };

    reset = () => {
        this.setState({ data: null, loading: false });
    };

    performScroll = (elementId) => {
        let height = 0;
        let blackBarHeight = document.querySelector(".black-bar");
        if (blackBarHeight) {
            height += blackBarHeight.offsetHeight;
        }
        let navBarHeidght = document.querySelector(".sps-navbar-container");
        if (navBarHeidght) {
            height += navBarHeidght.offsetHeight;
        }
        let scrollToElement = document.getElementById(elementId);
        if (scrollToElement) {
            scrollToElement.scrollIntoView();
            window.scrollBy(0, -height);
        }
    };

    generateDescriptionListTerm = (id, path) => {
        if (Array.isArray(this.ids) && this.ids.includes(id)) {
            const elementId = `${`${path}${path ? "." : ""}${id}`}`;
            return (
                <SpsButton
                    className="font-weight-bold"
                    kind="link"
                    onClick={this.performScroll.bind(this, elementId)}
                >
                    {id}
                </SpsButton>
            );
        } else {
            return id;
        }
    };

    generateLengthInfo(minLength, maxLength, type) {
        return (
            <div>
                {maxLength && (
                    <>
                        <b>Max {type === "array" ? "Number of Occurrences" : "Length"}:</b>{" "}
                        {maxLength}
                    </>
                )}
                <br />
                {minLength && (
                    <>
                        <b>Min {type === "array" ? "Number of Occurrences" : "Length"}:</b>{" "}
                        {minLength}
                    </>
                )}
            </div>
        );
    }

    generateDate(format) {
        return (
            <div>
                <b>Date Format:</b> {format || "YYYY-MM-DD"}
            </div>
        );
    }

    generateEnumFields(enumDescriptions) {
        return (
            <div>
                <b>Enumerators:</b>
                <br />
                {Object.keys(enumDescriptions).map((key, index) => {
                    return (
                        <div className="ml-1" key={index}>
                            <SpsTag kind="key">
                                {" "}
                                <span>{key}</span>{" "}
                            </SpsTag>{" "}
                            : "{enumDescriptions[key]}"
                            <br />
                        </div>
                    );
                })}
            </div>
        );
    }

    generateAllowableFields(allowable_fields) {
        return (
            <div>
                <b>Allowable Fields:</b>
                <br />
                {allowable_fields.map((term, index) => {
                    return (
                        <Fragment key={uuid()}>
                            {index > 0 ? (
                                <Fragment>
                                    ,<br />
                                </Fragment>
                            ) : (
                                ""
                            )}{" "}
                            "{Object.keys(term)[0]}":"{Object.values(term)[0]}"
                        </Fragment>
                    );
                })}
            </div>
        );
    }

    generateTypeInfo(type, enumDescriptions, format) {
        return (
            <div className="my-1">
                <div className="label-tool-type-info d-inline">{type}</div>{" "}
                {format && format === "date" ? "(date)" : enumDescriptions && "(enumeration)"}
            </div>
        );
    }

    generateDescriptionListRow(rowObject, path) {
        const {
            description,
            id,
            type,
            minLength,
            maxLength,
            allowable_fields,
            enumDescriptions,
            required,
            format,
            dateFormat,
        } = rowObject;
        return (
            <Fragment key={uuid()}>
                <SpsDescriptionListTerm>
                    {this.generateDescriptionListTerm(id, path)}
                    {type && this.generateTypeInfo(type, enumDescriptions, format)}
                    {required && (
                        <SpsTag className="text-capitalize mt-0" kind="info">
                            required
                        </SpsTag>
                    )}
                </SpsDescriptionListTerm>
                <SpsDescriptionListDefinition>
                    <div className="row">
                        <div className="col-10">
                            <span className="definition">
                                <div>{description}</div>
                                {(minLength || maxLength) &&
                                    this.generateLengthInfo(minLength, maxLength, type)}
                                {enumDescriptions && this.generateEnumFields(enumDescriptions)}
                                {allowable_fields && this.generateAllowableFields(allowable_fields)}
                                {format && format === "date" ? this.generateDate(dateFormat) : null}
                            </span>
                        </div>
                        <div className="col-2" style={{ textAlign: "right" }} />
                    </div>
                </SpsDescriptionListDefinition>
            </Fragment>
        );
    }

    generateSectionBody(items, path) {
        let output = [];
        items.map((section) => {
            return output.push(this.generateDescriptionListRow(section, path));
        });
        return (
            <SpsDescriptionList wideTerms className="mt-2">
                {output}
            </SpsDescriptionList>
        );
    }

    handleRequiredTags(path, pathArray) {
        let found = {};
        let foundRequiredValue = {};
        found = this.state.data.sections.find((item) => item.path === path);
        if (found.parent_id !== '') {
            let foundParent = {};
            let newArrayPathforParent = [];
            let newPathToFindParent = [];
            if (pathArray.length >= 2) {
                pathArray.forEach((item) => {
                    newArrayPathforParent.push(item);
                })
                for (let i = 0; i < newArrayPathforParent.length - 1; i++) {
                    newPathToFindParent[i] = newArrayPathforParent[i] + ".";
                }
                newPathToFindParent = newPathToFindParent.join('');
                if (newPathToFindParent[newPathToFindParent.length - 1] === '.') {
                    newPathToFindParent = newPathToFindParent.slice(0, -1);
                }
                foundParent = this.state.data.sections.find((item) => item.path === newPathToFindParent)
                foundRequiredValue = foundParent.items.find((item) => item.id === found.id);
            }
            else if (pathArray.length < 2) {
                foundParent = this.state.data.sections.find((item) => item.id === found.parent_id)
                foundRequiredValue = foundParent.items.find((item) => item.id === found.id);
            }
        }
        return foundRequiredValue;
    }

    generateSectionTitle(id, conditionDescriptions, path) {
        const pathArray = path.split(".");
        let linkTo = "";

        const foundRequiredValue = this.handleRequiredTags(path, pathArray);

        return (
            <>
                <div className="section-title">
                    {pathArray.map((section, index) => {
                        if (index === 0) {
                            linkTo = pathArray[index];
                        } else {
                            linkTo += `.${pathArray[index]}`;
                        }
                        if (!(index === pathArray.length - 1)) {
                            return (
                                <Fragment key={uuid()}>
                                    <span key={index} className="section-parent">
                                        {pathArray.length > 1 && (
                                            <SpsButton
                                                kind="link"
                                                onClick={this.performScroll.bind(this, linkTo)}
                                            >
                                                {section}
                                            </SpsButton>
                                        )}
                                    </span>
                                    {!(index === pathArray.length - 2) && (
                                        <SpsIcon
                                            className="mx-1 section-icon"
                                            size={SpsIconSize.EXTRA_LARGE}
                                            icon={IconNames.ANGLE_RIGHT}
                                        />
                                    )}
                                </Fragment>
                            );
                        } else {
                            return null;
                        }
                    })}

                    {pathArray.length > 1 && (
                        <SpsIcon
                            className="mx-1 section-icon"
                            size={SpsIconSize.EXTRA_LARGE}
                            icon={IconNames.ANGLE_RIGHT}
                        />
                    )}
                    <span className="current-section" id={path ? path : id}>
                        {id}
                    </span>
                    <span className='ml-1'>
                        {foundRequiredValue && foundRequiredValue.required === true ? <SpsTag className="text-capitalize mt-0" kind="info">
                            required
                        </SpsTag> : ''}
                    </span>
                </div>
                {conditionDescriptions && conditionDescriptions.length > 0 && (
                    <div className="conditional-description">
                        <b>Conditional Validation:</b>
                        <ul>
                            {conditionDescriptions.map((desc, index) => {
                                return <li key={index}>{desc}</li>;
                            })}
                        </ul>
                    </div>
                )}
            </>
        );
    }

    handleChildSections = (sections) => { };

    generateSection(section) {
        const { items, id, conditionDescriptions, path, child } = section;
        return (
            <Fragment key={uuid()}>
                <Fragment>
                    {this.generateSectionTitle(id, conditionDescriptions, path)}
                    {this.generateSectionBody(items, path)}
                </Fragment>
                {child && child.map((section) => this.generateSection(section))}
            </Fragment>
        );
    }

    renderSchemaDocument = (schema) => {
        let { sections } = schema;
        this.ids = sections.map((section) => section.id);
        let sectionList = [];
        const rootSections = sections.filter((s) => !s.parent_id);
        if (rootSections && rootSections.length) {
            sectionList = rootSections.map((section) => {
                const { items } = section;
                if (items && items.length) {
                    return (
                        <SpsCard key={uuid()} className="schema-document--card">
                            {this.generateSection(section)}
                        </SpsCard>
                    );
                } else {
                    return null;
                }
            });
        }
        sections = sections.filter((s) => s.parent_id);
        if (sections && sections.length) {
            const map = new Map();
            sections.forEach((item) => {
                item["child"] = [];
                if (!map.has(item.path.split(".")[0])) {
                    map.set(item.id, item);
                } else {
                    map.get(item.path.split(".")[0]).child.push(item);
                }
            });
            const elements = Array.from(map, (m) => {
                return m[1];
            });
            elements.forEach((section) => {
                const { items } = section;
                if (items && items.length) {
                    sectionList.push(
                        <SpsCard key={uuid()} className="schema-document--card">
                            {this.generateSection(section)}
                        </SpsCard>,
                    );
                } else {
                    return null;
                }
            });
        }
        return sectionList;
    };

    render() {
        const { data, loading } = this.state;

        return (
            <div className="schema-document">
                {loading && (
                    <div className="schema-document--loader">
                        <Loading mode="full" />
                    </div>
                )}
                {!loading && data && this.renderSchemaDocument(data)}
            </div>
        );
    }
}

export default withCommercePlatform(SchemaDocument);
