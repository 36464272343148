import React from "react";

import { SpsButton } from "@spscommerce/ds-react";

import "./Footer.scss";

export default function Footer() {
    return (
        <div className="sps-footer">
            <div className="sps-footer__content">
                <div className="sps-footer__item">
                    <img className="sps-footer__logo" src="assets/SPS-logo.svg" alt="SPS logo" />
                </div>
                <div className="sps-footer__item">
                    <SpsButton kind="link" href="#/contact-info">
                        Contact
                    </SpsButton>
                </div>
                <div className="sps-vertical-rule" />
                <div className="sps-footer__item">
                    <SpsButton kind="link" href="#/locations">
                        Office Locations
                    </SpsButton>
                </div>
                <div className="sps-vertical-rule" />
                <div className="sps-footer__item">
                    <SpsButton kind="link" href="#/terms-of-use">
                        Terms of Use
                    </SpsButton>
                </div>
                <div className="sps-footer__item d-flex align-items-center">
                    <div className="sps-footer__item">
                        © {new Date().getFullYear()} SPS Commerce, Inc.
                    </div>
                </div>
            </div>
        </div>
    );
}
