import React from "react";
import Loading from "../Loading";

export default function AuthRedirect(props) {
    const { auth } = props;
    if (auth) {
        auth.login({ appState: window.location.hash });
    }

    return <Loading mode="fullscreen" />;
}
