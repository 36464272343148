import React, { useEffect } from "react";

import { usePatchReducer } from "@spscommerce/ds-react";

export function obfuscateText(text) {
    if (text) {
        return Array.from(text, (x) => "\u2022").join(" ");
    }
    return null;
}

export default function Obfuscator(props) {
    const { children, obfuscate } = props;
    const [state, patchState] = usePatchReducer({
        text: "",
    });

    useEffect(() => {
        if (obfuscate) {
            patchState({ text: obfuscateText(children) });
        } else {
            patchState({ text: children });
        }
    }, [props]);

    return <span className="access-key__value">{state.text}</span>;
}
