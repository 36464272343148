import React from "react";
import classnames from "classnames";

export default function Loading(props) {
    const { mode } = props;
    const loadingClass = classnames("sps-spinner", {
        "sps-spinner--fullscreen": mode === "fullscreen" || mode === "full",
        "sps-spinner--small": mode === "small",
    });

    return <div className={loadingClass}>Loading...</div>;
}
