import React from "react";
import _ from "lodash";
import { SpsContentRow, SpsContentRowCol, SpsContentRowExpansion } from "@spscommerce/ds-react/";

import { uuid } from "../../utils/utils.js";

import SwaggerResponseKeys from "./SwaggerResponseKeysComponent";

const ResponseCodeRow = function ({ responseCode, className = "" }) {
    const responseKeys = responseCode.responseKeys;
    const responseType = responseCode.type;
    return (
        <SpsContentRow className="mt-2" key={uuid()}>
            <SpsContentRowCol>
                <div className="fs-14 lh-20">{`${responseCode.code} - ${responseCode.description}`}</div>
            </SpsContentRowCol>
            {!_.isEmpty(responseType) ? (
                <SpsContentRowCol>{responseCode.type}</SpsContentRowCol>
            ) : (
                <></>
            )}
            {!_.isEmpty(responseKeys) ? (
                <SpsContentRowExpansion className={className}>
                    <SwaggerResponseKeys responseKeys={responseKeys} />
                </SpsContentRowExpansion>
            ) : (
                <></>
            )}
        </SpsContentRow>
    );
};

export default ResponseCodeRow;
