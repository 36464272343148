import React from "react";
import { SpsFeedbackBlock } from "@spscommerce/ds-react";

export const InternalUserBanner = () => {
    const [isInternalDoc, setInternalDoc] = React.useState(false);

    React.useEffect(() => {
        if (window.location.href.includes("internal=true")) setInternalDoc(true);
        else setInternalDoc(false);
    }, [window.location.href]);

    return isInternalDoc ? (
        <SpsFeedbackBlock kind="info">
            This page is only visible to internal users.
        </SpsFeedbackBlock>
    ) : null;
};

export default InternalUserBanner;
