import {
    formControl,
    formGroup,
    SpsButton,
    SpsButtonGroup,
    SpsCard,
    SpsFocusedTask,
    SpsLabel,
    SpsTextInput,
    SpsTextarea,
    SpsValidators,
    useForm,
    SpsForm,
    SpsCheckbox,
} from "@spscommerce/ds-react";
import { ButtonKind, ButtonType } from "@spscommerce/ds-shared";
import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import SETTINGS from "../../App.config";
import { withCommercePlatform } from "@spscommerce/ui-react";
import "./ContactSupport.scss";

export function ContactSales(props) {
    const { onClose, show, commercePlatform, infoCard } = props;
    const [isShown, setIsShown] = useState(show);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [recaptchaSiteKey, setRecaptchaSiteKey] = useState();
    const recaptchaRef = React.createRef();
    const [recaptchaValue, setRecaptchaValue] = useState();
    const [isFormValid, setFormValid] = useState(false);

    useEffect(() => {
        setIsShown(show);
    }, [show]);
    useEffect(() => {
        if (commercePlatform.environment) {
            setRecaptchaSiteKey(SETTINGS.recaptchaKeys[commercePlatform.environment]);
        }
        const currentUser = commercePlatform.currentUser;
        if (currentUser) {
            const org = currentUser.organization;
            form.get("email").setValue(currentUser.email);
            form.get("phone").setValue(currentUser.phone_number);
            form.get("firstName").setValue(currentUser.first_name);
            form.get("lastName").setValue(currentUser.last_name);
            form.get("companyName").setValue(org.organization_name);
            form.get("country").setValue(currentUser.country);
        }
    }, [commercePlatform]);

    const onRecaptchaChange = (key) => {
        setRecaptchaValue(key);
    };

    const form = useForm(
        formGroup({
            firstName: formControl("", {
                validators: [SpsValidators.required, SpsValidators.maxLength(40)],
            }),
            lastName: formControl("", {
                validators: [SpsValidators.required, SpsValidators.maxLength(40)],
            }),
            email: formControl("", {
                validators: [
                    SpsValidators.required,
                    SpsValidators.pattern(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    ),
                    SpsValidators.maxLength(40),
                ],
            }),
            companyName: formControl("", {
                validators: [SpsValidators.required],
            }),
            phone: formControl("", {
                validators: [SpsValidators.required],
            }),
            country: formControl("", {
                validators: [SpsValidators.required, SpsValidators.maxLength(3)],
            }),
            message: formControl(""),
            consentAgreed: formControl(false),
        }),
    );

    useEffect(() => {
        const formValue = form.getValue();
        if (
            formValue.firstName &&
            formValue.lastName &&
            formValue.email &&
            !form.get("email").errors &&
            formValue.country &&
            formValue.consentAgreed &&
            formValue.phone &&
            formValue.companyName &&
            recaptchaValue
        ) {
            setFormValid(true);
        } else {
            setFormValid(false);
        }
    }, [form.getValue()]);

    function handleFocusedTaskClose() {
        form.reset();
        if (onClose) {
            onClose();
        }
    }

    function close() {
        setIsShown(false);
        handleFocusedTaskClose();
    }

    function closeForm() {
        form.reset();
        recaptchaRef.current.reset();
        setRecaptchaValue("");
        close();
    }

    async function submitHandler(e) {
        e.preventDefault();
        if (form.isValid() && recaptchaValue !== "") {
            setIsSubmitting(true);
            let formHtml = document.getElementById("contactForm");
            const agreeCheckbox = document.getElementById("agreeConcent");
            agreeCheckbox.value = form.getValue().consentAgreed ? 1 : 0;
            formHtml.submit();
        }
    }

    return (
        <SpsFocusedTask id="contact-support-form" isOpen={isShown} onClose={handleFocusedTaskClose}>
            <div style={{ width: "50%", margin: "auto" }}>
                <div className="d-flex flex-column mb-3">
                    <h2>{infoCard}</h2>
                    <span style={{ color: "#62686B", fontSize: "14px" }}>
                        Please complete the form below and a member of our sales team will contact
                        you soon, usually within one business day.
                    </span>
                </div>
                <SpsForm
                    id="contactForm"
                    formGroup={form}
                    action={SETTINGS.salesforceSalesFormUrl[commercePlatform.environment]}
                    onSubmit={submitHandler}
                    method="POST"
                >
                    <input name="nospam:blank" value="" type="hidden" />
                    <input name="oid" value="00D300000000bzv" type="hidden" />
                    <input name="sfga" value="00D300000000bzv" type="hidden" />
                    <input name="Campaign_ID" value="7014u000001UgrHAAS" type="hidden" />
                    <input
                        id="lead_source"
                        name="lead_source"
                        value="Marketing Campaign"
                        type="hidden"
                    />
                    <input
                        id="agreeConcent"
                        name="00N0g000003x3Bv"
                        type="hidden"
                        required=""
                        value="1"
                    />
                    <input
                        type="hidden"
                        name="retURL"
                        value={
                            commercePlatform.environment === "test"
                                ? "https://test.developercenter.spscommerce.com/#/contact-info/true"
                                : "https://developercenter.spscommerce.com/#/contact-info/true"
                        }
                    />
                    <SpsCard className="text-left">
                        <div className="sps-row mb-2">
                            <div className="col-12">
                                <SpsLabel
                                    for={form.get("firstName")}
                                    errors={() =>
                                        form.get("firstName").hasError("required") &&
                                        "This field is required"
                                    }
                                >
                                    First Name
                                </SpsLabel>
                                <SpsTextInput
                                    name="first_name"
                                    formControl={form.get("firstName")}
                                />
                            </div>
                        </div>
                        <div className="sps-row mb-2">
                            <div className="col-12">
                                <SpsLabel
                                    for={form.get("lastName")}
                                    errors={() =>
                                        form.get("lastName").hasError("required") &&
                                        "This field is required"
                                    }
                                >
                                    Last Name
                                </SpsLabel>
                                <SpsTextInput name="last_name" formControl={form.get("lastName")} />
                            </div>
                        </div>
                        <div className="sps-row mb-2">
                            <div className="col-12">
                                <SpsLabel
                                    for={form.get("email")}
                                    errors={() => {
                                        return (
                                            (form.get("email").hasError("required") &&
                                                "This field is required") ||
                                            (form.get("email").hasError("pattern") &&
                                                "Please enter a valid Email Address")
                                        );
                                    }}
                                >
                                    Email Address
                                </SpsLabel>
                                <SpsTextInput name="email" formControl={form.get("email")} />
                            </div>
                        </div>
                        <div className="sps-row mb-2">
                            <div className="col-12">
                                <SpsLabel for={form.get("companyName")}>Company Name</SpsLabel>
                                <SpsTextInput
                                    name="company"
                                    formControl={form.get("companyName")}
                                />
                            </div>
                        </div>
                        <div className="sps-row mb-2">
                            <div className="col-12">
                                <SpsLabel for={form.get("phone")}>Phone Number</SpsLabel>
                                <SpsTextInput name="phone" formControl={form.get("phone")} />
                            </div>
                        </div>
                        <div className="sps-row mb-2">
                            <div className="col-12">
                                <SpsLabel
                                    for={form.get("country")}
                                    help={`Enter a 3-character code, eg. "USA".`}
                                >
                                    Country Code
                                </SpsLabel>
                                <SpsTextInput
                                    name="country"
                                    maxLength={3}
                                    formControl={form.get("country")}
                                />
                            </div>
                        </div>
                        <div className="sps-row mb-2">
                            <div className="col-12">
                                <SpsLabel
                                    for={form.get("message")}
                                    errors={() =>
                                        form.get("message").hasError("required") &&
                                        "This field is required"
                                    }
                                >
                                    Message
                                </SpsLabel>
                                <SpsTextarea
                                    name="00N30000000zCH1"
                                    placeholder="Enter your message for the support team"
                                    formControl={form.get("message")}
                                    rows={3}
                                />
                            </div>
                        </div>

                        <div className="sps-row mb-2">
                            <div className="col-12">
                                <span>
                                    <strong>Digital Contact Consent:</strong>
                                </span>
                                <span>
                                    By checking the box you agree that SPS Commerce may use the
                                    information you provide to keep you informed via email about
                                    products and services. You can change your mind at any time by
                                    clicking the unsubscribe link at the footer of any email we send
                                    you.
                                </span>
                            </div>
                        </div>
                        <div className="sps-row mb-2">
                            <div className=" ml-auto mr-auto mt-0">
                                <SpsCheckbox
                                    label="I agree to be contacted"
                                    formControl={form.get("consentAgreed")}
                                    className="mr-0"
                                />
                            </div>
                        </div>
                        {recaptchaSiteKey && (
                            <div className="mt-2 d-flex justify-content-center">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={recaptchaSiteKey}
                                    onChange={onRecaptchaChange}
                                />
                            </div>
                        )}
                    </SpsCard>
                    <div className="mt-3">
                        <SpsButtonGroup>
                            <SpsButton className="mr-1" onClick={closeForm}>
                                Cancel
                            </SpsButton>
                            <SpsButton
                                kind={ButtonKind.CONFIRM}
                                type={ButtonType.SUBMIT}
                                spinning={isSubmitting}
                                disabled={!isFormValid}
                            >
                                Submit
                            </SpsButton>
                        </SpsButtonGroup>
                    </div>
                </SpsForm>
            </div>
        </SpsFocusedTask>
    );
}

export default withCommercePlatform(ContactSales);
