import React from "react";

import Parser from "html-react-parser";

import { SpsCard } from "@spscommerce/ds-react";

import CodeRequestRow from "../../components/CodeContentRow/CodeRequestRow";
import CodeResponseRow from "../../components/CodeContentRow/CodeResponseRow";
import EssentialSchemaDocument from "../../components/EssentialSchemaDocument/EssentialSchemaDocument";
import MarkdownImage from "../../components/MarkdownImage/MarkdownImage";
import VersionTagWrapper from "../../components/VersionTagWrapper/VersionTagWrapper";
import PageTitleWrapper from "../../components/PageTitleWrapper/PageTitleWrapper";
import SpsDevCenterSwagger from "../../components/SpsDevCenterSwagger/SpsDevCenterSwagger";
import SwaggerDocumentation from "../../components/SwaggerDocumentation/SwaggerDocumentation";
import TableWrapper from "../../components/TableWrapper/TableWrapper";
import WorkflowNavigation from "../../components/WorkflowNavigation/WorkflowNavigation";
import SpsCode from "../Code/";
import DescriptionListWrapper from "../DescriptionListWrapper/DescriptionListWrapper";
import DownloadLink from "../DownloadLink/DownloadLink";
import SchemaDocument from "../SchemaDocument/SchemaDocument";
import ResponseCodes from "../SwaggerDocumentation/SwaggerResponseCodesComponent";
import TabbedCardWrapper from "../TabbedCardWrapper/TabbedCardWrapper";
import TipBlockWrapper from "../TipBlockWrapper/TipBlockWrapper";
import WorkflowOverview from "../WorkflowOverview/WorkflowOverview";
import AuthInfo from "./AuthInfo";
import SpsLabelBrowser from "./SpsLabelBrowser";

/**
 * A component that either returns a custom sps react
 * component or the original <pre /> tag that was being
 * requested for a code block.
 */
function CodeOrSpsBlock(props) {
    const { spsBlockComponentType, spsBlockProps } = parseForSpsBlock(props);
    /* Our component whitelist allows only certain
     components to be used in markdown. If we want to
     support more components we could place them
     here.
    */

    switch (spsBlockComponentType) {
        case "SpsLabelBrowser":
            return (
                <SpsLabelBrowser {...spsBlockProps} style={{ backgroundColor: "rebeccapurple" }} />
            );
        case "SchemaDocument":
            return <SchemaDocument {...spsBlockProps} />;
        case "WorkflowOverview":
            return <WorkflowOverview {...spsBlockProps} />;
        case "TipBlockWrapper":
            return <TipBlockWrapper {...spsBlockProps} />;
        case "DescriptionListWrapper":
            return <DescriptionListWrapper {...spsBlockProps} />;
        case "WorkflowNavigation":
            return <WorkflowNavigation {...spsBlockProps} />;
        case "MarkdownImage":
            return <MarkdownImage {...spsBlockProps} cdn_url={props.cdn_url} />;
        case "SpsDevCenterSwagger":
            return <SpsDevCenterSwagger {...spsBlockProps} cdn_url={props.cdn_url} />;
        case "SwaggerDocumentation":
            return <SwaggerDocumentation {...spsBlockProps} cdn_url={props.cdn_url} />;
        case "EssentialSchemaDocument":
            return <EssentialSchemaDocument {...spsBlockProps} />;
        case "VersionTag":
            return <VersionTagWrapper {...spsBlockProps} />;
        case "PageTitle":
            return <PageTitleWrapper {...spsBlockProps} />;
        case "SpsTable":
            return <TableWrapper {...spsBlockProps} />;
        case "TabbedCard":
            return <TabbedCardWrapper {...spsBlockProps} />;
        case "CodeRequestRow":
            return <CodeRequestRow {...spsBlockProps} />;
        case "CodeResponseRow":
            return <CodeResponseRow {...spsBlockProps} />;
        case "ResponseCodes":
            return <ResponseCodes className="dev-center-ui-response-codes" {...spsBlockProps} />;
        case "TextHighlight":
            return <>{Parser(spsBlockProps.text)}</>;
        case "DownloadLink":
            return <DownloadLink {...spsBlockProps} cdn_url={props.cdn_url} />;
        case "AuthInfo":
            return <AuthInfo authDocs={props.authDocs} />;
        default:
            return (
                <pre {...props}>
                    <SpsCard className="code-card">{props.children}</SpsCard>
                </pre>
            );
    }
}

function parseForSpsBlock(props) {
    const innerNode = React.Children.toArray(props.children)[0];
    if (
        React.Children.count(props.children) === 1 &&
        innerNode.type === SpsCode &&
        innerNode.props &&
        innerNode.props.className === "language-sps"
    ) {
        const innerContent = JSON.parse(innerNode.props.children[0]);

        return {
            spsBlockFound: true,
            spsBlockComponentType: innerContent.component,
            spsBlockProps: innerContent.props,
        };
    }
    return false;
}

export default CodeOrSpsBlock;
