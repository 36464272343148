import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
    SpsDescriptionList,
    SpsDescriptionListTerm,
    SpsDescriptionListDefinition,
    SpsListToolbar,
    SpsTextInput,
    SpsListToolbarSearch,
    SpsZeroState,
    SpsLabel,
} from "@spscommerce/ds-react";

import "./DescriptionListWrapper.scss";

const DescriptionListWrapper = (props) => {
    const {
        termArray,
        wideTerms,
        widerTerms,
        compact,
        searchable = false,
        searchPlaceholder = "",
    } = props;

    const [searchTerm, setSearchTerm] = useState("");
    const [filterList, setFilterList] = useState(termArray);

    useEffect(() => {
        updateResults();
    }, [searchTerm]);

    const updateResults = () => {
        const searchResults = termArray.filter(
            (obj) =>
                Object.keys(obj)[0].toLowerCase().includes(searchTerm.toLowerCase()) ||
                Object.values(obj)[0].toLowerCase().includes(searchTerm.toLowerCase()),
        );
        setFilterList(searchResults);
    };

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
    };

    function SpsDdWrapper(props) {
        const rowInfo = props.rowInfo;
        const keys = Object.keys(rowInfo);
        if (Array.isArray(rowInfo[keys[0]])) {
            return (
                <SpsDescriptionListDefinition>
                    <div className="row">
                        {rowInfo[keys[0]].map((item, i) => (
                            <div key={i} className="col-5 pl-3 pr-0">
                                {item}
                            </div>
                        ))}
                    </div>
                </SpsDescriptionListDefinition>
            );
        } else {
            return <SpsDescriptionListDefinition>{rowInfo[keys[0]]}</SpsDescriptionListDefinition>;
        }
    }

    return (
        <>
            {searchable && (
                <SpsListToolbar pinned="false">
                    <SpsListToolbarSearch>
                        <SpsTextInput
                            name="search_text"
                            value={searchTerm}
                            onChange={handleSearchTermChange}
                            placeholder={searchPlaceholder}
                            icon="filter"
                        />
                    </SpsListToolbarSearch>
                </SpsListToolbar>
            )}

            <SpsDescriptionList wideTerms={wideTerms} widerTerms={widerTerms} compact={compact}>
                {filterList.map((row, index) => {
                    const keys = Object.keys(row);
                    return (
                        <React.Fragment key={index}>
                            <SpsDescriptionListTerm>
                                {row.labelText ? (
                                    <SpsLabel
                                        help={row.labelText}
                                        helpIcon={row.labelIcon}
                                        className="fs-14 d-flex justify-content-between font-weight-normal label-text--dark"
                                    >
                                        {keys[0]}
                                    </SpsLabel>
                                ) : (
                                    keys[0]
                                )}
                            </SpsDescriptionListTerm>
                            <SpsDdWrapper rowInfo={row} />
                        </React.Fragment>
                    );
                })}
            </SpsDescriptionList>
            {!filterList.length && (
                <SpsZeroState
                    heading="No results found"
                    artwork="https://cdn.prod.spsc.io/web/framework/assets/18.03.02/images/icon-error.svg"
                />
            )}
        </>
    );
};

DescriptionListWrapper.propTypes = {
    termArray: PropTypes.array.isRequired,
};

export default DescriptionListWrapper;
