import React from "react";
import { SpsCard } from "@spscommerce/ui-react";
import { SpsButton } from "@spscommerce/ds-react";
import "./ContactInfo.scss";

export default function ContactInfoCard(props) {
    const handleSupportLinkClick = () => {
        props.support && props.support();
    };
    return (
        <SpsCard>
            <div className="contact-card__body">
                <div>
                    <div className="circle">
                        {props.img ? (
                            <img
                                src={props.img}
                                className="circle-icon sps-icon mx-auto d-block"
                                alt={props.alt}
                            />
                        ) : (
                            <i
                                className={`circle-icon sps-icon sps-icon-${props.icon}
              ${props.iconClass}`}
                            />
                        )}
                    </div>
                </div>
                <div className="contact-card__body__heading">{props.heading}</div>
                {props.phoneText && (
                    <div className="contact-card__body__sub-heading">
                        {props.phoneText}: <span className="phone">{props.phone}</span>
                    </div>
                )}
                {props.email && (
                    <div className="contact-card__body__link">
                        Email:
                        <a href="mailto:support@spscommerce.com">{props.email}</a>
                    </div>
                )}
                {props.support && (
                    <div className="contact-card__body__link">
                        <SpsButton
                            onClick={handleSupportLinkClick}
                            kind="link"
                            className="fs-14 button-capital-heading"
                        >
                            Contact{" "}
                            {props.infoCard === "product"
                                ? props.infoCard + " team"
                                : props.infoCard}
                        </SpsButton>
                    </div>
                )}
            </div>
        </SpsCard>
    );
}
