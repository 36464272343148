import React, { Component } from "react";
import "./template-dropdown.scss";

export default class TemplateDropdown extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = { open: false, isOpen: props.isOpen };
        this.dropdownRef = React.createRef();
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    handleKeyPress = (event) => {
        if (event.target !== this.dropdownRef.current) {
            return;
        }
        const { key } = event;
        if (key === "Escape") {
            this.setState({ open: false });
        }
    };
    handleOutsideEvent = (e) => {
        if (
            (this.node && this.node.contains(e.target)) ||
            e.target.className.includes("suggestion")
        ) {
            return;
        }
        this.toggleVis();
    };

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ open: false });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        document.addEventListener("keydown", this.handleKeyPress);
        document.addEventListener("click", this.handleClickOutside);
        document.removeEventListener("focus", this.handleOutsideEvent);
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.isOpen !== prevProps.isOpen &&
            this._isMounted &&
            this.props.isOpen !== undefined
        ) {
            this.setState({ open: false });
            if (typeof this.props.afterOpen === "function") {
                this.props.afterOpen();
            }
        }
    }

    toggleVis = () => {
        // block setState if component has unmounted
        if (this._isMounted) {
            this.setState((prev, props) => {
                return { open: !prev.open };
            });
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
        document.removeEventListener("keydown", this.handleKeyPress);
        document.removeEventListener("click", this.handleClickOutside);
        document.removeEventListener("focus", this.handleOutsideEvent);
    }

    render() {
        const { children, triggerComponent: Trigger } = this.props;
        const triggerWithClick = (
            <button className="trigger" onClick={this.toggleVis} ref={this.dropdownRef}>
                <Trigger />
            </button>
        );
        return (
            <div
                className="template-dropdown"
                aria-haspopup="menu"
                aria-expanded={this.state.open}
                aria-label={this.props.label}
                ref={this.setWrapperRef}
            >
                <div>{triggerWithClick}</div>
                {this.state.open ? <div className="dropdown-content">{children}</div> : null}
            </div>
        );
    }
}
