import axios from "axios";
import get from "lodash/get";

import AppConfig from "../App.config";

export class DevCenterApi {
    constructor(props) {
        this.props = props;
    }

    async getServices() {
        const { environment, token } = this.props;
        const config = token ? { headers: { Authorization: `Bearer ${token}` } } : null;
        const url = token
            ? `${AppConfig.kubeRoot[environment]}/devcenter/v1/services/?page_size=100`
            : `${AppConfig.kubeRoot[environment]}/devcenter/public/v1/services/?page_size=100`;
        try {
            const servicesResponse = await axios.get(url, config);
            return { services: servicesResponse.data.results };
        } catch (err) {
            const msg = get(err, "response.data.error.errorDescription")
                ? get(err, "response.data.error.errorDescription")
                : "An error has occurred when fetching the services";
            const title = get(err, "response.data.error.error")
                ? get(err, "response.data.error.error")
                : "Error!";
            return { services: [], errorTitle: title, errorMessage: msg };
        }
    }
}
