import React from 'react';
import "./SplashPage.scss";
import logo from "./SPS Logo.png"

function SplashPage() {
    return (
        
        <div className="splash-main-content">
            <img className="sps-logo" src={logo} alt="sps-logo"/>
            <div className="looking-for-dev-cent">
                Sorry, Dev Center is down for scheduled maintenance. 
            </div>
            <div className="the-latest-dev-center">
                We'll be back soon - visit the <a href="https://status.spscommerce.com/" style={{textDecoration:"none"}}>status page</a> for additional updates. If you need immediate assistance, contact support at <span style={{fontWeight:"400"}}>888.739.3232</span>.
            </div>
        </div>
    );
}

export default SplashPage;