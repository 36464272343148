import React from "react";
import classnames from "classnames";
import _ from "lodash";

import { SpsCard, SpsTag } from "@spscommerce/ds-react";
import { NavLink } from "react-router-dom";

import { TOC } from "../TOC";
import { TopLevelTOC } from "../TOC";
import config from "../../App.config";
import "./SideNav.scss";

export function SideNavSection(props) {
    const { children, ...rest } = props;
    return <div {...rest}>{children}</div>;
}

export function SideNavSectionTitle(props) {
    const { children, ...rest } = props;
    return (
        <div className="sps-side-nav__section-title" {...rest}>
            {children}
        </div>
    );
}

export function SideNavLink(props) {
    const { children, className, ...rest } = props;
    let linkClasses = classnames("sps-side-nav__link", className);
    const el = document.querySelector(".page-banner");
    return (
        <NavLink
            exact={true}
            className={linkClasses}
            {...rest}
            onClick={() => {
                el
                    ? window.scrollTo({
                          top: el.offsetHeight + 10.8,
                          left: 0,
                          behavior: "smooth",
                      })
                    : window.scrollTo(0, 0);
            }}
        >
            {children}
        </NavLink>
    );
}

export function SideNav(props) {
    const {
        ldControlledApiDocs,
        ldClient,
        services,
        currentService,
        children,
        pathname,
        authenticationService,
        ...rest
    } = props;

    const {
        gettingStartedLinks,
        authenticationLinks,
        servicesLinks,
        internalLinks,
        developmentLinks,
    } = sortLinks(services);

    function alphabetizeLinks(a, b) {
        return a.name < b.name ? -1 : 1;
    }

    function sortLinks(docs) {
        // Any service whose slug is included  in the blacklist array for any environment
        // won't display in that environment
        const env = props.environment;

        const gettingStartedIDs = config.documentation.gettingStartedCategoryWhitelist;

        const authenticationIDs = config.documentation.authenticationWhitelist;

        const internalBuildGuideIDs = config.documentation.internalBuildGuideCategoryWhitelist;

        const internalDevelopmentToolsGuideIDs =
            config.documentation.internalDevelopmentToolsCategoryWhitelist;

        //Remove any documents that are blacklisted for this environment or not active
        const filteredDocs = docs.filter(
            (doc) => !config.documentation.serviceDocsBlacklist[env].includes(doc.id) && doc.active,
        );

        // Filter documents into the appropriate category
        const gettingStartedLinks = filteredDocs.filter((doc) =>
            gettingStartedIDs.includes(doc.id),
        );
        const authenticationLinks = filteredDocs.filter((doc) =>
            authenticationIDs.includes(doc.id),
        );
        const servicesLinks = filteredDocs.filter(
            (doc) =>
                doc.public &&
                !gettingStartedIDs.includes(doc.id) &&
                !authenticationIDs.includes(doc.id) &&
                !internalBuildGuideIDs.includes(doc.id),
        );
        const internalLinks = filteredDocs.filter(
            (doc) =>
                (!doc.public || internalBuildGuideIDs.includes(doc.id)) &&
                !gettingStartedIDs.includes(doc.id) &&
                !authenticationIDs.includes(doc.id) &&
                !internalDevelopmentToolsGuideIDs.includes(doc.id),
        );
        const developmentLinks = filteredDocs.filter(
            (doc) => !doc.public && internalDevelopmentToolsGuideIDs.includes(doc.id),
        );

        const sortedServicesLinks = servicesLinks.slice().sort(alphabetizeLinks);
        const sortedInternalLinks = internalLinks.slice().sort(alphabetizeLinks);
        const sortedDevelopmentLinks = developmentLinks.slice().sort(alphabetizeLinks);

        return {
            gettingStartedLinks,
            authenticationLinks,
            servicesLinks: sortedServicesLinks,
            internalLinks: sortedInternalLinks,
            developmentLinks: sortedDevelopmentLinks,
        };
    }

    function treeIsActive(service) {
        return props.currentService && props.currentService.slug === service.slug;
    }

    function renderSideNavLinksSection(docs, title) {
        const { currentService, pathname, ldControlledApiDocs, environment } = props;

        let gettingStartedActiveClass = classnames("sps-side-nav__section-content", {
            "tree-active": treeIsActive({ slug: "getting-started" }),
        });

        return (
            <SideNavSection className="sps-side-nav__section">
                {title && <SideNavSectionTitle>{title}</SideNavSectionTitle>}
                {title === "Welcome to Dev Center" && (
                    <div className={`${gettingStartedActiveClass}`} key={"/docs/getting-started"}>
                        <SideNavLink
                            to={`/docs/getting-started`}
                            className="sps-side-nav__link--top"
                        >
                            Get Started
                        </SideNavLink>
                    </div>
                )}
                {docs.map((doc, index) => {
                    let activeClass = classnames("sps-side-nav__section-content", {
                        "tree-active": treeIsActive(doc),
                    });
                    if (
                        (_.has(ldControlledApiDocs, doc.slug) && ldControlledApiDocs[doc.slug]) ||
                        !_.has(ldControlledApiDocs, doc.slug)
                    ) {
                        return (
                            <div className={`${activeClass}`} key={doc.slug + index}>
                                <SideNavLink
                                    to={`/docs/${doc.slug}`}
                                    className="sps-side-nav__link--top "
                                >
                                    {treeIsActive(doc) ? (
                                        <i className="sps-icon sps-icon-chevron-down" />
                                    ) : (
                                        <i className="sps-icon sps-icon-chevron-right" />
                                    )}
                                    {doc.name}
                                    {doc.isBeta && (
                                        <SpsTag kind="info" icon="star">
                                            <span>Beta</span>
                                        </SpsTag>
                                    )}
                                </SideNavLink>
                                {currentService && doc.slug === currentService.slug ? (
                                    <TOC
                                        pathname={pathname}
                                        docsRoot={doc.currentDocLocation}
                                        loading={() => <div>Loading navigation</div>}
                                        environment={environment}
                                    />
                                ) : null}
                            </div>
                        );
                    }
                    return null;
                })}
            </SideNavSection>
        );
    }

    function renderExpandedSideAuthenticationSection(docsRoot, serviceSlug, docs, title) {
        const { pathname, ldControlledApiDocs } = props;
        const docName = pathname.split("/").pop();
        const truePathname = `/docs/${serviceSlug}/${docName}`;

        return (
            <SideNavSection className="sps-side-nav__section">
                {title && <SideNavSectionTitle>{title}</SideNavSectionTitle>}
                {docs.map((doc, index) => {
                    let activeClass = classnames("sps-side-nav__section-content pre-expanded", {
                        "tree-active": treeIsActive(doc),
                    });

                    if (
                        (_.has(ldControlledApiDocs, doc.slug) && ldControlledApiDocs[doc.slug]) ||
                        !_.has(ldControlledApiDocs, doc.slug)
                    ) {
                        return (
                            <div className={`${activeClass}`} key={doc.slug + index}>
                                <TopLevelTOC
                                    isTop={true}
                                    pathname={truePathname}
                                    docsRoot={docsRoot}
                                    serviceSlug={serviceSlug}
                                    loading={() => <div>Loading navigation</div>}
                                />
                            </div>
                        );
                    }
                    return null;
                })}
            </SideNavSection>
        );
    }

    return (
        <div className="col-3 docs-body__sidebar sps-side-nav" {...rest}>
            <SpsCard
                className="sps-side-nav-card"
                style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                }}
                headerTitle="Apis and Services"
            >
                <div className="section-seprator" />
                {/* Always render the Getting Started section since we have a static documentation page included*/}
                {renderSideNavLinksSection(gettingStartedLinks, "Welcome to Dev Center")}
                {authenticationLinks.length > 0 &&
                    renderExpandedSideAuthenticationSection(
                        authenticationService.currentDocLocation,
                        authenticationService.slug,
                        authenticationLinks,
                        "Authentication",
                    )}
                {servicesLinks.length > 0 && renderSideNavLinksSection(servicesLinks, "Services")}
                {internalLinks.length > 0 &&
                    renderSideNavLinksSection(internalLinks, "Internal Build Guides")}
                {children}
                {developmentLinks.length > 0 &&
                    renderSideNavLinksSection(developmentLinks, "Internal Development Tools")}
            </SpsCard>
        </div>
    );
}

export default SideNav;
