import AppConfig from "../../App.config";

const examplePageData = [
    {
        header: "Orders",
        subtitle: "850, 875, 940, ORDERS, PurchaseOrders",
        toolTip: "Request goods or services.",
        files: {
            xml: {
                all: `v7.7/xml/Orders(850).zip`,
                allName: `Orders (${AppConfig.rsx.documentNumbers["Orders"]}).zip`,
                rest: [
                    {
                        name: "PO112853-1-v7.7-CrossDock",
                        url: `v7.7/xml/Orders(850)/PO112853-1-v7.7-CrossDock.xml`,
                    },
                    {
                        name: "PO112855-1-v7.7-MultiStore",
                        url: `v7.7/xml/Orders(850)/PO112855-1-v7.7-MultiStore.xml`,
                    },
                    {
                        name: "PO584615-1-v7.7-BulkImport",
                        url: `v7.7/xml/Orders(850)/PO584615-1-v7.7-BulkImport.xml`,
                    },
                    {
                        name: "PO584616-1-v7.7-DropShip",
                        url: `v7.7/xml/Orders(850)/PO584616-1-v7.7-DropShip.xml`,
                    },
                ],
            },
            csv: {
                all: `v7.7/csv/Orders(850).zip`,
                allName: `Orders (${AppConfig.rsx.documentNumbers["Orders"]}).zip`,
                rest: [
                    {
                        name: "PO112853-1-v7.7-CrossDock",
                        url: `v7.7/csv/Orders(850)/PO112853-1-v7.7-CrossDock.csv`,
                    },
                    {
                        name: "PO112855-1-v7.7-MultiStore",
                        url: `v7.7/csv/Orders(850)/PO112855-1-v7.7-MultiStore.csv`,
                    },
                    {
                        name: "PO584615-1-v7.7-BulkImport",
                        url: `v7.7/csv/Orders(850)/PO584615-1-v7.7-BulkImport.csv`,
                    },
                    {
                        name: "PO584616-1-v7.7-DropShip",
                        url: `v7.7/csv/Orders(850)/PO584616-1-v7.7-DropShip.csv`,
                    },
                ],
            },
            positional: {
                all: `v7.7/positional/Orders(850).zip`,
                allName: `Orders (${AppConfig.rsx.documentNumbers["Orders"]}).zip`,
                rest: [
                    {
                        name: "PO112853-1-v7.7-CrossDock",
                        url: `v7.7/positional/Orders(850)/PO112853-1-v7.7-CrossDock.txt`,
                    },
                    {
                        name: "PO112855-1-v7.7-MultiStore",
                        url: `v7.7/positional/Orders(850)/PO112855-1-v7.7-MultiStore.txt`,
                    },
                    {
                        name: "PO584615-1-v7.7-BulkImport",
                        url: `v7.7/positional/Orders(850)/PO584615-1-v7.7-BulkImport.txt`,
                    },
                    {
                        name: "PO584616-1-v7.7-DropShip",
                        url: `v7.7/positional/Orders(850)/PO584616-1-v7.7-DropShip.txt`,
                    },
                ],
            },
            json: {
                all: `v7.7/json/Orders(850).zip`,
                allName: `Orders (${AppConfig.rsx.documentNumbers["Orders"]}).zip`,
                rest: [
                    {
                        name: "PO112853-1-v7.7-CrossDock",
                        url: `v7.7/json/Orders(850)/PO112853-1-v7.7-CrossDock.json`,
                    },
                    {
                        name: "PO112855-1-v7.7-MultiStore",
                        url: `v7.7/json/Orders(850)/PO112855-1-v7.7-MultiStore.json`,
                    },
                    {
                        name: "PO584615-1-v7.7-BulkImport",
                        url: `v7.7/json/Orders(850)/PO584615-1-v7.7-BulkImport.json`,
                    },
                    {
                        name: "PO584616-1-v7.7-DropShip",
                        url: `v7.7/json/Orders(850)/PO584616-1-v7.7-DropShip.json`,
                    },
                ],
            },
        },
    },
    {
        header: "OrderAcks",
        subtitle: "855, ORDRSP, PurchaseOrderAcks",
        toolTip: "Accept or reject an order, and share any errors or changes made to the order.",
        files: {
            xml: {
                all: `v7.7/xml/OrderAcks(855).zip`,
                allName: `OrderAcks (${AppConfig.rsx.documentNumbers["OrderAcks"]}).zip`,
                rest: [
                    {
                        name: "PR112853-1-v7.7-CrossDock_AllAccept_With_Change",
                        url: `v7.7/xml/OrderAcks(855)/PR112853-1-v7.7-CrossDock_AllAccept_With_Change.xml`,
                    },
                    {
                        name: "PR112855-1-v7.7-MultiStore_AllAccept_With_Change",
                        url: `v7.7/xml/OrderAcks(855)/PR112855-1-v7.7-MultiStore_AllAccept_With_Change.xml`,
                    },
                    {
                        name: "PR584615-1-v7.7-BulkImport_AllAccept_With_Change",
                        url: `v7.7/xml/OrderAcks(855)/PR584615-1-v7.7-BulkImport_AllAccept_With_Change.xml`,
                    },
                    {
                        name: "PR584616-1-v7.7-DropShip_AllAccept_With_Change",
                        url: `v7.7/xml/OrderAcks(855)/PR584616-1-v7.7-DropShip_AllAccept_With_Change.xml`,
                    },
                ],
            },
            csv: {
                all: `v7.7/csv/OrderAcks(855).zip`,
                allName: `OrderAcks (${AppConfig.rsx.documentNumbers["OrderAcks"]}).zip`,
                rest: [
                    {
                        name: "PR112853-1-v7.7-CrossDock_AllAccept_With_Change",
                        url: `v7.7/csv/OrderAcks(855)/PR112853-1-v7.7-CrossDock_AllAccept_With_Change.csv`,
                    },
                    {
                        name: "PR112855-1-v7.7-MultiStore_AllAccept_With_Change",
                        url: `v7.7/csv/OrderAcks(855)/PR112855-1-v7.7-MultiStore_AllAccept_With_Change.csv`,
                    },
                    {
                        name: "PR584615-1-v7.7-BulkImport_AllAccept_With_Change",
                        url: `v7.7/csv/OrderAcks(855)/PR584615-1-v7.7-BulkImport_AllAccept_With_Change.csv`,
                    },
                    {
                        name: "PR584616-1-v7.7-DropShip_AllAccept_With_Change",
                        url: `v7.7/csv/OrderAcks(855)/PR584616-1-v7.7-DropShip_AllAccept_With_Change.csv`,
                    },
                ],
            },
            positional: {
                all: `v7.7/positional/OrderAcks(855).zip`,
                allName: `OrderAcks (${AppConfig.rsx.documentNumbers["OrderAcks"]}).zip`,
                rest: [
                    {
                        name: "PR112853-1-v7.7-CrossDock_AllAccept_With_Change",
                        url: `v7.7/positional/OrderAcks(855)/PR112853-1-v7.7-CrossDock_AllAccept_With_Change.txt`,
                    },
                    {
                        name: "PR112855-1-v7.7-MultiStore_AllAccept_With_Change",
                        url: `v7.7/positional/OrderAcks(855)/PR112855-1-v7.7-MultiStore_AllAccept_With_Change.txt`,
                    },
                    {
                        name: "PR584615-1-v7.7-BulkImport_AllAccept_With_Change",
                        url: `v7.7/positional/OrderAcks(855)/PR584615-1-v7.7-BulkImport_AllAccept_With_Change.txt`,
                    },
                    {
                        name: "PR584616-1-v7.7-DropShip_AllAccept_With_Change",
                        url: `v7.7/positional/OrderAcks(855)/PR584616-1-v7.7-DropShip_AllAccept_With_Change.txt`,
                    },
                ],
            },
            json: {
                all: `v7.7/json/OrderAcks(855).zip`,
                allName: `OrderAcks (${AppConfig.rsx.documentNumbers["OrderAcks"]}).zip`,
                rest: [
                    {
                        name: "PR112853-1-v7.7-CrossDock_AllAccept_With_Change",
                        url: `v7.7/json/OrderAcks(855)/PR112853-1-v7.7-CrossDock_AllAccept_With_Change.json`,
                    },
                    {
                        name: "PR112855-1-v7.7-MultiStore_AllAccept_With_Change",
                        url: `v7.7/json/OrderAcks(855)/PR112855-1-v7.7-MultiStore_AllAccept_With_Change.json`,
                    },
                    {
                        name: "PR584615-1-v7.7-BulkImport_AllAccept_With_Change",
                        url: `v7.7/json/OrderAcks(855)/PR584615-1-v7.7-BulkImport_AllAccept_With_Change.json`,
                    },
                    {
                        name: "PR584616-1-v7.7-DropShip_AllAccept_With_Change",
                        url: `v7.7/json/OrderAcks(855)/PR584616-1-v7.7-DropShip_AllAccept_With_Change.json`,
                    },
                ],
            },
        },
    },
    {
        header: "Shipments",
        subtitle: "856, 943, 945, AdvanceShipNotices, DESADV",
        toolTip: "Give advance notice of the shipment.",
        files: {
            xml: {
                all: `v7.7/xml/Shipments(856).zip`,
                allName: `Shipments (${AppConfig.rsx.documentNumbers["Shipments"]}).zip`,
                rest: [
                    {
                        name: "Consolidated_Shipment-v7.7-MultiOrders",
                        url: `v7.7/xml/Shipments(856)/Consolidated_Shipment-v7.7-MultiOrders.xml`,
                    },
                    {
                        name: "SH112853-1-v7.7-CrossDock",
                        url: `v7.7/xml/Shipments(856)/SH112853-1-v7.7-CrossDock.xml`,
                    },
                    {
                        name: "SH112855-1-v7.7-MultiStore",
                        url: `v7.7/xml/Shipments(856)/SH112855-1-v7.7-MultiStore.xml`,
                    },
                    {
                        name: "SH584615-1-v7.7-BulkImport",
                        url: `v7.7/xml/Shipments(856)/SH584615-1-v7.7-BulkImport.xml`,
                    },
                    {
                        name: "SH584615-1-v7.7-BulkImport_TareLevel",
                        url: `v7.7/xml/Shipments(856)/SH584615-1-v7.7-BulkImport_TareLevel.xml`,
                    },
                    {
                        name: "SH584616-1-v7.7-DropShip",
                        url: `v7.7/xml/Shipments(856)/SH584616-1-v7.7-DropShip.xml`,
                    },
                ],
            },
            csv: {
                all: `v7.7/csv/Shipments(856).zip`,
                allName: `Shipments (${AppConfig.rsx.documentNumbers["Shipments"]}).zip`,
                rest: [
                    {
                        name: "SH112853-1-v7.7-CrossDock",
                        url: `v7.7/csv/Shipments(856)/SH112853-1-v7.7-CrossDock.csv`,
                    },
                    {
                        name: "SH112855-1-v7.7-MultiStore",
                        url: `v7.7/csv/Shipments(856)/SH112855-1-v7.7-MultiStore.csv`,
                    },
                    {
                        name: "SH584615-1-v7.7-BulkImport",
                        url: `v7.7/csv/Shipments(856)/SH584615-1-v7.7-BulkImport.csv`,
                    },
                    {
                        name: "SH584616-1-v7.7-DropShip",
                        url: `v7.7/csv/Shipments(856)/SH584616-1-v7.7-DropShip.csv`,
                    },
                ],
            },
            positional: {
                all: `v7.7/positional/Shipments(856).zip`,
                allName: `Shipments (${AppConfig.rsx.documentNumbers["Shipments"]}).zip`,
                rest: [
                    {
                        name: "SH112853-1-v7.7-CrossDock",
                        url: `v7.7/positional/Shipments(856)/SH112853-1-v7.7-CrossDock.txt`,
                    },
                    {
                        name: "SH112855-1-v7.7-MultiStore",
                        url: `v7.7/positional/Shipments(856)/SH112855-1-v7.7-MultiStore.txt`,
                    },
                    {
                        name: "SH584615-1-v7.7-BulkImport",
                        url: `v7.7/positional/Shipments(856)/SH584615-1-v7.7-BulkImport.txt`,
                    },
                    {
                        name: "SH584615-1-v7.7-BulkImport",
                        url: `v7.7/positional/Shipments(856)/SH584615-1-v7.7-BulkImport_TareLevel.txt`,
                    },
                    {
                        name: "SH584616-1-v7.7-DropShip",
                        url: `v7.7/positional/Shipments(856)/SH584616-1-v7.7-DropShip.txt`,
                    },
                ],
            },
            json: {
                all: `v7.7/json/Shipments(856).zip`,
                allName: `Shipments (${AppConfig.rsx.documentNumbers["Shipments"]}).zip`,
                rest: [
                    {
                        name: "Consolidated_Shipment-v7.7-MultiOrders",
                        url: `v7.7/json/Shipments(856)/Consolidated_Shipment-v7.7-MultiOrders.json`,
                    },
                    {
                        name: "SH112853-1-v7.7-CrossDock",
                        url: `v7.7/json/Shipments(856)/SH112853-1-v7.7-CrossDock.json`,
                    },
                    {
                        name: "SH112855-1-v7.7-MultiStore",
                        url: `v7.7/json/Shipments(856)/SH112855-1-v7.7-MultiStore.json`,
                    },
                    {
                        name: "SH584615-1-v7.7-BulkImport",
                        url: `v7.7/json/Shipments(856)/SH584615-1-v7.7-BulkImport.json`,
                    },
                    {
                        name: "SH584615-1-v7.7-BulkImport_TareLevel",
                        url: `v7.7/json/Shipments(856)/SH584615-1-v7.7-BulkImport_TareLevel.json`,
                    },
                    {
                        name: "SH584616-1-v7.7-DropShip",
                        url: `v7.7/json/Shipments(856)/SH584616-1-v7.7-DropShip.json`,
                    },
                ],
            },
        },
    },
    {
        header: "Invoices",
        subtitle: "810, 880, INVOIC",
        toolTip: "Request payment for delivered products.",
        files: {
            xml: {
                all: `v7.7/xml/Invoices(810).zip`,
                allName: `Invoices (${AppConfig.rsx.documentNumbers["Invoices"]}).zip`,
                rest: [
                    {
                        name: "IN112853-1-v7.7-CrossDock",
                        url: `v7.7/xml/Invoices(810)/IN112853-1-v7.7-CrossDock.xml`,
                    },
                    {
                        name: "IN112855-1-v7.7-MultiStore",
                        url: `v7.7/xml/Invoices(810)/IN112855-1-v7.7-MultiStore.xml`,
                    },
                    {
                        name: "IN584615-1-v7.7-BulkImport",
                        url: `v7.7/xml/Invoices(810)/IN584615-1-v7.7-BulkImport.xml`,
                    },
                    {
                        name: "IN584616-1-v7.7-DropShip",
                        url: `v7.7/xml/Invoices(810)/IN584616-1-v7.7-DropShip.xml`,
                    },
                ],
            },
            csv: {
                all: `v7.7/csv/Invoices(810).zip`,
                allName: `Invoices (${AppConfig.rsx.documentNumbers["Invoices"]}).zip`,
                rest: [
                    {
                        name: "IN112853-1-v7.7-CrossDock",
                        url: `v7.7/csv/Invoices(810)/IN112853-1-v7.7-CrossDock.csv`,
                    },
                    {
                        name: "IN112855-1-v7.7-MultiStore",
                        url: `v7.7/csv/Invoices(810)/IN112855-1-v7.7-MultiStore.csv`,
                    },
                    {
                        name: "IN584615-1-v7.7-BulkImport",
                        url: `v7.7/csv/Invoices(810)/IN584615-1-v7.7-BulkImport.csv`,
                    },
                    {
                        name: "IN584616-1-v7.7-DropShip",
                        url: `v7.7/csv/Invoices(810)/IN584616-1-v7.7-DropShip.csv`,
                    },
                ],
            },
            positional: {
                all: `v7.7/positional/Invoices(810).zip`,
                allName: `Invoices (${AppConfig.rsx.documentNumbers["Invoices"]}).zip`,
                rest: [
                    {
                        name: "IN112853-1-v7.7-CrossDock",
                        url: `v7.7/positional/Invoices(810)/IN112853-1-v7.7-CrossDock.txt`,
                    },
                    {
                        name: "IN112855-1-v7.7-MultiStore",
                        url: `v7.7/positional/Invoices(810)/IN112855-1-v7.7-MultiStore.txt`,
                    },
                    {
                        name: "IN584615-1-v7.7-BulkImport",
                        url: `v7.7/positional/Invoices(810)/IN584615-1-v7.7-BulkImport.txt`,
                    },
                    {
                        name: "IN584616-1-v7.7-DropShip",
                        url: `v7.7/positional/Invoices(810)/IN584616-1-v7.7-DropShip.txt`,
                    },
                ],
            },
            json: {
                all: `v7.7/json/Invoices(810).zip`,
                allName: `Invoices (${AppConfig.rsx.documentNumbers["Invoices"]}).zip`,
                rest: [
                    {
                        name: "IN112853-1-v7.7-CrossDock",
                        url: `v7.7/json/Invoices(810)/IN112853-1-v7.7-CrossDock.json`,
                    },
                    {
                        name: "IN112855-1-v7.7-MultiStore",
                        url: `v7.7/json/Invoices(810)/IN112855-1-v7.7-MultiStore.json`,
                    },
                    {
                        name: "IN584615-1-v7.7-BulkImport",
                        url: `v7.7/json/Invoices(810)/IN584615-1-v7.7-BulkImport.json`,
                    },
                    {
                        name: "IN584616-1-v7.7-DropShip",
                        url: `v7.7/json/Invoices(810)/IN584616-1-v7.7-DropShip.json`,
                    },
                ],
            },
        },
    },
    {
        header: "OrderChanges",
        subtitle: "860, ORDCHG",
        toolTip: "Describe the original order, and any changes to that order.",
        files: {
            xml: {
                all: `v7.7/xml/OrderChanges(860).zip`,
                allName: `OrderChanges (${AppConfig.rsx.documentNumbers["OrderChanges"]}).zip`,
                rest: [
                    {
                        name: "PC584615-1-v7.7-BulkImport",
                        url: `v7.7/xml/OrderChanges(860)/PC584615-1-v7.7-BulkImport.xml`,
                    },
                ],
            },
            positional: {
                all: `v7.7/positional/OrderChanges(860).zip`,
                allName: `OrderChanges (${AppConfig.rsx.documentNumbers["OrderChanges"]}).zip`,
                rest: [
                    {
                        name: "PC584615-1-v7.7-BulkImport",
                        url: `v7.7/positional/OrderChanges(860)/PC584615-1-v7.7-BulkImport.txt`,
                    },
                ],
            },
            // No csv example files
        },
    },
    {
        header: "OrderChangeAcks",
        subtitle: "865",
        toolTip: "Accept or reject order changes, or request changes for a previous order.",
        files: {
            xml: {
                all: `v7.7/xml/OrderChangeAcks(865).zip`,
                allName: `OrderChangeAcks (${AppConfig.rsx.documentNumbers["OrderChangeAcks"]}).zip`,
                rest: [
                    {
                        name: "CA584615-1-v7.7-BulkImport",
                        url: `v7.7/xml/OrderChangeAcks(865)/CA584615-1-v7.7-BulkImport.xml`,
                    },
                ],
            },
            positional: {
                all: `v7.7/positional/OrderChangeAcks(865).zip`,
                allName: `OrderChangeAcks (${AppConfig.rsx.documentNumbers["OrderChangeAcks"]}).zip`,
                rest: [
                    {
                        name: "CA584615-1-v7.7-BulkImport",
                        url: `v7.7/positional/OrderChangeAcks(865)/CA584615-1-v7.7-BulkImport.txt`,
                    },
                ],
            },
            // No csv example files
        },
    },
    {
        header: "InventoryInquiriesAndAdvices",
        subtitle: "846",
        toolTip: "Share detailed product and price information.",
        files: {
            xml: {
                all: `v7.7/xml/InventoryAdvice(846).zip`,
                allName: `InventoryInquiriesAndAdvices (${AppConfig.rsx.documentNumbers["InventoryInquiriesAndAdvices"]}).zip`,
                rest: [
                    {
                        name: "IB123546-1-v7.7-OneWarehouse",
                        url: `v7.7/xml/InventoryAdvice(846)/IB123546-1-v7.7-OneWarehouse.xml`,
                    },
                ],
            },
            csv: {
                all: `v7.7/csv/InventoryAdvice(846).zip`,
                allName: `InventoryInquiriesAndAdvices (${AppConfig.rsx.documentNumbers["InventoryInquiriesAndAdvices"]}).zip`,
                rest: [
                    {
                        name: "IB123546-1-v7.7-OneWarehouse",
                        url: `v7.7/csv/InventoryAdvice(846)/IB123546-1-v7.7-OneWarehouse.csv`,
                    },
                ],
            },
            positional: {
                all: `v7.7/positional/InventoryAdvice(846).zip`,
                allName: `InventoryInquiriesAndAdvices (${AppConfig.rsx.documentNumbers["InventoryInquiriesAndAdvices"]}).zip`,
                rest: [
                    {
                        name: "IB123546-1-v7.7-OneWarehouse",
                        url: `v7.7/positional/InventoryAdvice(846)/IB123546-1-v7.7-OneWarehouse.txt`,
                    },
                ],
            },
            json: {
                all: `v7.7/json/InventoryAdvice(846).zip`,
                allName: `InventoryInquiriesAndAdvices (${AppConfig.rsx.documentNumbers["InventoryInquiriesAndAdvices"]}).zip`,
                rest: [
                    {
                        name: "IB123546-1-v7.7-OneWarehouse",
                        url: `v7.7/json/InventoryAdvice(846)/IB123546-1-v7.7-OneWarehouse.json`,
                    },
                ],
            },
        },
    },
];

export default examplePageData;
