import React, { useState, useEffect } from "react";
import ContactInfoCard from "./ContactInfoCard";
import "./ContactInfo.scss";
import { ContactSupport } from "../ContactSupport/ContactSupport";
import { ContactSales } from "../ContactSupport/ContactSales";
import { SpsButton, SpsFocusedTask, usePatchReducer, SpsCard } from "@spscommerce/ds-react";
import { withCommercePlatform } from "@spscommerce/ui-react";
import { withRouter } from "react-router-dom";
import { ButtonKind } from "@spscommerce/ds-shared";
import queryString from "query-string";

export function ContactInfo({ commercePlatform, history, match, ...props }) {
    const [successFocussedTask, showSuccessFocussedTask] = useState(false);
    const success = match.params.success;

    const [state, patchState] = usePatchReducer({
        isLoading: true,
        showContactSupport: false,
        showContactSales: false,
        infoCard: "",
    });

    useEffect(() => {
        showSuccessFocussedTask(success);
        const queryValue = queryString.parse(props.location.search);
        if (queryValue.contactSupport) {
            patchState({ showContactSupport: true, infoCard: "Contact Support" });
        }
    }, [success, props.location.search]);

    return (
        <>
            <section className="col-12">
                <h2>Contact Us</h2>
                <div className="contact-card">
                    <ContactInfoCard
                        img="assets/devcenter-logo.svg"
                        iconClass="orange-icon"
                        heading="Dev Center Help"
                        phone="888.739.3232"
                        support={() =>
                            patchState({
                                showContactSupport: true,
                                infoCard: "Contact Support",
                            })
                        }
                        infoCard="support"
                        phoneText="Phone Number"
                    />
                    <ContactInfoCard
                        icon="handshake"
                        heading="Upgrade Your Account"
                        phoneText="SPS Billing Number"
                        phone="866.245.8100, option 2"
                        support={() =>
                            patchState({
                                showContactSales: true,
                                infoCard: "Contact Sales",
                            })
                        }
                        infoCard="sales"
                    />
                </div>
                <div className="contact-card">
                    <ContactInfoCard
                        icon="lightbulb"
                        heading="Product Requests & Feedback"
                        support={() =>
                            patchState({
                                showContactSupport: true,
                                infoCard: "Contact Product Team",
                            })
                        }
                        infoCard="product"
                    />
                    <ContactInfoCard
                        icon="dollar-sign"
                        heading="Billing & Payment Inquiries"
                        phoneText="SPS Billing Number"
                        phone="888.739.3232, option 4"
                        email="billing@spscommerce.com"
                    />
                </div>
            </section>
            <ContactSupport
                show={state.showContactSupport}
                onClose={() => {
                    patchState({ showContactSupport: false });
                    if (window.location.href.includes("contactSupport=true"))
                        history.push("/contact-info");
                }}
                commercePlatform={commercePlatform}
                infoCard={state.infoCard}
            />

            <ContactSales
                show={state.showContactSales}
                onClose={() => patchState({ showContactSales: false })}
                commercePlatform={commercePlatform}
                infoCard={state.infoCard}
            />
            {successFocussedTask && (
                <SpsFocusedTask
                    id="contact-support-form"
                    isOpen={successFocussedTask}
                    onClose={() => {
                        showSuccessFocussedTask(false);
                    }}
                >
                    <div style={{ width: "50%", margin: "auto" }}>
                        <h2>Ticket Submitted</h2>
                        <SpsCard>
                            Your support ticket has been submitted. A member of our support team
                            will contact you soon, usually within one business day.
                        </SpsCard>
                        <SpsButton
                            className="mt-3"
                            kind={ButtonKind.CONFIRM}
                            onClick={() => {
                                showSuccessFocussedTask(false);
                                history.push("/contact-info");
                            }}
                        >
                            Close
                        </SpsButton>
                    </div>
                </SpsFocusedTask>
            )}
        </>
    );
}

export default withRouter(withCommercePlatform(ContactInfo));
