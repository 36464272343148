import "./DocumentationPage.scss";

import React, { useEffect } from "react";

import find from "lodash/find";
import { matchPath, Route, Switch, withRouter } from "react-router-dom";

import { usePatchReducer } from "@spscommerce/ds-react";

import AppConfig from "../../App.config";
import { DocumentationServiceContext } from "../../components/DocumentationServiceContext";
import { FileNotFound } from "../../components/FileNotFound";
import { InternalUserBanner } from "../../components/InternalUserBanner/InternalUserBanner";
import RenderedMarkdown, { FetchedMarkdown } from "../../components/RenderedMarkdown";
import ServiceCard from "../../components/ServiceCard";
import SideNav from "../../components/SideNav";
import { SwaggerDocumentation } from "../../components/SwaggerDocumentation";
import SwaggerDocumentationVerifier from "../../components/SwaggerDocumentation/SwaggerDocumentationVerifier";
import Footer from "../../Footer";
import gettingStartedMarkdownUrl from "./GettingStarted.md";

const isMatch = (currentPath, pathPattern) => {
    return matchPath(currentPath, {
        path: pathPattern,
    })
        ? true
        : false;
};

const getParams = (pathname) => {
    // we need to check /docs/:serviceSlug AND /docs/:serviceSlug/:path yuck
    // Nothing like a little mutation!
    let matchingPath;

    if (!isMatch(pathname, `/docs/:serviceSlug/:path`)) {
        matchingPath = matchPath(pathname, {
            path: `/docs/:serviceSlug/`,
        });
    } else {
        matchingPath = matchPath(pathname, {
            path: `/docs/:serviceSlug/:path`,
        });
    }
    return (matchingPath && matchingPath.params && matchingPath.params.serviceSlug) || {};
};

const resolveServiceObject = (services, currentService) => {
    const service = find(services, (service) => {
        //
        return service.slug === currentService;
    });
    return service || null;
};

export function DocumentationPage(props) {
    const [state, patchState] = usePatchReducer({
        services: null,
        currentlySelectedService: null,
        authenticationDocs: {},
        showGrowler: false,
        growlerKind: "error",
        growlerTitle: "",
        growlerMessage: "",
    });

    const { documentCollectionApi, inventoryApi, devCenterApi, createGrowler } = props;
    // Launchdarkly controlled 'API Resources' docs go here ('RSX Resources' docs are not currently controlled by Launchdarkly):
    const LdControlledDocs = {
        // "<service slug>": <Launchdarkly feature flag>
        "document-collection-api": documentCollectionApi,
        "inventory-api": inventoryApi,
    };

    useEffect(() => {
        const { token, environment, hasSession } = props;

        if ((token && environment) || (environment && hasSession)) {
            fetchServices();
        }
    }, [props]);

    const el = document.querySelector(".page-banner");
    useEffect(() => {
        el
            ? window.scrollTo({
                  top: el.offsetHeight + 10.8,
                  left: 0,
                  behavior: "smooth",
              })
            : window.scrollTo(0, 0);
    }, [props.location.pathname]);

    useEffect(() => {
        setCurrentlySelectedService(state.services, props.location.pathname);
    }, [props.location.pathname]);

    useEffect(() => {
        if (state.growlerTitle !== "") {
            createGrowler({
                title: state.growlerTitle,
                kind: state.growlerKind,
                content: state.growlerMessage,
            });
        }
    }, [state.growlerTitle, createGrowler]);

    function setCurrentlySelectedService(services, location) {
        const pathname = getParams(location);
        const currentService = resolveServiceObject(services, pathname);
        patchState({ currentlySelectedService: currentService });
    }

    async function fetchServices() {
        const response = await devCenterApi.getServices();

        if (response.errorTitle) {
            patchState({
                growlerMessage: response.errorMessage,
                growlerTitle: response.errorTitle,
                showGrowler: true,
                services: [],
            });
        } else {
            const services = response.services;
            const authenticationDocInfo =
                services.filter(
                    (service) => service.id === AppConfig.documentation.authenticationServiceId,
                )[0] || {};

            const authDocsPath = `/docs/${authenticationDocInfo.slug}/getting-an-access-token`;
            const patchedResults = services.map((service) => {
                if (
                    typeof process.env.REACT_APP_DOCS_PATCH_SLUG !== "undefined" &&
                    service.slug === process.env.REACT_APP_DOCS_PATCH_SLUG
                ) {
                    return {
                        ...service,
                        currentDocLocation: process.env.REACT_APP_DOCS_PATCH_URL,
                    };
                }
                return service;
            });

            patchState({
                authenticationDocs: { ...authenticationDocInfo, path: authDocsPath },
                services: patchedResults,
            });
            setCurrentlySelectedService(patchedResults, props.location.pathname);
        }
    }

    if (state.services == null) {
        return <div className="sps-spinner">Loading...</div>;
    }

    return (
        <>
            <DocumentationServiceContext.Provider
                value={{
                    currentlySelectedService: state.currentlySelectedService,
                }}
            >
                <div className="row sps-page-wrapper">
                    <SideNav
                        authenticationService={state.authenticationDocs}
                        ldControlledApiDocs={LdControlledDocs}
                        environment={props.environment}
                        services={state.services}
                        currentService={state.currentlySelectedService}
                        pathname={props.location.pathname}
                    />
                    <Switch>
                        <Route
                            exact
                            path="/docs"
                            render={() => {
                                return (
                                    <div className="col-9 docs-main-content doc-grid-content">
                                        <div className="doc-grid">
                                            {state.services.map((service) => {
                                                return (
                                                    <ServiceCard
                                                        service={service}
                                                        key={service.id}
                                                    />
                                                );
                                            })}
                                        </div>
                                        <Footer />
                                    </div>
                                );
                            }}
                        />
                        <Route
                            path="/docs/:serviceSlug/swagger-documentation-verification"
                            exact={true}
                            render={(routeProps) => {
                                return (
                                    <div className="col-9 docs-main-content">
                                        <SwaggerDocumentationVerifier
                                            cdn_url={
                                                state.currentlySelectedService &&
                                                state.currentlySelectedService.currentDocLocation
                                            }
                                            authDocsPath={state.authenticationDocs.path}
                                        />
                                        <Footer />
                                    </div>
                                );
                            }}
                        />
                        <Route
                            path="/docs/:serviceSlug/api-doc/:doc?/:api?"
                            exact={true}
                            render={(routeProps) => {
                                return (
                                    <div className="col-9 docs-main-content">
                                        <div className="swagger-docs">
                                            <InternalUserBanner />
                                            <SwaggerDocumentation
                                                cdn_url={
                                                    state.currentlySelectedService &&
                                                    state.currentlySelectedService
                                                        .currentDocLocation
                                                }
                                                authDocsPath={state.authenticationDocs.path}
                                            />
                                        </div>
                                        <Footer />
                                    </div>
                                );
                            }}
                        />
                        <Route
                            path="/docs/:serviceSlug"
                            render={(routeProps) => {
                                const service = find(state.services, (service) => {
                                    return service.slug === routeProps.match.params.serviceSlug;
                                });

                                if (routeProps.match.params.serviceSlug === "getting-started") {
                                    // We need to short circuit this route page so it doesn't show 404s for
                                    // static content we're specifically allowing.
                                    return (
                                        <div className="col-9 docs-main-content">
                                            <InternalUserBanner />
                                            <FetchedMarkdown
                                                url={gettingStartedMarkdownUrl}
                                                authDocs={state.authenticationDocs}
                                            />
                                            <Footer />
                                        </div>
                                    );
                                }

                                if (service) {
                                    return (
                                        <div className="col-9 docs-main-content">
                                            <Switch>
                                                <Route
                                                    path="/docs/:serviceSlug/:path?"
                                                    render={(documentationRouteProps) => {
                                                        return (
                                                            <>
                                                                <InternalUserBanner />
                                                                <RenderedMarkdown
                                                                    documentationRouteProps={
                                                                        documentationRouteProps
                                                                    }
                                                                    currentService={service}
                                                                    services={state.services}
                                                                />
                                                            </>
                                                        );
                                                    }}
                                                />
                                            </Switch>
                                            <Footer />
                                        </div>
                                    );
                                }
                                return (
                                    <div className="col-9 docs-main-content">
                                        <FileNotFound
                                            heading="Service Not Found"
                                            subheading="We could not find documentation for this service"
                                        />
                                        <Footer />
                                    </div>
                                );
                            }}
                        />
                    </Switch>
                </div>
            </DocumentationServiceContext.Provider>
        </>
    );
}

export default withRouter(DocumentationPage);
