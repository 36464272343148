import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import IconCard from "../IconCard";
import SpsTruncate from "../SpsTruncate";
import "./AppCard.scss";

export default function AppCard(props) {
    const { app, variant, descriptionLength, titleLength } = props;
    const DESCRIPTION_LENGTH = descriptionLength || 50;
    const TITLE_LENGTH = titleLength || 50;
    const iconClasses = classnames(
        "sps-icon",
        app.iconName || "sps-icon-burst-bolt",
        app.iconColor || "orange200",
    );
    return (
        <IconCard to={`/app/${app.id}`} icon={iconClasses} variant={variant}>
            <div className="app-card-container-bottom">
                <div className="app-card-container-app-title clearfix">
                    <div className="app-card-title">
                        {variant === "small" ? (
                            <SpsTruncate length={TITLE_LENGTH} truncateWith="ellipses">
                                {app.name}
                            </SpsTruncate>
                        ) : (
                            <Link to={`/app/${app.id}`}>{`${app.name}`}</Link>
                        )}
                    </div>
                </div>
                {variant === "small" ? (
                    <div className="app-card-container-app-description clearfix">
                        <SpsTruncate length={DESCRIPTION_LENGTH} truncateWith="ellipses">
                            {app.description}
                        </SpsTruncate>
                    </div>
                ) : null}
                {variant !== "small" ? (
                    <div className="app-card-container-links clearfix">
                        <div className="app-card-view-details-link">
                            <Link to={`/app/${app.id}`}>
                                <i className="sps-icon sps-icon-gear" />
                            </Link>
                        </div>
                    </div>
                ) : null}
            </div>
        </IconCard>
    );
}
