import React from "react";
import PropTypes from "prop-types";

import { SpsPageTitle, SpsPageSubtitle } from "@spscommerce/ds-react";

const PageTitleWrapper = (props) => {
    const { heading, description } = props;

    return (
        <>
            {heading ? (
                <SpsPageTitle className="mb-3">
                    {heading}
                    {description && <SpsPageSubtitle>{description}</SpsPageSubtitle>}
                </SpsPageTitle>
            ) : null}
        </>
    );
};

PageTitleWrapper.propTypes = {
    heading: PropTypes.string,
    description: PropTypes.string,
};

export default PageTitleWrapper;
