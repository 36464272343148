import React, { useState, useEffect } from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import { SpsCard } from "@spscommerce/ds-react";
import AppConfig from "../../App.config";
import axios from "axios";
import _ from "lodash";
import UserGuideSideNav from "./UserGuideSideNav";
import Microsoft from "./Microsoft";
import Netsuite from "./Netsuite";
import Oracle from "./Oracle";
import QBO from "./QBO";
import SAP_IDOC from "./SAP_IDOC";
import MicrosoftSFC from "./MicrosoftS&FC";
import RsxMarkdownWrapper from "../RsxUiPage/RsxMarkdownWrapper";

const userGuidesContentId = "7a748cf3-1670-43c4-9caa-251afe4cba8f";

const LandingPageUi = function (props) {
    const [contentUrl, setContentUrl] = useState(null);
    const [toc, setToc] = useState(null);
    const [path, setPath] = useState(null);
    const [activeParent, setActiveParent] = useState("");
    const [activeChild, setActiveChild] = useState("");

    const fetchContentUrl = async () => {
        const config = {
            headers: { Authorization: `Bearer ${props.token}` },
        };
        const url = `${
            AppConfig.kubeRoot[props.environment]
        }/devcenter/v1/services/${userGuidesContentId}/`;
        const contentService = await axios.get(url, config);
        setContentUrl(_.get(contentService, "data.currentDocLocation"));
    };

    const fetchAndParseTocs = async () => {
        if (!toc && contentUrl) {
            const response = await axios.get(`${contentUrl}toc.json`);
            const toc = response.data.map((item) => {
                const updatedLink = item.link.replace(".md", "");
                item.link = updatedLink;
                return item;
            });
            setToc(toc);
        }
    };

    useEffect(() => {
        fetchContentUrl();
    }, []);

    useEffect(() => {
        fetchAndParseTocs();
    }, [contentUrl]);

    return (
        <div className="row sps-page-wrapper">
            <div className="col-3 docs-body__sidebar sps-side-nav">
                <SpsCard
                    className="sps-side-nav-card"
                    style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                    }}
                    headerTitle="User Guides"
                >
                    <UserGuideSideNav
                        servicePath="docs"
                        toc={toc}
                        activeParent={activeParent}
                        activeChild={activeChild}
                    />
                </SpsCard>
            </div>
            <Switch>
                <Route
                    path="/user-guides/docs/:doc"
                    exact={true}
                    render={(routeProps) => {
                        const {
                            match: {
                                params: { doc },
                            },
                        } = routeProps;
                        if (!path) {
                            setPath(doc);
                        }
                        if (activeParent !== doc) {
                            setActiveParent(doc);
                        }
                        if (activeChild) {
                            setActiveChild("");
                        }
                        return <RsxMarkdownWrapper toc={toc} doc={doc} contentUrl={contentUrl} />;
                    }}
                />
                <Route
                    path="/user-guides/docs/customer-mapping-guides/microsoft-sfc-guides"
                    exact={true}
                    render={() => {
                        if (!path) {
                            setPath("customer-mapping-guides/microsoft-guides");
                        }
                        if (activeParent !== "customer-mapping-guides") {
                            setActiveParent("customer-mapping-guides");
                        }
                        return <MicrosoftSFC url={contentUrl} />;
                    }}
                />
                <Route
                    path="/user-guides/docs/customer-mapping-guides/microsoft-guides"
                    exact={true}
                    render={() => {
                        if (!path) {
                            setPath("customer-mapping-guides/microsoft-guides");
                        }
                        if (activeParent !== "customer-mapping-guides") {
                            setActiveParent("customer-mapping-guides");
                        }
                        return <Microsoft url={contentUrl} />;
                    }}
                />
                <Route
                    path="/user-guides/docs/customer-mapping-guides/netsuite-guides"
                    exact={true}
                    render={() => {
                        if (!path) {
                            setPath("customer-mapping-guides/netsuite-guides");
                        }
                        if (activeParent !== "customer-mapping-guides") {
                            setActiveParent("customer-mapping-guides");
                        }
                        return <Netsuite url={contentUrl} />;
                    }}
                />
                <Route
                    path="/user-guides/docs/customer-mapping-guides/oracle-guides"
                    exact={true}
                    render={() => {
                        if (!path) {
                            setPath("customer-mapping-guides/oracle-guides");
                        }
                        if (activeParent !== "customer-mapping-guides") {
                            setActiveParent("customer-mapping-guides");
                        }
                        return <Oracle url={contentUrl} />;
                    }}
                />
                <Route
                    path="/user-guides/docs/customer-mapping-guides/qbo-guides"
                    exact={true}
                    render={() => {
                        if (!path) {
                            setPath("customer-mapping-guides/qbo-guides");
                        }
                        if (activeParent !== "customer-mapping-guides") {
                            setActiveParent("customer-mapping-guides");
                        }
                        return <QBO url={contentUrl} />;
                    }}
                />
                <Route
                    path="/user-guides/docs/customer-mapping-guides/sap-idoc-guides"
                    exact={true}
                    render={() => {
                        if (!path) {
                            setPath("customer-mapping-guides/sap-idoc-guides");
                        }
                        if (activeParent !== "customer-mapping-guides") {
                            setActiveParent("customer-mapping-guides");
                        }
                        return <SAP_IDOC url={contentUrl} />;
                    }}
                />
            </Switch>
        </div>
    );
};

export default withRouter(LandingPageUi);