import React from "react";
import classnames from "classnames";

export default function MarkdownImage(props) {
    const { src, altText, className, cdn_url, ...rest } = props;
    const classList = classnames("markdown-image__wrapper", className);
    const pattern = /^(http:\/\/|https:\/\/)(www\.)?[a-z]+\.?/gi;

    if (pattern.test(src)) {
        return (
            <div className={classList} {...rest}>
                <img src={src} alt={altText} />
            </div>
        );
    } else {
        return (
            <div className={classList} {...rest}>
                <img src={`${cdn_url}images/${src}`} alt={altText} />
            </div>
        );
    }
}
