import React, { Component } from "react";
import classnames from "classnames";
import "./TextAreaList.scss";

export default class TextAreaList extends Component {
    constructor(props) {
        super(props);
        this.state = { textString: "", errorMessage: "" };
    }
    splitToArray = (e) => {
        this.setState({ textString: e.target.value }, () => {
            this.props.onChange({
                target: {
                    name: this.props.name,
                    value: this.splitMultilineString(this.state.textString),
                },
            });
        });
    };
    splitMultilineString = (textString) => {
        // validate
        const { handleError, required } = this.props;

        if (required && !textString) {
            const errorMessage = "You must have at least one Redirect URI";
            this.setState({ errorMessage });
            handleError("textAreaList", errorMessage);
        } else if (this.checkForSemis(textString) || this.checkForCommas(textString)) {
            const errorMessage =
                "Use only linebreaks to separate URIs; do not use commas or semicolons";
            this.setState({ errorMessage });
            handleError("textAreaList", errorMessage);
        } else {
            this.setState({ errorMessage: "" });
            // No errors found, reset
            handleError("textAreaList", "");
        }
        return textString !== "" ? textString.split("\n").map((line) => line.trim()) : [];
    };
    checkForSemis = (textString) => {
        return textString.split(";").length > 1;
    };
    checkForCommas = (textString) => {
        return textString.split(",").length > 1;
    };

    componentDidMount() {
        const { list = [] } = this.props;
        this.setState({ textString: list.join("\n") });
    }
    componentDidUpdate(prevProps) {
        if (this.props.list !== prevProps.list) {
            const { list = [] } = this.props;
            this.setState({ textString: list.join("\n") });
        }
    }
    render() {
        // Filter out any props that we don't want passed down to the textarea
        const {
            name,
            inputLabel,
            description,
            onChange,
            handleError,
            required,
            ...props
        } = this.props;

        const groupClassList = classnames(
            "sps-form-group",
            {
                "sps-form-group--error": this.state.errorMessage,
            },
            {
                "sps-form-group--required": required,
            },
        );
        const labelClassList = classnames("sps-form-group__label", {
            "sps-form-group_label--required": required,
            "sps-form-group__label--description": description,
        });
        const descriptionClassList = classnames("sps-form-control__description");

        return (
            <div className={groupClassList}>
                <label htmlFor={name} className={labelClassList}>
                    {inputLabel}
                </label>
                {description && <div className={descriptionClassList}>{description}</div>}
                <textarea
                    className="sps-form-control"
                    onChange={this.splitToArray}
                    onBlur={this.validationChecker}
                    id={name}
                    value={this.state.textString}
                    name="redirectURIs"
                    {...props}
                />
                {this.state.errorMessage ? (
                    <small className="error-feedback sps-form-group__feedback--error">
                        {this.state.errorMessage}
                    </small>
                ) : null}
            </div>
        );
    }
}
