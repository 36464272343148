import "./MarkdownDocument.scss";

import React, { useEffect } from "react";

import remark from "remark";
import remark2react from "remark-react";
import slug from "remark-slug";
import { defaultSchema } from "hast-util-sanitize/lib/schema.js"; 

import { SpsCard, SpsFeedbackBlock, usePatchReducer } from "@spscommerce/ds-react";
import { withCommercePlatform } from "@spscommerce/ui-react";

import AppConfig from "../../App.config";
import SpsCode from "../Code";
import CodeOrSpsBlock from "./CodeOrSpsBlock";

const StaticMarkdownDocument = function (props) {
    const { currentUser: user } = props.commercePlatform;
    const [state, patchState] = usePatchReducer({
        text: null,
    });

    useEffect(() => {
        parseDocument(props.markdown);
    }, [props.markdown]);

    function isInternalUser() {
        return (
            user &&
            user.organization &&
            (user.organization.namespace === "sps" || user.organization.namespace === "spsc")
        );
    }

    async function parseDocument(markdown) {
        /*
         This attributed whitelist ensures that the className
         attribute doesn't get stripped out before the html
         is put into react
      */
        defaultSchema.attributes["*"].push("className");
        const rmk = remark()
            .use(slug)
            .use(remark2react, {
                sanitize: defaultSchema,
                remarkReactComponents: {
                    code: SpsCode,
                    pre: (blockProps) => (
                        <CodeOrSpsBlock {...blockProps} authDocs={props.authDocs} />
                    ),
                },
            });
        const contentResult = await rmk.process(markdown);
        patchState({
            text: contentResult.contents,
        });
        if (props.scrollTo != null) {
            // Scroll to an anchor if we were told to.
            const el = document.getElementById(props.scrollTo);
            if (el) {
                el.scrollIntoView();
            }
        }
    }

    return (
        <div className="sps-docs-container-markdown">
            {props.url.includes("GettingStarted") && isInternalUser() && (
                <SpsFeedbackBlock kind="info">
                    <b style={{ fontWeight: "600" }}>SPS Software Engineers:</b> Visit the{" "}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={AppConfig.documentation.atlasDocsUrl}
                    >
                        Atlas Documentation{" "}
                    </a>
                    for more information on using Atlas.
                </SpsFeedbackBlock>
            )}
            <SpsCard className="sps-docs-markdown" id="preview">
                {state.text}
            </SpsCard>
        </div>
    );
};

export default withCommercePlatform(StaticMarkdownDocument);
