import React, { Component, Fragment } from "react";
import "./wizard.scss";

export default class WizardBody extends Component {
  render() {
    const { children, expandAll, initiallyCollapsed } = this.props;
    if (initiallyCollapsed && !expandAll) {
      return null;
    }
    return (
      <Fragment>
        <div className="wizard-body" aria-expanded="true">
          {children}
        </div>
      </Fragment>
    );
  }
}
