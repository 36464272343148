import React, { Fragment } from "react";
import { SpsCard, SpsButton } from "@spscommerce/ds-react";
import PropTypes from "prop-types";
import "./wizard.scss";

const APP_TYPES = [
    {
        label: "Web Service Application",
        type: "web",
        id: 1000,
        description:
            "A web service application is a server-side application running independent of the end user's platform or device. Web service apps use the Authorization Code without PCKE grant flow, assuming the application is capable of securely storing secrets.",
    },
    {
        label: "Native Application",
        type: "native",
        id: 2000,
        description:
            "A native application is a client-side app installed and running on a platform or device. Native applications use the Authorization Code with PCKE grant flow.",
    },
    {
        label: "Single-Page Application (SPA)",
        type: "spa",
        id: 3000,
        description:
            "A single page application is a client-side application running in a browser. A SPA uses an implicit grant flow. For security reasons, this is the only grant flow that should be used for single page applications.",
    },
    {
        label: "Machine-to-Machine Application",
        type: "m2m",
        id: 4000,
        description:
            "A machine-to-machine application is a client or server-side application (e.g. a CLI or background service) that doesn't require specific user credentials to function. Machine-to-machine applications use the Client Credentials Flow to authenticate to other services. The M2M application type is best suited for companies connecting directly to the SPS Dev Center on their own behalf.",
    },
];

/* Some helper functions for determining which app types the user can use */
function getBaseAllowedAppTypes() {
    // App types open to all users
    return ["native", "spa", "web", "m2m"];
}

function WizardAppTypeItem(props) {
    function radioChangeListener(e) {
        if (typeof props.onChange === "function") {
            props.onChange({
                app: Object.assign({}, props.app, { appType: e.target.value }),
            });
        }
    }
    const {
        onSave,
        app: { appType },
    } = props;

    return (
        <Fragment>
            <SpsCard>
                <div className="row">
                    <div className="col-8">
                        {APP_TYPES.filter((app_type) =>
                            getBaseAllowedAppTypes().includes(app_type.type),
                        ).map((appTypeItem) => {
                            return (
                                <SpsCard className="wizard-card" key={appTypeItem.type}>
                                    <div className="row wizard-app-row">
                                        <div className="col-1 radio">
                                            <div className="sps-custom-control sps-custom-radio">
                                                <input
                                                    id={`${appTypeItem.type}-radio`}
                                                    type="radio"
                                                    name="appType"
                                                    value={appTypeItem.type}
                                                    checked={appType === appTypeItem.type}
                                                    onChange={radioChangeListener}
                                                    className="sps-custom-control__input"
                                                />
                                                <label
                                                    htmlFor={`${appTypeItem.type}-radio`}
                                                    className="sps-custom-control__label"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-11 app-name-container">
                                            <div className="row app-name no-gutters">
                                                {appTypeItem.label}
                                            </div>
                                            <div className="row no-gutters">
                                                <p className="app-type-descr">
                                                    {appTypeItem.description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </SpsCard>
                            );
                        })}
                    </div>
                    <div className="col-4 gray-next-box app-type">
                        <SpsCard>
                            <p>
                                Each application and grant type has a different methods for
                                acquiring access tokens, has a different life cycle for tokens, and
                                has an effect on how users grant access permission. More information
                                on application implementation types and grant types can be found{" "}
                                <a href="#/docs/new-authentication-docs/getting-an-access-token">here</a>
                                .
                            </p>
                        </SpsCard>
                    </div>
                </div>
                <div className="row">
                    <div className="col save-button-app-types">
                        <SpsButton
                            type="button"
                            kind="key"
                            disabled={!Boolean(appType)}
                            onClick={onSave}
                        >
                            Save and Continue
                        </SpsButton>
                    </div>
                </div>
            </SpsCard>
        </Fragment>
    );
}
WizardAppTypeItem.propTypes = {
    app: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

export { WizardAppTypeItem, getBaseAllowedAppTypes };
export default WizardAppTypeItem;
