import React, { useEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import get from "lodash/get";

import {
    SpsCard,
    SpsButton,
    SpsSelect,
    SpsTooltip,
    SpsPageTitle,
    SpsTextInput,
    SpsContentRow,
    usePatchReducer,
    SpsContentRowCol,
    SpsContentRowExpansion,
} from "@spscommerce/ds-react";
import { withCommercePlatform } from "@spscommerce/ui-react";

import Loading from "../../components/Loading";
import DownloadLink from "../../components/DownloadLink/DownloadLink";
import exampleFilesData from "./exampleDownloadPageData";
import Footer from "../../Footer";

const fileFormats = ["CSV", "JSON", "Positional", "XML"];
const allFilesLocation = {
    XML: "files.xml.all",
    CSV: "files.csv.all",
    Positional: "files.positional.all",
    JSON: "files.json.all",
};
const restFilesLocation = {
    XML: "files.xml.rest",
    CSV: "files.csv.rest",
    Positional: "files.positional.rest",
    JSON: "files.json.rest",
};
const restFileExtension = {
    XML: ".xml",
    CSV: ".csv",
    Positional: ".txt",
    JSON: ".json",
};
const sortByDoc = ["Most Used to Least Used", "Document Name: A-Z", "Document Name: Z-A"];

const ExampleDownloadPage = function (props) {
    const { commercePlatform, token } = props;

    const [state, patchState] = usePatchReducer({
        orderBy: sortByDoc[0],
        filterTerm: null,
        isApiCalled: false,
        selectedFormat: "XML",
        itemDownloading: "",
        downloadBaseUrl: null,
        sortedExampleFiles: null,
    });

    useEffect(() => {
        if (props.contentUrl) {
            patchState({
                downloadBaseUrl: `${props.contentUrl}OMM-example-files`,
            });
        }
    }, [props.contentUrl]);

    useEffect(() => {
        if (state.orderBy === "Most Used to Least Used") {
            const filter = get(state, "filterTerm");
            if (filter) {
                const results = [...exampleFilesData].filter(
                    (i) =>
                        i.header.toLowerCase().includes(filter) ||
                        i.subtitle.toLowerCase().includes(filter),
                );
                patchState({
                    sortedExampleFiles: results,
                });
            } else {
                patchState({
                    sortedExampleFiles: exampleFilesData,
                });
            }
        } else if (state.orderBy === "Document Name: A-Z") {
            const ascending = [...state.sortedExampleFiles].sort(compareValues("header", "asc"));
            patchState({
                sortedExampleFiles: ascending,
            });
        } else if (state.orderBy === "Document Name: Z-A") {
            const descending = [...state.sortedExampleFiles].sort(compareValues("header", "desc"));
            patchState({
                sortedExampleFiles: descending,
            });
        }
    }, [state.orderBy]);

    function downloadFunc(data, fileName) {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const fileLink = document.createElement("a");
        fileLink.href = downloadUrl;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
    }

    async function getExample(url, filename, name) {
        const headers = {
            Authorization: `Bearer ${commercePlatform ? commercePlatform.token : ""}`,
        };
        try {
            patchState({
                itemDownloading: name,
                isApiCalled: true,
            });
            const reqConfig = {
                method: "get",
                headers,
                responseType: "arraybuffer",
                url,
            };

            const schemaResponse = await axios(reqConfig);
            downloadFunc(schemaResponse.data, filename);
            window.dataLayer.push({
                event: "exampleDownload",
                exampleDownloadInfo: `${name}_all_${state.selectedFormat}`,
            });
        } catch (error) {
            console.log(error);
        } finally {
            patchState({
                isApiCalled: false,
            });
        }
    }

    function compareValues(key, order = "asc") {
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }

            const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
            const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return order === "desc" ? comparison * -1 : comparison;
        };
    }

    function handleFilterChange(event) {
        const results = [...exampleFilesData].filter(
            (i) =>
                i.header.toLowerCase().includes(event.target.value) ||
                i.subtitle.toLowerCase().includes(event.target.value),
        );

        if (state.orderBy === "Document Name: A-Z") {
            patchState({
                sortedExampleFiles: results.sort(compareValues("header", "asc")),
                filterTerm: event.target.value,
            });
        } else if (state.orderBy === "Document Name: Z-A") {
            patchState({
                sortedExampleFiles: results.sort(compareValues("header", "desc")),
                filterTerm: event.target.value,
            });
        } else {
            patchState({
                sortedExampleFiles: results,
                filterTerm: event.target.value,
            });
        }
    }

    function handleSortFiles(event) {
        patchState({
            orderBy: event.target.value,
        });
    }

    function handleFormatChange(event) {
        const format = event.target.value;
        patchState({
            selectedFormat: format,
        });
    }

    return (
        <>
            <div className="col-9 docs-main-content">
                <div className="schema-download__wrapper">
                <SpsPageTitle className="mb-3">Example Files</SpsPageTitle>
                <SpsCard className="mb-3">
                    <p className="fs-14 lh-20">
                        Example Files contain production-like mock data broken out by fulfillment
                        model. The mock data matches the values in the{" "}
                        <NavLink to="/rsx/docs/connect-to-sps-commerce/test-data/">
                            Test Data
                        </NavLink>
                        , which your test environment should be set up to use.
                    </p>
                </SpsCard>
                <div className="sps-summary-list-row sps-content-row" tabIndex="-1">
                    <div className="sps-content-row__col-group" tabIndex="-1">
                        <div className="sps-content-row__col">
                            <div className="sps-form-group sps-text-input w-75">
                                <SpsTextInput
                                    icon="filter"
                                    className="w-75"
                                    onChange={handleFilterChange}
                                    placeholder="Filter for a specific document"
                                />
                            </div>
                        </div>
                        <div
                            className="sps-content-row__col sps-content-row__col--leaner sps-content-row__col--borderless pr-0"
                            style={{ width: "3rem" }}
                        >
                            <label className="sps-form-group__label mb-0">
                                <span className="sps-form-group__label-content">Sort By</span>
                            </label>
                        </div>
                        <div
                            className="sps-content-row__col sps-content-row__col--leaner sps-content-row__col--borderless"
                            style={{ width: "12rem" }}
                        >
                            <SpsSelect
                                className="selectSortByDoc"
                                options={sortByDoc}
                                placeholder={sortByDoc[0]}
                                value={sortByDoc[0]}
                                notClearable
                                onChange={handleSortFiles}
                            />
                        </div>
                        <div
                            className="sps-content-row__col sps-content-row__col--leaner"
                            style={{ width: "10rem" }}
                        >
                            <div className="sps-form-group sps-select">
                                <SpsSelect
                                    options={fileFormats}
                                    value={state.selectedFormat}
                                    notClearable
                                    onChange={handleFormatChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {state.sortedExampleFiles ? (
                    <>
                        {state.sortedExampleFiles.map((item, index) => (
                            <>
                                {get(item, allFilesLocation[state.selectedFormat]) && (
                                    <SpsContentRow key={index}>
                                        <SpsContentRowCol>
                                            <div className="fs-18 lh-24">
                                                {item.header}{" "}
                                                <i
                                                    id={`header-${item.header}`}
                                                    className={`sps-icon sps-icon--sm sps-icon-info-circle`}
                                                    style={{ color: "#0077AA" }}
                                                />
                                                <SpsTooltip for={`header-${item.header}`}>
                                                    {item.toolTip}
                                                </SpsTooltip>
                                            </div>
                                            <div className="fs-14 lh-20">{item.subtitle}</div>
                                        </SpsContentRowCol>
                                        <SpsContentRowCol style={{ width: "3.8rem" }} leaner>
                                            <SpsButton
                                                kind="icon"
                                                icon="download-cloud"
                                                onClick={() => {
                                                    if (state.selectedFormat === "XML") {
                                                        getExample(
                                                            `${state.downloadBaseUrl}/${item.files.xml.all}`,
                                                            item.files.xml.allName,
                                                            item.header,
                                                        );
                                                    } else if (state.selectedFormat === "CSV") {
                                                        getExample(
                                                            `${state.downloadBaseUrl}/${item.files.csv.all}`,
                                                            item.files.csv.allName,
                                                            item.header,
                                                        );
                                                    } else if (state.selectedFormat === "JSON") {
                                                        getExample(
                                                            `${state.downloadBaseUrl}/${item.files.json.all}`,
                                                            item.files.json.allName,
                                                            item.header,
                                                        );
                                                    } else {
                                                        getExample(
                                                            `${state.downloadBaseUrl}/${item.files.positional.all}`,
                                                            item.files.positional.allName,
                                                            item.header,
                                                        );
                                                    }
                                                }}
                                                spinning={
                                                    state.isApiCalled &&
                                                    state.itemDownloading === item.header
                                                }
                                            />
                                        </SpsContentRowCol>
                                        <SpsContentRowExpansion>
                                            {get(item, restFilesLocation[state.selectedFormat]) && (
                                                <SpsCard>
                                                    {get(
                                                        item,
                                                        restFilesLocation[state.selectedFormat],
                                                    ).map((file, index) => (
                                                        <DownloadLink
                                                            url={`${state.downloadBaseUrl}/${file.url}`}
                                                            text={file.name}
                                                            icon="download-cloud"
                                                            token={token}
                                                            fileName={
                                                                file.name.replace(".", "_") +
                                                                restFileExtension[
                                                                    state.selectedFormat
                                                                ]
                                                            }
                                                            prettyPrintJSON={
                                                                state.selectedFormat === JSON
                                                            }
                                                            key={`${file.name}-${index}`}
                                                            dataLayerEvent={{
                                                                event: "exampleDownload",
                                                                eventInfoLabel:
                                                                    "exampleDownloadInfo",
                                                                eventInfoValue: `${item.header}_${file.name}_${state.selectedFormat}`,
                                                            }}
                                                        />
                                                    ))}
                                                </SpsCard>
                                            )}
                                        </SpsContentRowExpansion>
                                    </SpsContentRow>
                                )}
                            </>
                        ))}
                    </>
                ) : (
                    <div className="mt-2 d-flex justify-content-center">
                        <Loading mode="medium" />
                    </div>
                )}
                </div>
                <Footer/>
            </div>
        </>
    );
};

export default withCommercePlatform(ExampleDownloadPage);
