import React from "react";
import { Link } from "react-router-dom";

import "./BannerTrialUser.scss";

function BannerTrialUser({ className: addClassName }) {
    return (
        <div className={`d-flex justify-content-center p-2 w-100 mb-3 trial-user-banner${addClassName ? " " + addClassName : ""}`}>
            <p className="m-0">
                We hope you enjoy your Trial experience! Is your company already working with SPS
                Commerce?
                <Link to="/contact-info?contactSupport=true"> Contact support </Link>to get
                Production access.
            </p>
        </div>
    );
}

export default BannerTrialUser;
