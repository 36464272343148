import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { HashRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import "./polyfills";
import AppConfig from "./App.config";
import { SpsFileUploadComponent, register } from "@spscommerce/ds-web-components";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

// Allows live reloading when previewing in the docs tool
// eslint-disable-next-line
import reload from "./reload";

const { fromObject } = require("url-env");

const { env } = fromObject(AppConfig.envMap);

Sentry.init({
    dsn: AppConfig.sentry.dsn,
    integrations: [new Integrations.BrowserTracing()],
    environment: env,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: AppConfig.sentry.tracesSampleRate,
});

register(SpsFileUploadComponent);
ReactDOM.render(
    <Router>
        <App />
    </Router>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
