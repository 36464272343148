import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import get from "lodash/get";

import {
    SpsCard,
    SpsButton,
    usePatchReducer,
    SpsModal,
    SpsModalBody,
    SpsModalFooter,
    SpsTextInput,
} from "@spscommerce/ds-react";
import { withCommercePlatform } from "@spscommerce/ui-react";

import AppDetails from "../../components/AppDetails/AppDetails";
import AppSideBar from "../../components/AppSideBar";
import ConnectionInformation from "../../components/ConnectionInformation/ConnectionInformation";
import Wizard from "../../components/Wizard/Wizard";
import WizardHeader from "../../components/Wizard/WizardHeader";
import WizardBody from "../../components/Wizard/WizardBody";
import WizardSection from "../../components/Wizard/WizardSection";
import WizardAppTypeItem from "../../components/Wizard/WizardAppTypeItem";
import TextAreaList from "../../components/TextAreaList";
import FileNotFound from "../../components/FileNotFound";

import AppConfig from "../../App.config";
import "./AppEditPage.scss";

export function AppEditPageWithOutCommercePlatform(props) {
    const {
        commercePlatform: { currentUser },
        match: {
            params: { appId },
        },
        createGrowler,
    } = props;

    const [state, patchState] = usePatchReducer({
        staticApp: {},
        staticServices: [],
        app: {},
        errors: [],
        loading: true,
        appNotFound: false,
        errorHeading: "",
        errorSubheading: "",
        servicesUpdated: [],
        servicesLoaded: false,
        expandNextItem: false,
        servicesWarning: false,
        initiallyExpanded: getExpandIndex(),
        deleteConfirmationText: "",
        isTrial: true,
        showModal: false,
    });

    useEffect(() => {
        const values = queryString.parse(props.location.search);
        patchState({ initiallyExpanded: values.expandIndex });
    }, [props.location.search]);

    useEffect(() => {
        const {
            commercePlatform: { token },
        } = props;
        if (token) {
            fetchData();
        }
    }, [props]);

    function getExpandIndex() {
        const values = queryString.parse(props.location.search);
        return values.expandIndex || 0;
    }

    function handleAppRedirectURIChange(e) {
        const newAppState = {
            ...state.app,
            ...{ redirectUris: e.target.value },
        };
        patchState({ app: newAppState });
    }

    function handleError(fieldName, errorMessage) {
        // This function receives errors from fields in the form
        // and allows field-level errors to be reacted to in this component

        // Borrowed from App details component pending full rewrite of this form, only used on Redirect URIs currently
        const newErrors = { ...state.errors };
        newErrors[fieldName] = errorMessage;
        patchState({ errors: newErrors });
    }

    function appHasSecret() {
        const {
            staticApp: { appType },
        } = state;
        return appType === "web" || appType === "m2m";
    }

    function appHasRedirectURI() {
        const {
            staticApp: { appType },
        } = state;
        return appType !== "m2m";
    }

    function fetchData() {
        // Perform any required data fetching
        fetchOrgTrialStatus();
        fetchApp();
    }

    async function fetchOrgTrialStatus() {
        const {
            commercePlatform: { token, environment, currentUser },
        } = props;
        const url = `${AppConfig.identityRoot[environment]}/identity/v2/organizations/${currentUser.organization.id}/`;
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        const orgDetailsConfig = {
            method: "get",
            headers,
            url: url,
        };
        try {
            const orgDetailsResponse = await axios(orgDetailsConfig);
            patchState({
                isTrial: orgDetailsResponse.data.is_trial,
            });
        } catch (e) {
            const msg = "Org Trial Status Could Not Be Fetched";
            createGrowler({
                kind: "error",
                title: "Error Encountered",
                content: () => msg,
                onClose() {
                    console.log("Growler closed.");
                },
            });
        }
    }

    async function fetchApp() {
        const {
            match: {
                params: { appId },
            },
            commercePlatform: { environment },
        } = props;

        if (!appId) {
            // A new app is being created, no data to fetch
            patchState({ loading: false });
            return;
        }
        patchState({ loading: true });
        const endSlash = appId ? "/" : "";
        const appUrl = `${AppConfig.kubeRoot[environment]}/devcenter/v1/applications/${
            appId + endSlash
        }`;
        const appConfig = {
            headers: {
                Authorization: `Bearer ${props.commercePlatform.token}`,
            },
        };

        try {
            const appResponse = await axios.get(appUrl, appConfig);
            // Other permissions shiould be updated here as they are implemented
            patchState({
                app: appResponse.data,
                loading: false,
                staticApp: appResponse.data,
                staticServices: appResponse.data.services,
            });
        } catch (err) {
            console.error(err);
            const heading =
                err.response && err.response.status === 404
                    ? "The application you're looking for can't be found."
                    : "An error occurred when fetching your application.";
            const subheading =
                err.response && err.response.status === 404
                    ? "You may have mistyped the URL or the application has been deleted."
                    : "Please try refreshing the page.";
            patchState({
                appNotFound: true,
                loading: false,
                errorHeading: heading,
                errorSubheading: subheading,
            });
        }
    }

    async function updateApp() {
        const {
            commercePlatform: { environment },
        } = props;
        const method = props.match.params.appId ? "put" : "post";
        const values = queryString.parse(props.location.search);
        const initiallyExpanded = values.expandIndex ? values.expandIndex : 0;
        const url = state.app.id
            ? `${AppConfig.kubeRoot[environment]}/devcenter/v1/applications/${state.app.id}/`
            : `${AppConfig.kubeRoot[environment]}/devcenter/v1/applications/`;
        const config = {
            headers: {
                Authorization: `Bearer ${props.commercePlatform.token}`,
                "Content-Type": "application/json",
            },
        };
        const filteredRedirectUris = Array.isArray(state.app.redirectUris)
            ? state.app.redirectUris.filter((uri) => uri !== "")
            : [];
        const cleanedApp = {
            ...state.app,
            ...{ redirectUris: filteredRedirectUris },
        };
        try {
            const updateAppResponse = await axios[method](url, cleanedApp, config);
            patchState({
                app: updateAppResponse.data,
                staticApp: updateAppResponse.data,
                staticServices: updateAppResponse.data.services,
                expandNextItem: !state.expandNextItem,
            });
            props.history.push(
                `/app/${updateAppResponse.data.id}?expandIndex=${parseInt(initiallyExpanded) + 1}`,
            );
            const msg =
                method === "put"
                    ? "Your application has successfully been updated"
                    : "Your application has successfully been created";
            createGrowler({
                kind: "success",
                title: "Success!",
                content: () => msg,
                onClose() {
                    console.log("Growler closed.");
                },
            });
        } catch (err) {
            const msg = get(err, "response.data.error.errorDescription")
                ? get(err, "response.data.error.errorDescription")
                : "An error has occurred when updating the application";
            const title = get(err, "response.data.error.error")
                ? get(err, "response.data.error.error")
                : "Error!";
            createGrowler({
                kind: "error",
                title: title,
                content: () => msg,
                onClose() {
                    console.log("Growler closed.");
                },
            });
        }
    }

    async function deleteApp(appId) {
        const {
            commercePlatform,
            commercePlatform: { environment },
            history,
        } = props;
        const config = {
            method: "delete",
            url: `${AppConfig.kubeRoot[environment]}/devcenter/v1/applications/${appId}/`,
            headers: {
                Authorization: `Bearer ${commercePlatform.token}`,
                "Content-Type": "application/json",
            },
        };

        try {
            await axios(config);
            createGrowler({
                kind: "success",
                title: "Success!",
                content: () => "Successfully deleted your application",
                onClose() {
                    console.log("Growler closed.");
                },
            });
            history.push("/applications");
        } catch (err) {
            const msg = get(err, "response.data.error.errorDescription")
                ? get(err, "response.data.error.errorDescription")
                : "An error has occurred when updating the application";
            const title = get(err, "response.data.error.error")
                ? get(err, "response.data.error.error")
                : "Error!";
            createGrowler({
                kind: "error",
                title: title,
                content: () => msg,
                onClose() {
                    console.log("Growler closed.");
                },
            });
        }
    }

    function showDeleteModal() {
        patchState({ showModal: true });
    }

    function handleDeleteConfirmationTextChange(e) {
        patchState({ deleteConfirmationText: e.target.value });
    }

    function handleAppDetailChange(e) {
        patchState({ app: e.app });
    }

    const staticIconColor = () => {
        return state.staticApp.iconColor || "orange200";
    };
    const staticIconName = () => {
        return state.staticApp.iconName || "sps-icon-burst-bolt";
    };
    const connectionSaveEnabled = () => {
        return state.deleteConfirmationText === state.app.name;
    };

    return (
        <>
            {!state.loading ? (
                <>
                    <div className="container-fluid app-edit-page">
                        <div className="row app-edit-header">
                            <div className="col">
                                <a
                                    href="#/applications"
                                    className="back-to-apps fs-16 font-weight-bold"
                                    style={{ textDecoration: "none" }}
                                >
                                    <i
                                        className="sps-icon sps-icon-angle-left sps-icon--md"
                                        style={{
                                            marginRight: "5px",
                                            position: "relative",
                                            top: "3px",
                                        }}
                                    ></i>
                                    Back to Applications
                                </a>
                            </div>
                        </div>
                        <div className="page-wrapper row">
                            {!state.appNotFound ? (
                                <>
                                    <div className="col-3">
                                        <SpsCard
                                            className="app-side-bar"
                                            headerContent={() => (
                                                <div className="header">
                                                    <i
                                                        className={`app-side-bar-icon sps-icon ${
                                                            staticIconName() +
                                                            " " +
                                                            staticIconColor()
                                                        }`}
                                                    />
                                                </div>
                                            )}
                                        >
                                            <AppSideBar
                                                app={state.staticApp}
                                                user={currentUser}
                                                showDeleteModal={showDeleteModal}
                                                includeRedirectURI={appHasRedirectURI()}
                                            />
                                        </SpsCard>
                                    </div>
                                    <div className="col-9">
                                        <Route
                                            path={`/app/:appId?`}
                                            render={() => {
                                                return (
                                                    <>
                                                        <div className="row">
                                                            <div className="col">
                                                                <Wizard
                                                                    initially={"collapse"}
                                                                    expandedItem={
                                                                        state.expandNextItem
                                                                    }
                                                                    initiallyExpanded={
                                                                        state.initiallyExpanded
                                                                    }
                                                                    includeRedirectURI={appHasRedirectURI()}
                                                                >
                                                                    <WizardSection key="step1">
                                                                        <WizardHeader message="App Details" />
                                                                        <WizardBody>
                                                                            <AppDetails
                                                                                app={state.app}
                                                                                onChange={
                                                                                    handleAppDetailChange
                                                                                }
                                                                                onSave={updateApp}
                                                                            />
                                                                        </WizardBody>
                                                                    </WizardSection>
                                                                    <WizardSection key="step2">
                                                                        <WizardHeader message="App Types" />
                                                                        <WizardBody>
                                                                            <WizardAppTypeItem
                                                                                app={state.app}
                                                                                onSave={updateApp}
                                                                                onChange={
                                                                                    handleAppDetailChange
                                                                                }
                                                                                className="app-edit-services"
                                                                            />
                                                                        </WizardBody>
                                                                    </WizardSection>
                                                                    <WizardSection key="step3">
                                                                        <WizardHeader message="Redirect URI" />
                                                                        <WizardBody>
                                                                            <SpsCard>
                                                                                <div className="row">
                                                                                    <div className="col-1" />
                                                                                    <div className="col-5">
                                                                                        <TextAreaList
                                                                                            name="redirectUris"
                                                                                            description="Redirect URI requests must come from a web server. For apps in development, the URI can be localhost and can use HTTP. Production URIs must use HTTPS."
                                                                                            inputLabel="Redirect URI (enter multiple URIs on separate lines)"
                                                                                            list={
                                                                                                state
                                                                                                    .app
                                                                                                    .redirectUris
                                                                                            }
                                                                                            onChange={
                                                                                                handleAppRedirectURIChange
                                                                                            }
                                                                                            handleError={
                                                                                                handleError
                                                                                            }
                                                                                            rows={4}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-5">
                                                                                        <div className="gray-next-box">
                                                                                            <SpsCard>
                                                                                                <h4>
                                                                                                    Authorization
                                                                                                    Redirect
                                                                                                    Configuration
                                                                                                </h4>
                                                                                                <p>
                                                                                                    To
                                                                                                    ensure
                                                                                                    that
                                                                                                    users
                                                                                                    are
                                                                                                    only
                                                                                                    redirected
                                                                                                    to
                                                                                                    appropriate
                                                                                                    locations,
                                                                                                    you
                                                                                                    must
                                                                                                    register
                                                                                                    one
                                                                                                    or
                                                                                                    more
                                                                                                    redirect
                                                                                                    URIs.
                                                                                                    While
                                                                                                    a
                                                                                                    redirect
                                                                                                    URI
                                                                                                    is
                                                                                                    not
                                                                                                    required
                                                                                                    for
                                                                                                    application
                                                                                                    creation,
                                                                                                    one
                                                                                                    will
                                                                                                    be
                                                                                                    required
                                                                                                    to
                                                                                                    obtain
                                                                                                    an
                                                                                                    access
                                                                                                    token.
                                                                                                </p>
                                                                                            </SpsCard>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-1" />
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col app-save-button-container">
                                                                                        <SpsButton
                                                                                            type="button"
                                                                                            kind="key"
                                                                                            onClick={
                                                                                                updateApp
                                                                                            }
                                                                                        >
                                                                                            Save and
                                                                                            Continue
                                                                                        </SpsButton>
                                                                                    </div>
                                                                                </div>
                                                                            </SpsCard>
                                                                        </WizardBody>
                                                                    </WizardSection>
                                                                    <WizardSection key="step4">
                                                                        <WizardHeader message="Connection Information" />
                                                                        <WizardBody>
                                                                            <SpsCard>
                                                                                <div className="row">
                                                                                    <div className="col-1" />
                                                                                    <div className="col-10">
                                                                                        {/* ConnectionInformation should be basing state off of the static app versus app. */}
                                                                                        <ConnectionInformation
                                                                                            staticApp={
                                                                                                state.staticApp
                                                                                            }
                                                                                            isTrial={
                                                                                                state.isTrial
                                                                                            }
                                                                                            hasSecret={
                                                                                                appHasSecret
                                                                                            }
                                                                                            onChange={
                                                                                                handleAppDetailChange
                                                                                            }
                                                                                            onSave={
                                                                                                updateApp
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-1" />
                                                                                </div>
                                                                                {appHasSecret && (
                                                                                    <div className="row fs-14 lh-20">
                                                                                        <div className="col-1" />
                                                                                        <div className="col-5 mt-3">
                                                                                            To get a
                                                                                            new App
                                                                                            Secret,
                                                                                            please{" "}
                                                                                            <Link to="/contact-info">
                                                                                                contact
                                                                                                us
                                                                                            </Link>
                                                                                            .
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                                <div className="row connection-information__grey-next-box">
                                                                                    <div className="col-1" />
                                                                                    <div className="col-5 gray-next-box">
                                                                                        <SpsCard>
                                                                                            <h4>
                                                                                                What's
                                                                                                Next?
                                                                                            </h4>
                                                                                            <p>
                                                                                                <Link to="/docs/getting-started">
                                                                                                    Get
                                                                                                    Started
                                                                                                </Link>{" "}
                                                                                                with
                                                                                                integrating
                                                                                                your
                                                                                                app.
                                                                                            </p>
                                                                                            <p>
                                                                                                Learn
                                                                                                more
                                                                                                about{" "}
                                                                                                <Link to="/docs/authentication">
                                                                                                    Authentication
                                                                                                    in
                                                                                                    Dev
                                                                                                    Center
                                                                                                </Link>
                                                                                            </p>
                                                                                            <p>
                                                                                                Learn
                                                                                                more
                                                                                                about
                                                                                                the{" "}
                                                                                                <Link to="/docs/shipping-doc-api">
                                                                                                    Shipping
                                                                                                    Document
                                                                                                    API{" "}
                                                                                                </Link>
                                                                                            </p>
                                                                                            <p>
                                                                                                Use
                                                                                                the{" "}
                                                                                                <Link to="/tools/shipping-doc-browser/">
                                                                                                    Shipping
                                                                                                    Label
                                                                                                    and
                                                                                                    Packing
                                                                                                    Slip
                                                                                                    Browser
                                                                                                </Link>
                                                                                            </p>
                                                                                            <p>
                                                                                                Return
                                                                                                to
                                                                                                the{" "}
                                                                                                <Link to="/applications">
                                                                                                    Applications
                                                                                                    Page
                                                                                                </Link>
                                                                                            </p>
                                                                                        </SpsCard>
                                                                                    </div>
                                                                                    <div className="col-1" />
                                                                                </div>
                                                                            </SpsCard>
                                                                        </WizardBody>
                                                                    </WizardSection>
                                                                </Wizard>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            }}
                                        />
                                    </div>
                                </>
                            ) : (
                                <div className="col-12">
                                    <FileNotFound
                                        heading={state.errorHeading}
                                        subheading={state.errorSubheading}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <SpsModal
                        isOpen={state.showModal}
                        id="sps_app_delete_modal"
                        size="small"
                        kind="delete"
                        header="Delete Application"
                        onClose={() => {
                            patchState({ showModal: false });
                        }}
                    >
                        <SpsModalBody>
                            <p>
                                Are you sure you want to delete your application named{" "}
                                <strong>"{state.app.name}"</strong>?
                            </p>
                            <p>
                                <strong>This action cannot be undone.</strong>
                            </p>
                            Type the name of your app to confirm.
                            <div className="sps-form-group">
                                <SpsTextInput
                                    name="deleteConfirmationInput"
                                    value={state.deleteConfirmationText}
                                    onChange={handleDeleteConfirmationTextChange}
                                />
                            </div>
                        </SpsModalBody>
                        <SpsModalFooter>
                            <SpsButton
                                kind="default"
                                onClick={() => patchState({ showModal: false })}
                            >
                                Cancel
                            </SpsButton>
                            <SpsButton
                                kind="key"
                                onClick={() => {
                                    deleteApp(appId);
                                    patchState({ showModal: false });
                                }}
                                disabled={!connectionSaveEnabled}
                            >
                                Delete Application
                            </SpsButton>
                        </SpsModalFooter>
                    </SpsModal>
                </>
            ) : (
                <div className="sps-spinner">Loading...</div>
            )}
        </>
    );
}

export default withCommercePlatform(AppEditPageWithOutCommercePlatform);
