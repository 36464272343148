import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPlaceholder from "react-placeholder";

import {
    SpsPageTitle,
    SpsSelect,
    SpsButton,
    SpsCard,
    SpsContentRow,
    SpsContentRowCol,
    SpsModal,
    SpsModalFooter,
    SpsModalBody,
} from "@spscommerce/ds-react";
import { withCommercePlatform } from "@spscommerce/ui-react";

import Footer from "../../Footer";
import MyDesignsSchemaDocument from "./MyDesignsSchemaDocument";

const MyDesignsPage = function (props) {
    const { url } = props;
    const [loading, setLoading] = useState(true);
    const [schemas, setSchemas] = useState();
    const [showModal, setShowModal] = React.useState(false);
    const [viewRetailers, setViewRetailers] = React.useState([]);

    const schemaOptions = [
        {
            schemaName: "Order",
            schemaPath: "Orders(850)/consolidated_850_design_dump.json",
        },
        {
            schemaName: "OrderAcks",
            schemaPath: "OrderAcks(855)/consolidated_855_design_dump.json",
        },
        {
            schemaName: "Shipments",
            schemaPath: "Shipments(856)/consolidated_856_design_dump.json",
        },
        {
            schemaName: "Invoices",
            schemaPath: "Invoices(810)/consolidated_810_design_dump.json",
        },
    ];

    const [selectedSchema, setSelectedSchema] = useState(schemaOptions[0]);

    useEffect(() => {
        getSchemas(selectedSchema.schemaPath);
    }, []);

    const getSchemas = async (filePath) => {
        if (url) {
            const response = await axios.get(`${url}My-design-files/${filePath}`);
            const { data } = response;
            setSchemas(data);
            setViewRetailers(data.extraProperties.consolidatedMandatoryFor);
            setLoading(false);
        }
    };

    function handleSchemaChange(event) {
        const { schemaPath } = event.target.value;
        setLoading(true);
        setSelectedSchema(event.target.value);
        getSchemas(schemaPath);
    }

    return (
        <>
            <div className="col-9 docs-main-content">
                <div className="schema-download__wrapper">
                    <SpsPageTitle className="mb-3">My Designs</SpsPageTitle>
                    <SpsCard className="mb-3">
                        <h3>Overview</h3>
                        <p>
                            On this page you can find consolidated retailer requirements by document
                            type. These requirements show the necessary fields to map to all your
                            trading partners.
                        </p>
                    </SpsCard>

                    <div>
                        <SpsContentRow>
                            <SpsContentRowCol>
                                <h4 className="d-inline-block align-middle">My Designs</h4>
                            </SpsContentRowCol>
                            <SpsContentRowCol style={{ width: "8.9rem" }} leaner>
                                <SpsButton onClick={() => setShowModal(true)} disabled={loading}>
                                    View Retailer List
                                </SpsButton>
                            </SpsContentRowCol>
                            <SpsContentRowCol style={{ width: "10rem" }} leaner>
                                <SpsSelect
                                    options={schemaOptions}
                                    value={selectedSchema}
                                    textKey="schemaName"
                                    comparisonKey="schemaName"
                                    onChange={handleSchemaChange}
                                    notClearable
                                />
                            </SpsContentRowCol>
                        </SpsContentRow>
                        <ReactPlaceholder
                            className="mt-3"
                            showLoadingAnimation
                            ready={!loading}
                            rows={15}
                        >
                            <MyDesignsSchemaDocument data={schemas} loading={loading} />
                        </ReactPlaceholder>
                    </div>
                    <SpsModal
                        isOpen={showModal}
                        id="sps_app_info_modal"
                        size="small"
                        kind="info"
                        header={`${selectedSchema.schemaName}: List of Retailers`}
                        onClose={() => setShowModal(false)}
                    >
                        <SpsModalBody>
                            <ul>
                                {viewRetailers.map((retailers) => (
                                    <li>{retailers}</li>
                                ))}
                            </ul>
                        </SpsModalBody>
                        <SpsModalFooter>
                            <SpsButton kind="default" onClick={() => setShowModal(false)}>
                                Cancel
                            </SpsButton>
                        </SpsModalFooter>
                    </SpsModal>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default withCommercePlatform(MyDesignsPage);
