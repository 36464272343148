import React from "react";
import { SpsCard } from "@spscommerce/ds-react/";
import "./RsxLandingPage.scss";
import Footer from "../../Footer";

export default function RSXLandingPage() {
    return (
        <div className="docs-main-content">
            <div className="col-9 rsx-landing-page">
                <SpsCard className="home-page-content__card">
                    <h2 className="home-page-content__card-heading">What is RSX?</h2>
                    <p>
                        Retail Standard XML (RSX) is a comprehensive data interchange format used by our
                        Universal Network. It includes the data elements that trading partners need to
                        transact business as well as communicate inventory, delivery, and status
                        information. By utilizing RSX, you can use a single connection to interact with
                        any of our trading partners.
                    </p>
                    <p>
                        The RSX format enables us to quickly and easily define a multi-tenant solution
                        that can accommodate a variety of external file formats. This format is
                        all-inclusive and incorporates virtually all EDI functionality for processing
                        data. As a provider of hosted services, we assume responsibility for developing
                        unique connectivity and mapping requirements for each trading partner. These
                        business documents represent the payload that is sent with each connection to
                        the platform.
                    </p>
                    <p>We support many different document types, including the following:</p>
                    <ul>
                        <li>
                            Financial Documents - Payment-related transaction for remittance to banks
                            and suppliers.
                        </li>
                        <li>
                            Fulfillment Models - We support all major fulfillment models, such as bulk
                            import, dropship, cross-dock, and multi-store.
                        </li>
                        <li>
                            Product Inventory Information - Used to transmit inventory item and pricing
                            catalog information between suppliers and retailers.
                        </li>
                        <li>
                            Warehouse Documents - Used to manage third-party logistic distribution
                            centers with communication between supplier, 3PL and retailer.
                        </li>
                    </ul>
                </SpsCard>
            </div>
                <Footer/>
        </div>
    );
}
