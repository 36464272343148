import React from "react";

import { SpsButton, SpsCheckbox, useGrowlers } from "@spscommerce/ds-react";
import { ButtonKind } from "@spscommerce/ds-shared";
import { withCommercePlatform } from "@spscommerce/ui-react/";

import updateCurrentUser from "./UserAgrementService";

const UserAcceptance = withCommercePlatform(
    ({
        commercePlatform: { identityServiceUrl, token, currentUser, refreshCurrentUser, auth } = {},
    }) => {
        const [isAgreed, setAgreed] = React.useState(false);
        const [isSubmitting, setSubmiting] = React.useState(false);

        const [growlers, createGrowler] = useGrowlers();

        return (
            <>
                <div className="tos-page-checkbox">
                    <SpsCheckbox
                        onChange={(e) => {
                            setAgreed(e.target.checked);
                        }}
                        name="agree"
                        className="tos-page-agree"
                        label="I agree to accept these terms of service."
                    />
                </div>
                <div className="sps-list-action-bar-container z-stratum-bar">
                    <div className="sps-list-action-bar">
                        <div className="sps-list-action-bar__actions">
                            <SpsButton
                                className="mr-1"
                                onClick={() => {
                                    auth.logout();
                                }}
                            >
                                Decline{" "}
                            </SpsButton>
                            <SpsButton
                                disabled={!isAgreed}
                                spinning={isSubmitting}
                                kind={ButtonKind.CONFIRM}
                                onClick={() => {
                                    setSubmiting(true);
                                    updateCurrentUser({
                                        identityServiceUrl,
                                        token,
                                        currentUser,
                                        refreshCurrentUser,
                                        setSubmiting,
                                        createGrowler,
                                    });
                                }}
                            >
                                Submit
                            </SpsButton>
                        </div>
                    </div>
                </div>
                {growlers()}
            </>
        );
    },
);

export default UserAcceptance;
