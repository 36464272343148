import React from "react";
import ReactPlaceholder from "react-placeholder";

import { AppCardPlaceholder } from "../Placeholders";
import AppCard from "../AppCard";
import CreateAppButtonCard from "../CreateAppButtonCard/CreateAppButtonCard";

import "./AppList.scss";

/**
 * create a sub array for each row in application grid
 * @param {int} colsPerRow - the number of columns in each row
 * @param {Array} apps - all the applications
 */
export function makeSubArrays(colsPerRow, apps) {
    const totalRowCount = Math.ceil((apps.length + 1) / colsPerRow);
    let subArrays = [];
    for (let i = 0; i < totalRowCount; i++) {
        let startIdx = i * colsPerRow;
        let endIdx = startIdx + colsPerRow;
        subArrays.push(apps.slice(startIdx, endIdx));
    }
    return subArrays;
}

/**
 * basic math but moved to a method for test
 * @param {int} colsPerRow - number of columns in the row
 * @param {Array} apps - array of app objects
 */
export function determineCols(colsPerRow, apps) {
    return apps.length === colsPerRow ? colsPerRow : apps.length + 1;
}

export default function AppList(props) {
    const { applications, appsLoaded } = props;
    const subArrays = makeSubArrays(3, applications);

    /**
     * normal map process for row that doesn't have 'create app' button
     * @param {Array} - array of apps for that row
     */
    function mapNormalRow(apps) {
        return apps.map((app, idx) => {
            return <AppCard key={app.id} app={app} slot={idx + 1} />;
        });
    }

    /**
     * map process for row with 'create app' button
     * @param {int} colsPerRow - number of columns in the row
     * @param {Array} apps - apps that will appear on the row with the
     *                       'create app' button
     */
    function mapLastRow(colsPerRow, apps) {
        const normalApps = mapNormalRow(apps);
        return normalApps.concat(<CreateAppButtonCard key={"createapp"} />);
    }

    return (
        <div className="app-list">
            <ReactPlaceholder customPlaceholder={<AppCardPlaceholder />} ready={appsLoaded}>
                {subArrays.map((subArray) => {
                    const cols = determineCols(3, subArray);
                    const uniqueKey = subArray.reduce((accum, app) => {
                        return accum + app.id;
                    }, "");
                    return (
                        <div className={`row-wrapper column${cols}`} key={uniqueKey}>
                            {subArray.length === 3
                                ? mapNormalRow(subArray)
                                : mapLastRow(3, subArray)}
                        </div>
                    );
                })}
            </ReactPlaceholder>
        </div>
    );
}
