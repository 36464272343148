export let validatePassword = (passwordPolicy, password) => {
    if (!password) {
        return false;
    }
    if (passwordPolicy) {
        let pattern = new RegExp(passwordPolicy);
        return pattern.test(password);
    }
};

export let validateAll = (passwordPolicy, password) => {
    if (passwordPolicy) {
        let index = -1;
        const length = passwordPolicy == null ? 0 : passwordPolicy.length;

        while (++index < length) {
            if (!validatePassword(passwordPolicy[index].pattern, password)) {
                return false;
            }
        }
        return true;
    }
};
