import React, { useState, useEffect } from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import { SpsCard } from "@spscommerce/ds-react";
import AppConfig from "../../App.config";
import axios from "axios";
import _ from "lodash";

import RsxSideNav from "./RsxSideNav";
import RsxMarkdownWrapper from "./RsxMarkdownWrapper";
import SchemaDownloadPage from "../SchemaDownloadPage/SchemaDownloadPage";
import MyDesignsPage from "../MyDesignsPage/MyDesignsPage";
import ExampleDownloadPage from "../ExampleDownloadPage/ExampleDownloadPage";
import FieldsAndQualifierPage from "../RsxUiPage/FieldsAndQualifiers/FieldsAndQualifierPage";
import FieldAndQualifierListPage from "../RsxUiPage/FieldsAndQualifiers/FieldAndQualifierListPage";
import QualifierDetailsPage from "../RsxUiPage/FieldsAndQualifiers/QualifierDetailsPage";

const rsxContentId = "f81019be-b353-417c-ba1e-d592eaf8a41a";
const rsxInternalDocsId = "2f276798-830c-4d3b-99a8-f4fb33475be9";
// TODO: make SideNavSection stuff generic and shareable for SideNav.jsx

const RsxUi = function (props) {
    const [contentUrl, setContentUrl] = useState(null);
    const [toc, setToc] = useState(null);
    const [internalDocToc, setInternalDocToc] = useState(null);
    const [internalDocContentUrl, setInternalDocContentUrl] = useState(null);
    const [path, setPath] = useState(null);
    const [activeParent, setActiveParent] = useState("");
    const [activeChild, setActiveChild] = useState("");

    const fetchContentUrl = async () => {
        const config = {
            headers: { Authorization: `Bearer ${props.token}` },
        };
        const url = `${
            AppConfig.kubeRoot[props.environment]
        }/devcenter/v1/services/${rsxContentId}/`;
        const contentService = await axios.get(url, config);
        setContentUrl(_.get(contentService, "data.currentDocLocation"));

        const internalDocUrl = `${
            AppConfig.kubeRoot[props.environment]
        }/devcenter/v1/services/${rsxInternalDocsId}/`;
        const internalContentService = await axios.get(internalDocUrl, config);
        setInternalDocContentUrl(_.get(internalContentService, "data.currentDocLocation"));
    };

    const fetchAndParseTocs = async () => {
        if (!toc && contentUrl) {
            const response = await axios.get(`${contentUrl}toc.json`);
            const toc = response.data.map((item) => {
                const updatedLink = item.link.replace(".md", "");
                item.link = updatedLink;
                return item;
            });
            setToc(toc);
        }

        if (!internalDocToc && internalDocContentUrl) {
            const internalDocResponse = await axios.get(`${internalDocContentUrl}toc.json`);
            const internalToc = internalDocResponse.data.map((item) => {
                const updatedLink = item.link.replace(".md", "");
                item.link = updatedLink;
                return item;
            });
            setInternalDocToc(internalToc);
        }
    };

    useEffect(() => {
        fetchContentUrl();
    }, []);

    useEffect(() => {
        fetchAndParseTocs();
    }, [contentUrl, internalDocContentUrl]);

    return (
        <div className="row sps-page-wrapper">
            <div className="col-3 docs-body__sidebar sps-side-nav rsx-side-nav">
                <SpsCard
                    className="sps-side-nav-card"
                    style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                    }}
                    headerTitle="Integration Resources"
                >
                    <RsxSideNav
                        servicePath="docs"
                        toc={toc}
                        activeParent={activeParent}
                        activeChild={activeChild}
                    />
                    {internalDocToc && (
                        <>
                            <div className="section-seprator" />
                            <div className="sps-side-nav__section-title section-heading">
                                Internal Documentation
                            </div>
                            <RsxSideNav
                                servicePath="internal-docs"
                                toc={internalDocToc}
                                activeParent={activeParent}
                                activeChild={activeChild}
                            />
                        </>
                    )}
                </SpsCard>
            </div>
            <Switch>
                <Route
                    path="/rsx/docs/schema-download"
                    exact={true}
                    render={() => {
                        if (!path) {
                            setPath("schema-download");
                        }
                        if (activeParent !== "schema-download") {
                            setActiveParent("schema-download");
                        }
                        return (
                            <SchemaDownloadPage
                                csvUrl={contentUrl}
                                environment={props.environment}
                            />
                        );
                    }}
                />
                <Route
                    path="/rsx/docs/my-designs"
                    exact={true}
                    render={() => {
                        if (!path) {
                            setPath("my-designs");
                        }
                        if (activeParent !== "my-designs") {
                            setActiveParent("my-designs");
                        }
                        return contentUrl && <MyDesignsPage url={contentUrl} />;
                    }}
                />
                <Route
                    path="/rsx/docs/fields-qualifiers"
                    exact={true}
                    render={() => {
                        if (!path) {
                            setPath("fields-qualifiers");
                        }
                        if (activeParent !== "fields-qualifiers") {
                            setActiveParent("fields-qualifiers");
                        }
                        return <FieldAndQualifierListPage environment={props.environment} />;
                    }}
                />
                <Route
                    path="/rsx/docs/fields-qualifiers/:version/:documentType"
                    exact={true}
                    render={(routeProps) => {
                        const {
                            match: {
                                params: { version, documentType },
                            },
                        } = routeProps;
                        if (!path) {
                            setPath("fields-qualifiers");
                        }
                        if (activeParent !== "fields-qualifiers") {
                            setActiveParent("fields-qualifiers");
                        }
                        return (
                            <FieldsAndQualifierPage
                                version={version}
                                documentType={documentType}
                                environment={props.environment}
                            />
                        );
                    }}
                />
                <Route
                    path="/rsx/docs/fields-qualifiers/:version/:documentType/:qualifier"
                    exact={true}
                    render={(routeProps) => {
                        const {
                            match: {
                                params: { version, documentType, qualifier },
                            },
                        } = routeProps;
                        if (!path) {
                            setPath("fields-qualifiers");
                        }
                        if (activeParent !== "fields-qualifiers") {
                            setActiveParent("fields-qualifiers");
                        }
                        return (
                            <QualifierDetailsPage
                                version={version}
                                documentType={documentType}
                                qualifier={qualifier}
                                environment={props.environment}
                            />
                        );
                    }}
                />
                <Route
                    path="/rsx/docs/example-download"
                    exact={true}
                    render={() => {
                        if (!path) {
                            setPath("example-download");
                        }
                        if (activeParent !== "example-download") {
                            setActiveParent("example-download");
                        }
                        return (
                            <ExampleDownloadPage
                                contentUrl={contentUrl}
                                environment={props.environment}
                            />
                        );
                    }}
                />
                <Route
                    path="/rsx/:service/:doc"
                    exact={true}
                    render={(routeProps) => {
                        const {
                            match: {
                                params: { service, doc },
                            },
                        } = routeProps;
                        if (!path) {
                            setPath(doc);
                        }
                        if (activeParent !== doc) {
                            setActiveParent(doc);
                        }
                        if (activeChild) {
                            setActiveChild("");
                        }
                        return (
                            <RsxMarkdownWrapper
                                toc={toc}
                                doc={doc}
                                contentUrl={service === "docs" ? contentUrl : internalDocContentUrl}
                            />
                        );
                    }}
                />
                <Route
                    exact={true}
                    path="/rsx/:service/:doc/:childDoc"
                    render={(routeProps) => {
                        const {
                            match: {
                                params: { doc, childDoc, service },
                            },
                        } = routeProps;
                        if (!path) {
                            setPath(doc + childDoc);
                        }
                        if (activeParent !== doc) {
                            setActiveParent(doc);
                        }
                        if (activeChild !== childDoc) {
                            setActiveChild(childDoc);
                        }
                        return (
                            <RsxMarkdownWrapper
                                toc={toc}
                                doc={childDoc}
                                contentUrl={service === "docs" ? contentUrl : internalDocContentUrl}
                            />
                        );
                    }}
                />
                <Route
                    exact={true}
                    path="/rsx/:service/:doc/:childDoc/:grandChildDoc"
                    render={(routeProps) => {
                        const {
                            match: {
                                params: { doc, childDoc, grandChildDoc, service },
                            },
                        } = routeProps;
                        if (!path) {
                            setPath(doc + childDoc + grandChildDoc);
                        }
                        if (activeParent !== doc) {
                            setActiveParent(doc);
                        }
                        if (activeChild !== childDoc) {
                            setActiveChild(childDoc);
                        }
                        return (
                            <RsxMarkdownWrapper
                                toc={toc}
                                doc={grandChildDoc}
                                contentUrl={service === "docs" ? contentUrl : internalDocContentUrl}
                            />
                        );
                    }}
                />
            </Switch>
        </div>
    );
};

export default withRouter(RsxUi);
