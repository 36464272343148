import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import "./wizard.scss";

export default class WizardHeader extends Component {
  render() {
    const {
      initiallyCollapsed,
      compIndex,
      onWizardHeaderClick,
      message,
      disableItems,
      expandAll
    } = this.props;
    let buttonCls = "trigger";
    let numCircleCls = "col-1 number-circle";
    let messageTextCls = "message-text";
    if (!initiallyCollapsed && !expandAll) {
      numCircleCls += "-expanded";
      buttonCls += " item-expanded";
      messageTextCls += "-expanded";
    }
    if (expandAll) {
      numCircleCls += "-expanded";
      buttonCls += " item-expanded";
      messageTextCls += "-expanded";
    }

    return (
      <Fragment>
        <div className="wizard-header">
          <button
            type="button"
            className={buttonCls}
            onClick={onWizardHeaderClick}
            disabled={disableItems}
          >
            <div className="container">
              <div className="row header">
                <div className={numCircleCls}>
                  <p className="number-text">{compIndex + 1}</p>
                </div>
                <div className="col message">
                  <p className={messageTextCls}>{message}</p>
                </div>
              </div>
            </div>
          </button>
        </div>
      </Fragment>
    );
  }
}

WizardHeader.propTypes = {
  message: PropTypes.string.isRequired
};
