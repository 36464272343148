import React, { createContext, useState } from "react";
import config from "../App.config";

export const PrivateRoutesContext = createContext({});

export const PrivateRoutesProvider = ({children}) => {

    const [privateRoutes, setPrivateRoutes] = useState(config.sideNavLockByLink);

    function addPrivateRoute(route) {
        if(!privateRoutes.includes(route)) {
            setPrivateRoutes(pr => {
                return [...pr, route];
            });
        }
    }

    const contextObj = {
        privateRoutes,
        setPrivateRoutes,
        addPrivateRoute
    };

  return <PrivateRoutesContext.Provider value={contextObj}>{children}</PrivateRoutesContext.Provider>;
};
