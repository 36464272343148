import React from "react";
import { SpsTag, SpsTooltip } from "@spscommerce/ds-react";

import { Position } from "@spscommerce/positioning";

function VersionTagWrapper({ serviceName, version, latestVersion, latestVersionPath }) {
    // TODO: Replace SpsTag with SpsKeyValueTag after ds-react version updates
    return (
        <>
            {version === latestVersion ? (
                <SpsTag kind="key" className="mb-2">
                    {serviceName} | {version}
                </SpsTag>
            ) : (
                <>
                    <SpsTag
                        id="versionTag"
                        kind="warning"
                        icon="exclamation-triangle"
                        className="mb-2"
                    >
                        {serviceName} | {version}
                    </SpsTag>
                    <SpsTooltip
                        for="versionTag"
                        id="tooltip-target-bottom-middle"
                        position={Position.BOTTOM_MIDDLE}
                    >
                        <a
                            href={`#/docs/${latestVersionPath}`}
                            rel="noopener noreferrer"
                            style={{ color: "#FFFFFF", textDecoration: "underline" }}
                        >
                            {latestVersion}
                        </a>{" "}
                        is available for this endpoint.
                    </SpsTooltip>
                </>
            )}
        </>
    );
}
export default VersionTagWrapper;
