import React, { useEffect, useState } from "react";
import "./LocationsPage.scss";

export default function LocationCard(props) {
    const locations = props.locations;
    const [hqLocation, setHqLocation] = useState({});
    const [otherLocations, setOtherLocations] = useState([]);

    useEffect(() => {
        setHqLocation(locations.find((loc) => loc.isHeadquarter === true));
        setOtherLocations(locations.filter((loc) => !loc.isHeadquarter));
    }, []);

    const renderLocations = (loc) => {
        return (
            <>
                <div className="location-address-card__col">
                    <div className="mb-1">
                        <div className="font-weight-600">{loc.locationName}</div>
                        {loc.officeType && (
                            <div className="location-address-card__office-type">
                                {loc.officeType}
                            </div>
                        )}
                    </div>
                    {loc.address &&
                        Object.keys(loc.address).map((key) => {
                            return <div>{loc.address[key]}</div>;
                        })}
                    <span>
                        {loc.city && `${loc.city},`} {loc.state} {loc.pin}
                    </span>
                    <div className="location-address-card__phone">
                        {loc.phones &&
                            Object.keys(loc.phones).map((key) => {
                                return (
                                    <div>
                                        <span>{key}: </span>
                                        <span className="font-weight-600">{loc.phones[key]}</span>
                                    </div>
                                );
                            })}
                    </div>
                    {loc.customerServiceLoc && (
                        <div className="location-address-card__phone">
                            <div className="font-weight-600">{loc.customerServiceLoc}:</div>
                            {loc.customerService &&
                                Object.keys(loc.customerService).map((key) => {
                                    return (
                                        <div>
                                            <span>{key}: </span>
                                            <span className="font-weight-600">
                                                {loc.customerService[key]}
                                            </span>
                                        </div>
                                    );
                                })}
                        </div>
                    )}
                    {loc.links && (
                        <div className="font-weight-600 mt-1">
                            {loc.links.map((link) => {
                                let lin = "";
                                if (typeof link === "object") {
                                    lin = Object.keys(link).map((key) => {
                                        return (
                                            <div>
                                                <a href={link[key]}>{key}</a>
                                            </div>
                                        );
                                    });
                                } else {
                                    lin = <a href={link}>{link}</a>;
                                }
                                return lin;
                            })}
                        </div>
                    )}
                </div>
                {loc.isHeadquarter && <span className="sps-vertical-rule divider flex-1" />}
            </>
        );
    };

    return (
        <>
            <div className="location-address-card w-100">
                {renderLocations(hqLocation)}
                {otherLocations && (
                    <div className="location-address-card other-location" style={{ width: "48%" }}>
                        {otherLocations.map((loc) => {
                            return renderLocations(loc);
                        })}
                    </div>
                )}
            </div>
        </>
    );
}
