import React from "react";

import { SpsCard } from "@spscommerce/ds-react";

import "./LoginCard.scss";

export default function LoginCard(props) {
    const { children, topShelf, bodyClass = "" } = props;

    return (
        <SpsCard
            className="sps-login-card"
            style={{
                padding: 0,
            }}
        >
            <div className="sps-login-card__top-shelf">{topShelf ? topShelf() : null}</div>
            <div className="sps-login-card__col sps-login-card__col--left">
                <div className="sps-commerce-logo">
                    <img src="assets/sps-logo@2x.png" alt="SPS Commerce" />
                </div>
                <div className="logo-separator">
                    <img
                        src="https://cdn.prod.spsc.io/web/framework/assets/21.04.01/images/rectangle.svg"
                        alt="SPS Commerce"
                    />
                </div>
                <div className="dev-center-logo-sub-header">DEV CENTER</div>
            </div>
            <div className="sps-login-card__col sps-login-card__col--right">
                <div className={`login-content-body ${bodyClass}`}>{children}</div>
            </div>
        </SpsCard>
    );
}
