import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import classnames from "classnames";
import ReactPlaceholder from "react-placeholder";
import { TieredPlaceHolder } from "../../components/Placeholders";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

export function SideNavSection(props) {
    return <div {...props}>{props.children}</div>;
}
export function SideNavLink(props) {
    let linkClasses = classnames("sps-side-nav__link", props.className);

    return (
        <NavLink exact={true} className={linkClasses} {...props}>
            {props.children}
        </NavLink>
    );
}

const sideNavEntryRules = {
    "My Designs": "myDesignsPage",
};

function showParent(flags, text) {
    if (sideNavEntryRules[text]) {
        return flags[sideNavEntryRules[text]];
    }
    return true;
}

function generateParent(servicePath, toc, activeParent, activeChild, parentLink = "", flags) {
    let parentContent = toc.map((item, key) => {
        const trimmedLink = item.link.replace("/", "").replace(".md", "");
        const isActive = activeParent === trimmedLink;
        const hasGrandChildren = activeChild === trimmedLink;
        const isNotFieldsAndQualifierPage = activeParent !== "fields-qualifiers";
        let iconClass;
        if (hasGrandChildren) {
            iconClass = classnames("sps-icon", {
                "sps-icon-chevron-right": !hasGrandChildren,
                "sps-icon-chevron-down": hasGrandChildren,
            });
        } else {
            iconClass = classnames("sps-icon", {
                "sps-icon-chevron-right": !isActive,
                "sps-icon-chevron-down": isActive,
            });
        }
        const sectionParentClass = classnames("sps-side-nav__section-content", {
            active: isActive,
        });

        const linkWithRsx = `/rsx/${servicePath}${parentLink}${item.link}`.replace(".md", "");
        return (
            <>
                {showParent(flags, item.text) && (
                    <div className={sectionParentClass} key={item.link + key}>
                        <NavLink
                            className="sps-side-nav__link sps-side-nav__link--top"
                            to={linkWithRsx || ""}
                            exact={isNotFieldsAndQualifierPage}
                        >
                            {item.links && item.links.length > 0 ? (
                                <i className={iconClass} />
                            ) : null}
                            {item.text}
                        </NavLink>
                        <div>
                            <ul className="side-nav__branch">
                                {(item.children.length && isActive) ||
                                (item.children.length && hasGrandChildren)
                                    ? generateChildren(
                                          servicePath,
                                          item,
                                          activeParent,
                                          activeChild,
                                          parentLink,
                                      )
                                    : null}
                            </ul>
                        </div>
                    </div>
                )}
            </>
        );
    });
    return parentContent;
}

function generateChildren(servicePath, item, activeParent, activeChild, parentLinkReceived = "") {
    let childContent = item.children.map((itemChild, key) => {
        const parentLink = item.link.replace(".md", "");
        const trimmedUrl =
            "/rsx/" +
            servicePath +
            parentLinkReceived +
            parentLink +
            itemChild.link.replace(".md", "") +
            "/";

        return (
            <li key={trimmedUrl + key}>
                {itemChild.children.length ? (
                    generateParent(servicePath, [itemChild], activeParent, activeChild, parentLink)
                ) : (
                    <NavLink className="sps-side-nav__link side-nav__leaf" to={trimmedUrl || ""}>
                        {itemChild.text}
                    </NavLink>
                )}
            </li>
        );
    });
    return childContent;
}

const RsxSideNav = (props) => {
    const { servicePath, toc, activeParent, activeChild, flags } = props;

    return (
        <SideNavSection className="sps-side-nav__section">
            <ul className="side-nav__branch side-nav__branch--1 rsx-side-nav">
                <ReactPlaceholder customPlaceholder={<TieredPlaceHolder />} ready={!!toc}>
                    {toc
                        ? generateParent(
                              servicePath,
                              toc,
                              activeParent,
                              activeChild,
                              undefined,
                              flags,
                          )
                        : []}
                </ReactPlaceholder>
            </ul>
        </SideNavSection>
    );
};
export default withLDConsumer()(withRouter(RsxSideNav));
