import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPlaceholder from "react-placeholder";
import { withCommercePlatform } from "@spscommerce/ui-react";
import {
    SpsCard,
    SpsButtonGroup,
    SpsButton,
    SpsModal,
    SpsModalBody,
    SpsModalFooter,
    SpsModalAction,
    useGrowlers,
} from "@spscommerce/ds-react";
import "./ProfileImage.scss";
import { AUTH_FLOW_OAUTH_IMPLICIT } from "@spscommerce/ui-react";
import "cropperjs/dist/cropper.css";
import EditProfileImage from "./EditProfileImage";
import FileUploadWrapper from "./FileUploadWrapper";

function ProfileImage({
    commercePlatform: {
        identityServiceUrl,
        token,
        currentUser,
        refreshCurrentUser,
        environment,
    } = {},
}) {
    const [profilePhotoUrl, setProfilePhotoUrl] = useState();
    const [isDeleting, setIsDeleting] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [growlers, createGrowler] = useGrowlers();
    const s3BaseUrl = "https://s3.amazonaws.com/static-assets.spscommerce.com/user-images";

    useEffect(() => {
        const imageUrl = `${s3BaseUrl}/${environment}/400x400/${currentUser.avatar_image_id}`;
        setProfilePhotoUrl(imageUrl);
    }, [currentUser.avatar_image_id]);

    function showProfilePhoto(imageUrl) {
        return (
            <div>
                <img alt="Profile" style={{ width: "240px", height: "240px" }} src={imageUrl} />
            </div>
        );
    }

    function onImageSelect(newImage) {
        if (newImage) {
            setProfilePhotoUrl(newImage);
            setIsEditing(true);
        }
    }

    function closeEditHandler(response) {
        if (response) {
            createGrowler(response);
            if (response.kind === "success") {
                setTimeout(() => {
                    refreshCurrentUser(AUTH_FLOW_OAUTH_IMPLICIT);
                }, 500);
            }
        }
        setIsEditing(false);
    }

    async function deletePhoto() {
        const url = `${identityServiceUrl}/identity/v3/users/${currentUser.id}/profile/`;
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const payload = {
            email: currentUser.email,
            avatar_image_id: "",
        };

        try {
            await axios.patch(url, payload, config);
            setProfilePhotoUrl("");
            createGrowler({
                kind: "success",
                title: "Profile Photo deleted",
                content: () => "Your profile photo was successfully deleted.",
            });
            setTimeout(() => {
                refreshCurrentUser(AUTH_FLOW_OAUTH_IMPLICIT);
            }, 500);
        } catch (error) {
            createGrowler({
                kind: "error",
                title: "Error deleting Profile Photo",
                content: () => "Your profile photo was not deleted.",
            });
        }
    }

    return (
        <>
            {growlers()}
            {isEditing ? (
                <EditProfileImage
                    imageUrl={profilePhotoUrl || currentUser.avatar_image_url}
                    showEditModal={isEditing}
                    onEditClose={closeEditHandler}
                />
            ) : null}
            {isDeleting && (
                <DeletePhoto
                    onDelete={deletePhoto}
                    onDeleteClose={() => {
                        setIsDeleting(false);
                    }}
                />
            )}
            <SpsCard
                headerTitle="Profile Photo"
                headerContent={() => {
                    return currentUser.avatar_image_url ? (
                        <SpsButtonGroup>
                            <SpsButton
                                kind="icon"
                                icon="trash"
                                onClick={() => {
                                    setIsDeleting(true);
                                }}
                                aria-label="Delete"
                            />
                            <SpsButton
                                kind="icon"
                                icon="pencil"
                                onClick={() => {
                                    setIsEditing(true);
                                }}
                                aria-label="Edit"
                            />
                        </SpsButtonGroup>
                    ) : null;
                }}
            >
                <ReactPlaceholder ready={true}>
                    {!currentUser.avatar_image_url ? (
                        <div className="d-flex">
                            <FileUploadWrapper onImageSelect={onImageSelect} />
                        </div>
                    ) : (
                        showProfilePhoto(currentUser.avatar_image_url)
                    )}
                </ReactPlaceholder>
            </SpsCard>
        </>
    );
}

export default withCommercePlatform(ProfileImage);

function DeletePhoto({ onDelete, onDeletClose }) {
    return (
        <SpsModal
            id="edit-photo"
            kind="delete"
            isOpen={true}
            header="Delete current photo"
            size="small"
            onClose={onDeletClose}
        >
            <SpsModalBody>Are you sure you want to delete your current photo?</SpsModalBody>
            <SpsModalFooter>
                <SpsModalAction kind="default">Cancel</SpsModalAction>
                <SpsModalAction kind="confirm" onClick={onDelete}>
                    Delete
                </SpsModalAction>
            </SpsModalFooter>
        </SpsModal>
    );
}
