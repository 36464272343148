import React from "react";
import { NavLink } from "react-router-dom";

import "./BlackBar.scss";

export default function BlackBar(props) {
    const { className: addClassName } = props;
    return (
        <div className={`black-bar${addClassName ? " " + addClassName : ""}`}>
            <div className="logo-container">
                <h1 className="logo" aria-label="SPS Commerce logo">
                    <NavLink to="/home">SPS Commerce for Developers</NavLink>
                </h1>
            </div>

            <div className="dropdown-container">{props.children}</div>
        </div>
    );
}
