import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

const SpsDevCenterSwagger = function (props) {
    const { path, className, cdn_url, ...rest } = props;
    const classList = classnames("sps-dev-center-swagger__wrapper", className);
    const pattern = /^(http:\/\/|https:\/\/)(www\.)?[a-z]+\.?/gi;
    let swaggerUrl = "";

    if (pattern.test(path)) {
        swaggerUrl = path;
    } else {
        swaggerUrl = `${cdn_url}${path}`;
    }
    return (
        <div className={classList} {...rest}>
            <SwaggerUI url={swaggerUrl} />
        </div>
    );
};

SpsDevCenterSwagger.propTypes = {
    path: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default SpsDevCenterSwagger;
