import React from "react";

import _ from "lodash";

import {
    SpsContentRow,
    SpsContentRowCol,
    SpsContentRowExpansion,
    SpsSelect,
} from "@spscommerce/ds-react";

import CopyCode from "../SwaggerDocumentation/CopyCode";

const responseFormatOptions = ["JSON"];

function CodeResponseRow({
    name,
    codes = [],
    responseFormat,
    responseFormatDropdown = true,
    className,
}) {
    const [responseKeys, setResponseKeys] = React.useState(JSON.stringify([{ key: "value" }]));

    React.useEffect(() => {
        handleCodeChange({ target: { value: codes[0] } });
    }, []);

    function handleCodeChange(e) {
        const rKeys = _.get(e, "target.value.responseKeys");
        const response = _.get(e, "target.value.response");
        setResponseKeys(rKeys ? parseResponseKeys(rKeys) : response);
    }

    function parseResponseKeys(keys) {
        const responseTypesAndValues = {
            string: "string",
            array: [],
            integer: 0,
        };
        function getValueForKey(key) {
            if (key && key.items) {
                const response = JSON.parse(parseResponseKeys(key.items));
                return key.type === "array" ? [response] : response;
            }
            return responseTypesAndValues.hasOwnProperty(key.type)
                ? responseTypesAndValues[key.type]
                : key.type;
        }
        try {
            return JSON.stringify(
                keys.reduce((acc, cur) => ({ ...acc, [cur.key]: getValueForKey(cur) }), {}),
                null,
                " ",
            );
        } catch (e) {
            return JSON.stringify(keys, null, " ");
        }
    }

    return (
        <SpsContentRow className={className}>
            <SpsContentRowCol>
                <div className="fs-14 lh-20">{name || "Response"}</div>
            </SpsContentRowCol>
            {codes.length > 0 && codes[0].code && codes[0].description ? (
                <SpsContentRowCol style={{ width: "13.4rem" }}>
                    <SpsSelect
                        notClearable
                        options={codes}
                        value={codes[0]}
                        onChange={handleCodeChange}
                        textKey="description"
                    />
                </SpsContentRowCol>
            ) : (
                <></>
            )}
            <SpsContentRowCol
                style={{
                    width: responseFormatDropdown ? "8.4rem" : "4.4rem",
                    textAlign: "center",
                }}
            >
                {responseFormat ? (
                    <>
                        {responseFormatDropdown && (
                            <SpsSelect
                                notClearable
                                options={[responseFormat]}
                                value={responseFormat}
                            />
                        )}
                        {!responseFormatDropdown && responseFormat}
                    </>
                ) : (
                    <SpsSelect
                        notClearable
                        options={responseFormatOptions}
                        value={responseFormatOptions[0]}
                    />
                )}
            </SpsContentRowCol>
            <SpsContentRowCol style={{ width: "7.4rem" }}>
                <CopyCode textToCopy={responseKeys} />
            </SpsContentRowCol>
            <SpsContentRowExpansion>
                <pre className="m-0 dcui-code-snippet">{responseKeys || " NO DATA"}</pre>
            </SpsContentRowExpansion>
        </SpsContentRow>
    );
}

export { CodeResponseRow };
export default CodeResponseRow;
