import "./SwaggerDocumentation.scss";

import React, { Fragment, useEffect, useState } from "react";

import axios from "axios";
import _ from "lodash";
import PropTypes from "prop-types";
import queryString from "query-string";
import ReactPlaceholder from "react-placeholder";
import { withRouter } from "react-router";

import { MarkdownPlaceHolder } from "../../components/Placeholders";
import { makePath } from "../../utils";
import { swaggerParser } from "../../utils/jsonParser";
import SwaggerDocumentationPage from "./SwaggerDocumentationPage";

const itemsHeaderPara = [
    {
        name: "Content-Type",
        Mandatory: "No",
        DefaultValue: "application/json",
        description: "Content type of your request",
        schema: {
            type: "string",
        },
    },
    {
        name: "Authorization",
        Mandatory: "Yes",
        DefaultValue: "None",
        description: "Authentication token with a prefix of 'Bearer'",
        schema: {
            type: "string",
        },
    },
];

const SwaggerDocumentation = function (props) {
    const { swaggerUri, authDocsPath, location, hideSections = [], modifySections = {} } = props;

    const [jsonData, setJsonData] = useState();
    const [loading, setLoading] = useState(false);
    const [docLocation, setDocLocation] = useState("");
    const [apiPath, setApiPath] = useState("");
    const [apiMethod, setApiMethod] = useState("");

    useEffect(() => {
        fetchJson(docLocation || swaggerUri);
    }, [docLocation, apiMethod, apiPath]);

    useEffect(() => {
        if (location.search) {
            const { doc, path, method } = queryString.parse(location.search);
            if (doc !== docLocation) {
                setDocLocation(doc);
            }
            if (path !== apiPath) {
                setApiPath(path);
            }
            if (method !== apiMethod) {
                setApiMethod(method);
            }
        }
    }, [location.search]);

    async function fetchJson(docLocation) {
        try {
            setLoading(true);
            const { path, method } = queryString.parse(location.search);
            if (docLocation) {
                if (docLocation.startsWith("/")) docLocation = docLocation.slice(1);
                if (!docLocation.includes("http"))
                    docLocation = makePath({
                        root: props.cdn_url || "",
                        path: docLocation || "",
                        appendExtension: "",
                        defaultPath: "",
                    });
                if (docLocation.endsWith(".")) docLocation = docLocation.slice(0, -1);
                const response = await axios.get(docLocation);
                setJsonData(await swaggerParser(response.data, path, method));
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    return (
        <ReactPlaceholder customPlaceholder={<MarkdownPlaceHolder />} ready={!loading}>
            <Fragment>
                {jsonData &&
                    Array.isArray(jsonData) &&
                    jsonData.map((data, index) => (
                        <SwaggerDocumentationPage
                            key={index}
                            data={data}
                            hideSections={hideSections}
                            modifySections={modifySections}
                            itemsHeaderPara={itemsHeaderPara}
                            authDocsPath={authDocsPath}
                        />
                    ))}
                {jsonData && !_.isEmpty(jsonData) && !Array.isArray(jsonData) && (
                    <SwaggerDocumentationPage
                        data={jsonData}
                        hideSections={hideSections}
                        modifySections={modifySections}
                        itemsHeaderPara={itemsHeaderPara}
                        authDocsPath={authDocsPath}
                    />
                )}
            </Fragment>
        </ReactPlaceholder>
    );
};

SwaggerDocumentation.propTypes = {
    swaggerUri: PropTypes.string,
    authDocsPath: PropTypes.string,
    hideSections: PropTypes.array,
    modifySections: PropTypes.object,
};

export default withRouter(SwaggerDocumentation);
