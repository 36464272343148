import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  SpsCard,
  SpsForm,
  SpsValidators,
  useForm,
  formControl,
  formGroup,
  SpsLabel,
  SpsTextInput,
  SpsButtonGroup,
  SpsButton,
  useGrowlers,
  SpsFeedbackBlock
} from "@spscommerce/ds-react";
import { ButtonKind, ButtonType } from "@spscommerce/ds-shared";
import { AUTH_FLOW_OAUTH_IMPLICIT } from "@spscommerce/ui-react";
import PatternMatcher from "../../components/PatternMatcher";
import clsx from "clsx";
import Footer from "../../Footer";

const Password_Pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?,#`^;"'&])[A-Za-z0-9@$!%*?,#`^;"'&]{8,}$/;

function ProfilePassword({
  commercePlatform: {
    identityServiceUrl,
    token,
    currentUser,
    refreshCurrentUser
  } = {}
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [growlers, createGrowler] = useGrowlers();
  const [passwordPolicy, setPasswordPolicy] = React.useState(null);
  const [isSpsEmployee, setIsSpsEmployee] = React.useState(false);

  const form = useForm(
    formGroup({
      password: formControl("", {
        validators: [
          SpsValidators.required,
          SpsValidators.pattern(Password_Pattern)
        ]
      }),
      new_password: formControl("", {
        validators: [
          SpsValidators.required,
          SpsValidators.pattern(Password_Pattern)
        ]
      })
    })
  );

  const nonRequiredForm = useForm(
    formGroup({
      password: formControl(""),
      new_password: formControl("")
    })
  );

  useEffect(
    () => {
      if (currentUser.user_type === "SPS Commerce Employee") {
        setIsSpsEmployee(true);
        form.setControl("password", formControl(""));
        form.setControl("new_password", formControl(""));
      } else {
        fetchPasswordPolicy();
      }
    },
    [currentUser]
  );

  function isFormValid() {
    const password = form.get("password");
    const newPassword = form.get("new_password");
    if (
      password.value &&
      password.value.length &&
      !password.errors &&
      newPassword.value &&
      password.value.length &&
      !newPassword.errors
    ) {
      return true;
    } else {
      return false;
    }
  }

  async function updatePassword() {
    if (form.isValid() && isFormValid()) {
      const payload = {
        ...form.getValue()
      };
      if (payload.password === payload.new_password) {
        createGrowler({
          kind: "error",
          title: "Error Saving New Password",
          content: () =>
            "New passwords must be different from current passwords. Please update the new password and try again."
        });
        return null;
      }
      const url = `${identityServiceUrl}/identity/v1/users/me/change-password/`;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      };
      try {
        setIsSubmitting(true);
        await axios.put(url, payload, config);
        createGrowler({
          kind: "success",
          title: "Password Updated",
          content: () => "Your new password was successfully updated."
        });
        setTimeout(() => {
          refreshCurrentUser(AUTH_FLOW_OAUTH_IMPLICIT);
        }, 500);
      } catch (error) {
        createGrowler({
          kind: "error",
          title: "Error Saving New Password",
          content: () =>
            "You new password was unable to be saved. Please check your internet connection and try again."
        });
      } finally {
        setIsSubmitting(false);
      }
    }
  }

  const fetchPasswordPolicy = async () => {
    const url = `${identityServiceUrl}/identity/v2/users/password-policy/`;
    let paswordPolicyResponse;
    try {
      paswordPolicyResponse = await axios.get(url);
    } catch (err) {
      console.error(err);
      createGrowler({
        kind: "error",
        title: "Error",
        content: () => "Error fetching password policy."
      });
    } finally {
      try {
        // putting in try block in case there is some issue with the object coming in response.
        const policyObject = paswordPolicyResponse.data.data.regex_policy;
        const polices = Object.keys(policyObject).map(key => {
          return Object.assign({}, policyObject[key], { name: key });
        });
        setPasswordPolicy(polices);
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <>
      {growlers()}
      <div className="col-9 docs-main-content">
        <div className="sps-docs-container-markdown">
          <SpsForm
            formGroup={isSpsEmployee ? nonRequiredForm : form}
            onSubmit={updatePassword}
          >
            <SpsCard
              className="sps-docs-markdown"
              headerTitle="Password Management"
              footer={
                !isSpsEmployee
                  ? () => {
                      return (
                        <SpsButtonGroup className="text-right">
                          <SpsButton
                            kind={ButtonKind.DEFAULT}
                            onClick={() => {
                              form.reset();
                            }}
                          >
                            Cancel
                          </SpsButton>
                          <SpsButton
                            disabled={
                              !isFormValid() ||
                              form.isPristine() ||
                              isSpsEmployee
                            }
                            kind={ButtonKind.CONFIRM}
                            type={ButtonType.SUBMIT}
                            spinning={isSubmitting}
                          >
                            Save
                          </SpsButton>
                        </SpsButtonGroup>
                      );
                    }
                  : undefined
              }
            >
              <>
                {isSpsEmployee && (
                  <SpsFeedbackBlock kind="info">
                    As an SPS employee, password management is handled by
                    changing your network password.
                  </SpsFeedbackBlock>
                )}
                <div
                  className={clsx("sps-row", {
                    "sps-employee": isSpsEmployee
                  })}
                >
                  <div className="col-6">
                    <SpsLabel
                      for={form.get("password")}
                      errors={() =>
                        (form.get("password").hasError("required") &&
                          "This field is required.") ||
                        (form.get("password").hasError("pattern") &&
                          "Please enter a valid password")
                      }
                    >
                      Current Password
                    </SpsLabel>
                    <SpsTextInput
                      type="password"
                      formControl={form.get("password")}
                      disabled={isSpsEmployee}
                    />
                  </div>
                  <div className="col-6">
                    <SpsLabel
                      for={form.get("new_password")}
                      errors={() =>
                        (form.get("new_password").hasError("required") &&
                          "This field is required.") ||
                        (form.get("new_password").hasError("pattern") &&
                          "Please enter a valid password")
                      }
                    >
                      New Password
                    </SpsLabel>
                    <SpsTextInput
                      type="password"
                      formControl={form.get("new_password")}
                      disabled={isSpsEmployee}
                    />
                    <PatternMatcher
                      passwordPolicy={passwordPolicy}
                      password={form.getValue().new_password}
                    />
                  </div>
                </div>
              </>
            </SpsCard>
          </SpsForm>
        </div>
        <Footer/>
      </div>
    </>
  );
}

export default ProfilePassword;
