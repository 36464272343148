import React, { Fragment } from "react";

import { SpsPageTitle, SpsCard } from "@spscommerce/ds-react/";
import { withCommercePlatform } from "@spscommerce/ui-react";

import config from "../../App.config";
import SchemaDocument from "../SchemaDocument/SchemaDocument";
import DownloadLink from "../../components/DownloadLink/DownloadLink";

import "./EssentialSchemaDocument.scss";

const EssentialSchemaDocument = function (props) {
    const token = props.commercePlatform.token;

    const urlIsFullyQualified = (url) => {
        return url.startsWith("http://") || url.startsWith("https://");
    };

    const getFullSchemaUrl = (url) => {
        if (urlIsFullyQualified(url)) {
            return url;
        } else {
            const env = props.commercePlatform.environment;
            const host = config.kubeRoot[env];
            return `${host}${url}`;
        }
    };

    return (
        <Fragment>
            <SpsPageTitle className="col-12 home-page__heading">{props.title}</SpsPageTitle>
            <SpsCard className="overview-margin">
                <h4>Overview</h4>
                <p>{props.description}</p>
                <div className="sps-row-layout">
                    {props.downloadLinks.map((section, index) => {
                        return (
                            <SpsCard key={index}>
                                {section.map((link, index) => {
                                    return (
                                        <DownloadLink
                                            url={getFullSchemaUrl(link.url)}
                                            text={link.linkText}
                                            icon={link.icon}
                                            token={token}
                                            fileName={link.fileName}
                                            prettyPrintJSON={true}
                                            key={index}
                                        />
                                    );
                                })}
                            </SpsCard>
                        );
                    })}
                </div>
            </SpsCard>
            <SchemaDocument
                schemaUrl={props.schemaUrl}
                needsAuth={true}
                requestType="GET"
            ></SchemaDocument>
        </Fragment>
    );
};

export default withCommercePlatform(EssentialSchemaDocument);
