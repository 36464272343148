import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import * as queryString from "query-string";
import get from "lodash/get";
import axios from "axios";

import {
    SpsButton,
    SpsCheckbox,
    SpsSelect,
    SpsForm,
    formGroup,
    formControl,
    useForm,
} from "@spscommerce/ds-react";
import { withCommercePlatform } from "@spscommerce/ui-react";

import LoginCardSupplyPike from "../LoginCardSupplyPike";

const SupplyPikeConsent = function ({ location, commercePlatform }) {
    const [orgs, setOrgs] = useState();
    // const [hasAuthedApp, setHasAuthedApp] = useState(); // Not using for now.
    const [state, setState] = useState();
    const [selectedOrg, setSelectedOrg] = useState();
    // const [appName, setAppName] = useState(); // Not using for now.
    const [orgInfo, setOrgInfo] = useState();
    const [partnersList, setPartnersList] = useState();
    const form = useForm(
        formGroup({
            isAuthorize: formControl(false),
        }),
    );

    useEffect(() => {
        document.body.classList.add("sps-wallpaper");
        // state is before the hash
        const searchParams = queryString.parse(window.location.href.split("#")[0].split("?")[1]);
        setState(searchParams.state);
        // setHasAuthedApp(searchParams.has_authed_app === "true");
        fetchOrgInfo();
        return () => {
            document.body.classList.remove("sps-wallpaper");
        };
    }, []);

    function parsePartners(orgInfo) {
        const originalPartnersList = get(orgInfo, "consent_requested.partners");
        if (originalPartnersList) {
            if (originalPartnersList.length === 1) {
                setPartnersList(`${originalPartnersList[0].name}.`);
            } else if (originalPartnersList.length === 2) {
                const lastPartner = originalPartnersList.pop();
                const partners = originalPartnersList.map((p) => p.name).join(", ");
                setPartnersList(`${partners} and ${lastPartner.name}.`);
            } else if (originalPartnersList.length > 2) {
                const lastPartner = originalPartnersList.pop();
                const partners = originalPartnersList.map((p) => p.name).join(", ");
                setPartnersList(`${partners}, and ${lastPartner.name}.`);
            }
        }
    }

    async function fetchOrgInfo() {
        const appDomain =
            commercePlatform.environment === "test"
                ? "https://api.test.spsapps.net"
                : "https://api.spscommerce.com";
        const url = `${appDomain}/devcenter-auth0/redirect/v1/login-organizations-cache`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        // key is after the hash
        const body = {
            key: queryString.parse(window.location.href.split("#")[1].split("?")[1]).key,
        };
        try {
            const orgInfo = await axios.post(url, body, config);
            parsePartners(orgInfo.data);
            setOrgInfo(orgInfo.data);

            if (orgInfo.data.org_list.length === 1) {
                setSelectedOrg(orgInfo.data.org_list[0]);
            } else {
                setOrgs(orgInfo.data.org_list);
            }
        } catch (err) {
            console.error(err);
        }
    }

    async function authorizeApp() {
        const appDomain =
            commercePlatform.environment === "test"
                ? "https://auth.test.spsapps.net"
                : "https://auth.spscommerce.com";
        window.location.replace(
            `${appDomain}/continue?state=${state}&org_id=${selectedOrg.org_id}`,
        );
    }

    async function doNotAuthorizeApp() {
        // const appDomain =
        //     commercePlatform.environment === "test"
        //         ? "https://auth.test.spsapps.net"
        //         : "https://auth.spscommerce.com";

        const redirectUri = get(orgInfo, "redirect_uri", "#");
        window.location.replace(
            `${redirectUri}?error=access_denied&error_description=User%20did%20not%20authorize%20the%20request`,
        );
    }

    function handleOrgChange(event) {
        setSelectedOrg(event.target.value);
    }

    return (
        <LoginCardSupplyPike bodyClass="supply-pike">
            <SpsForm formGroup={form}>
                <h1 className="mb-3">Authorize SupplyPike</h1>
                <div className="mb-2 fs-14">
                    <p className="fs-14 mb-3">
                        By authorizing access to your account, SupplyPike will be able to:
                    </p>
                    {partnersList && (
                        <>
                            <div className="supply-pike__label">View retailer data</div>
                            <p className="fs-12 lh-14 mb-2">{partnersList}</p>
                        </>
                    )}
                    <div className="supply-pike__label">View document data</div>
                    <p className="fs-12 lh-14 mb-2">
                        This includes credit/debit adjustments, invoices, orders, and remittances.
                    </p>
                    {orgs && (
                        <>
                            <div className="supply-pike__label">View data from</div>
                            <SpsSelect
                                className="mb-2"
                                options={orgs}
                                textKey="org_name"
                                onChange={handleOrgChange}
                                placeholder="Select an organization"
                            />
                        </>
                    )}
                </div>
                <div className="supply-pike__checkbox">
                    <SpsCheckbox name="isAuthorize" formControl={form.get("isAuthorize")} />
                    <p>
                        I agree to the
                        <a
                            href={get(orgInfo, "agreement_terms_url", "#")}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {" "}
                            Third Party Consent{" "}
                        </a>
                        terms.
                    </p>
                </div>
                <div className="supply-pike__btn">
                    <SpsButton onClick={doNotAuthorizeApp} type="submit" data-testid="form-cancel">
                        Cancel
                    </SpsButton>
                    <SpsButton
                        kind="confirm"
                        data-testid="form-authorize"
                        disabled={
                            (orgs ? selectedOrg === undefined : false) ||
                            !form.get("isAuthorize").value
                        }
                        onClick={authorizeApp}
                    >
                        Continue
                    </SpsButton>
                </div>
            </SpsForm>
        </LoginCardSupplyPike>
    );
};

export default withCommercePlatform(withRouter(SupplyPikeConsent));
