import React, { Fragment, useState, useEffect } from "react";
// import { NavLink } from "react-router-dom";
import PatternMatcher from "../../../components/PatternMatcher";
import axios from "axios";
import { parse } from "query-string";
import { validateAll } from "../../../utils";
import { SpsButton } from "@spscommerce/ds-react";
import {
    SpsGrowlerFactory,
    SpsTextInput,
    // SpsCheckbox
} from "@spscommerce/ui-react";
import SpsPasswordInput from "../../../components/SpsPasswordInput";
import get from "lodash/get";
import "./CreateUser.scss";

export default function CreateUser(props) {
    const {
        commercePlatform: { token, auth, identityServiceUrl },
    } = props;
    const [form, setForm] = useState({
        firstName: "",
        lastName: "",
        password: "",
        // Email is read-only but included for ease of access
        email: "",
    });
    // const [termsAccepted, setTermsAcceptedStatus] = useState(false);
    const [success, setSuccess] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [passwordPolicy, setPasswordPolicy] = useState(null);
    const [isVisible, setVisible] = useState(false);

    useEffect(() => {
        if (!passwordPolicy) {
            fetchPasswordPolicy()
                .then((resp) => {
                    setPasswordPolicy(buildPolicyObject(resp.data.data.regex_policy));
                })
                .catch((err) => {
                    const msg = get(err, "response.data.error.errorDescription")
                        ? get(err, "response.data.error.errorDescription")
                        : "An error has occurred when fetching the password policy";
                    const title = get(err, "response.data.error.error")
                        ? get(err, "response.data.error.error")
                        : "Error!";
                    SpsGrowlerFactory.error(msg, {
                        preset: "error",
                        timeoutDuration: 0,
                        fade: false,
                        title: title,
                    });
                });
        }
    }, [token]);

    useEffect(() => {
        checkForm();
        // }, [form, termsAccepted]);
    }, [form]);

    useEffect(() => {
        fetchInviteDetails()
            .then((resp) => {
                const { first_name, last_name, email } = resp.data;
                setForm({ firstName: first_name, lastName: last_name, email });
            })
            .catch((err) => {
                const msg = get(err, "response.data.error.errorDescription")
                    ? get(err, "response.data.error.errorDescription")
                    : "An error has occurred when fetching the password policy";
                const title = get(err, "response.data.error.error")
                    ? get(err, "response.data.error.error")
                    : "Error!";
                SpsGrowlerFactory.error(msg, {
                    preset: "error",
                    timeoutDuration: 0,
                    fade: false,
                    title: title,
                });
            });
    }, []);

    function fetchPasswordPolicy() {
        const url = `${identityServiceUrl}/identity/v2/users/password-policy/`;
        return axios.get(url);
    }

    function fetchInviteDetails() {
        // Look up user information for the unverified user based on their signed invite
        const queryParams = parse(window.location.search);
        const url = `${identityServiceUrl}/identity/v3/users/invitations/unauthed/${queryParams.signed_invite}/`;
        return axios.get(url);
    }

    async function updateUser() {
        try {
            const url = `${identityServiceUrl}/identity/v3/users/invitations/verify/`;
            const queryParams = parse(window.location.search);
            const body = {
                first_name: form.firstName,
                last_name: form.lastName,
                password: form.password,
                signed_invite: queryParams.signed_invite,
            };
            await axios.patch(url, body);
            setSuccess(true);
        } catch (err) {
            const msg = get(err, "response.data.error.errorDescription")
                ? get(err, "response.data.error.errorDescription")
                : "An error has occurred when creating user.";
            const title = get(err, "response.data.error.error")
                ? get(err, "response.data.error.error")
                : "Error!";
            SpsGrowlerFactory.error(msg, {
                preset: "error",
                timeoutDuration: 0,
                fade: false,
                title: title,
            });
        }
    }

    function buildPolicyObject(policies) {
        return Object.keys(policies).map((key) => {
            return Object.assign({}, policies[key], { name: key });
        });
    }

    function handleInputChange(e) {
        const field = { [e.target.name]: e.target.value };
        const updatedForm = { ...form, ...field };
        setForm(updatedForm);
    }

    // function handleCheckboxChange() {
    //   setTermsAcceptedStatus(!termsAccepted);
    // }

    function checkForm() {
        if (fieldsValid()) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }

    function fieldsValid() {
        if (validatePassword()) {
            const formFields = Object.values(form);
            return formFields.every((i) => {
                return i.length > 0;
            });
        }
        return false;
    }

    function validatePassword() {
        // returns true if password meets all criteria
        return validateAll(passwordPolicy, form.password);
    }

    function login() {
        auth.login({ appState: "#/home" });
    }

    function toggleVisiblity() {
        isVisible ? setVisible(false) : setVisible(true);
    }

    return (
        <Fragment>
            {!success ? (
                <Fragment>
                    <h1 className="login-content-body__header">Finalize your account</h1>
                    <div className="login-content-body__description">
                        Welcome, {form.firstName}! Please complete this form to finish creating your
                        trial account for Dev Center.
                    </div>
                    <SpsTextInput
                        id="firstName"
                        name="firstName"
                        inputLabel="First Name"
                        value={form.firstName}
                        onChange={handleInputChange}
                        required
                    />
                    <SpsTextInput
                        id="lastName"
                        name="lastName"
                        inputLabel="Last Name"
                        value={form.lastName}
                        onChange={handleInputChange}
                        required
                    />
                    <div className="login-content-body__label" style={{ marginTop: -10 }}>
                        <div className="sps-form-group--required">
                            <label className="login-content-body__form-label sps-form-group__label mt-2">
                                Password*
                            </label>
                        </div>
                        <SpsButton onClick={toggleVisiblity} kind="link">
                            {isVisible ? "Hide Password" : "Show Password"}
                        </SpsButton>
                    </div>
                    <SpsPasswordInput
                        className="sps-form-control login-content-body__form-input"
                        id="password"
                        name="password"
                        value={form.password}
                        onChange={handleInputChange}
                        visible={isVisible}
                        required
                    />

                    <PatternMatcher passwordPolicy={passwordPolicy} password={form.password} />
                    {/* commenting this and all related lines until CAF-594 is resolved */}
                    {/* <div className="login-content-body__form-checkbox create-user-accept-terms__checkbox">
            <SpsCheckbox
              id="acceptTerms"
              name="sps_checkbox"
              checked={termsAccepted}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor="acceptTerms"
              className="login-content-body__form-checkbox-label sps-form-group__label "
            >
              I agree to the{" "}
              <NavLink to="/user-agreement" target="_blank">
                Terms of Use
              </NavLink>
            </label>
          </div> */}
                    <div className="login-content-body__form-buttons">
                        <SpsButton
                            style={{ width: "100%" }}
                            className="login-content-body__form-submit"
                            kind="confirm"
                            onClick={updateUser}
                            disabled={disabled}
                        >
                            Finalize Trial Account
                        </SpsButton>
                    </div>
                </Fragment>
            ) : (
                <Fragment>
                    <div className={"sps-account-finalized"}>
                        <img src="assets/password-reset-complete.svg" alt="SPS Commerce" />
                        <h1>Account finalized</h1>
                        <p>You're ready to go! Click the button below to log in to Dev Center.</p>
                        <div className="login-content-body__form-buttons">
                            <SpsButton
                                onClick={login}
                                className="login-content-body__form-submit btn"
                                kind="confirm"
                            >
                                Sign In
                            </SpsButton>
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
}
