import React, { useEffect, useState } from "react";
import { parse } from "query-string";
import axios from "axios";
import { Link } from "react-router-dom";

import { SpsButton, usePatchReducer } from "@spscommerce/ds-react";
import { withCommercePlatform } from "@spscommerce/ui-react";

import SpsPasswordInput from "../../../components/SpsPasswordInput";
import PatternMatcher from "../../../components/PatternMatcher";
import { validateAll } from "../../../utils";

export function CreateNewPasswordWithoutCommercePlatform(props) {
    const [state, patchState] = usePatchReducer({
        requestMade: false,
        password: "",
        disabled: true,
        passwordPolicy: null,
        requestErred: false,
    });
    const [isVisible, setVisible] = useState(false);

    useEffect(() => {
        const {
            commercePlatform: { identityServiceUrl },
        } = props;
        if (identityServiceUrl) {
            fetchPasswordPolicy();
        }
    }, [props]);

    useEffect(() => {
        validatePassword();
    }, [state.password]);

    async function createNewPassword() {
        const {
            commercePlatform: { identityServiceUrl },
            location: { search },
        } = props;
        const searchParams = parse(search);
        const url = `${identityServiceUrl}/identity/users/reset-password/`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const body = {
            email_address: searchParams.email_address,
            reset_code: searchParams.reset_code,
            new_password: state.password,
        };
        try {
            await axios.post(url, body, config);
            patchState({ requestMade: true });
        } catch (err) {
            console.error(err);
            patchState({ requestMade: true, requestErred: true });
        }
    }

    async function fetchPasswordPolicy() {
        const {
            commercePlatform: { identityServiceUrl },
        } = props;
        const url = `${identityServiceUrl}/identity/v2/users/password-policy/`;
        try {
            const passwordPolicyResponse = await axios.get(url);
            const policyObject = passwordPolicyResponse.data.data.regex_policy;
            const polices = Object.keys(policyObject).map((key) => {
                return Object.assign({}, policyObject[key], { name: key });
            });
            patchState({ passwordPolicy: polices });
        } catch (err) {
            console.error(err);
        }
    }

    function handleInputChange(e) {
        patchState({ [e.target.name]: e.target.value });
    }

    function validatePassword() {
        patchState({
            disabled: !validateAll(state.passwordPolicy, state.password),
        });
    }

    function login() {
        const {
            commercePlatform: { auth },
        } = props;
        auth.login({ appState: "#/home" });
    }

    function toggleVisiblity() {
        isVisible ? setVisible(false) : setVisible(true);
    }

    return (
        <>
            {!state.requestMade ? (
                <>
                    <h1 className="login-content-body__header">Create a new password</h1>
                    <p className="fs-14">
                        If you did not request a password reset, stop this process and{" "}
                        <Link to="/contact-info?contactSupport=true"> contact SPS support</Link>.
                    </p>
                    <div className="login-content-body__label">
                        <div className="sps-form-group--required">
                            <label className="login-content-body__form-label sps-form-group__label mt-2">
                                Password*
                            </label>
                        </div>
                        <SpsButton onClick={toggleVisiblity} kind="link">
                            {isVisible ? "Hide Password" : "Show Password"}
                        </SpsButton>
                    </div>
                    <SpsPasswordInput
                        className="sps-form-control login-content-body__form-input"
                        value={state.password}
                        name="password"
                        onChange={handleInputChange}
                        visible={isVisible}
                    />
                    <PatternMatcher
                        passwordPolicy={state.passwordPolicy}
                        password={state.password}
                    />
                    <div className="login-content-body__form-buttons">
                        <SpsButton
                            style={{ width: "100%" }}
                            className="login-content-body__form-submit"
                            kind="confirm"
                            onClick={createNewPassword}
                            disabled={state.disabled}
                        >
                            Reset Password
                        </SpsButton>
                    </div>
                </>
            ) : (
                <>
                    {!state.requestErred ? (
                        <>
                            <div className="password-reset-complete">
                                <img src="assets/password-reset-complete.svg" alt="SPS Commerce" />
                                <h1 className="mb-0">Password reset complete</h1>
                                <p>
                                    You’re ready to go! Click the button below to sign in to Dev
                                    Center.
                                </p>
                                <div className="login-content-body__form-buttons mt-0">
                                    <SpsButton
                                        onClick={login}
                                        className="login-content-body__form-submit btn"
                                        kind="confirm"
                                    >
                                        Sign In
                                    </SpsButton>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="password-reset-complete">
                                <img src="assets/password-reset-error.svg" alt="SPS Commerce" />
                                <h1 className="mb-0">Oops! We’re having a problem on our end.</h1>
                                <p>
                                    Please try again later or{" "}
                                    <Link to="/contact-info?contactSupport=true">
                                        {" "}
                                        contact SPS support{" "}
                                    </Link>{" "}
                                    if the problem persists.
                                </p>
                                <div className="login-content-body__form-buttons mt-0">
                                    <SpsButton kind="link" href="#/login/reset-password">
                                        Try to Reset Password Again
                                    </SpsButton>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
}

export const CreateNewPassword = withCommercePlatform(CreateNewPasswordWithoutCommercePlatform);
