import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import "./IconCard.scss";

export default function IconCard(props) {
    const { to, variant } = props;

    const renderIconCard = (props) => {
        const { icon, to, children, variant } = props;
        const iconCardClasses = classnames("icon-card", {
            "icon-card--small": variant === "small",
        });
        return (
            <div className={iconCardClasses}>
                <div className="icon-card-container-icon">
                    {to && variant !== "small" ? (
                        <Link to={to} className="icon-card-link">
                            <div className="inner">
                                <div className="circle">
                                    {icon ? <i className={`${icon} circle-icon`} /> : null}
                                </div>
                            </div>
                        </Link>
                    ) : (
                        <div className="inner">
                            <div className="circle">
                                {icon ? <i className={`${icon} circle-icon`} /> : null}
                            </div>
                        </div>
                    )}
                </div>
                {children}
            </div>
        );
    };

    return (
        <React.Fragment>
            {variant === "small" ? (
                <Link to={to} className="icon-card-link">
                    {renderIconCard(props)}
                </Link>
            ) : (
                renderIconCard(props)
            )}
        </React.Fragment>
    );
}
