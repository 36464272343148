import React from "react";
import { Link } from "react-router-dom";

import { SpsCard, SpsButton } from "@spscommerce/ds-react/";

import "./CreateAppButtonCard.scss";

export default function CreateAppButtonCard(props) {
    const { onClickFodal } = props;

    return (
        <SpsCard className="create-app-card">
            <Link to={`/app`}>
                <span className="create-app-card__body">
                    <img src="assets/build-icon.svg" className="create-app-card__icon" alt="" />
                    <span className="create-app-card__container-header">
                        <span className="sps-text-bold create-app-card__text--header">
                            Build An App
                        </span>

                        <span className="sps-text-light create-app-card__text--light">
                            You haven’t built any Apps or been granted access.
                        </span>

                        <SpsButton
                            className="create-app-card__button"
                            kind="confirm"
                            onClick={onClickFodal}
                        >
                            Create App
                        </SpsButton>
                    </span>
                </span>
            </Link>
        </SpsCard>
    );
}
