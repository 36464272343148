import { Search as ElementsSearch, useGetNodes } from '@stoplight/elements-dev-portal';
import React, { useState, useEffect } from 'react';
import "./SearchStoplight.scss";

const docsPath = "stoplight-docs";

export function SearchStoplight({ services }) {
    const [projectIds, setProjectIds] = useState([]);
    const [search, setSearch] = useState("");
    const [open, setOpen] = useState(false);
    const { data } = useGetNodes({
        search,
        projectIds,
    });

    useEffect(() => {
        if(services) {
            setProjectIds(services.map( service => service.stoplightProjectId));
        }
    }, [services]);

    const handleClose = () => {
        setOpen(false);
        setSearch("");
    };

    const handleClick = (data) => {
        const serviceSlug = services.find(service => service.stoplightProjectId === data.project_id).slug;
        window.location.href = `/#/${docsPath}/${serviceSlug}/${data.slug}`;
        handleClose();
    };

    return (
        <div className="stoplight-search">
            <input placeholder="Search..." onFocus={() => setOpen(true)} />
            <ElementsSearch
                search={search}
                onSearch={setSearch}
                onClick={handleClick}
                onClose={handleClose}
                isOpen={open}
                searchResults={data}
            />
        </div>
    );
};

export default SearchStoplight;
