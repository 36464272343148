import React from "react";
import FieldsetQualifier from "../../../components/FieldsetQualifier/FieldsetQualifier";
import { SpsCard } from "@spscommerce/ds-react";
import Footer from "../../../Footer";

const FieldAndQualifierListPage = function (props) {
  return (
    <div className="col-9 docs-main-content">
      <div className="schema-download__wrapper">
        <SpsCard>
          <FieldsetQualifier
            heading="Field and Qualifier List"
            description="You can access the certified RSX schemas qualifier list (approved for Universal Network eligibility) for a specific set of documents, tailored to an Industry and Fulfillment Model or access the full schema qualifier list."
          />
        </SpsCard>
      </div>
      <Footer/>
    </div>
  );
};

export default FieldAndQualifierListPage;
