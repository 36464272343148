import "./SchemaDownloadPage.scss";

import React, { useEffect, useState } from "react";

import axios from "axios";
import { isEmpty } from "lodash";
import ReactPlaceholder from "react-placeholder";
import { Link } from "react-router-dom";

import {
    SpsButton,
    SpsCard,
    SpsFeedbackBlock,
    SpsListActionBar,
    SpsPageTitle,
    SpsSelect,
    SpsTextInput,
    SpsToggle,
    SpsZeroState,
    useGrowlers,
} from "@spscommerce/ds-react";
import { withCommercePlatform } from "@spscommerce/ui-react";
import { getPath } from "@spscommerce/utils";

import AppConfig from "../../App.config";
import { MarkdownPlaceHolder } from "../../components/Placeholders";
import Footer from "../../Footer";
import { groupInternalAssets } from "../../utils";
import SchemaDownloadRow from "./SchemaDownloadRow";

const fileFormats = ["CSV", "JSON", "Positional", "XML"];
const defaultSelectedFormat = "XML";

const sortByDoc = ["Most Used to Least Used", "Document Name: A-Z", "Document Name: Z-A"];

const schemaTypes = ["Show Network Schemas", "Show Qualified Schemas"];

const SchemaDownloadPage = function (props) {
    const { environment, commercePlatform, csvUrl } = props;
    const mostUsed = AppConfig.rsx["documentOrderForNewerVersions"];
    const localDocNumbers = AppConfig.rsx["documentNumbers"];
    const { currentUser: user } = commercePlatform;

    const [schemas, setSchemas] = useState({});
    const [visibleDocs, setVisibleDocs] = useState({});
    const [selectedDocs, setSelectedDocs] = useState([]);
    const [selectedformat, setSelectedformat] = useState(defaultSelectedFormat);
    const [loading, setloading] = useState(false);
    const [groupedDocs, setGroupedDocs] = useState({});
    const [docNumbers, setDocNumbers] = useState({});
    const [showLegacy, setShowLegacy] = useState(false);
    const [schemaType, setSchemaType] = useState(schemaTypes[0]);

    const [growlers, createGrowler] = useGrowlers();

    useEffect(() => {
        getSchemasNames(schemaType);
        setSelectedformat(defaultSelectedFormat);
        getDocNumber();
    }, [schemaType]);

    useEffect(() => {
        setVisibleDocs(schemas);
    }, [schemas]);

    function changeHandler() {
        setShowLegacy(!showLegacy);
    }

    /* Commenting checkbox until the bulk download is figured out */
    // function handleToolbarSelectionChange(event) {
    //   if (event.target.checked) {
    //     const keys = Object.keys(visibleDocs);
    //     setSelectedDocs(alreadySelectedDocs => [
    //       ...alreadySelectedDocs,
    //       ...keys.map(doc => {
    //         return {
    //           docName: doc,
    //           version: visibleDocs[doc][0]
    //         };
    //       })
    //     ]);
    //   } else {
    //     setSelectedDocs([]);
    //   }
    // }

    function isInternalUser(user) {
        return (
            getPath(user, "organization?.namespace") &&
            user.roles.includes("network-toolbox:user") &&
            (user.organization.namespace === "sps" || user.organization.namespace === "spsc")
        );
    }

    function findDoc(docNumber, searchTerm, doc) {
        return Object.keys(docNumber).some((key) => {
            if (
                key === "docTypes" &&
                docNumber["name"] === doc &&
                docNumber[key].toString().includes(searchTerm)
            ) {
                return true;
            }
            if (
                (Array.isArray(docNumber[key]) || typeof docNumber[key] === "object") &&
                docNumber[key] !== null
            ) {
                return findDoc(docNumber[key], searchTerm, doc);
            }
            return false;
        });
    }

    function handleChange(event) {
        const docs = Object.keys(schemas);
        let searchTerm = event.target.value;
        if (searchTerm && searchTerm !== "") {
            const searchResult = docs
                .filter((doc) => {
                    const docNums = localDocNumbers[doc.split(".")[0]];
                    return (
                        doc.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        (docNums && docNums.toLowerCase().includes(searchTerm.toLowerCase())) ||
                        findDoc(docNumbers, searchTerm.toString().toUpperCase(), doc.split(".")[0])
                    );
                })
                .reduce((obj, key) => {
                    obj[key] = schemas[key];
                    return obj;
                }, {});

            !isEmpty(searchResult) ? setVisibleDocs(searchResult) : setVisibleDocs([]);
        }
        if (searchTerm === "" || searchTerm == null) {
            setVisibleDocs(schemas);
        }
    }

    function handleSortDocs(event) {
        const sortType = event.target.value;
        function sortObjectInAsc(visibleDoc) {
            return Object.keys({ ...(visibleDoc ? visibleDoc : {}) })
                .sort()
                .reduce(function (res, key) {
                    res[key] = visibleDoc[key];
                    return res;
                }, {});
        }

        function sortObjectInDesc(visibleDoc) {
            return Object.keys({ ...(visibleDoc ? visibleDoc : {}) })
                .sort()
                .reverse()
                .reduce(function (res, key) {
                    res[key] = visibleDoc[key];
                    return res;
                }, {});
        }

        function sortMostUsed(visibleDoc) {
            const sortingArray = [...mostUsed].reverse();
            return Object.keys(visibleDoc)
                .sort(function (a, b) {
                    return (
                        sortingArray.indexOf(b.split(".")[0]) -
                        sortingArray.indexOf(a.split(".")[0])
                    );
                })
                .reduce(function (res, key) {
                    const visible = visibleDoc[key];
                    if (visible) res[key] = visibleDoc[key];
                    return res;
                }, {});
        }

        switch (sortType) {
            case "Document Name: A-Z":
                setVisibleDocs(sortObjectInAsc(visibleDocs));
                break;

            case "Document Name: Z-A":
                setVisibleDocs(sortObjectInDesc(visibleDocs));
                break;

            default:
                setVisibleDocs(sortMostUsed(visibleDocs));
                break;
        }
    }

    function handleFormatChange(event) {
        const format = event.target.value;
        setSelectedformat(format);
        if (format === "CSV") {
            setSchemas(AppConfig.rsx["csvDocuments"][schemaType === schemaTypes[0] ? "networkSchemas" : "qualifiedSchemas"]);
        } else if (format === "Positional") {
            setSchemas(AppConfig.rsx["positionalCsvDocuments"][schemaType === schemaTypes[0] ? "networkSchemas" : "qualifiedSchemas"]);
        } else {
            setSchemas(groupedDocs);
        }
    }

    const headers = {
        Authorization: `Bearer ${commercePlatform ? commercePlatform.token : ""}`,
        "X-SPS-Service": `${AppConfig.devCenterUI.name}:${AppConfig.devCenterUI.id}`,
    };

    const getDocNumber = async () => {
        try {
            const url = `${AppConfig.rsx[environment]}/v1/schema_document_relationship/rsx/`;
            const apiConfig = {
                method: "get",
                headers,
                url,
            };
            const response = await axios(apiConfig);
            setDocNumbers(response.data);
        } catch (error) {
            createGrowler({
                kind: "error",
                title: "An error has occurred while fetching doc number",
                content: () =>
                    "An error has occurred while fetching doc number, please try again later.",
            });
        }
    };

    const getSchemasNames = async (schemaType) => {
        setloading(true);
        try {
            const url = `${AppConfig.rsx[environment]}/v1/assets/`;
            const apiConfig = {
                method: "get",
                headers,
                url,
            };

            let groupedDocs = {};

            // Show internal assets to SPS users, otherwise show all the docs.
            if (isInternalUser(user)) {
                apiConfig.url = `${AppConfig.rsx[environment]}/v2/internal/assets/`;
            }
            const response = await axios(apiConfig);
            groupedDocs = groupInternalAssets(response.data, schemaType === schemaTypes[0]);
            setGroupedDocs(groupedDocs);
            setSchemas(groupedDocs);
        } catch (error) {
            createGrowler({
                kind: "error",
                title: "An error has occurred while fetching schema names",
                content: () =>
                    "An error has occurred while fetching schema names, please try again later.",
            });
        } finally {
            setloading(false);
        }
    };

    function handleRowSelect(event, docName, version) {
        if (event.target.checked) {
            let selectedObj = {};
            selectedObj["docName"] = docName;
            selectedObj["version"] = version;
            setSelectedDocs((docs) => [...docs, selectedObj]);
        } else {
            setSelectedDocs((docs) => {
                const updatedselectedDocs = docs.filter((doc) => doc.docName !== docName);
                return updatedselectedDocs;
            });
        }
    }

    function handleClear() {
        setSelectedDocs([]);
    }

    const versionListObj = (versionList) => versionList.map((v, i) => ({ label: schemaType === schemaTypes[0] ? parseFloat(v) : i ===0 ? `${v} (latest)` : v, value: v }));

    const placeholderClass = !visibleDocs ? "schema-download-placeholder-container" : "";

    return (
        <>
            {growlers()}
            <div className="col-9 docs-main-content">
                <div className="schema-download__wrapper">
                    <div className="d-flex justify-content-between">
                        <SpsPageTitle className="mb-3">Schema Downloads</SpsPageTitle>
                        <SpsSelect
                            id="debounce-select"
                            options={schemaTypes}
                            value={schemaTypes[0]}
                            onChange={(event) => setSchemaType(event.target.value)}
                            searchDebounce={200}
                            notClearable
                            searchPlaceholder="Find a version"
                            className="w-25"
                        />
                    </div>
                    {getPath(user, "organization?.namespace") &&
                        (user.organization.namespace === "sps" ||
                            user.organization.namespace === "spsc") &&
                        (user.roles.includes("network-toolbox:user") ? (
                            <SpsFeedbackBlock kind="info">
                                Schema version 7.1.0 is only available to internal users.
                            </SpsFeedbackBlock>
                        ) : (
                            <SpsFeedbackBlock kind="info">
                                Schema version 7.1.0 is available on Dev Center. To get access,
                                reach out to the Service Desk and get Network Toolbox added to your
                                account.
                            </SpsFeedbackBlock>
                        ))}
                    <SpsCard className="mb-3">
                        <p>
                            Schemas contain the data structure and element definitions of the SPS
                            Commerce Network. After you install a schema, you can use the included
                            Examples to validate your setup. Visit the{" "}
                            <Link to="/rsx/docs/example-download">Example Files</Link> page to
                            download production-like data. We recommend using Network Schemas for
                            implementation, and Qualified Schemas only as a reference.
                        </p>
                        <p>
                            For additional information on flat files, visit the{" "}
                            <Link to="/rsx/docs/using-flat-files">Using Flat Files</Link> page. To
                            learn more about EDI documents, visit SPS Commerce’s{" "}
                            <a href="https://www.spscommerce.com/resources/edi-documents-transactions/">
                                EDI Documents and Transactions
                            </a>{" "}
                            page.
                        </p>
                    </SpsCard>

                    <div className={placeholderClass}>
                        <div className="sps-summary-list-row sps-content-row" tabIndex="-1">
                            <div className="sps-content-row__col-group" tabIndex="-1">
                                {/* Commenting checkbox until the bulk download is figured out */}
                                {/* <div
                className="sps-content-row__col sps-content-row__col--lean"
                style={{ width: "2.8rem" }}
              >
                <SpsCheckbox
                  aria-label="Row Selected"
                  disabled={isDownloading}
                  onChange={handleToolbarSelectionChange}
                />
              </div> */}
                                <div className="sps-content-row__col">
                                    <div className="sps-form-group sps-text-input w-75">
                                        <SpsTextInput
                                            icon="filter"
                                            className="w-80"
                                            onChange={handleChange}
                                            placeholder="Filter for a specific document"
                                        />
                                    </div>
                                </div>
                                <div
                                    className="sps-content-row__col sps-content-row__col--leaner sps-content-row__col--borderless pr-0"
                                    style={{ width: "10rem" }}
                                >
                                    <SpsToggle
                                        activeDescription="Show legacy schemas"
                                        active={showLegacy}
                                        onChange={changeHandler}
                                        inactiveDescription="Show legacy schemas"
                                    />
                                </div>
                                <div
                                    className="sps-content-row__col sps-content-row__col--leaner pr-0"
                                    style={{ width: "3.1rem" }}
                                >
                                    <label className="sps-form-group__label mb-0">
                                        <span className="sps-form-group__label-content">
                                            Sort By
                                        </span>
                                    </label>
                                </div>
                                <div
                                    className="sps-content-row__col sps-content-row__col--leaner sps-content-row__col--borderless"
                                    style={{ width: "12rem", paddingLeft: "5px" }}
                                >
                                    <SpsSelect
                                        className="selectSortByDoc"
                                        options={sortByDoc}
                                        placeholder={sortByDoc[0]}
                                        value={sortByDoc[0]}
                                        notClearable
                                        onChange={handleSortDocs}
                                    />
                                </div>
                                <div
                                    className="sps-content-row__col sps-content-row__col--leaner sps-content-row__col--borderless"
                                    style={{ width: "7.125rem", paddingLeft: "5px" }}
                                >
                                    <div className="sps-form-group sps-select">
                                        <SpsSelect
                                            options={fileFormats}
                                            value={selectedformat}
                                            notClearable
                                            onChange={handleFormatChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ReactPlaceholder
                            customPlaceholder={<MarkdownPlaceHolder />}
                            ready={!loading}
                        >
                            {!isEmpty(visibleDocs) ? (
                                Object.keys(visibleDocs).map((objKey, index) => (
                                    <SchemaDownloadRow
                                        key={index}
                                        docName={objKey}
                                        versionList={
                                            versionListObj(visibleDocs[objKey].versions || visibleDocs[objKey])
                                        }
                                        commercePlatform={commercePlatform}
                                        environment={environment}
                                        createGrowler={createGrowler}
                                        handleRowSelect={handleRowSelect}
                                        selectedVersion={selectedDocs.filter(
                                            (item) => item.docName === objKey,
                                        )}
                                        format={selectedformat}
                                        url={`${csvUrl}files/`}
                                        isInternal={isInternalUser(commercePlatform.currentUser)}
                                        docNumbers={docNumbers}
                                        showLegacy={showLegacy}
                                        isBaseSchema={schemaType === schemaTypes[0]}
                                    />
                                ))
                            ) : (
                                <SpsCard className="mt-2">
                                    <SpsZeroState
                                        heading="No records found"
                                        artwork="https://cdn.prod.spsc.io/web/framework/assets/18.03.02/images/icon-error.svg"
                                    />
                                </SpsCard>
                            )}
                        </ReactPlaceholder>
                    </div>
                    <SpsListActionBar
                        itemsSelected={selectedDocs.length}
                        clearSelected={() => handleClear()}
                    >
                        <SpsButton className="mr-1 mb-1" icon="download-cloud" kind="link">
                            Download Schemas
                        </SpsButton>
                    </SpsListActionBar>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default withCommercePlatform(SchemaDownloadPage);
