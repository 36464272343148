import React, { useEffect, Fragment } from "react";
import ReactPlaceholder from "react-placeholder";
import axios from "axios";

import { usePatchReducer } from "@spscommerce/ds-react";

import { renderNavList } from "../CollapsibleItem";
import { TieredPlaceHolder } from "../Placeholders";
import { SideNavSection } from "../SideNav";
import config from "../../App.config";

const fetchTOC = async ({ docsRoot, tocPath = "", tocFilename = "toc.json" }) => {
    const tocUrl = docsRoot + tocFilename;
    try {
        const tocResponse = await axios.get(tocUrl);
        return tocResponse.data;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export default function TOC(props) {
    const { pathname, environment } = props;
    const [state, patchState] = usePatchReducer({
        toc: null,
        tocLoaded: false,
    });

    useEffect(() => {
        fetchData();
    }, [props]);

    function parseTitleRules(tocResult) {
        return tocResult.map((item) => {
            let rules = item.text.split(config.sidenavRulesCharacter);
            const isInternal = rules.includes(config.swaggerSidebarRules.INTERNAL);
            rules.shift();
            rules = rules.reduce(
                (acc, cur) => ({
                    ...acc,
                    [cur]: true,
                }),
                Object.create({}),
            );
            return {
                ...item,
                ...rules,
                ...(isInternal ? { link: item.link + "?internal=true" } : {}),
                ...(Array.isArray(item.children)
                    ? { children: parseTitleRules(item.children) }
                    : {}),
            };
        });
    }

    async function fetchData() {
        const { docsRoot } = props;
        if (docsRoot) {
            const tocResult = await fetchTOC({
                docsRoot,
            });
            patchState({
                toc: parseTitleRules(tocResult),
                tocLoaded: true,
            });
        }
    }

    return (
        <Fragment>
            <SideNavSection>
                <ReactPlaceholder customPlaceholder={<TieredPlaceHolder />} ready={state.tocLoaded}>
                    <Fragment>
                        {state.toc !== null
                            ? renderNavList(pathname, state.toc, 0, environment, props.docsRoot)
                            : null}
                    </Fragment>
                </ReactPlaceholder>
            </SideNavSection>
        </Fragment>
    );
}
