import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import ReactPlaceholder from "react-placeholder";
import { withCommercePlatform } from "@spscommerce/ui-react";
import { useGrowlers } from "@spscommerce/ds-react";

import { isExternal, stripTrailingSlash, makePath, swaggerParser } from "../../utils";
import { DocumentationSideLink } from "../DocumentationLink";
import { TieredPlaceHolder } from "../Placeholders";

export default function CollapsibleItem(props) {
    const { child, depth, pathname = "", environment } = props;
    const [expandedByUser, setExpandedByUser] = useState(false);

    useEffect(() => {
        if (
            child.links &&
            child.links.filter((l) => window.location.href.includes(l.split(".")[1]))[0]
        ) {
            const isswagger = child.links.filter((l) => l.split("?")[1]).length;
            if (
                isswagger &&
                !child.links.filter((l) => window.location.href.includes(l.split("?")[0])).length
            ) {
                return;
            }
            handleExpandedByUser();
        }
    }, [child]);

    useEffect(() => {
        const activeNavLink = document.querySelector("a.sps-side-nav__link.active");
        const el = document.querySelector(".page-banner");
        if (activeNavLink) {
            activeNavLink.scrollIntoView({
                block: "center",
                inline: "center",
            });
            el
                ? window.scrollTo({
                      top: el.offsetHeight + 1.5,
                      left: 0,
                      behavior: "smooth",
                  })
                : window.scrollTo(0, 0);
        }
    }, []);

    function handleExpandedByUser() {
        setExpandedByUser(true);
    }

    function handleCollapsedByUser() {
        setExpandedByUser(false);
    }

    function handleToggleByUser() {
        setExpandedByUser((prevExpandedByUser) => !prevExpandedByUser);
    }

    function isExpanded() {
        // pluck out the path to the md file
        const propsShortPath =
            "/" + stripTrailingSlash(props.pathname.split("/").slice(3).join("/")) + ".md";

        if (expandedByUser) {
            return true;
        }
        if (props.child.link === propsShortPath || props.child.links.indexOf(propsShortPath) > -1) {
            return true;
        }
    }

    return (
        <li>
            {child.children.length > 0 ? (
                isExpanded() ? (
                    <span className="side-nav__toggle opened" onClick={handleCollapsedByUser}>
                        <i className="sps-icon sps-icon-chevron-down" />
                    </span>
                ) : (
                    <span className="side-nav__toggle closed" onClick={handleExpandedByUser}>
                        <i className="sps-icon sps-icon-chevron-right" />
                    </span>
                )
            ) : null}
            {child.link ? (
                isExternal(child.link) ? (
                    <a
                        className="side-nav__leaf side-nav__leaf--external"
                        href={child.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {child.text}
                    </a>
                ) : (
                    <DocumentationSideLink
                        className="side-nav__leaf"
                        to={child.link || ""}
                        baselink={`/${pathname.split("/")[1]}`}
                        isswagger={child.swagger}
                        handleexpandedbyuser={handleExpandedByUser}
                    >
                        {child.text}
                    </DocumentationSideLink>
                )
            ) : (
                <span onClick={handleToggleByUser} className="side-nav__leaf">
                    {child.text}
                </span>
            )}
            {child.children && child.children.length > 0 && isExpanded()
                ? renderNavList(pathname, child.children, depth, environment, props.docsRoot)
                : null}
        </li>
    );
}

export const renderNavList = (pathname, node, depth = 0, env, docsRoot) => {
    depth++;
    return (
        <ul className={`side-nav__branch side-nav__branch--${depth}`}>
            {node.map((child, key) => {
                if (child.swagger) {
                    if (child[env]) {
                        return (
                            <Fragment key={child.text + child.link + child.children.length}>
                                {child.API ? (
                                    <CollapsibleItem
                                        pathname={pathname}
                                        docsRoot={docsRoot}
                                        child={child}
                                        depth={depth}
                                        environment={env}
                                    />
                                ) : (
                                    <SwaggerSideNavLinksWithCP
                                        pathname={pathname}
                                        child={child}
                                        link={child.link}
                                        env={env}
                                        docsRoot={docsRoot}
                                        depth={depth}
                                    />
                                )}
                            </Fragment>
                        );
                    } else {
                        return null;
                    }
                } else {
                    return (
                        <CollapsibleItem
                            key={child.text + child.link + child.children.length}
                            pathname={pathname}
                            docsRoot={docsRoot}
                            child={child}
                            depth={depth}
                            environment={env}
                        />
                    );
                }
            })}
        </ul>
    );
};

const SwaggerSideNav = ({
    pathname,
    link,
    env,
    docsRoot,
    depth,
    commercePlatform: { token },
    isAPI,
}) => {
    const [swaggerSideNavLinks, setSwaggerSideNavLinks] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const [growlers, createGrowler] = useGrowlers();

    useEffect(() => {
        getSwaggerData();
    }, []);

    async function getSwaggerData() {
        try {
            if (!isAPI && link && env) {
                setLoading(true);
                if (link && link.startsWith("../")) link = link.slice(3);
                if (link && link.startsWith("./")) link = link.slice(2);
                if (link && link.startsWith("/")) link = link.slice(1);
                if (link && !link.includes("http"))
                    link = makePath({
                        root: docsRoot || "",
                        path: link || "",
                        appendExtension: "",
                        defaultPath: "",
                    });
                if (link.endsWith(".")) link = link.slice(0, -1);
                const res = await axios.get(link, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const parsedData = await swaggerParser(res.data, null, null, link, env);
                setSwaggerSideNavLinks(parsedData);
            }
        } catch (e) {
            console.error(e);
            createGrowler({
                kind: "error",
                title: "An error has occurred while fetching the API document",
                content: () =>
                    "An error has occurred while fetching the API document, please try again later.",
            });
        } finally {
            setLoading(false);
        }
    }

    return (
        <ReactPlaceholder customPlaceholder={<TieredPlaceHolder />} ready={!loading}>
            {growlers()}
            {swaggerSideNavLinks &&
                swaggerSideNavLinks.map((item, i) => (
                    <CollapsibleItem
                        key={item.text + item.link + i}
                        pathname={pathname}
                        child={item}
                        depth={depth}
                        environment={env}
                    />
                ))}
        </ReactPlaceholder>
    );
};

const SwaggerSideNavLinksWithCP = withCommercePlatform(SwaggerSideNav);
