import React, { useEffect } from "react";

import axios from "axios";

import {
    SpsGrowler,
    usePatchReducer,
} from "@spscommerce/ds-react";

import { StaticMarkdownDocument } from "../MarkdownDocument";

export default function FetchedMarkdown(props) {
    const [state, patchState] = usePatchReducer({
        content: "",
        growlerIsRendered: false,
        growlerKind: "error",
        growlerTitle: "Page Load Error",
        growlerMessage: "An error has occurred while loading this page. Please try again later.",
    });

    useEffect(() => {
        fetchData();
    }, [props]);

    async function fetchData() {
        try {
            const response = await axios(props.url);
            patchState({ content: response.data });
        } catch (e) {
            patchState({ growlerIsRendered: true });
        }
    }

    return (
        <>
            <StaticMarkdownDocument
                authDocs={props.authDocs}
                markdown={state.content}
                url={props.url}
            />
            {state.growlerIsRendered && (
                <SpsGrowler
                    kind={state.growlerKind}
                    title={state.growlerTitle}
                    onClose={() => {
                        patchState({ growlerIsRendered: false });
                    }}
                >
                    {state.growlerMessage}
                </SpsGrowler>
            )}
        </>
    );
}
