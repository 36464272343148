import axios from "axios";
import get from "lodash/get";

import { AUTH_FLOW_OAUTH_IMPLICIT } from "@spscommerce/ui-react";

export default async function updateCurrentUser({
    identityServiceUrl,
    token,
    currentUser,
    refreshCurrentUser,
    setSubmitting = () => {},
    createGrowler,
}) {
    setSubmitting(true);
    const now = new Date().toISOString();
    const url = `${identityServiceUrl}/identity/v3/users/${currentUser.id}/profile/`;
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };
    const requestBody = { developer_center_tos_agreed_at: now };

    try {
        await axios.patch(url, requestBody, config);
        refreshCurrentUser(AUTH_FLOW_OAUTH_IMPLICIT);
    } catch (err) {
        const msg = get(err, "response.data.error.errorDescription")
            ? get(err, "response.data.error.errorDescription")
            : "An error has occurred when updating your user";
        const title = get(err, "response.data.error.error")
            ? get(err, "response.data.error.error")
            : "Error!";
        createGrowler({
            kind: "error",
            title: title,
            content: msg,
        });
    } finally {
        setSubmitting(false);
    }
}
