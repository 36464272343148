import React, { useState, useEffect } from "react";
import {
  formControl,
  formGroup,
  SpsButton,
  SpsButtonGroup,
  SpsCard,
  SpsFocusedTask,
  SpsLabel,
  SpsTextInput,
  SpsTextarea,
  SpsValidators,
  useForm,
  SpsForm
} from "@spscommerce/ds-react";
import { withCommercePlatform } from "@spscommerce/ui-react";
import { ButtonKind, ButtonType } from "@spscommerce/ds-shared";
import ReCAPTCHA from "react-google-recaptcha";
import SETTINGS from "../../App.config";
import "./ContactSupport.scss";

export function ContactSupport(props) {
  const { onClose, show, commercePlatform, infoCard } = props;
  const [isShown, setIsShown] = useState(show);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [recaptchaSiteKey, setRecaptchaSiteKey] = useState();
  const recaptchaRef = React.createRef();
  const [recaptchaValue, setRecaptchaValue] = useState();
  const [isFormValid, setFormValid] = useState(false);

  useEffect(() => {
    setIsShown(show);
  }, [show]);

  useEffect(() => {
    if (commercePlatform.environment) {
      setRecaptchaSiteKey(SETTINGS.recaptchaKeys[commercePlatform.environment]);
    }
    const currentUser = commercePlatform.currentUser;
    if (currentUser) {
      const org = currentUser.organization;
      form.get("email").setValue(currentUser.email);
      form.get("phone").setValue(currentUser.phone_number);
      form.get("firstName").setValue(currentUser.first_name);
      form.get("lastName").setValue(currentUser.last_name);
      form.get("companyName").setValue(org.organization_name);
    }
  }, [commercePlatform]);

  const onRecaptchaChange = key => {
    setRecaptchaValue(key);
  };

  const form = useForm(
    formGroup({
      firstName: formControl("", {
        validators: [SpsValidators.required, SpsValidators.maxLength(40)]
      }),
      lastName: formControl("", {
        validators: [SpsValidators.required, SpsValidators.maxLength(40)]
      }),
      email: formControl("", {
        validators: [
          SpsValidators.required,
          SpsValidators.pattern(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
          SpsValidators.maxLength(40)
        ]
      }),
      companyName: formControl(""),
      phone: formControl(""),
      subject: formControl("", {
        validators: [SpsValidators.required, SpsValidators.maxLength(80)]
      }),
      message: formControl("", {
        validators: [SpsValidators.required]
      })
    })
  );

  useEffect(() => {
    const formValue = form.getValue();
    if (
      formValue.firstName &&
      formValue.lastName &&
      formValue.email &&
      !form.get("email").errors &&
      (formValue.feature || formValue.subject) &&
      formValue.message &&
      recaptchaValue
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [form.getValue()]);

  function handleFocusedTaskClose() {
    form.reset();
    if (onClose) {
      onClose();
    }
  }

  function close() {
    setIsShown(false);
    handleFocusedTaskClose();
  }

  function closeForm() {
    form.reset();
    recaptchaRef.current.reset();
    setRecaptchaValue("");
    close();
  }

  async function submitHandler(e) {
    e.preventDefault();
    if (form.isValid() && recaptchaValue !== "") {
      setIsSubmitting(true);
      let formHtml = document.getElementById("contactForm");
      const firstName = document.getElementsByName("name")[0];
      firstName.value =
        form.getValue().firstName + " " + form.getValue().lastName;
      formHtml.submit();
    }
  }

  return (
    <SpsFocusedTask
      id="contact-support-form"
      isOpen={isShown}
      onClose={handleFocusedTaskClose}
    >
      <div style={{ width: "50%", margin: "auto" }}>
        <div className="d-flex flex-column mb-3">
          <h2>{infoCard}</h2>
          <span style={{ color: "#62686B", fontSize: "14px" }}>
            Please complete the form below and a member of our support team will
            contact you soon, usually within one business day.
          </span>
        </div>
        <SpsForm
          id="contactForm"
          formGroup={form}
          action={
            SETTINGS.salesforceContactFormUrl[commercePlatform.environment]
          }
          onSubmit={submitHandler}
          method="POST"
        >
          <input type="hidden" id="external" name="external" value="1" />
          <input
            type="hidden"
            name="orgid"
            value={SETTINGS.orgId[commercePlatform.environment]}
          />
          <input type="hidden" name="type" value="Dev Center" />
          {infoCard === "Contact Product Team" && (
            <input type="hidden" name="Escalation_To__c" value="Product" />
          )}
          <input
            type="hidden"
            name="retURL"
            value={
              commercePlatform.environment === "test"
                ? "https://test.developercenter.spscommerce.com/#/contact-info/true"
                : "https://developercenter.spscommerce.com/#/contact-info/true"
            }
          />
          <SpsCard className="text-left">
            <div className="sps-row mb-2">
              <div className="col-12">
                <SpsLabel
                  for={form.get("firstName")}
                  errors={() =>
                    form.get("firstName").hasError("required") &&
                    "This field is required"
                  }
                >
                  First Name
                </SpsLabel>
                <SpsTextInput name="name" formControl={form.get("firstName")} />
              </div>
            </div>
            <div className="sps-row mb-2">
              <div className="col-12">
                <SpsLabel
                  for={form.get("lastName")}
                  errors={() =>
                    form.get("lastName").hasError("required") &&
                    "This field is required"
                  }
                >
                  Last Name
                </SpsLabel>
                <SpsTextInput formControl={form.get("lastName")} />
              </div>
            </div>
            <div className="sps-row mb-2">
              <div className="col-12">
                <SpsLabel
                  for={form.get("email")}
                  errors={() => {
                    return (
                      (form.get("email").hasError("required") &&
                        "This field is required") ||
                      (form.get("email").hasError("pattern") &&
                        "Please enter a valid Email Address")
                    );
                  }}
                >
                  Email Address
                </SpsLabel>
                <SpsTextInput name="email" formControl={form.get("email")} />
              </div>
            </div>
            <div className="sps-row mb-2">
              <div className="col-12">
                <SpsLabel>Company Name</SpsLabel>
                <SpsTextInput
                  name="company"
                  formControl={form.get("companyName")}
                />
              </div>
            </div>
            <div className="sps-row mb-2">
              <div className="col-12">
                <SpsLabel>Phone Number</SpsLabel>
                <SpsTextInput name="phone" formControl={form.get("phone")} />
              </div>
            </div>
            <div className="sps-row mb-2">
              <div className="col-12">
                <SpsLabel
                  for={form.get("subject")}
                  errors={() =>
                    form.get("subject").hasError("required") &&
                    "This field is required"
                  }
                >
                  {infoCard === "Contact Support" ? "Subject" : "Feature"}
                </SpsLabel>
                <SpsTextInput
                  name="subject"
                  formControl={form.get("subject")}
                />
              </div>
            </div>
            <div className="sps-row mb-2">
              <div className="col-12">
                <SpsLabel
                  for={form.get("message")}
                  errors={() =>
                    form.get("message").hasError("required") &&
                    "This field is required"
                  }
                >
                  Message
                </SpsLabel>
                <SpsTextarea
                  name="description"
                  placeholder="Enter your message for the support team"
                  formControl={form.get("message")}
                  rows={3}
                />
              </div>
            </div>
            {recaptchaSiteKey && (
              <div className="mt-2 d-flex justify-content-center">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={recaptchaSiteKey}
                  onChange={onRecaptchaChange}
                />
              </div>
            )}
          </SpsCard>
          <div className="mt-3">
            <SpsButtonGroup>
              <SpsButton className="mr-1" onClick={closeForm}>
                Cancel
              </SpsButton>
              <SpsButton
                kind={ButtonKind.CONFIRM}
                type={ButtonType.SUBMIT}
                spinning={isSubmitting}
                disabled={!isFormValid}
              >
                Submit
              </SpsButton>
            </SpsButtonGroup>
          </div>
        </SpsForm>
      </div>
    </SpsFocusedTask>
  );
}

export default withCommercePlatform(ContactSupport);
