import React from "react";
import { SpsCard, SpsCardTabbedPane } from "@spscommerce/ds-react";
import Parser from "html-react-parser";
import PropTypes from "prop-types";

import { uuid } from "../../utils/utils.js";
import TableWrapper from "../TableWrapper/TableWrapper";
import ResponseCodeRow from "../SwaggerDocumentation/ResponseCodeRow";

export default function TabbedCardWrapper({
    data,
    cardContent = "table",
    headerTitle = "",
    headerContent = "",
}) {
    return (
        <SpsCard className="mb-3" headerTitle={headerTitle} headerContent={headerContent}>
            {data.map((obj) => {
                const paragraphData = obj.paragraph;
                return (
                    <SpsCardTabbedPane key={uuid()} label={obj.title}>
                        {paragraphData && <p>{Parser(paragraphData)}</p>}
                        {cardContent === "table" && <TableWrapper tableData={obj.tableData} />}
                        {cardContent === "responseCodes" &&
                            obj.responseCodes.map((responseCode) => (
                                <ResponseCodeRow key={uuid()} responseCode={responseCode} />
                            ))}
                    </SpsCardTabbedPane>
                );
            })}
        </SpsCard>
    );
}

TabbedCardWrapper.propTypes = {
    data: PropTypes.array,
    headerTitle: PropTypes.string,
    headerContent: PropTypes.string,
    cardContent: PropTypes.string,
};
