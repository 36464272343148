import React from "react";
import Footer from "../../Footer";
import RsxFetchedMarkdown from "./RsxFetchedMarkdown";

export default function RsxMarkdownWrapper(props) {
  const { contentUrl, doc } = props;
  return (
    <div className="col-9 docs-main-content">
      <RsxFetchedMarkdown url={contentUrl} doc={doc} />
      <Footer/>
    </div>
  );
}
