import React, { Fragment } from "react";
import { DateTime } from "luxon";

import { SpsButton } from "@spscommerce/ds-react";
import "./AppSideBar.scss";

const titleToClass = (name) => {
    if (name) {
        return name.split(" ").join("-").toLowerCase();
    }
};

const BorderedListItem = ({ title, children }) => {
    return (
        <div className={`app-side-bar__bordered-list-item ${titleToClass(title)}`}>
            <h5 className="bordered-list-item__header">{title}</h5>
            <div className="bordered-list-item__body">{children}</div>
        </div>
    );
};

export default function AppSideBar(props) {
    const { showDeleteModal, includeRedirectURI, app } = props;

    function formatDate(dateInput) {
        if (!props.user || !props.user.preferences) {
            return null;
        }
        const { preferences } = props.user;
        let dt = DateTime.fromISO(dateInput);
        if (dt.invalid !== null) {
            return null;
        }
        dt.setLocale(preferences.locale);
        let dateOut = dt.toLocaleString(DateTime.DATETIME_SHORT);
        return dateOut;
    }

    function renderAppType(appType) {
        const APP_TYPES = {
            spa: "Single-Page Application",
            native: "Native Application",
            web: "Web Service Application",
            m2m: "Machine-to-Machine Application",
        };
        return (
            <Fragment>
                <div className="app-side-bar__list-item">{APP_TYPES[appType]}</div>
            </Fragment>
        );
    }

    function renderList(list) {
        return (
            <Fragment>
                {list.map((item, idx) => {
                    return (
                        <div className="app-side-bar__list-item" key={`${item}-${idx}`}>
                            {item}
                        </div>
                    );
                })}
            </Fragment>
        );
    }

    return (
        <Fragment>
            <BorderedListItem title={app.name}>
                {app.description ? app.description : "-"}
            </BorderedListItem>
            {includeRedirectURI && (
                <BorderedListItem title="Redirect URIs">
                    <span className="app-side-bar__text">
                        {app.redirectUris && app.redirectUris.length > 0
                            ? renderList(app.redirectUris)
                            : "-"}
                    </span>
                </BorderedListItem>
            )}
            <BorderedListItem title="Grant Types">
                <span className="app-side-bar__text">
                    {app.appType ? renderAppType(app.appType) : "-"}
                </span>
            </BorderedListItem>
            <BorderedListItem title="Date Created">
                <span className="app-side-bar__text">
                    {app.createdAt ? formatDate(app.createdAt) : "-"}
                </span>
            </BorderedListItem>
            <BorderedListItem title="Date Updated">
                <span className="app-side-bar__text">
                    {app.updatedAt ? formatDate(app.updatedAt) : "-"}
                </span>
            </BorderedListItem>
            <BorderedListItem>
                <span className="app-side-bar__text app-side-bar__delete_link">
                    <SpsButton
                        id="deleteApplicationButton"
                        kind="link"
                        onClick={() => showDeleteModal()}
                    >
                        Delete Application
                    </SpsButton>
                </span>
            </BorderedListItem>
        </Fragment>
    );
}
