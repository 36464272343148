import "./SwaggerDocumentation.scss";

import React, {
    Fragment,
    useEffect,
    useState,
} from "react";

import axios from "axios";
import _ from "lodash";
import PropTypes from "prop-types";
import queryString from "query-string";
import ReactPlaceholder from "react-placeholder";
import { withRouter } from "react-router";

import {
    formControl,
    formGroup,
    SpsCard,
    SpsForm,
    SpsPageTitle,
    SpsTextInput,
    useForm,
} from "@spscommerce/ds-react";

import { makePath } from "../../utils";
import { swaggerParser } from "../../utils/jsonParser";
import { FileNotFound } from "../FileNotFound";
import { MarkdownPlaceHolder } from "../Placeholders";
import SwaggerDocumentationPage from "./SwaggerDocumentationPage";

const itemsHeaderPara = [
    {
        ParameterName: "Content-Type",
        Type: "String",
        Mandatory: "No",
        DefaultValue: "application/json",
        Description: "Content type of your request",
    },
    {
        ParameterName: "Authorization",
        Type: "String",
        Mandatory: "Yes",
        DefaultValue: "None",
        Description: "Authentication token with a prefix of 'Bearer'",
    },
];

const SwaggerDocumentationVerifier = function (props) {
    const { swaggerUri, authDocsPath, hideSections = [], modifySections = {}, location } = props;

    const [jsonData, setJsonData] = useState();
    const [loading, setLoading] = useState(false);
    const [docLocation, setDocLocation] = useState("");

    const form = useForm(
        formGroup({
            swaggerUrl: formControl(""),
        }),
    );

    useEffect(() => {
        fetchJson(docLocation || swaggerUri);
    }, [docLocation]);

    useEffect(() => {
        console.log("form.getValue().swaggerUrl", form.getValue().swaggerUrl);
        setDocLocation(form.getValue().swaggerUrl);
    }, [form.getValue().swaggerUrl]);

    async function fetchJson(docLocation) {
        try {
            setLoading(true);
            const { path, method } = queryString.parse(location.search);
            if (docLocation && docLocation.startsWith("/")) docLocation = docLocation.slice(1);
            if (docLocation && !docLocation.includes("http"))
                docLocation = makePath({
                    root: props.cdn_url || "",
                    path: docLocation || "",
                    appendExtension: "",
                    defaultPath: "",
                });
            if (docLocation && docLocation.endsWith(".")) docLocation = docLocation.slice(0, -1);
            const response = await axios.get(docLocation);
            setJsonData(await swaggerParser(response.data, path, method));
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <SpsPageTitle className="col-12 home-page__heading">
                Swagger Documentation Verification
            </SpsPageTitle>
            <SpsCard className="mb-3">
                <SpsForm formGroup={form}>
                    <SpsTextInput
                        formControl={form.get("swaggerUrl")}
                        placeholder="Please paste your swagger.json hosted URL here."
                    />
                </SpsForm>
            </SpsCard>
            <ReactPlaceholder customPlaceholder={<MarkdownPlaceHolder />} ready={!loading}>
                <Fragment>
                    {jsonData &&
                        Array.isArray(jsonData) &&
                        jsonData.map((data, index) => (
                            <SwaggerDocumentationPage
                                key={index}
                                data={data}
                                hideSections={hideSections}
                                modifySections={modifySections}
                                itemsHeaderPara={itemsHeaderPara}
                                authDocsPath={authDocsPath}
                            />
                        ))}
                    {jsonData && !_.isEmpty(jsonData) && !Array.isArray(jsonData) && (
                        <SwaggerDocumentationPage
                            data={jsonData}
                            hideSections={hideSections}
                            modifySections={modifySections}
                            itemsHeaderPara={itemsHeaderPara}
                            authDocsPath={authDocsPath}
                        />
                    )}
                    {_.isEmpty(jsonData) && (
                        <FileNotFound
                            heading="API documentation Not Found"
                            subheading="We are not getting api doc for given URL, please try changing the URL in input box above!!"
                        />
                    )}
                </Fragment>
            </ReactPlaceholder>
        </>
    );
};

SwaggerDocumentationVerifier.propTypes = {
    swaggerUri: PropTypes.string.isRequired,
    authDocsPath: PropTypes.string,
    hideSections: PropTypes.array,
    modifySections: PropTypes.object,
};

export default withRouter(SwaggerDocumentationVerifier);
