import React from "react";

import { SpsCard } from "@spscommerce/ds-react";

export default function ConnectToCard(props) {
    return (
        <SpsCard className="home-page-connect-card">
            <h3 className="home-page-connect-card__heading text-center">{props.heading}</h3>
            <div className="row">{props.children}</div>
        </SpsCard>
    );
}
