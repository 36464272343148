import React, { Component, Fragment } from "react";
import classnames from "classnames";
import axios from "axios";
import { SpsCard } from "@spscommerce/ds-react";
import { SpsGrowlerFactory, withCommercePlatform } from "@spscommerce/ui-react";
import AppConfig from "../../App.config";
import "./ConnectionInformation.scss";
import ConnectionInformationRow from "./ConnectionInformationRow";

export class ConnectionInformation extends Component {
    state = {
        appSecret: "",
        testAppSecret: "",
        hideSecret: true,
        appLoaded: false,
        secretLoaded: false,
    };

    toggleVisibility = () => {
        this.setState((prevState) => ({ hideSecret: !prevState.hideSecret }));
    };

    componentDidMount() {
        const { hasSecret } = this.props;
        if (this.props.staticApp.id) {
            this.setState({ appLoaded: true });
        }
        if (hasSecret) {
            this.fetchAccessSecret();
        }
    }

    hasSecret = () => {
        const {
            staticApp: { appType },
        } = this.props;
        return appType === "web" || appType === "m2m";
    };

    componentDidUpdate(prevProps) {
        const {
            staticApp: { id },
        } = this.props;
        if (prevProps.staticApp.id !== id) {
            if (id) {
                this.setState({ appLoaded: true });
            }
            if (this.hasSecret()) {
                this.fetchAccessSecret();
            }
        }
    }

    fetchAccessSecret = async () => {
        const {
            staticApp: { id },
            commercePlatform: { token, environment },
            isTrial,
        } = this.props;
        const url = `${AppConfig.kubeRoot[environment]}/devcenter/v1/applications/${id}/retrieve_secret/`;
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        const secretConfig = {
            method: "get",
            headers,
            url: url,
        };
        const testSecretConfig = {
            method: "get",
            headers,
            url: url,
            params: {
                client_type: "test",
            },
        };

        // Only fetch production app secret with non trial orgs.
        if (!isTrial) {
            try {
                const resp = await axios(secretConfig);
                this.setState({
                    appSecret: resp.data.secret,
                });
            } catch (e) {
                const msg = "Secret Could Not Be Fetched";
                SpsGrowlerFactory.error(msg, {
                    preset: "error",
                    timeoutDuration: 5000,
                    fade: true,
                });
            }
        }
        try {
            const resp = await axios(testSecretConfig);
            this.setState({
                testAppSecret: resp.data.secret,
                secretLoaded: true,
            });
        } catch (e) {
            const msg = "Secret Could Not Be Fetched";
            SpsGrowlerFactory.error(msg, {
                preset: "error",
                timeoutDuration: 5000,
                fade: true,
            });
        }
    };

    render() {
        const {
            staticApp: { clientId, testClientId },
            isTrial,
        } = this.props;
        const { appSecret, testAppSecret, secretLoaded } = this.state;
        const hasSecretClass = classnames("connection-informations", {
            "has-secret": this.hasSecret(),
        });

        return (
            <div className="row">
                {!isTrial && (
                    <div className="col-6">
                        <span className="connection-information-title">Production Keys</span>
                        <p>
                            These credentials are used to access SPS commerce services from your
                            app.
                        </p>
                        <div>
                            <div className={`${hasSecretClass}`}>
                                <SpsCard className="connection-information connection-information__app-id">
                                    <ConnectionInformationRow
                                        title="App ID"
                                        value={clientId}
                                        loaded={this.state.appLoaded}
                                        hiddenField={false}
                                    />
                                </SpsCard>
                                {this.hasSecret() ? (
                                    <Fragment>
                                        <SpsCard className="connection-information connection-information__app-secret">
                                            <ConnectionInformationRow
                                                title="App Secret"
                                                value={appSecret}
                                                loaded={secretLoaded}
                                                hiddenField={true}
                                            />
                                        </SpsCard>
                                    </Fragment>
                                ) : null}
                            </div>
                        </div>
                    </div>
                )}
                <div className="col-6">
                    <span className="connection-information-title">Sandbox Keys</span>
                    <p>These credentials are used to create test applications using test data.</p>
                    <div>
                        <div className={`${hasSecretClass}`}>
                            <SpsCard className="connection-information connection-information__app-id">
                                <ConnectionInformationRow
                                    title="App ID"
                                    value={testClientId}
                                    loaded={this.state.appLoaded}
                                    hiddenField={false}
                                />
                            </SpsCard>
                            {this.hasSecret() ? (
                                <Fragment>
                                    <SpsCard className="connection-information connection-information__app-secret">
                                        <ConnectionInformationRow
                                            title="App Secret"
                                            value={testAppSecret}
                                            loaded={secretLoaded}
                                            hiddenField={true}
                                        />
                                    </SpsCard>
                                </Fragment>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withCommercePlatform(ConnectionInformation);
