import React from "react";
import PropTypes from "prop-types";

import "./WorkflowOverview.scss";

const WorkflowOverview = function (props) {
    const { heading, description, checkList, icon } = props;
    const itemList = checkList && checkList.map((obj) => <li key={obj}>{obj}</li>);

    return (
        <div className="workflow-overview">
            <div className="workflow-overview__icon-container">
                <i className={`sps-icon sps-icon-${icon} gray400`} />
            </div>
            <div className="workflow-overview__content-container">
                <h2>{heading}</h2>
                <p>{description}</p>
                {itemList && <ul>{itemList}</ul>}
            </div>
        </div>
    );
};

WorkflowOverview.propTypes = {
    icon: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default WorkflowOverview;
