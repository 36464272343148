import codegen from "postman-code-generators";
import sdk from "postman-collection";

const defaultOptions = {
    indentCount: 4,
    indentType: "Space",
    trimRequestBody: true,
    followRedirect: true,
};

const optedLanguages = ["curl", "python", "java", "csharp", "javascript"];

export function getLanguageList() {
    const allLanguges = codegen.getLanguageList();
    const filteredLanguages = allLanguges.filter(function (language) {
        return optedLanguages.indexOf(language.key) > -1;
    });
    return filteredLanguages;
}

export function generateCode(
    language = "Python",
    variant = "Requests",
    request = "",
    options = {},
) {
    const req = new sdk.Request(request);
    const opt = { ...defaultOptions, ...options };
    if (language === "python") {
        variant = "Requests";
    }
    return new Promise((resolve, reject) => {
        codegen.convert(language, variant, req, opt, function (error, snippet) {
            if (error) {
                reject(error);
            }
            resolve(snippet);
        });
    });
}
