import "./SwaggerDocumentation.scss";

import React from "react";

import { Link } from "react-router-dom";

import { SpsCard } from "@spscommerce/ds-react/";

const SwaggerAuthenticationComponent = ({ authDocsPath = "" }) => {
    return (
        <SpsCard className="card-margin">
            <h4>Authentication</h4>
            <p>
                Obtain an authentication token which must be passed in the header of all requests.
                Refer to the <Link to={authDocsPath}>Authentication</Link> section of the
                documentation to learn how to obtain an authentication token.
            </p>
        </SpsCard>
    );
};

export default SwaggerAuthenticationComponent;
