import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {
    SpsModal,
    SpsModalBody,
    SpsModalFooter,
    SpsModalAction,
    withCommercePlatform,
} from "@spscommerce/ui-react";
import "./ProfileImage.scss";
import FileUploadWrapper from "./FileUploadWrapper";

function EditProfileImage({
    imageUrl,
    onEditClose,
    showEditModal,
    commercePlatform: { identityServiceUrl, token, currentUser } = {},
}) {
    const [editImageUrl, setEditImageUrl] = useState();
    const [editImage, setEditImage] = useState(null);

    function onEditImageSelect(newImage) {
        if (newImage) {
            setEditImageUrl(newImage);
            setEditImage(newImage);
        }
    }

    const cropper = useRef();

    useEffect(() => {
        setEditImageUrl(imageUrl);
    }, [imageUrl]);

    function crop() {
        cropper.current.getCroppedCanvas().toBlob(
            (blob) => {
                setEditImage(blob);
            },
            "image/jpeg",
            1,
        );
    }

    async function savePhoto() {
        let url = `${identityServiceUrl}/identity/users/${currentUser.id}/avatar/`;
        const formData = new FormData();
        formData.append("uploadedFile", editImage);
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            },
        };

        try {
            const response = await axios.post(url, formData, config);
            if (response.status === 200) {
                const payload = {
                    email: currentUser.email,
                    avatar_image_id: response.data.image_id,
                };
                let url = `${identityServiceUrl}/identity/v3/users/${currentUser.id}/profile/`;
                config.headers["Content-Type"] = "application/json";
                await axios.patch(url, payload, config);
            }

            onEditClose({
                kind: "success",
                title: "Profile Photo saved",
                content: () => "Your profile photo was successfully saved.",
            });
        } catch (error) {
            onEditClose({
                kind: "error",
                title: "Error saving Profile Photo",
                content: () => "Your profile photo was not saved.",
            });
        }
    }

    return (
        <SpsModal
            id="edit-photo"
            kind="default"
            isOpen={showEditModal}
            header="Edit photo"
            size="default"
            actionRequired
            onClose={() => {
                onEditClose();
            }}
        >
            <SpsModalBody>
                <Cropper
                    ref={cropper}
                    src={editImageUrl}
                    style={{ height: 320, width: "auto" }}
                    aspectRatio={3 / 3}
                    guides={false}
                    crop={crop}
                    crossOrigin="true"
                    checkImageOrigin={false}
                    checkCrossOrigin={true}
                    checkOrientation={false}
                    zoomable={false}
                    viewMode={1}
                    background={false}
                    modal={false}
                />
                <div className="mt-3">
                    <FileUploadWrapper onImageSelect={onEditImageSelect} mini={true} />
                </div>
            </SpsModalBody>
            <SpsModalFooter>
                <SpsModalAction kind="default">Cancel</SpsModalAction>
                <SpsModalAction
                    kind="confirm"
                    onClick={() => {
                        return new Promise(async (resolve) => {
                            await savePhoto();
                            resolve();
                        });
                    }}
                >
                    Save
                </SpsModalAction>
            </SpsModalFooter>
        </SpsModal>
    );
}

export default withCommercePlatform(EditProfileImage);
