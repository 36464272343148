import React from "react";

import { SpsCard } from "@spscommerce/ds-react";

import LocationCard from "./LocationCard";
import locations from "./Locations.json";
import generalEmailAddresses from "./GeneralEmailAddresses";
import "./LocationsPage.scss";

export default function OfficeLocationPage() {
    return (
        <>
            <div className="row ml-2 w-100">
                <h2 className="location-page-content__card-heading fs-24">Office Locations</h2>
            </div>
            <section className="sps-main-content sps-column-layout p-0">
                {locations &&
                    locations.map((country) => {
                        return (
                            <SpsCard
                                className="location-page-content__card"
                                headerTitle={country.country}
                            >
                                <LocationCard locations={country.locations} />
                            </SpsCard>
                        );
                    })}
            </section>
            <aside className="sps-body__sidebar sps-body__sidebar--350">
                <div className="inline-location-card">
                    <SpsCard
                        className="location-page-content__card sps-card--collpased"
                        headerTitle="General Email Addresses"
                    >
                        {generalEmailAddresses.map((email) => {
                            return (
                                <div key={email.id} className="location-row">
                                    <div className="container-circle">
                                        <div className="circle">
                                            <i
                                                className={`circle-icon sps-icon sps-icon-${email.icon}`}
                                            />
                                        </div>
                                    </div>
                                    <div className="location-link">
                                        <div>
                                            <p className="location-name">{email.name}</p>
                                        </div>
                                        <div className="location-email">
                                            <a href={"mailto:" + email.emailTo}>{email.emailTo}</a>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </SpsCard>
                </div>
            </aside>
        </>
    );
}
