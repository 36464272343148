import React from "react";
import ClipboardJS from "clipboard";

import { usePatchReducer, SpsMicroBlock, SpsButton } from "@spscommerce/ds-react";
import { SpsIcon, ButtonKind, FeedbackBlockKind } from "@spscommerce/ds-shared";

import "./ClickToCopy.scss";

export default function ClickToCopy({ value, id }) {
    const [state, patchState] = usePatchReducer({
        clicked: false,
    });

    new ClipboardJS(`#${id}`);

    function handleClick() {
        patchState({ clicked: true });
        setTimeout(() => {
            patchState({ clicked: false });
        }, 2000);
    }

    return (
        <>
            {state.clicked && (
                <div style={{ paddingBottom: "1px" }}>
                    <SpsMicroBlock kind={FeedbackBlockKind.SUCCESS} message=""></SpsMicroBlock>
                </div>
            )}

            {!state.clicked && (
                <SpsButton
                    style={{ padding: "0px", color: "#4B5356" }}
                    onClick={handleClick}
                    icon={SpsIcon.DUPLICATE}
                    kind={ButtonKind.LINK}
                    id={id}
                    data-clipboard-text={value}
                ></SpsButton>
            )}
        </>
    );
}
