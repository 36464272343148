import React, { useState } from "react";
import Loading from "../Loading";

import { SpsSelect } from "@spscommerce/ds-react";
import { withCommercePlatform } from "@spscommerce/ui-react";

import { switchOrgSilently } from "../../utils";
import "./CompanySwitcher.scss";

function alphabetizeOrgs(a, b) {
    return a.organization_name.toLowerCase() < b.organization_name.toLowerCase() ? -1 : 1;
}

export function CompanySwitcher(props) {
    // set state
    const [selectedOrg, setSelectedOrg] = useState(props.currentUser.organization);
    const suggestedOrgs = [...props.currentUser.organizations].sort(alphabetizeOrgs);
    // handle events

    function organizationSearch(search) {
        return suggestedOrgs.filter((s) => new RegExp(search, "i").test(s.organization_name));
    }

    function selectOrg(event) {
        const newOrg = event.target.value;
        const { environment } = props.commercePlatform;
        setSelectedOrg(newOrg);
        switchOrgSilently(newOrg, environment);
    }

    return (
        <div className="profile-organization-selector">
            {props.commercePlatform.auth ? (
                <SpsSelect
                    notClearable
                    searchPlaceholder="Find an Organization"
                    id="debounce-select"
                    options={organizationSearch}
                    onChange={selectOrg}
                    textKey="organization_name"
                    searchDebounce={1000}
                    placeholder={selectedOrg.organization_name}
                    value={selectedOrg}
                />
            ) : (
                <Loading />
            )}
        </div>
    );
}
export default withCommercePlatform(CompanySwitcher);
