import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { withRouter } from "react-router-dom";
import ReactPlaceholder from "react-placeholder";

import { useGrowlers, SpsSelect } from "@spscommerce/ds-react";
import { withCommercePlatform } from "@spscommerce/ui-react";

import AppConfig from "../../App.config";
import { MarkdownPlaceHolder } from "../../components/Placeholders";
import { orderRsxDocuments } from "../../utils";

import QualifierLink from "./QualifierLink";
import "./FieldsetQualifier.scss";

const FieldsetQualifier = function (props) {
    const { heading, description, history, commercePlatform } = props;

    const [version, setVersion] = useState();
    const [allVersions, setAllVersions] = useState([]);
    const [schemas, setSchemas] = useState([]);
    const [growlers, createGrowler] = useGrowlers();
    const [docNumbers, setDocNumbers] = useState({});

    useEffect(() => {
        getVersions();
        getDocNumber();
    }, []);

    useEffect(() => {
        if (version) {
            getSchemasNames();
        }
    }, [version]);

    function versionSearch(search) {
        return [...allVersions].filter((s) => new RegExp(search, "i").test(s));
    }
    const versionChangeHandler = (event) => {
        setSchemas([]);
        setVersion(event.target.value);
    };

    const getVersion = () => (version || "").split("(")[0].trim();

    const qualifierClickHandler = (text) => {
        history.push(`fields-qualifiers/${getVersion()}/${text ? text.replace(/ /g, "") : ""}`);
    };

    const headers = {
        Authorization: `Bearer ${commercePlatform ? commercePlatform.token : ""}`,
    };

    const getVersions = async () => {
        try {
            const url = `${AppConfig.rsx[commercePlatform.environment]}/v1/valid_schema_names/`;
            const apiConfig = {
                method: "get",
                headers,
                url,
            };

            const response = await axios(apiConfig);
            const schemas = Object.keys(response.data.schemas).reverse();
            schemas[0] = schemas[0] += " (Latest)";
            setAllVersions(schemas);
            setVersion(schemas[0]);
        } catch (error) {
            createGrowler({
                kind: "error",
                title: "An error has occurred while fetching versions",
                content: () =>
                    "An error has occurred while fetching versions, please try again later.",
            });
        }
    };

    const getDocNumber = async () => {
        try {
            const url = `${AppConfig.rsx[commercePlatform.environment]
                }/v1/schema_document_relationship/rsx/`;
            const apiConfig = {
                method: "get",
                headers,
                url,
            };
            const response = await axios(apiConfig);
            setDocNumbers(response.data);
        } catch (error) {
            createGrowler({
                kind: "error",
                title: "An error has occurred while fetching doc number",
                content: () =>
                    "An error has occurred while fetching doc number, please try again later.",
            });
        }
    };

    const getSchemasNames = async () => {
        try {
            const url = `${AppConfig.rsx[commercePlatform.environment]
                }/v1/valid_schema_names/?version=${getVersion()}`;
            const apiConfig = {
                method: "get",
                headers,
                url,
            };

            const response = await axios(apiConfig);
            setSchemas(orderRsxDocuments(getVersion(), response.data.schemas[getVersion()]));
        } catch (error) {
            setSchemas([]);
            createGrowler({
                kind: "error",
                title: "An error has occurred while fetching schema names",
                content: () =>
                    "An error has occurred while fetching schema names, please try again later.",
            });
        }
    };

    const getDocType = (qualifier) => {
        const rowVersion = docNumbers[getVersion().split(/.(?=[^.]+$)/)[0]];
        if (rowVersion) {
            const rowDocTypes = rowVersion
                .filter((doc) => doc.name === qualifier)
                .map((doc) => doc.docTypes.join(", "));
            return rowDocTypes;
        }
    };

    return (
        <>
            {growlers()}
            <div className="schema-download rsx-ui-page">
                <h2 className="schema-download__heading">{heading}</h2>
                <div className="documents-list-container">
                    <SpsSelect
                        options={versionSearch}
                        value={version}
                        onChange={versionChangeHandler}
                        notClearable
                        className="qualifier-list-select"
                        searchPlaceholder="Find a version"
                    />
                    <p>{description}</p>
                    <p className="start-choosing__title">Start with choosing a Document Type</p>
                    <p>
                        These are the available Document types based on the version number selected.
                        Each contain a list of field and qualifier lists contained within the
                        schemas.
                    </p>

                    <ReactPlaceholder
                        customPlaceholder={<MarkdownPlaceHolder />}
                        ready={!!schemas.length}
                    >
                        <div className="row">
                            {schemas &&
                                schemas.map((qualifier, index) => (
                                    <QualifierLink
                                        text={qualifier}
                                        description={getDocType(qualifier)}
                                        onClick={qualifierClickHandler}
                                        key={index}
                                    />
                                ))}
                        </div>
                    </ReactPlaceholder>
                </div>
            </div>
        </>
    );
};

FieldsetQualifier.propTypes = {
    qualifiers: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            description: PropTypes.string,
        }),
    ),
    heading: PropTypes.string.isRequired,
    description: PropTypes.string,
};

export default withRouter(withCommercePlatform(FieldsetQualifier));
