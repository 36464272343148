import React from "react";

export default function FileNotFound(props) {
    const {
        img = "https://cdn.prod.spsc.io/web/framework/assets/18.10.01/images/icon-error.svg",
        heading = "File Not Found",
        subheading = "We could not find the requested document",
    } = props;

    return (
        <div className="sps-card">
            <div className="sps-card__body">
                <div className="sps-zero-state">
                    <img className="sps-zero-state__image" src={img} alt={heading} />
                    <h1 className="sps-zero-state__heading">{heading}</h1>
                    <h2 className="sps-zero-state__subheading">{subheading}</h2>
                </div>
            </div>
        </div>
    );
}
