import React from "react";
import { SpsCard } from "@spscommerce/ds-react/";

import "./SwaggerDocumentation.scss";

const SwaggerPathComponent = function ({ path }) {
    return (
        <SpsCard className="card-margin">
            <h4>Path</h4>
            <p>
                <span className="text-highlight">{path || ""}</span>
            </p>
        </SpsCard>
    );
};

export default SwaggerPathComponent;
