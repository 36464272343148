import React from "react";

import { SpsButton } from "@spscommerce/ds-react";
import { withCommercePlatform } from "@spscommerce/ui-react";

export function NoUserFoundWithoutCommercePlatform(props) {
    function logout() {
        const {
            commercePlatform: { auth },
        } = props;
        auth.logout();
    }

    return (
        <div>
            <div className="sps-logo-ul">
                <img
                    src="https://www.spscommerce.com/wp-content/uploads/2018/07/logo-blue.svg"
                    alt="sps commerce logo"
                />
            </div>
            <h1 className="login-content-body__header">Dev Center Access Needed</h1>
            <p className="login-content-body__text">
                This account does not have access to Dev Center. Please submit a ticket via the{" "}
                <a className="login-content-body__contact-link" href="#/contact-info">
                    Contact
                </a>{" "}
                page to request access.
            </p>
            <SpsButton
                style={{ width: "100%" }}
                className="login-content-body__form-submit-ul"
                kind="confirm"
                onClick={logout}
            >
                Return to Dev Center Home
            </SpsButton>
        </div>
    );
}

export default withCommercePlatform(NoUserFoundWithoutCommercePlatform);
