import React from "react";

import {
    SpsTag,
    SpsTooltip,
} from "@spscommerce/ds-react";
import { Position } from "@spscommerce/positioning";

function SwaggerVersionTag({ serviceName, tagName, latestTag }) {
    // TODO: Replace SpsTag with SpsKeyValueTag after ds-react version updates
    return (
        <>
            {tagName.search("(Latest)") > 0 || !latestTag ? (
                <SpsTag kind="key" className="mb-2">
                    {serviceName} | {tagName.replace(" (Latest)", "")}
                </SpsTag>
            ) : (
                <>
                    <SpsTag
                        id="versionTag"
                        kind="warning"
                        icon="exclamation-triangle"
                        className="mb-2"
                    >
                        {serviceName} | {tagName}
                    </SpsTag>
                    <SpsTooltip
                        for="versionTag"
                        id="tooltip-target-bottom-middle"
                        position={Position.BOTTOM_MIDDLE}
                    >
                        {latestTag.name} is available for this endpoint.
                    </SpsTooltip>
                </>
            )}
        </>
    );
}
export default SwaggerVersionTag;
