import React from "react";
import { NavLink } from "react-router-dom";

import { withCommercePlatform } from "@spscommerce/ui-react";

import "./PageBanner.scss";

export function PageBanner(props) {
    const {
        commercePlatform: { auth },
        className: addClassName,
    } = props;

    const signIn = (e) => {
        e.preventDefault();
        auth.login({ appState: window.location.hash });
    };

    return (
        <div className={`page-banner${addClassName ? " " + addClassName : ""}`}>
            <div className="page-banner__contents">
                <h1 className="page-banner__heading">Building Applications Made Easy</h1>
                <div className="page-banner__button-container">
                    <NavLink className="sps-btn sps-btn--confirm page-banner__button" to="/signup">
                        Create Trial Account
                    </NavLink>
                    <a
                        className="sps-btn sps-btn--confirm page-banner__button bg-none"
                        href="/"
                        onClick={signIn}
                    >
                        Sign in to Dev Center
                    </a>
                </div>
            </div>
        </div>
    );
}

export default withCommercePlatform(PageBanner);
