import React from "react";
import IconCard from "../IconCard";

import "./ServiceCard.scss";

export default function ServiceCard(props) {
    const { service } = props;

    return (
        <IconCard icon="sps-icon sps-icon-map-maker" to={`/docs/${service.slug}`}>
            <div className="service-card-title">{`${service.name}`}</div>
        </IconCard>
    );
}
