import React, {
    Fragment,
    useEffect,
} from "react";

import axios from "axios";
import get from "lodash/get";

import {
    SpsGrowler,
    SpsPagination,
    usePatchReducer,
} from "@spscommerce/ds-react";
import { withCommercePlatform } from "@spscommerce/ui-react";

import AppConfig from "../../App.config";
import AppList from "../../components/AppList";
import InlineServiceCard from "../../components/InlineServiceCard";
import { ToolsNavCard } from "../../components/ToolsNavCard/ToolsNavCard";

export function ApplicationsPage(props) {
    const {
        commercePlatform: { currentUser, environment },
        devCenterApi,
    } = props;

    const [state, patchState] = usePatchReducer({
        applications: [],
        appsLoaded: false,
        services: [],
        servicesLoaded: false,
        page: 1,
        pageSize: 25,
        count: 0,
        showGrowler: false,
        growlerKind: "error",
        growlerTitle: "",
        growlerMessage: "",
    });

    useEffect(() => {
        const {
            commercePlatform: { token, hasSession },
        } = props;
        if (token) {
            getApplications(state.page);
        } else if (hasSession) {
            patchState({ appsLoaded: true });
        }
        getServices();
    }, [props]);

    useEffect(() => {
        getApplications();
    }, [state.page, state.pageSize]);

    async function getApplications() {
        const {
            commercePlatform: { token, currentUser, hasSession, environment },
        } = props;
        // TODO: handle pagination, url generation for different envs, errors
        if (hasSession) {
            if (currentUser) {
                const url = `${AppConfig.kubeRoot[environment]}/devcenter/v1/applications/?page=${state.page}&page_size=${state.pageSize}`;
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                };
                try {
                    const appResponse = await axios.get(url, config);
                    patchState({
                        count: appResponse.data.count,
                        applications: appResponse.data.results,
                        appsLoaded: true,
                    });
                } catch (err) {
                    console.error(err);
                    const msg = get(err, "response.data.error.errorDescription")
                        ? get(err, "response.data.error.errorDescription")
                        : "An error has occurred when fetching the applications";
                    const title = get(err, "response.data.error.error")
                        ? get(err, "response.data.error.error")
                        : "Error!";
                    patchState({ growlerMessage: msg, growlerTitle: title });
                    patchState({ showGrowler: true });
                }
            }
        }
    }

    async function getServices() {
        const response = await devCenterApi.getServices();

        if (response.errorTitle) {
            patchState({
                growlerMessage: response.errorMessage,
                growlerTitle: response.errorTitle,
                showGrowler: true,
            });
        } else {
            patchState({
                services: response.services,
                servicesLoaded: true,
            });
        }
    }

    function handlePageChange(newPage, newPageSize) {
        patchState({
            page: newPage,
            pageSize: newPageSize,
            appsLoaded: false,
        });
    }

    return (
        <Fragment>
            {state.showGrowler && (
                <SpsGrowler
                    kind={state.growlerKind}
                    title={state.growlerTitle}
                    onClose={() => {
                        patchState({ showGrowler: false });
                    }}
                >
                    {state.growlerMessage}
                </SpsGrowler>
            )}
            <section className="sps-main-content sps-column-layout">
                <AppList applications={state.applications} appsLoaded={state.appsLoaded} />
                <SpsPagination
                    id="basic"
                    totalResults={state.count}
                    page={state.page}
                    pageSize={state.pageSize}
                    onPageChange={handlePageChange}
                />
            </section>
            <aside className="sps-body__sidebar sps-body__sidebar--350">
                {state.services.length > 0 && (
                    <InlineServiceCard
                        environment={environment}
                        hasHeader={true}
                        headerTitle={`SERVICES DOCUMENTATION`}
                        services={state.services}
                        servicesLoaded={state.servicesLoaded}
                    />
                )}
                <ToolsNavCard
                    environment={environment}
                    currentUser={currentUser}
                    tools={AppConfig.tools.toolsArray}
                />
            </aside>
        </Fragment>
    );
}

export default withCommercePlatform(ApplicationsPage);
