import React from "react";

export default function TitleTracker() {
    React.useEffect(() => {
        try {
            let path = window.location.href.split("/#/");
            path = path[1];
            if (path.indexOf("access_token") === 0) {
                path = "home";
            }
            if (path.includes("?")) path = path.split("?")[0];
            if (path.lastIndexOf("/") === path.length - 1) path = path.slice(0, path.length - 1);
            path = path.replace(path.charAt(0), path.charAt(0).toUpperCase());
            path = path.replace(/[-,_]/g, " ");
            path = path.replace(/[/]/g, " | ");
            document.title = `Dev Center: ${path}`;
        } catch (e) {
            console.error(e.message);
            document.title = `Dev Center`;
        }
    }, [window.location.href]);
    return null;
}
