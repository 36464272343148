import "./SwaggerDocumentation.scss";

import React, { Fragment } from "react";

import {
    SpsCard,
    SpsTable,
    SpsTableBody,
    SpsTableCell,
    SpsTableHead,
    SpsTableHeader,
    SpsTableRow,
} from "@spscommerce/ds-react/";

const SwaggerQueryParamsComponent = function ({ itemsQueryPara = [], notes = [] }) {
    function checkDefault(val = "") {
        return typeof val === "string" ? val || "None" : val;
    }

    return (
        <SpsCard className="card-margin">
            <h4>Query Parameters</h4>
            <SpsTable>
                <SpsTableHead>
                    <SpsTableRow>
                        <SpsTableHeader>Parameter Name</SpsTableHeader>
                        <SpsTableHeader>Type</SpsTableHeader>
                        <SpsTableHeader>Mandatory</SpsTableHeader>
                        <SpsTableHeader>Default Value</SpsTableHeader>
                        <SpsTableHeader>Description</SpsTableHeader>
                    </SpsTableRow>
                </SpsTableHead>
                <SpsTableBody>
                    {itemsQueryPara.map((row, index) => {
                        const { type, default: def } = row.schema ? row.schema : row;
                        return (
                            <SpsTableRow key={index}>
                                <SpsTableCell>
                                    <span className="text-highlight">{row["name"]}</span>
                                </SpsTableCell>
                                <SpsTableCell className="text-capitalize">{type}</SpsTableCell>
                                <SpsTableCell>{row["required"] ? "Yes" : "No"}</SpsTableCell>
                                <SpsTableCell>{checkDefault(def)}</SpsTableCell>
                                <SpsTableCell wrap={400}>{row["description"]}</SpsTableCell>
                            </SpsTableRow>
                        );
                    })}
                </SpsTableBody>
            </SpsTable>
            {notes.length ? (
                <Fragment>
                    <h6 className="mt-3">
                        <b>Notes</b>
                    </h6>
                    {notes.map((note, index) => {
                        return <p key={index}>{note}</p>;
                    })}
                </Fragment>
            ) : null}
        </SpsCard>
    );
};

export default SwaggerQueryParamsComponent;
