import React from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import Loadable from "react-loadable";

import ViewFieldRequirementsPage from "../ViewFieldRequirementsPage/ViewFieldRequirementsPage";

import "./ToolsPage.scss";

function Loading({ error, retry, timedOut, pastDelay }) {
    if (error) {
        return (
            <div>
                Error! <button onClick={retry}>Retry</button>
            </div>
        );
    } else if (timedOut) {
        return (
            <div>
                Taking a long time... <button onClick={retry}>Retry</button>
            </div>
        );
    } else if (pastDelay) {
        return (
            <div className="row">
                <div className="col tool-spinner">
                    <div className="sps-spinner">Loading...</div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

const ExampleTool = Loadable({
    loader: () => import("../../tools/example-tool/ExampleTool"),
    loading: Loading,
    timeout: 10000,
});

const LabelTool = Loadable({
    loader: () => import("../../tools/label-tool/LabelTool.jsx"),
    loading: Loading,
    timeout: 10000,
});

function ToolsPageWithoutRouter({ token, history }) {
    return (
        <div className="row sps-page-wrapper">
            <Switch>
                <Route
                    path="/tools/example-tool"
                    render={() => {
                        return <ExampleTool token={token} />;
                    }}
                />
                <Route
                    exact
                    path="/tools/shipping-doc-browser"
                    history={history}
                    render={() => {
                        return <LabelTool />;
                    }}
                />
                <Route
                    path="/tools/shipping-doc-browser/field-requirements"
                    history={history}
                    render={() => {
                        return <ViewFieldRequirementsPage />;
                    }}
                />
            </Switch>
        </div>
    );
}
const ToolsPage = withRouter(ToolsPageWithoutRouter);
export default ToolsPage;
