import React from "react";

import { SpsFocusedTask, SpsCard } from "@spscommerce/ds-react";

import AgreementPageContent from "./UserAgreementContent";
import ServiceAcceptance from "./UserAcceptance";
import "./UserAgreementPage.scss";

export const SpsLogo = () => {
    return (
        <div className="tos-page-logo">
            <img src="assets/SPS-logo-horiz-4C.png" alt="SPS logo" />
        </div>
    );
};

export function UserAgreementPage() {
    return (
        <SpsFocusedTask className="tos-page" isOpen={true}>
            <SpsLogo />
            <h2 className="text-center">Terms of Use</h2>
            <AgreementPageContent headingClass="tos-page-heading" />
            <ServiceAcceptance />
        </SpsFocusedTask>
    );
}

export function UserAgreementPageWithoutAcceptance() {
    return (
        <SpsFocusedTask className="tos-page" isOpen={true}>
            <SpsLogo />
            <h2 className="text-center">Terms of Use</h2>
            <AgreementPageContent headingClass="tos-page-heading" />
        </SpsFocusedTask>
    );
}

export function TermsOfUsePage() {
    return (
        <div className="tos-page">
            <h2>Terms of Use</h2>
            <SpsCard>
                <AgreementPageContent headingClass="font-weight-bold" />
            </SpsCard>
        </div>
    );
}
