import React from "react";
import {
    SpsCard,
    SpsTable,
    SpsTableHead,
    SpsTableRow,
    SpsTableHeader,
    SpsTableBody,
    SpsTableCell,
} from "@spscommerce/ds-react/";

import "./SwaggerDocumentation.scss";

const SwaggerHeaderParamsComponent = function ({ itemsHeaderParams = [] }) {
    return (
        <SpsCard className="card-margin">
            <h4>Header Parameters</h4>
            <SpsTable>
                <SpsTableHead>
                    <SpsTableRow>
                        <SpsTableHeader>Parameter Name</SpsTableHeader>
                        <SpsTableHeader>Type</SpsTableHeader>
                        <SpsTableHeader>Mandatory</SpsTableHeader>
                        <SpsTableHeader>Default Value</SpsTableHeader>
                        <SpsTableHeader>Description</SpsTableHeader>
                    </SpsTableRow>
                </SpsTableHead>
                <SpsTableBody>
                    {itemsHeaderParams &&
                        itemsHeaderParams.map((row, index) => {
                            return (
                                <SpsTableRow key={index}>
                                    <SpsTableCell className="parameter">
                                        <span className="text-highlight">{row["name"]}</span>
                                    </SpsTableCell>
                                    <SpsTableCell>{row["schema"].type}</SpsTableCell>
                                    <SpsTableCell>{row["Mandatory"]}</SpsTableCell>
                                    <SpsTableCell>{row["DefaultValue"]}</SpsTableCell>
                                    <SpsTableCell wrap={400}>{row["description"]}</SpsTableCell>
                                </SpsTableRow>
                            );
                        })}
                </SpsTableBody>
            </SpsTable>
        </SpsCard>
    );
};

export default SwaggerHeaderParamsComponent;
