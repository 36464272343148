import * as React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import classnames from "classnames";

import { SpsWorkflow, SpsWorkflowStep, SpsWorkflowDocument } from "@spscommerce/ds-react";

import "./WorkflowNavigation.scss";

function WorkflowNavigation(props) {
    const { steps, title, activeStep, className, ...rest } = props;

    const classList = classnames("col-4", className);

    function isAciveStep(path) {
        let splitArr = path.split("/");
        return splitArr[1] ? splitArr[1] === activeStep : splitArr[0] === activeStep;
    }

    const workflowStepsList = steps.map((step) => {
        return (
            <SpsWorkflowStep
                key={step.label}
                icon={step.icon}
                title={step.label}
                className={isAciveStep(step.path) ? "active" : ""}
            >
                <SpsWorkflowDocument>
                    <NavLink to={`/rsx/docs/${step.path}`}>{step.pathText}</NavLink>
                </SpsWorkflowDocument>
            </SpsWorkflowStep>
        );
    });

    return (
        <div className={classList}>
            <SpsWorkflow title={title} {...rest}>
                <div className="sps-workflow__steps__wrapper">{workflowStepsList}</div>
            </SpsWorkflow>
        </div>
    );
}

SpsWorkflow.WorkflowNavigation = {
    steps: PropTypes.array.isRequired,
    title: PropTypes.string,
    activeStep: PropTypes.string,
};

export default WorkflowNavigation;
