import React, { useState } from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";

import {
    SpsTable,
    SpsTableHead,
    SpsTableRow,
    SpsTableBody,
    SpsTableCell,
    SpsTableHeader,
} from "@spscommerce/ds-react";

import { SortDirection } from "@spscommerce/ds-shared";

import { uuid } from "../../utils/utils.js";

const TableWrapper = (props) => {
    const data = props.tableData;
    const dataKeys = Object.keys(data[0]);
    const className = props.className || "";
    const wrapColumns = props.wrapColumns || {};

    const [sortedData, setSortedData] = useState(data);

    function sortItems([newSort]) {
        const { key, direction } = newSort;
        sortedData.sort(
            (a, b) =>
                (direction === SortDirection.ASCENDING ? 1 : -1) * (a[key] >= b[key] ? 1 : -1),
        );
        setSortedData([...sortedData]);
    }

    return (
        <SpsTable className={className} onSortChange={sortItems}>
            <SpsTableHead>
                <SpsTableRow>
                    {dataKeys.map((key) => (
                        <SpsTableHeader sortKey={key} key={uuid()}>
                            {key.charAt(0).toUpperCase() + key.slice(1)}
                        </SpsTableHeader>
                    ))}
                </SpsTableRow>
            </SpsTableHead>
            <SpsTableBody>
                {sortedData.map((obj, index) => {
                    return (
                        <SpsTableRow key={index}>
                            {dataKeys.map((key) => (
                                <SpsTableCell
                                    key={uuid()}
                                    wrap={wrapColumns && wrapColumns[key] ? wrapColumns[key] : null}
                                >
                                    {parse(obj[key].toString())}
                                </SpsTableCell>
                            ))}
                        </SpsTableRow>
                    );
                })}
            </SpsTableBody>
        </SpsTable>
    );
};

TableWrapper.propTypes = {
    tableData: PropTypes.array,
    className: PropTypes.string,
    wrapColumns: PropTypes.object,
};

export default TableWrapper;
