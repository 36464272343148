import React from "react";

import PropTypes from "prop-types";

import { SpsFeedbackBlock } from "@spscommerce/ds-react";
import { FeedbackBlockKind } from "@spscommerce/ds-shared";

const TipBlockWrapper = (props) => {
    const { kind, heading, description } = props;

    return (
        <SpsFeedbackBlock kind={kind ? kind : FeedbackBlockKind.TIP}>
            {heading && <span style={{ fontWeight: 600 }}>{heading}</span>}
            {description}
        </SpsFeedbackBlock>
    );
};

TipBlockWrapper.propTypes = {
    kind: PropTypes.string.isRequired,
    heading: PropTypes.string,
    description: PropTypes.string,
};

export default TipBlockWrapper;
