import React, { useState } from "react";
import ReactPlaceholder from "react-placeholder";

import { SpsPageTitle, SpsButton, SpsCard } from "@spscommerce/ds-react";
import { withCommercePlatform } from "@spscommerce/ui-react";
import { ButtonKind, SpsIcon } from "@spscommerce/ds-shared";

const Oracle = function (props) {
    const { url } = props;
    const [loading] = useState(false);
    const [downloading, setDownloading] = useState();

    const [links] = useState([]);

    async function fetchFile(link) {
        if (url) {
            setDownloading(link.name);
            try {
                handleDownload(formatUrl(`${url}Oracle/${link.name}/${link.path}`), link.name);
            } catch (e) {
                console.error(e);
            }
            setDownloading(null);
        }
    }

    function formatUrl(inputString) {
        return inputString.split(" ").join("%20");
    }

    function handleDownload(uri, name) {
        const fileLink = document.createElement("a");
        fileLink.setAttribute("download", name);
        fileLink.href = uri;
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
    }

    return (
        <>
            <div className="col-9 docs-main-content">
                <div className="schema-download__wrapper">
                    <SpsPageTitle className="mb-3">Oracle</SpsPageTitle>
                    <SpsCard className="mb-3">
                        <ReactPlaceholder
                            className="mt-3"
                            showLoadingAnimation
                            ready={!loading}
                            rows={15}
                        >
                            {links.map((link) => {
                                return (
                                    <>
                                        <SpsButton
                                            kind={ButtonKind.LINK}
                                            icon={SpsIcon.DOWNLOAD_CLOUD}
                                            onClick={() => fetchFile(link)}
                                            spinning={downloading === link.name}
                                        >
                                            {link.name}
                                        </SpsButton>
                                        <br />
                                    </>
                                );
                            })}
                        </ReactPlaceholder>
                    </SpsCard>
                </div>
            </div>
        </>
    );
};

export default withCommercePlatform(Oracle);
