import React, { useEffect, useState, Fragment } from "react";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import ReactPlaceholder from "react-placeholder";
import axios from "axios";

import {
    SpsCard,
    SpsButton,
    SpsTextInput,
    SpsListToolbar,
    SpsDescriptionList,
    SpsListToolbarSearch,
    SpsDescriptionListTerm,
    SpsDescriptionListDefinition,
} from "@spscommerce/ds-react";

import { SpsGrowlerFactory, withCommercePlatform } from "@spscommerce/ui-react";

import { MarkdownPlaceHolder } from "../../../components/Placeholders";
import AppConfig from "../../../App.config";
import Footer from "../../../Footer";

const QualifierDetailsPage = function (props) {
    const { environment, token } = props.commercePlatform;
    const { version, documentType, qualifier } = props;

    const [resultList, setResultList] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filterList, setFilterList] = useState([]);

    useEffect(() => {
        getSchema();
    }, []);

    useEffect(() => {
        updateResults();
    }, [resultList, searchTerm]);

    const handleSearchTermChange = (e) => {
        if (!e || !e.target) return null;
        setSearchTerm(e.target.value || "");
    };

    const getSchema = async () => {
        if (environment && token) {
            try {
                const url = `${AppConfig.rsx[environment]}/v1/schema/${version}/${documentType}/fields/`;
                const headers = {
                    Authorization: `Bearer ${token}`,
                };
                const schemaEndPointConfig = {
                    method: "get",
                    headers,
                    url: url,
                };
                axios(schemaEndPointConfig).then(
                    (response) => {
                        const data = response.data.fields;
                        setResultList(_.find(data, ["name", qualifier]).enums);
                    },
                    (error) => {
                        setResultList([]);
                        return;
                    },
                );
            } catch (error) {
                setResultList([]);
                const msg =
                    "An error has occurred while getting qualifier details, please try again later.";
                SpsGrowlerFactory.error(msg, {
                    timeoutDuration: 5000,
                    preset: "error",
                    fade: true,
                });
            }
        }
    };
    const updateResults = () => {
        const searchResults = resultList.filter(
            (obj) =>
                obj.value.toLowerCase().includes(searchTerm.toLowerCase()) ||
                obj.description.toLowerCase().includes(searchTerm.toLowerCase()),
        );
        setFilterList(searchResults);
    };

    const renderFields = () => {
        return filterList.map((obj) => {
            const val = obj.value;
            return (
                <Fragment key={val}>
                    <SpsDescriptionListTerm>{val}</SpsDescriptionListTerm>
                    {_.get(obj, "long_description") ? (
                        <SpsDescriptionListDefinition>
                            <p>{obj.description}</p>
                            {obj.long_description}
                        </SpsDescriptionListDefinition>
                    ) : (
                        <SpsDescriptionListDefinition>
                            {obj.description}
                        </SpsDescriptionListDefinition>
                    )}
                </Fragment>
            );
        });
    };

    return (
        <Fragment>
            <div className="breadcrumb">
                <NavLink to={`/rsx/docs/fields-qualifiers`}>Fields and Qualifiers</NavLink>{" "}
                <i className="sps-icon sps-icon-angle-right section-icon" aria-hidden="true" />{" "}
                <NavLink to={`/rsx/docs/fields-qualifiers/${version}/${documentType}`}>
                    {documentType}
                </NavLink>{" "}
                <i className="sps-icon sps-icon-angle-right section-icon" aria-hidden="true" />{" "}
                <br/>
                {qualifier}
            </div>

            <div className="col-9 docs-main-content fields-qualifiers-page">
                <SpsCard className="sps-docs-markdown">
                    <span className="version-display">Version {version}</span>
                    <div className="rsx-ui-page">
                        <h2>{qualifier}</h2>
                        <SpsListToolbar>
                            <SpsListToolbarSearch>
                                <SpsTextInput
                                    name="search_text"
                                    onChange={handleSearchTermChange}
                                    value={searchTerm}
                                    placeholder="Filter Qualifier List"
                                />
                                <SpsButton type="submit" kind="icon" icon="search" />{" "}
                            </SpsListToolbarSearch>
                        </SpsListToolbar>
                        <ReactPlaceholder
                            customPlaceholder={<MarkdownPlaceHolder />}
                            ready={!!resultList.length}
                        >
                            <SpsDescriptionList wideTerms>{renderFields()}</SpsDescriptionList>
                        </ReactPlaceholder>
                    </div>
                </SpsCard>
                <Footer/>
            </div>
        </Fragment>
    );
};

export default withCommercePlatform(QualifierDetailsPage);
