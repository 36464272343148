import React from "react";
import { SpsI } from "@spscommerce/ds-react";
import { SpsIcon, SpsIconSize } from "@spscommerce/ds-shared";
import "./BannerIEWarning.scss";

function BannerIEWarning() {
    return (
        <div className="notification-banner" >
            <div className="inner-content d-flex justify-content-center align-items-center">
                <SpsI icon={SpsIcon.STATUS_WARNING} size={SpsIconSize.EXTRA_LARGE} />
                <span className="warning">
                    <span className="orange200 font-weight-bold">WARNING!</span>
                    <span className="pl-1">
                        SPS Commerce support for Internet Explorer will end April 2022. This change
                        follows Microsoft's decision to end support of Internet Explorer as of August
                        17, 2021 (
                        <a
                            className="inner-content white font-weight-bold"
                            href="https://blogs.windows.com/windowsexperience/2021/05/19/the-future-of-internet-explorer-on-windows-10-is-in-microsoft-edge/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <u>Microsoft blog post</u>
                        </a>
                        ). To ensure an optimal and secure experience, we encourage you to upgrade to a
                        modern browser by April.
                    </span>
                </span>
            </div>
        </div>
    );
}

export default BannerIEWarning;
