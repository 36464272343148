import React from "react";
import { Route } from "react-router-dom";

import AuthRedirect from "../AuthRedirect";

export default function ProtectedRoute(props) {
    const { auth } = props;

    const renderRoutes = () => {
        const { component: Component, render, ...rest } = props;
        if (render && typeof render === "function") {
            return <Route {...rest} render={render} />;
        }
        if (Component) {
            return (
                <Route
                    {...rest}
                    render={(props) => {
                        return <Component {...props} />;
                    }}
                />
            );
        }
        throw new Error("ProtectedRoute expects either a component or render property");
    };

    return <>{auth && auth.isAuthenticated() ? renderRoutes() : <AuthRedirect auth={auth} />}</>;
}
