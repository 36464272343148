import React from "react";
import { Link } from "react-router-dom";

import { SpsCard } from "@spscommerce/ds-react";

import { getPath } from "@spscommerce/utils";
import "./ToolsNavCard.scss";

function getTools(tools = [], currentUser = {}, environment = "prod") {
    // Apply any needed filtering rules to the array
    // of tools based on the current user and the app environment
    const user = currentUser ? currentUser : {}; // Prevent errors if currentUser is null
    const toolsArray = tools.filter(
        ({ hideInEnvironments = [] }) => !hideInEnvironments.includes(environment),
    );

    // Show all tools to SPS users, otherwise only show tools not marked as internal
    if (
        getPath(user, "organization?.namespace") &&
        (user.organization.namespace === "sps" || user.organization.namespace === "spsc")
    ) {
        return toolsArray;
    } else {
        return toolsArray.filter((tool) => !tool.internal);
    }
}

function ToolsNavCard(props) {
    const { currentUser, environment, tools } = props;
    const filteredTools = getTools(tools, currentUser, environment);

    return (
        <SpsCard headerTitle="DEVELOPMENT TOOLS" className="tools-nav-card">
            {filteredTools.map((tool) => {
                return (
                    <div key={tool.id} className="tool-row">
                        <div className="container-circle">
                            <div className="circle">
                                <i className="circle-icon sps-icon sps-icon-file-text" />
                            </div>
                        </div>
                        <div className="tool-link">
                            <div className="tool-name">
                                <Link to={{ pathname: `/tools/${tool.route}` }}>{tool.label}</Link>
                            </div>
                            <div>
                                <p className="tool-descr">{tool.description}</p>{" "}
                            </div>
                        </div>
                    </div>
                );
            })}
        </SpsCard>
    );
}

export { ToolsNavCard, getTools };
