import React from "react";
import Parser from "html-react-parser";
import { SpsCard } from "@spscommerce/ds-react/";

import "./SwaggerDocumentation.scss";

const SwaggerAboutComponent = function ({ about = "", modifySections }) {
    return (
        <SpsCard className="card-margin">
            <h4>About This Endpoint</h4>
            <p>{about}</p>
            {modifySections.ABOUT && <p>{Parser(modifySections.ABOUT)}</p>}
        </SpsCard>
    );
};

export default SwaggerAboutComponent;
