import _ from "lodash";
import { DateTime } from "luxon";

import AppConfig from "../App.config";

const mostUsed = AppConfig.rsx["documentOrderForNewerVersions"];

export const getWindowHrefWithMaxOneHash = () => {
    const lastHashPosition = window.document.location.href.lastIndexOf("#");
    const firstHashPosition = window.document.location.href.indexOf("#");
    const hashDetected = lastHashPosition > firstHashPosition;
    const modifiedHref = window.location.href.substr(0, lastHashPosition);
    const windowHrefWithMaxOneHash = hashDetected ? modifiedHref : window.location.href;
    return windowHrefWithMaxOneHash;
};

export const stripFileExtension = (filePathWithExtension) => {
    const extensionPosition = filePathWithExtension.lastIndexOf(".");
    let hash;

    if (filePathWithExtension.lastIndexOf("#") > -1) {
        hash = filePathWithExtension.substr(filePathWithExtension.lastIndexOf("#") + 1);
    }
    return {
        hash,
        pathWithoutExtension:
            filePathWithExtension.substr(0, extensionPosition) || filePathWithExtension,
        pathAfterExtension:
            filePathWithExtension.substr(extensionPosition, filePathWithExtension.length) ||
            filePathWithExtension,
    };
};

export const stripLeadingDotSlashes = (urlWithLeadingDotSlashes) => {
    const idx = urlWithLeadingDotSlashes.lastIndexOf("./");
    return urlWithLeadingDotSlashes.substring(idx + 2, urlWithLeadingDotSlashes.length);
};
export const isHash = (url) => {
    return url[0] === "#";
};
export const isExternal = (url) => {
    const match = url.match(
        // eslint-disable-next-line
        /^([^:\/?#]+:)?(?:\/\/([^\/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/,
    );
    if (
        typeof match[1] === "string" &&
        match[1].length > 0 &&
        match[1].toLowerCase() !== window.document.location.protocol
    ) {
        return true;
    }
    if (
        typeof match[2] === "string" &&
        match[2].length > 0 &&
        match[2].replace(
            new RegExp(
                ":(" + { "http:": 80, "https:": 443 }[window.document.location.protocol] + ")?$",
            ),
            "",
        ) !== window.document.location.host
    ) {
        return true;
    }
    return false;
};

export const isInternalLink = (link) => {
    return link.indexOf("/#/") > -1;
};

export const makePath = ({ root, path, defaultPath, appendExtension }) => {
    path = path || defaultPath;
    return root + path + "." + appendExtension;
};

export const stripTrailingSlash = (str) => {
    while (str.substr(-1) === "/") {
        str = str.substr(0, str.length - 1);
    }
    return str;
};

export const uuid = () => {
    return String(("000000" + ((Math.random() * Math.pow(36, 6)) << 0).toString(36)).slice(-6));
};

/**
 * @param date
 * Method take date string as input in format MM/dd/yyyy (ex: "01/30/2020")
 * Returns in format MMMM dd, yyyy (ex: "January 30, 2020")
 */
export const dateParser = (date) => {
    const dateObj = new Date(date);
    return DateTime.local(
        dateObj.getFullYear(),
        dateObj.getMonth() + 1,
        dateObj.getDate(),
    ).toFormat("MMMM dd, yyyy");
};

export const orderRsxDocuments = (rsxVersion, documentsArray) => {
    const rsxObj = AppConfig.rsx;
    const sortArr =
        parseFloat(rsxVersion) >= parseFloat("7.5.0")
            ? rsxObj.documentOrderForNewerVersions
            : rsxObj.documentOrderForOlderVersions;

    documentsArray.sort(function (a, b) {
        return sortArr.indexOf(a) - sortArr.indexOf(b);
    });
    return documentsArray;
};

export const getVersionOfDocs = (schemas = {}) => {
    let obj = {},
        docs = [];
    Object.keys(schemas).map((ver) => (docs = [...docs, ...schemas[ver]]));
    docs = [...new Set([...(docs || [])])];
    docs.sort(function (a, b) {
        return mostUsed.indexOf(a.split(".")[0]) - mostUsed.indexOf(b.split(".")[0]);
    });

    docs.forEach(function (docName) {
        let docVersions = Object.keys(schemas).map((key) => {
            if (schemas[key].includes(docName)) return key;
            return undefined;
        });
        docVersions = docVersions.filter((version) => version !== undefined || null);
        docVersions = docVersions.reverse();
        obj[docName] = { versions: docVersions };
    });
    return obj;
};

function parseCertifiedUnCertified(docs, structure = false) {
    if (!docs) return {};
    const keys = Object.keys(docs);
    const obj = {};
    keys.map((k) => {
        obj[k] = [
            ...new Set([...(docs[k].certified[structure ? "structure_schemas" : "schemas"] || [])]),
        ];
        return k;
    });
    return obj;
}

function parseSips(docs, _structure = false) {
    if (!docs) return {};
    const keys = Object.keys(docs);
    const obj = {};
    keys.map((k) => {
        obj[k] = [...new Set([...(docs[k]["schemas"] || [])])];
        return k;
    });
    return obj;
}

export function groupInternalAssets(docs, structured = false) {
    try {
        const rsx = parseCertifiedUnCertified(docs.rsx, structured);
        const sip = parseSips(docs.sip, structured);
        let rsxObj = {};
        AppConfig.rsx.baseSchemaVersion.map((v) => {
            rsxObj[v] = rsx[v];
            return v;
        });
        const versions = getVersionOfDocs(structured ? rsxObj : rsx);
        const sipVersions = getVersionOfDocs(sip);
        function customizer(objValue, srcValue) {
            if (_.isArray(objValue)) {
                return objValue.concat(srcValue);
            }
        }
        return _.mergeWith(versions, structured ? sipVersions : {}, customizer);
    } catch (e) {
        console.log(e)
        return {};
    }
}

export function getDocPath(service) {
    const systemIntegrationDocs = ["netsuite"];

    const serviceName = () => {
        if (systemIntegrationDocs.includes(service.slug)) {
            return "system-integrations";
        }
        return "docs";
    };

    if (service.id === AppConfig.documentation.authenticationServiceId) {
        return `/${serviceName()}/${service.slug}/getting-an-access-token`;
    }

    return `/${serviceName()}/${service.slug}`;
}
