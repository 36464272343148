import React, { useEffect } from "react";
import { usePatchReducer, SpsButton } from "@spscommerce/ds-react";
import { StoplightProject } from '@stoplight/elements-dev-portal';
import SearchStoplight from "../../components/SearchStoplight";
import "./ServiceStoplight.scss";

const docsBasePath = "stoplight-docs";

export function ServiceStoplight({ serviceSlug, devCenterApi, createGrowler, history }) {
    const [state, patchState] = usePatchReducer({
        services: [],
        stoplightProjectId: "",
        showGrowler: false,
        growlerKind: "error",
        growlerTitle: "",
        growlerMessage: "",
    });

    useEffect(() => {
        getServices();
    }, []);

    useEffect(() => {
        findProjectIdBySlug();
    }, [serviceSlug, state.services]);

    useEffect(() => {
        if (state.growlerTitle !== "") {
            createGrowler({
                title: state.growlerTitle,
                kind: state.growlerKind,
                content: state.growlerMessage,
            });
        }
    }, [state.growlerTitle, createGrowler]);


    function findProjectIdBySlug() {
        if (serviceSlug !== "") {
            const stoplightService = state.services.find(service => service.slug === serviceSlug);
            if (stoplightService) {
                patchState({ stoplightProjectId: stoplightService.stoplightProjectId });
            }
        }
    }

    async function getServices() {
        const response = await devCenterApi.getServices();

        if (response.errorTitle) {
            patchState({
                growlerMessage: response.errorMessage,
                growlerTitle: response.errorTitle,
                showGrowler: true,
                services: [],
            });
        } else {
            patchState({ services: response.services });
        }
    }

    function stoplightServices(services) {
        return services.filter(service => {
            return service.stoplightProjectId && service.stoplightProjectId !== "";
        });
    }

    if (state.stoplightServiceId === "") {
        return <div className="sps-spinner">Loading...</div>;
    }

    return (
        <>
            <div className="stoplight-nav-row">
                <SpsButton
                    className="mb-3"
                    icon="arrow-left-circle"
                    kind="link"
                    onClick={() => history.goBack()}
                >
                    Back
                </SpsButton>
            </div>
            <div className="stoplight-block">
                <SearchStoplight services={stoplightServices(state.services)} />
                <StoplightProject
                    projectId={state.stoplightProjectId}
                    router="hash"
                    basePath={`/${docsBasePath}/${serviceSlug}`}
                />
            </div>
        </>
    )
}

export default ServiceStoplight;
