import "./HomePage.scss";

import React, { useEffect } from "react";

import { usePatchReducer } from "@spscommerce/ds-react";
import { GrowlerKind } from "@spscommerce/ds-shared";

import AppConfig from "../../App.config";
import ColSixCard from "../../components/ColSixCard";
import ConnectionPanel from "../../components/ConnectionPanel/ConnectionPanel";
import ConnectToCard from "../../components/ConnectToCard/ConnectToCard";
import FullWidthCard from "../../components/FullWidthCard";
import { getDocPath } from "../../utils";

export default function HomePage(props) {
    const { devCenterApi, createGrowler } = props;

    const [state, patchState] = usePatchReducer({
        authDocsServicePath: "/",
        showGrowler: false,
    });

    useEffect(() => {
        fetchServices();
    }, []);

    useEffect(() => {
        if (state.showGrowler) {
            createGrowler({
                title: state.growlerTitle,
                kind: GrowlerKind.ERROR,
                content: state.growlerMessage,
            });
        }
    }, [state.showGrowler, createGrowler]);

    async function fetchServices() {
        const response = await devCenterApi.getServices();

        if (response.errorTitle) {
            patchState({
                growlerMessage: response.errorMessage,
                growlerTitle: response.errorTitle,
                showGrowler: true,
            });
        } else {
            const services = response.services;
            const authDocsService =
                services.filter(
                    (service) => service.id === AppConfig.documentation.authenticationServiceId,
                )[0] || {};
            const authDocsServicePath = getDocPath(authDocsService);

            patchState({ authDocsServicePath: authDocsServicePath });
        }
    }

    return (
        <div className="row home-page-container collapse">
            <h2 className="col-12 home-page__heading">Welcome to Dev Center</h2>
            <section className="col-12 home-page-content__main">
                <div className="row">
                    <div className="col-6">
                        <ConnectToCard heading="Connect to API Services">
                            <ConnectionPanel
                                title="Build Applications"
                                linkText="Get Started With API Services"
                                to="docs/getting-started"
                                img="assets/buildapps.svg"
                            />
                            <ConnectionPanel
                                title="API Services"
                                to="docs/shipping-doc-api"
                                linkText="Explore API Services"
                                img="assets/apiservices.svg"
                            />
                        </ConnectToCard>
                    </div>
                    <div className="col-6">
                        <ConnectToCard heading="Connect your Network to RSX">
                            <ConnectionPanel
                                title="Connection Tools"
                                linkText="Connecting with RSX"
                                to="rsx/docs/rsx-overview"
                                img="assets/connectiontools.svg"
                            />
                            <ConnectionPanel
                                title="Schema, Fields, and Qualifiers"
                                to="rsx/docs/schema-download"
                                linkText="Explore RSX Schema"
                                img="assets/schema.svg"
                            />
                        </ConnectToCard>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <FullWidthCard
                            heading="API Services"
                            body={`SPS Commerce Dev Center API Services leverage the power of our retail network
                      with easy to build connections through an OAuth model. Whatever your business
                      solution, Dev Center allows you to easily get connected to our vast and growing
                      retail network.`}
                        >
                            <ColSixCard
                                icon="sps-icon-rocket"
                                iconColor="blue300"
                                title="Building Applications"
                                description="Build an app that identifies and enables a connection to Dev Center API services."
                                links={[
                                    {
                                        link: "/docs/getting-started",
                                        description: "Get Started",
                                    },
                                    {
                                        link: state.authDocsServicePath,
                                        description: "Authentication Info",
                                    },
                                    {
                                        link: "/applications",
                                        description: "My Apps",
                                    },
                                ]}
                            />
                            <ColSixCard
                                icon="sps-icon-truck-shipping"
                                iconColor="green200"
                                title="Shipping Label & Packing Slip Service"
                                description="Build an app that identifies and enables a connection to Dev Center API services."
                                links={[
                                    {
                                        link: "/docs/shipping-doc-api",
                                        description: "API Service Docs",
                                    },
                                    {
                                        link: "/tools/shipping-doc-browser",
                                        description: "Shipping Label & Packing Slip Browser",
                                    },
                                ]}
                            />
                        </FullWidthCard>
                    </div>
                    <div className="col-12">
                        <FullWidthCard
                            heading={() => (
                                <>
                                    <span className="mr-1">RSX Integration Tools</span>
                                </>
                            )}
                            body={`SPS Commerce Universal Network utilizes Retail Standard XML (RSX) as a comprehensive data interchange format.
                       By utilizing RSX, simply connect once to SPS Commerce and then access an infinite number of retail trading partners. `}
                        >
                            <ColSixCard
                                icon="sps-icon-handshake"
                                iconColor="orange200"
                                title="RSX Connections"
                                description="View our comprehensive guide on understanding SPS Commerce best practices for mapping to the SPS Commerce Network"
                                links={[
                                    {
                                        link: "/rsx/docs/rsx-overview",
                                        description: "RSX Overview",
                                    },
                                    {
                                        link: "/rsx/docs/schema-download",
                                        description: "Schema Downloads",
                                    },
                                    {
                                        link: "/rsx/docs/fields-qualifiers",
                                        description: "Fields and Qualifiers",
                                    },
                                ]}
                            />
                        </FullWidthCard>
                    </div>
                </div>
            </section>
        </div>
    );
}
